import React from 'react'
import ProviderInclude from '../../assets/icons/iconProviderInclude.png'
import styles from './styles.module.scss'


export function ProviderIncludeIsActive({ name, isActive }) {
  return (
    isActive === true ?
      <div className={styles.providerInclude}>
        <img style={{ minWidth: '21,82px', minHeight: '22px' }} src={ProviderInclude} alt="Include" />
        <p>{name}</p>
      </div>
      :
      <div className={styles.providerIncludeFalse}>
        <img style={{ minWidth: '21,82px', minHeight: '22px' }} src={ProviderInclude} alt="Include" />
        <p>{name}</p>
      </div>

  )
}