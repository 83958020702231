import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import EscopoAvaliacaoService from "../../../../services/EscopoAvaliacaoService";
import { toast } from "react-toastify";
import { inherits } from "util";

export default function EscopoAvaliacaoForm({
  token,
  send,
  currentEscopoAvaliacao,
}) {
  const [loading, setLoading] = useState(false);
  const [escopoAvaliacao, setEscopoAvaliacao] = useState({
    nome: "",
    aprovado: 0,
    aprovadoComRestricao: 0,
  });

  const handleChange = (event) => {
    event.persist();
    setEscopoAvaliacao((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (currentEscopoAvaliacao && currentEscopoAvaliacao.id > 0) {
      return handleUpdate();
    }
    handleRegister();
  };

  const handleRegister = () => {
    setLoading(true);
    escopoAvaliacao.aprovado = parseFloat(escopoAvaliacao.aprovado);
    escopoAvaliacao.aprovadoComRestricao = parseFloat(escopoAvaliacao.aprovadoComRestricao);
    EscopoAvaliacaoService.registerEscopoAvaliacao(token, escopoAvaliacao)
      .then((response) => {
        if (response) {
          setLoading(false);
        }
        setLoading(false);
        setEscopoAvaliacao({
          nome: "",
          aprovado: 0,
          aprovadoComRestricao: 0,
        });
        send(response);
      })
      .catch((reason) => {
        let response = reason.response || {};
        toast.error(response.data || "Erro ao cadastrar item!");
        setLoading(false);
      });
  };

  const handleUpdate = () => {
    setLoading(true);
    escopoAvaliacao.aprovado = parseFloat(escopoAvaliacao.aprovado);
    escopoAvaliacao.aprovadoComRestricao = parseFloat(escopoAvaliacao.aprovadoComRestricao);
    EscopoAvaliacaoService.updateEscopoAvaliacao(token, escopoAvaliacao)
      .then((response) => {
        if (response) {
          setLoading(false);
        }
        setLoading(false);
        setEscopoAvaliacao({
          nome: "",
          aprovado: 0,
          aprovadoComRestricao: 0,
        });
        send(response);
      })
      .catch((reason) => {
        let response = reason.response || {};
        toast.error(response.data || "Erro ao cadastrar item!");
        setLoading(false);
      });
  };

  const validaValorMinimo = (event) => {
    event.persist();
    if (event.target.value.trim(event.target.value) === "") {
      setEscopoAvaliacao((values) => ({
        ...values,
        [event.target.name]: 0,
      }));
      return;
    }
  }

  useEffect(() => {
    if (currentEscopoAvaliacao) {
      setEscopoAvaliacao(currentEscopoAvaliacao);
    }
  }, currentEscopoAvaliacao);
  const history = useHistory();

  return (
    <>
      <div className="user-config-container">
        <div className="card-container">
          <div className="card">
            <div className="card-header">
              <h1>CRIAR NOVO ESCOPO</h1>
            </div>
            <div className="card-body">
              <form className="form-container" onSubmit={handleSubmit}>
                <div className="group-input escopo-avaliacao created-form">
                  <div className="content-input" >
                    <label className="escopo-avaliacao">Nome</label>
                    <input
                      className="global-input"
                      type="text"
                      id="nome"
                      placeholder=""
                      name="nome"
                      style={{ width: "100%" }}
                      value={escopoAvaliacao.nome}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="content-input" >

                    <label>Aprovado</label>
                    <input
                      className="global-input"
                      type="number"
                      style={{ width: "60px" }}
                      id="aprovado"
                      placeholder=""
                      name="aprovado"
                      value={escopoAvaliacao.aprovado}
                      onChange={handleChange}
                      onBlur={validaValorMinimo}
                    />
                  </div>
                  <div className="content-input" >

                    <label>Aprovado com Restrição </label>
                    <input
                      className="global-input"
                      type="number"
                      id="aprovadoComRestricao"
                      style={{ width: "60px" }}
                      placeholder=""
                      name="aprovadoComRestricao"
                      value={escopoAvaliacao.aprovadoComRestricao}
                      onChange={handleChange}
                      onBlur={validaValorMinimo}
                    />
                  </div>
                  <button className="global-btn">
                    {currentEscopoAvaliacao && currentEscopoAvaliacao.id > 0
                      ? !loading && "SALVAR"
                      : !loading && "CRIAR"}

                    {loading && <FontAwesomeIcon icon={faCircleNotch} pulse />}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
