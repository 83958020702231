import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  DOCUMENT_LICENCE: BASE_URL + "ProvedorDocumentoLicenca",
};

export default class ProvedorDocumentoLicencaService {
  static registerDocumentLicence = async (token, params, formData) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let body = params;

    try {
      const { data } = await axios.post(URLS.DOCUMENT_LICENCE, body, config);
      const provedorDocumentoLicencaId = data

      if (provedorDocumentoLicencaId) {
        let config = {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        };

        let url = `${URLS.DOCUMENT_LICENCE}/Upload?provedorDocumentoLicencaId=${provedorDocumentoLicencaId}`
        const { data } = await axios.post(url, formData, config);

        return data;
      }

    } catch (err) {
      return err;
    }
  };

  static getDocumentLicences = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.DOCUMENT_LICENCE

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static updateDocumentLicences = async (token, params, editarArquivos, formData) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let body = params;
    let url = URLS.DOCUMENT_LICENCE
    try {
      const { data } = await axios.put(url, body, config);
      if (editarArquivos) {
        const provedorDocumentoLicencaId = data

        if (provedorDocumentoLicencaId) {
          let config = {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${token}`,
            },
          };

          let url = `${URLS.DOCUMENT_LICENCE}/Upload?provedorDocumentoLicencaId=${provedorDocumentoLicencaId}`
          const { data } = await axios.post(url, formData, config);
        }
      }

      return data;
    } catch (err) {
      return err;
    }
  }

  static deleteDocumentLicence = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.DOCUMENT_LICENCE + "/" + id

    try {
      const { data } = await axios.delete(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static download = async (token, id, isDocAssociado = false) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        responseType: 'blob',
      },
    };

    let url = `${URLS.DOCUMENT_LICENCE}/Download?id=${id}&isDocAssociado=${isDocAssociado}`

    try {
      const blob = await fetch(url, config)
        .then(response => response.blob())
        .then(blob => blob)
      return blob
    } catch (err) {
      return err;
    }
  };
}