import React, { useState } from "react";
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { formataCnpj } from "../../../components/Common/FormataCamposComMascara";

export default function UserForm({
  initialValues,
  title,
  send,
  error,
  success,
  loading,
  alert,
}) {
  const [emailVerification, setEmailVerification] = useState(false);
  const [passwordVerification, setPasswordVerification] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [values, setValues] = useState(initialValues);

  const handleChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.name === "cnpj" ? formataCnpj(event.target.value) : event.target.value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    setEmailVerification(false);
    setPasswordVerification(false);
    setPasswordValidation(false);

    let emailVerification = new RegExp(
      /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
    ).test(values.email);

    if (emailVerification === false) {
      setEmailVerification(true);
    }

    let passwordValidation = new RegExp(
      /^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{8,}$/gm
    ).test(values.password);

    if (passwordValidation === false) {
      setPasswordValidation(true);
    }

    if (values.password !== values.passwordConfirm) {
      setPasswordVerification(true);
    }

    send(values);
  };

  return (
    <div className="buyer-container">
      {error && <span className="error-list">{error && error}</span>}
      {success && <span className="success-msg">Salvo com sucesso.</span>}
      <form className="form-container-two-column" onSubmit={handleSubmit}>
        <div className="header-container">
          <div className="public-app-item">
            <h1>{title}</h1>
          </div>
          <div className="public-app-item item-rigth">
            <p>
              {" "}
              ou <Link to="/login">login</Link>{" "}
            </p>
          </div>
        </div>

        <div className="group-input">
          <select
            className="global-input"
            type="text"
            id="profile"
            placeholder="Perfil"
            name="profile"
            onChange={handleChange}
          >
            <option value="">Selecione o seu Perfil</option>
            <option value={2}>{"Comprador"}</option>
            <option value={4}>{"Provedor"}</option>

          </select>
          {submitted && !values.profile && (
            <span className="error-msg">Esse campo é necessario</span>
          )}
        </div>
        <div className="group-input">
          <input
            placeholder="Nome da empresa *"
            className={`global-input ${submitted && !values.companyName ? "error" : ""
              }`}
            type="text"
            id="companyName"
            name="companyName"
            value={values.companyName}
            onChange={handleChange}
          />
          {submitted && !values.companyName && (
            <span className="error-msg">Esse campo é necessario</span>
          )}
        </div>
        <div className="group-input">
          <input
            placeholder="CNPJ"
            className={`global-input ${submitted && !values.cnpj ? "error" : ""
              }`}
            id="cnpj"
            name="cnpj"
            value={values.cnpj}
            onChange={handleChange}
          />
          {submitted && !values.cnpj && (
            <span className="error-msg">Esse campo é necessario</span>
          )}
        </div>
        <div className="group-input">
          <input
            placeholder="Nome do usuario *"
            className={`global-input ${submitted && !values.name ? "error" : ""
              }`}
            id="name"
            name="name"
            value={values.name}
            onChange={handleChange}
          />
          {submitted && !values.name && (
            <span className="error-msg">Esse campo é necessario</span>
          )}
        </div>
        <div className="group-input">
          <input
            placeholder="Email *"
            className={`global-input ${submitted && !values.email ? "error" : ""
              }`}
            id="email"
            name="email"
            value={values.email}
            onChange={handleChange}
          />
          {submitted && !values.email && (
            <span className="error-msg">Esse campo é necessario</span>
          )}
          {emailVerification && (
            <span className="error-msg">Insira um e-mail válido</span>
          )}
        </div>
        <div className="group-input">
          <input
            type="password"
            placeholder="Senha *"
            className={`global-input ${submitted && !values.password ? "error" : ""
              }`}
            id="password"
            name="password"
            value={values.password}
            onChange={handleChange}
          />
          {submitted && !values.password && (
            <span className="error-msg">Esse campo é necessario</span>
          )}
        </div>
        <div className="group-input">
          <input
            type="password"
            placeholder="Confirmar senha *"
            className={`global-input ${submitted && !values.passwordConfirm ? "error" : ""
              }`}
            id="passwordConfirm"
            name="passwordConfirm"
            value={values.passwordConfirm}
            onChange={handleChange}
          />
          {submitted && !values.passwordConfirm && (
            <span className="error-msg">Esse campo é necessario</span>
          )}

          {passwordVerification && (
            <span className="error-msg">Os campos devem ser iguais</span>
          )}

          {!success  && (
            <span className="error-msg">{alert}</span>
          )}
        </div>
        <div className="footer-form-container">
          <div className="footer-form-item">
            <div className="group-input-checkbox">
              <input type="checkbox" id="conected" className="global-input" />
              <label className="checkbox-label" htmlFor="conected">
                Lembrar meus dados
              </label>
            </div>
            <div className="link-container">
              <Link to="/recuperar-senha">Esqueceu sua senha?</Link>
            </div>
          </div>
          <div className="footer-form-item">
            <button className="global-btn">
              Criar conta{" "}
              {loading && <FontAwesomeIcon icon={faCircleNotch} pulse />}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
