import React, { useState, memo } from "react";

import './styles.scss'

import Icon1 from '../../../../../assets/icons/iconeResultados.svg'
import Icon2 from '../../../../../assets/icons/iconeIdoneidadeIntegridade.svg'
import Icon3 from '../../../../../assets/icons/iconeOrganismoCertificador.svg'
import Icon4 from '../../../../../assets/icons/iconeMateriaisServicos.png'
import Icon5 from '../../../../../assets/icons/iconeCriticidadeAvaliacao.svg'
import Icon6 from '../../../../../assets/icons/iconeTrak.svg'

function Nav({ handleModule }) {
    const [active, setActive] = useState(1);

    return (
        <div className="nav-wrap">
            <button className={active === 1 ? 'active' : ''}
                onClick={() => {
                    handleModule('resultados')
                    setActive(1)
                }}
            >
                <div>
                    <img src={Icon1} />
                    <h2>Resultados</h2>
                </div>
            </button>
            <button className={active === 2 ? 'active' : ''}
                onClick={() => {
                    handleModule('idoneidade-e-integridade')
                    setActive(2)
                }}
            >
                <div>
                    <img src={Icon2} />
                    <h2>Idoneidade e Integridade</h2>
                </div>
            </button>
            <button className={active === 3 ? 'active' : ''}
                onClick={() => {
                    handleModule('documentos-e-Licencas')
                    setActive(3)
                }}
            >
                <div>
                    <img src={Icon3} />
                    <h2>Documentos e Licenças</h2>
                </div>
            </button>
            <button className={active === 4 ? 'active' : ''}
                onClick={() => {
                    handleModule('produtos-homologados')
                    setActive(4)
                }}
            >
                <div>
                    <img src={Icon4} />
                    <h2>Produtos Homologados</h2>
                </div>
            </button>
            <button className={active === 5 ? 'active' : ''}
                onClick={() => {
                    handleModule('historico-de-Notificacao')
                    setActive(5)
                }}
            >
                <div>
                    <img src={Icon5} />
                    <h2>Histórico de Notificação</h2>
                </div>
            </button>
            <button className={active === 6 ? 'active' : ''}
                onClick={() => {
                    handleModule('historico-de-recebimento')
                    setActive(6)
                }}
            >
                <div>
                    <img src={Icon6} />
                    <h2>Histórico de Recebimento</h2>
                </div>
            </button>
        </div>
    )
}

export default memo(Nav)