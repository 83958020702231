import React, { useState, useEffect } from "react";

import "./cardProvedores.scss"
import { useHistory } from "react-router-dom";
import ProvedorChart from "./provedores-chart"
import infoIcon from "../../../../../assets/icons/info.svg";
import lupaIcon from "../../../../../assets/icons/lupaVazia.svg";
import carrinhoIcon from "../../../../../assets/icons/carrinho.svg";
import addIcon from "../../../../../assets/icons/WhiteSubtract.svg";
import provedoresIcon from "../../../../../assets/icons/provedores-icon.svg";
import ProvedoresService from "../../../../../services/ProvedoresService";

export default function CardProvedores(props) {
  const { token } = props.state;

  const history = useHistory();

  const navigate = (url) => history.push(url)

  const [provedoresCount, setProvedoresCount] = useState({
    homologados: 0,
    emHomologacao: 0,
    naoHomologados: 0,
    total: 0,
  });

  useEffect(() => {
    _init();
  }, []);

  const _init = async () => {
    const provedoresCount = await ProvedoresService.getCountHomologacoes(token);
    setProvedoresCount(provedoresCount);
  }

  return (
    <>
      <div className="d-flex align-items-center provedores-custom" >
        <div className="card-provedores">
          <div className="row row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-2 h-100 justify-content-between">

            <div className="card-provedores-body d-flex flex-column justify-content-between align-items-center">

              <div className="provedores-title text-center">
                <img src={provedoresIcon} />
                <span>
                  Provedores
                </span>
              </div>

              <div className="provedores-search w-100">
                <button className="w-100" onClick={() => navigate('/provedores/relacao-provedores')}>
                  <img src={lupaIcon} />
                  <span>
                    Busque seus provedores
                  </span>
                </button>
              </div>

              <div className="incluir-provedor w-100">
                <button className="w-100" onClick={() => navigate('/provedores/incluir-aprovedor')}>
                  <img src={addIcon} />
                  <span>
                    Incluir um novo provedor
                  </span>
                </button>
              </div>

              <div className="oportunidades d-flex w-100">
                <div className="title d-flex align-items-center cursor-pointer">
                  {/* TO DO Navegar para Oportunidades de Homologação */}
                  <img src={infoIcon} />
                  <a
                    href="/oportunidade-de-homologacao">
                      Oportunidade de homologação
                  </a>
                </div>
                <div className="count">
                  <div className="number">
                    {/* TO DO Count Oportunidades de Homologação */}
                    16
                  </div>
                </div>
              </div>

              <div className="situacao d-flex  w-100">
                <div className="title d-flex align-items-center cursor-pointer" onClick={() => navigate('/provedores/relacao-provedores')}>
                  <img src={infoIcon} />
                  Avaliações com pendência
                </div>
                <div className="count">
                  <div className="number">
                    {/* TO DO Count Avaliações com pendência */}
                    9
                  </div>
                </div>
              </div>

            </div>

            <div className="card-provedores-body d-flex flex-column justify-content-between">

              <div className="d-flex w-100 justify-content-between align-items-center">
                <img src={carrinhoIcon} />
                <span className="numero-provedor">
                  Número de provedor
                </span>
                <span className="blue">
                  {provedoresCount.total}
                </span>
              </div>

              <div className="line"></div>

              <div className="text-center mt-4">
                <div className="provedores-chart-label">
                  {(provedoresCount.homologados / provedoresCount.total * 100 || 0).toFixed()}%
                </div>
                <div className="d-flex justify-content-center">
                  <ProvedorChart provedoresCount={provedoresCount} />
                </div>
              </div>

              <div className="provedores-data d-flex flex-row w-100 justify-content-between">

                <div className="d-flex flex-column justify-content-between">
                  <span className="homologados-label">
                    Provedores homologados
                  </span>
                  <span className="homologados-label">
                    Provedores em homologação
                  </span>
                  <span className="homologados-label">
                    Provedores não homologados
                  </span>
                </div>

                <div className="d-flex flex-column justify-content-between">
                  <span className="provedor-count green">{provedoresCount.homologados}</span>
                  <span className="provedor-count warning">{provedoresCount.emHomologacao}</span>
                  <span className="provedor-count red">{provedoresCount.naoHomologados}</span>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
