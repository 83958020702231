import React from "react";
// import "./assets/scss/styles.scss";
// import "./App.css";
import { connect } from "react-redux";
import PublicAppContainer from "./public-app/PublicAppContainer";
import PrivateAppContainer from "./private-app/PrivateAppContainer";

const App = (props) => {
  const WrapperComponent = props.isAuthenticated
    ? PrivateAppContainer
    : PublicAppContainer;
  return <WrapperComponent>{props.children}</WrapperComponent>;
};

const mapStateToProps = (state) => ({
  isAuthenticated: state.isAuthenticated,
});

export default connect(mapStateToProps)(App);
