import React, { useEffect, useState } from "react";

import ChartResult from "./ChartResult";
import ChartPerformance from './ChartPerformance'
import QuestionTheme from '../../../../escopoAvaliacao/questionTheme'
import { IF } from '../../../../../components/Condition/Condition'
import ProvedoresService from "../../../../../services/ProvedoresService";

import './styles.scss'

export default function Resultados({ token, createQuestion, provedorId }) {
    const [isCreateQuestion, setIscreateQuestion] = useState(false)
    const [resultadosEscopo, setresultadosEscopo] = useState(false)

    const [dataSearch, setDataSearch] = useState({
        de: "",
        ate: "",
        indice: ''
    });

    const _init = async () => {
        const response = await ProvedoresService.getResultadosEscopo(token, provedorId);
        setresultadosEscopo(response);
    }

    useEffect(() => {
        _init()
    }, [])

    const handleChangeData = (event) => {
        event.persist();

        const dateMask = () => {
            var data = event.target.value;

            if (data.length === 2) {
                return event.nativeEvent.inputType === 'deleteContentBackward'
                    ? data
                    : data = data + '/';
            }
            if (data.length === 5) {
                return event.nativeEvent.inputType === 'deleteContentBackward'
                    ? data
                    : data = data + '/';
            }

            return event.target.value;
        }

        if (event.target.id === 'data-de') {
            setDataSearch(values => ({
                ...values,
                de: dateMask(),
            }));
        }

        if (event.target.id === 'data-ate') {
            setDataSearch(values => ({
                ...values,
                ate: dateMask(),
            }));
        }

        const percentMask = () => {
            if (event.target.id != 'data-indice') return dataSearch.indice

            const currentValue = event.target.value.replace('%', '').replace(',', '.')
            if (isNaN(Number(currentValue))) return

            return event.nativeEvent.inputType === 'deleteContentBackward'
                ? currentValue
                : currentValue + '%'
        }

        if (event.target.id === 'data-indice') {
            setDataSearch(values => ({
                ...values,
                indice: percentMask(),
            }));
        }
    };

    return (
        <div className="resultados-container">
            <div className="card-container">
                <div className="card subcard">
                    <div className="card-header">
                        <h1>RESULTADO</h1>

                        <div className="validate-data-wrap">
                            <b>Validar dados apresentados</b>

                            <div>
                                <button
                                    className="global-btn baby-blue"
                                >
                                    Sim
                                </button>

                                <button
                                    className="global-btn baby-blue"
                                >
                                    Não
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="list-container">
                            <div className="list">
                                <div className="list-header">
                                    <label style={{ maxWidth: "46%"}}>Escopo</label>
                                    <label className="space-list" style={{ maxWidth: "48%", marginRight:5}}>Resultado</label>
                                    <label style={{ flexGrow: 0}}>Ver</label>
                                </div>
                                {(resultadosEscopo || []).map((data, i) => (
                                    <div className="list-body" key={i}>
                                        <span className="list-item">{data.nomeEscopo}</span>
                                        <span className="list-item space-list">{data.resultado}</span>
                                        <span className="list-item document-list" style={{ flexGrow: 0 }}>
                                            <a className="list-button" onClick={() => {
                                                setIscreateQuestion(!isCreateQuestion)
                                                createQuestion(isCreateQuestion)
                                            }}>
                                                <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5 0.5C2.23858 0.5 0 2.73858 0 5.5V19.5C0 22.2614 2.23858 24.5 5 24.5H19C21.7614 24.5 24 22.2614 24 19.5V5.5C24 2.73858 21.7614 0.5 19 0.5H5ZM5.59868 19.5H18.3553C18.6776 19.5 19 19.0922 19 18.6165C19 18.1408 18.7237 17.733 18.4013 17.733H5.59868C5.27632 17.733 5 18.1408 5 18.6165C5 19.0922 5.27632 19.5 5.59868 19.5ZM5.59868 15.4223H18.3553C18.6776 15.4223 19 15.0146 19 14.5388C19 14.0631 18.7237 13.6553 18.4013 13.6553H5.59868C5.27632 13.6553 5 14.0631 5 14.5388C5 15.0146 5.27632 15.4223 5.59868 15.4223ZM5.59868 11.3447H18.3553C18.6776 11.3447 19 10.9369 19 10.4612C19 9.98544 18.7237 9.57767 18.4013 9.57767H5.59868C5.27632 9.57767 5 9.98544 5 10.4612C5 10.9369 5.27632 11.3447 5.59868 11.3447ZM5.59868 7.26699H18.3553C18.6776 7.26699 19 6.85922 19 6.38349C19 5.90777 18.7237 5.5 18.4013 5.5H5.59868C5.27632 5.5 5 5.90777 5 6.38349C5 6.85922 5.27632 7.26699 5.59868 7.26699Z" fill="#4B4B4B" />
                                                </svg>
                                            </a>
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <IF condition={isCreateQuestion}>
                <QuestionTheme token={token} escopo={[]} handleEscopo={() => { }} comprador={true} />

                <div className={`row mx-0`}>
                    <div className="col-12 px-0 justify-content-between">
                        <button
                            type="button"
                            className="global-btn w-auto"
                            onClick={() => {
                                setIscreateQuestion(false)
                                createQuestion(isCreateQuestion)
                            }}
                        >
                            <svg className="mr-1 back-icon" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.47211 5.05021L5.10332 9.09061L4.31819 9.69667L0 5.05021L4.31819 0.302734L5.00518 1.00981L1.47211 5.05021ZM9.71593 1.00981L9.02894 0.302734L4.71075 5.05021L9.02894 9.69667L9.71593 8.9896L6.18286 5.05021L9.71593 1.00981Z" fill="white" />
                            </svg>
                            VOLTAR
                        </button>
                    </div>
                </div>

            </IF>

            <IF condition={!isCreateQuestion}>
                <br></br>

                <div className="card-container">
                    <div className="card subcard">
                        <div className="card-header">
                            <h1>AVALIAÇÃO DE DESEMPENHO</h1>
                        </div>

                        <div className="filter-wrap">
                            <div id="title-filter">
                                <b>Filtrar avaliações por periodo</b>
                            </div>

                            <div id="input-filter-wrap">
                                <div className="input-card" id="input-01">
                                    <label htmlFor="data-de">de</label>
                                    <input
                                        className="global-input"
                                        type="text"
                                        name="data-de"
                                        id="data-de"
                                        placeholder="--/--/----"
                                        maxLength="10"
                                        value={dataSearch.de}
                                        onChange={handleChangeData}
                                    />
                                </div>
                                <div className="input-card" id="input-02">
                                    <label htmlFor="data-ate">até</label>
                                    <input
                                        className="global-input"
                                        type="text"
                                        name="data-ate"
                                        id="data-ate"
                                        placeholder="--/--/----"
                                        maxLength="10"
                                        value={dataSearch.ate}
                                        onChange={handleChangeData}
                                    />
                                </div>
                                <div className="input-card" id="input-03">
                                    <label htmlFor="data-indice">Indice</label>
                                    <input
                                        className="global-input"
                                        type="text"
                                        name="data-indice"
                                        id="data-indice"
                                        value={dataSearch.indice}
                                        onChange={handleChangeData}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="list-container">
                                <div className="list">
                                    <div className="list-header">
                                        <label>Últimas Atualizações</label>
                                        <label className="space-list">Resultado</label>
                                        <label style={{ flexGrow: 0.06 }}></label>
                                    </div>
                                    {[...Array(4)].map((data, i) => (
                                        <div className="list-body" key={i}>
                                            <span className="list-item">{"02/02/2021"}</span>
                                            <span className="list-item space-list">{"85,00%"}</span>
                                            <span className="list-item document-list" style={{ flexGrow: 0 }}></span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="chart-container">
                    <ChartResult />
                    <ChartPerformance />
                </div>
            </IF>
        </div>
    )
}