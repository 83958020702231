import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import Form from "./Form/documentLicenceForm";
import { ListAdmin, ListUser } from "./List/documentLicenceList";
import DocumentLicenceService from "../../services/DocumentLicenceService";
import Modal from "../../components/ModalExit";

import "./style.scss";

export default function Index(props) {
  const { token } = props.state;
  const [loading, setLoading] = useState(false);
  const [deleteID, setDeleteID] = useState(0);

  const [current, setCurrent] = useState({});
  const [listDefault, setListDefault] = useState([]);
  const [listUser, setListUser] = useState([]);

  const get = async () => {
    setCurrent({});

    DocumentLicenceService.get(token)
      .then((response) => {
        if (response) {
          if (response.default.valores.length > 0)
            setListDefault(response.default.valores);

          if (response.user.valores.length > 0)
            setListUser(response.user.valores);
          else
            setListUser([]);
          
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  const create = (payload) => {
    if(!payload.nome) return;
    if (listUser.some((currentValue) => {return currentValue.nome === payload.nome})){
      toast.error("Este documento já está cadastrado para este comprador e não pode ser incluído novamente!");
      return;
    }
    DocumentLicenceService.create(token, payload.nome)
      .then((response) => {
        if (response) {
          toast.success("Item cadastrado com sucesso!");
          get();
        }
      })
      .catch((error) => {
        toast.error("Erro ao cadastrar item!");
      });
  }

  const remove = async (id) => {
    setDeleteID(0);

    const response = await DocumentLicenceService.delete(token, id)

    if (response) {
      if (response.isAxiosError) {
        toast.error(response.response.data || "Erro inesperado ao excluir item!");
        return
      }
      toast.success("Item excluído com sucesso!");
      get();
    } else {
      toast.error("Erro inesperado ao excluir item!");
      setLoading(false);
    }
  }

  useEffect(() => {
    setLoading(true);
    get();
  }, []);

  return (
    <div className="dashboard-container documentLicense-container">
      {deleteID > 0 && (
        <Modal handleDelete={() => remove(deleteID)} handleModal={() => { setDeleteID(0) }} />
      )}
      {loading && (
        <div className={"loading-mask"}>
          <FontAwesomeIcon icon={faCircleNotch} pulse />
        </div>
      )}
      <Form
        token={token}
        current={current}
        callback={get}
      />
      <div className="row mt-2">
        <div className="col-md-6">
          <ListAdmin
            token={token}
            list={listDefault}
            include={create}
          />
        </div>
        <div className="col-md-6">
          <ListUser
            token={token}
            loading={loading}
            list={listUser}
            remove={setDeleteID}
            edit={setCurrent}
          />
        </div>
      </div>
    </div>
  );
}