import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import IndicadorDesempenho from "../../../services/IndicadorDesempenho";
import { toast } from "react-toastify";
import DropList from "../../../components/DropList/DropList"

export default function IndicadoresDesempenhoForm({
  token,
  currentIndicadoresDesempenho,
  currentWeight,
  padroesDesempenho,
  getPadraoSelected,
  currentData,
  callback,
  resetCurrentData,
}) {

  const defaultValue = {
    nome: "",
    padraoDesempenho: null,
    peso: '',
  }

  const [loading, setLoading] = useState(false);
  const [dropListSelect, setDropListSelect] = useState(null)

  const [IndicadoresDesempenho, setIndicadoresDesempenho] = useState(defaultValue)

  const weightLimit = 100;
  const [weight, setWeight] = useState(currentWeight);
  const [availableWeight, setAvailableWeight] = useState(null);
  const [weightLimitReached, setWeightLimitReached] = useState(false);

  useEffect(() => {

    const defaultPadrao = (currentIndicadoresDesempenho.length > 0) && currentIndicadoresDesempenho[0].padraoDesempenho

    if (!padraoSelected && defaultPadrao) {
      getPadraoSelected(defaultPadrao, currentIndicadoresDesempenho)
      setPadraoSelected(defaultPadrao)
    }

  }, [currentWeight, padroesDesempenho, currentIndicadoresDesempenho])

  useEffect(() => {    
    if(padraoSelected) {
      getPadraoSelected(padraoSelected, currentIndicadoresDesempenho)
    }

    onRegister(currentWeight)
    // eslint-disable-next-line
  }, [currentWeight, currentIndicadoresDesempenho]);

  // Ao deletar/editar
  useEffect(() => {

    // Deletar
    if (currentData) {
      setPadraoSelected(currentData.padraoDesempenho)
      getPadraoSelected(currentData.padraoDesempenho, currentIndicadoresDesempenho)
      // Editar
      if (!currentData.delete) {
        setIndicadoresDesempenho({
          ...currentData,
          peso: currentData.peso + '%'
        })
      }
    }
  }, [currentData])


  useEffect(() => {
    if (weight >= weightLimit)
      setWeightLimitReached(true)
    else
      setWeightLimitReached(false)
    // eslint-disable-next-line
  }, [weight, IndicadoresDesempenho, loading]);

  const handleChange = (event) => {
    event.persist();

    const weightMask = () => {
      if (event.target.name != 'peso') return IndicadoresDesempenho.peso

      const currentValue = event.target.value.replace('%', '').replace(',', '.')
      if (isNaN(Number(currentValue))) return

      return event.nativeEvent.inputType === 'deleteContentBackward'
        ? currentValue
        : currentValue + '%'
    }

    setIndicadoresDesempenho((values) => ({
      ...values,
      [event.target.name]: event.target.value,
      peso: weightMask()
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = { ...IndicadoresDesempenho, padraoDesempenho: padraoSelected }

    if (IndicadoresDesempenho.nome === ""
      || IndicadoresDesempenho.peso === ""
      || IndicadoresDesempenho.peso === null
      || IndicadoresDesempenho.peso === undefined
    ) return

    if (currentData && !currentData.delete && currentData.id > 0) {
      return handleUpdate(data);
    }

    handleRegister(data);
  };

  const handleRegister = async (data) => {
    const peso = Number(data.peso.replace('%', ''))
    setLoading(true);

    const totalPeso = currentWeight + peso

    const response = await IndicadorDesempenho.register(token, { ...data, peso: peso })

    if (response) {
      if (response.response && response.response.status === 400) {
        toast.error(response.response.data);
        setLoading(false);
        return
      }

      setLoading(false);
      toast.success("Indicar cadastrado com sucesso!");
      onRegister(totalPeso)
      setIndicadoresDesempenho(defaultValue);
      callback()
    } else {
      toast.error("Erro ao cadastrar indicador!");
      setLoading(false);
    }
  };

  const handleUpdate = async (data) => {
    const peso = Number(data.peso.replace('%', ''))
    setLoading(true);

    const totalPeso = currentWeight + peso
    const response = await IndicadorDesempenho.update(token, { ...data, peso: peso })

    if (response) {
      if (response.response && response.response.status === 400) {
        toast.error(response.response.data);
        setLoading(false);
        return
      }

      setLoading(false);
      toast.success("Indicar editado com sucesso!");
      callback()
      onRegister(totalPeso)
      setIndicadoresDesempenho(defaultValue);
    } else {
      toast.error("Erro ao editar indicador!");
      setLoading(false);
    }
  };


  const [padraoSelected, setPadraoSelected] = useState(null);
  const hadleselectedPadrao = (id) => {
    const padrao = padroesDesempenho.find(item => item.id === id)

    setPadraoSelected(padrao)
    getPadraoSelected(padrao, currentIndicadoresDesempenho)
    setIndicadoresDesempenho(defaultValue)
    resetCurrentData()
  }

  const handleDropList = (key) => {
    setDropListSelect((dropListSelect !== key) ? key : '')
  }

  const onRegister = (value) => {
    setWeight(value)
    setAvailableWeight(Math.round((weightLimit - value) * weightLimit) / weightLimit)
  }

  return (
    <>
      <div className="user-config-container">
        <div className="card-container">
          <div className="card">
            <div className="card-header">
              <h1>CADASTRO DE NOVO INDICADOR DE DESEMPENHO</h1>
            </div>
            <div className="card-body">
              <form className="form-container" onSubmit={handleSubmit}>
                <div className="group-input escopo-avaliacao created-form d-flex flex-row">

                  <div className="content-input" >

                    <div className="input-group-container">
                      <label style={{ maxWidth: "15ch" }} htmlFor="padraoDesempenho">Padrão de Desempenho</label>
                      <div className="input-group-span">
                        <div className="input-list-wrap">
                          <input
                            disabled={true}
                            type="text"
                            name="padraoDesempenho"
                            id="padraoDesempenho"
                            className="global-input"
                            style={{ minWidth: "140px", margin: 0 }}
                          />
                          <div className={"list-item d-inline-flex"}>
                            <span>{padraoSelected
                              ? padraoSelected.nome
                              : 'Selecione'}
                            </span>
                            <DropList
                              uniqid={'padrao-desempenho'}
                              label="Escolha um Padrão"
                              selectable
                              defaultValue={(padraoSelected) ? padraoSelected.id : []}
                              options={padroesDesempenho}
                              selectedOption={hadleselectedPadrao}
                              active={(dropListSelect === 'padrao-desempenho')}
                              onClick={handleDropList}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="content-input" >
                    <label style={{ maxWidth: "15ch", marginLeft: '32px' }} htmlFor="nome">Indicador de Desempenho</label>
                    <input
                      className="global-input"
                      type="text"
                      id="nome"
                      placeholder=""
                      style={{ width: "315px", margin: 0 }}
                      name="nome"
                      value={IndicadoresDesempenho.nome}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="content-input" style={{ textAlign: 'end', paddingRight: 16 }} >
                    <label style={{ maxWidth: "4ch", marginRight: '16px' }} htmlFor="weight">Peso</label>
                    <input
                      className="global-input"
                      type="text"
                      id="peso"
                      style={{ width: "75px", margin: 0 }}
                      placeholder=""
                      name="peso"
                      value={IndicadoresDesempenho.peso}
                      onChange={handleChange}
                    />
                  </div>
                  <div>
                    <button className="global-btn" style={{ width: "82px" }}>
                      {(currentData && !currentData.delete && currentData.id > 0)
                        ? !loading && "SALVAR"
                        : !loading && "CRIAR"}

                      {loading && <FontAwesomeIcon icon={faCircleNotch} pulse />}
                    </button>

                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="percentage-indicator-wrap">
          <div className={`frame-percentage-indicator ${weightLimitReached && `percent-complete`}`}>
            <span>
              {weightLimitReached
                ? padraoSelected
                  ? `A soma dos indicadores de desempenho de ${padraoSelected.nome} criados na lista acima tem resultado de 100% de peso. Portanto o cadastro desse indicador de desempenho está completo`
                  : ''
                : padraoSelected
                  ? `A soma dos indicadores de desempenho de ${padraoSelected.nome} criado na lista abaixo deve ter resultado de 100% de peso. O resultado desse padrão é de ${weight}% de peso faltando cadastrar`
                  : ''
              }
            </span>
          </div>
          <div className={weightLimitReached ? `percent-complete` : '-'}>
            <input type="text" defaultValue="0%" value={availableWeight + '%'} disabled={true} id="input-view-percent" />
          </div>
        </div>

      </div>
    </>
  );
}
