import React, { useState } from "react";
import { Redirect } from "react-router-dom";

import ForgetForm from "./Form/ForgetForm";

import AuthService from "../../services/AuthService";

export default function Index() {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);

  let initialValues = {
    email: "",
  };

  const send = (values) => {
    setLoading(true);
    let { email } = values;

    AuthService.recover(email)
      .then((response) => {
        let status =
          response && response.response && response.response.status
            ? response.response.status
            : "";
        if (status !== "") {
          if (response && response.response && response.response.data) {
            setError(response.response.data);
          }

          setSuccess(false);
          setLoading(false);
          setRedirect(false);
          return false;
        }

        setLoading(false);
        setSuccess(true);
        setError(false);
        setRedirect(true);
      })
      .catch((error) => {
        setLoading(false);
        setSuccess(false);
        setError(true);
        setRedirect(false);
      });
  };

  if (redirect) {
    return <Redirect to={{ pathname: "/nova-senha" }} />;
  }

  return (
    <>
      <ForgetForm
        title="Recuperar senha"
        initialValues={initialValues}
        send={send}
        error={error}
        success={success}
        loading={loading}
      />
    </>
  );
}
