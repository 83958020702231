import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import IdoneidadeIntegridadeService from "../../../services/IdoneidadeIntegridadeService";
import { toast } from "react-toastify";
import InputRadio from "../../../components/InputRadio/InputRadio";

export default function IdoneidadeIntegridadeForm({ token, send, currentIdoneidade }) {
  const [loading, setLoading] = useState(false)
  const [nome, setNome] = useState("");
  const [geraBloqueioHomologacao, setGeraBloqueioHomologacao] = useState(false);
  const [ativoComprador, setAtivoComprador] = useState(false);

  const handleChange = (value) => {
    setNome(value);
  };

  const handleGeraBloqueio = (e) => {
    e.persist();
    setGeraBloqueioHomologacao(e.target.value == 1 ? true : false)
  }

  const handleativoComprador = (e) => {
    e.persist();
    setAtivoComprador(e.target.value == 1 ? true : false)
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (nome === "") {
      return toast.error("Preencha o campo nome.")
    }
    if (currentIdoneidade && currentIdoneidade.id > 0) {
      return handleUpdate();
    }
    handleRegister();
  };

  const handleRegister = () => {
    setLoading(true);
    IdoneidadeIntegridadeService.registerIdoneidadeIntegridade(token, nome)
      .then((response) => {
        if (response) {
          setLoading(false);
        }
        setLoading(false);
        setNome("")
        send(response)
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleUpdate = () => {
    setLoading(true);
    if (isComprador()) {
      IdoneidadeIntegridadeService.updateIdoneidadeIntegridadeComprador(token, geraBloqueioHomologacao, ativoComprador, currentIdoneidade.id)
        .then((response) => {
          if (response) {
            setLoading(false);
          }
          setLoading(false);
          setNome("")
          setGeraBloqueioHomologacao(false);
          setAtivoComprador(false);
          send(response)
        })
        .catch((error) => {
          setLoading(false);
        });
    }
    else {
      IdoneidadeIntegridadeService.updateIdoneidadeIntegridade(token, nome, currentIdoneidade.id)
        .then((response) => {
          if (response) {
            setLoading(false);
          }
          setLoading(false);
          setNome("")
          send(response)
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  };

  const isComprador = () => {
    const profile = JSON.parse(localStorage.getItem("profile"));
    return (profile.profileId === 2);
  };

  useEffect(() => {
    if (currentIdoneidade) {
      setNome(currentIdoneidade.nome)
      setGeraBloqueioHomologacao(currentIdoneidade.geraBloqueioHomologacao)
      setAtivoComprador(currentIdoneidade.ativoComprador)
    }

  }, [currentIdoneidade])

  return (
    <>
      <div className="user-config-container">
        <div className="card-container">
          <div className="card">
            <div className="card-header">
              {isComprador() ? <h1>Editar Identidade de usuário</h1> : <h1>CRIAR Identidade de usuário</h1>}
            </div>
            <div className="card-body">
              <form className="form-container" onSubmit={handleSubmit}>
                {!isComprador() ?
                  <div className="group-input created-form gt">
                    <label>Identidade de usuário</label>
                    <input
                      className="global-input"
                      type="text"
                      id="name"
                      placeholder="Identidade de usuário"
                      name="name"
                      value={nome}
                      onChange={(e) => handleChange(e.target.value)}
                    />
                    <button className="global-btn">
                      {currentIdoneidade && currentIdoneidade.id > 0 ?
                        !loading && "SALVAR" : !loading && "CRIAR"
                      }

                      {loading && <FontAwesomeIcon icon={faCircleNotch} pulse />}
                    </button>
                  </div> :
                  <div className="group-input created-form gt" style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
                      <div className="group-input created-form gt" style={{ width: '60%' }}>
                        <label>Identidade de usuário</label>
                        <input
                          style={{ width: '100%' }}
                          className="global-input"
                          type="text"
                          id="name"
                          placeholder="Identidade de usuário"
                          name="name"
                          value={nome}
                          disabled={isComprador()}
                        />
                      </div>
                      <div style={{ display: 'flex' }}>
                        <label style={{ marginRight: 40 }}>Gera Bloqueio Homologação?</label>
                        <InputRadio
                          key={1}
                          name={'escopo-1'}
                          label="Sim"
                          value="1"
                          checked={(geraBloqueioHomologacao === true) ? '1' : geraBloqueioHomologacao}
                          onChange={handleGeraBloqueio}
                        />
                        <InputRadio
                          key={2}
                          name={'escopo-2'}
                          label="Não"
                          value="0"
                          checked={!geraBloqueioHomologacao ? '0' : geraBloqueioHomologacao}
                          onChange={handleGeraBloqueio}
                        />
                      </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "space-between", width: "100%", marginTop: 20 }}>
                      <div style={{ width: 400, display: "flex", alignItems: 'center' }}>
                        <label>Ativo</label>
                        <InputRadio
                          key={1}
                          name={'ativo-1'}
                          label="Sim"
                          value="1"
                          checked={(ativoComprador === true) ? '1' : ativoComprador}
                          onChange={handleativoComprador}
                        />
                        <InputRadio
                          key={2}
                          name={'ativo-2'}
                          label="Não"
                          value="0"
                          checked={!ativoComprador ? '0' : ativoComprador}
                          onChange={handleativoComprador}
                        />
                      </div>
                      <button style={{ marginLeft: 20 }} className="global-btn">
                        {!loading && "SALVAR"}
                        {loading && <FontAwesomeIcon icon={faCircleNotch} pulse />}
                      </button>
                    </div>
                  </div>}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
