import React, { useState } from "react";
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

export default function LoginForm({
  initialValues,
  title,
  send,
  error,
  success,
  loading,
}) {
  const [emailVerification, setEmailVerification] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [values, setValues] = useState(initialValues);

  const handleChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  // useEffect((success) => console.log("estamos aqui form", success), []);

  const handleChangeConnected = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      user_logged_storage: !values.user_logged_storage,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    setEmailVerification(false);
    setPasswordValidation(false);

    let emailVerification = new RegExp(
      /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
    ).test(values.email);
    let passwordValidation = new RegExp(
      /^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{8,}$/gm
    ).test(values.password);

    if (emailVerification === false)
      setEmailVerification(true);
    
    if (passwordValidation === false)
      setPasswordValidation(true);
    
    if (emailVerification && passwordValidation)
      send(values);
  };
  return (
    <div className="login-container">
      {error && <span className="error-list">{error && error}</span>}
      {success && (
        <span className="success-msg">Cadastro realizado. Te enviamos um email de confirmação.</span>
      )}
      <form className="form-container" onSubmit={handleSubmit}>
        <div className="header-container">
          <div className="public-app-item">
            <h1>{title}</h1>
          </div>
          <div className="public-app-item item-rigth">
            <p>
              {" "}
              ou <Link to="/cadastro-comprador">criar conta</Link>{" "}
            </p>
          </div>
        </div>

        <div className="group-input">
          {/* <label>Email</label> */}
          <input
            placeholder="Email *"
            className={`global-input ${submitted && !values.email ? "error" : ""
              }`}
            id="email"
            name="email"
            value={values.email}
            onChange={handleChange}
          />
          {submitted && !values.email && (
            <span className="error-msg">Esse campo é necessario</span>
          )}
          {emailVerification && (
            <span className="error-msg">Insira um e-mail válido</span>
          )}
        </div>
        <div className="group-input">
          {/* <label>Senha</label> */}
          <input
            type="password"
            placeholder="Senha *"
            className={`global-input ${submitted && !values.password ? "error" : ""
              }`}
            id="password"
            name="password"
            value={values.password}
            onChange={handleChange}
          />
          {/* <span className="sub-label">Mínimo de 8 caracteres alternados com letras, números e caracteres</span> */}
          {submitted && !values.password && (
            <span className="error-msg">Esse campo é necessario</span>
          )}
          {passwordValidation && (
            <span className="error-msg">
              Os campos devem conter no mínimo de 8 caracteres alternados com
              letras, números e caracteres
            </span>
          )}
        </div>
        <div className="footer-form-container">
          <div className="footer-form-item">
            <div className="group-input-checkbox">
              <input
                type="checkbox"
                className="global-input"
                id="user_logged_storage"
                name="user_logged_storage"
                value={values.user_logged_storage}
                onChange={handleChangeConnected}
              />
              <label className="checkbox-label" htmlFor="user_logged_storage">
                Lembrar meus dados
              </label>
            </div>
            <div className="link-container">
              <Link to="/recuperar-senha">Esqueceu sua senha?</Link>
            </div>
          </div>
          <div className="footer-form-item">
            <button className="global-btn">
              Acessar conta{" "}
              {loading && <FontAwesomeIcon icon={faCircleNotch} pulse />}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}
