import React, { useState, useEffect, useRef } from "react";

import Modal from '../../components/ModalExit'
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";

import IndicadoresDesempenhoList from "./List/indicadoresDesempenhoList";
import IndicadoresDesempenhoForm from "./Form/indicadoresDesempenhoForm";
import PadroesDesempenhoService from "../../services/padroesDesempenhoService";
import IndicadorDesempenhoService from "../../services/IndicadorDesempenho";

import './styles.scss';
import "../dashboard/dashboard.scss";

export default function Index(props) {
  const { token } = props.state;
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState({ open: false, msg: '', alert: true });
  const [currentData, setCurrentData] = useState(null);

  const [indicadoresDesempenho, setIndicadorDesempenho] = useState([]);
  const [currentWeight, setCurrentWeight] = useState(null);
  const [padroesDesempenho, setPadroesDesempenho] = useState([]);

  const _init = async () => {
    await PadroesDesempenhoService.getAll(token)
      .then((response) => {
        setPadroesDesempenho(response || [])
      })
  }

  const getIndicadorDesempenho = () => {
    setLoading(true);

    IndicadorDesempenhoService.get(token)
      .then((response) => {
        if (response) {
          response.sort((a, b) => a.padraoDesempenho.id - b.padraoDesempenho.id)
          setIndicadorDesempenho(response || []);

          if (currentData) {
            sumPadrao(currentData.padraoDesempenho, response)
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const _delete = (value) => {
    const id = value.id

    setLoading(true);
    IndicadorDesempenhoService.delete(token, id)
      .then((response) => {
        if (response) {
          toast.success("Item excluído com sucesso!");
          setLoading(false);
          _init();
          getIndicadorDesempenho();
        }
        setLoading(false);
        setModal(value => ({
          ...value,
          open: false
        }))
      })
      .catch((error) => {
        toast.error("Erro ao excluir item!");
        setLoading(false);
        setCurrentData(null)
        setModal(value => ({
          ...value,
          open: false
        }))
      });
  };

  const sumPadrao = (p, indicadores = []) => {
    const padroes = indicadores.filter(padraoDesempenho => padraoDesempenho.padraoDesempenho.id === p.id)
    const pesos = padroes.map(item => item.peso)
    const pesoTotal = Math.round((pesos.reduce((total, value) => total + value, 0)) * 100) / 100
    setCurrentWeight(pesoTotal)
  }

  useEffect(() => {
    callback()
  }, []);

  const callback = () => {
    _init();
    getIndicadorDesempenho();
  }

  const handleEdit = (value) => {
    setCurrentData(value);
  }

  const _handleDelete = (value) => {
    setCurrentData({ ...value, delete: true });

    setModal({
      open: true,
      msg: `Deseja realmente deletar o indicador "${value.nome} de Peso ${value.peso}%"?`,
      alert: false
    })
  };

  const handleModal = (value) => {
    setModal(value)
  }

  const resetCurrentData = () => {
    setCurrentData(null)
  }

  const formRef = useRef(null);

  const scrollToTop = () => formRef.current.scrollIntoView({ behavior: "smooth" })

  return (
    <div className='dashboard-container' id="indicador-desempenho-container" ref={formRef}>
      {(modal.open) &&
        <Modal
          title={modal.msg}
          handleDelete={!modal.alert ? () => _delete(currentData) : null}
          handleModal={handleModal}
          alert={modal.alert}
        />
      }

      {loading && (
        <div className={"loading-mask"}>
          <FontAwesomeIcon icon={faCircleNotch} pulse />
        </div>
      )}

      <IndicadoresDesempenhoForm
        token={token}
        currentIndicadoresDesempenho={indicadoresDesempenho}
        currentWeight={currentWeight}
        setCurrentWeight={e => setCurrentWeight(e)}
        padroesDesempenho={padroesDesempenho}
        getPadraoSelected={sumPadrao}
        currentData={currentData}
        callback={callback}
        resetCurrentData={resetCurrentData}
      />
      <IndicadoresDesempenhoList
        token={token}
        listIndicadores={indicadoresDesempenho}
        handleEdit={handleEdit}
        handleDelete={_handleDelete}
        scrollToTop={scrollToTop}
      />
    </div>
  );
}
