import React, { memo } from 'react';

import './styles.scss'

function Status() {

    return (
        <div className="card-status-wrap">
            <div className="status-wrap">
                <div>
                    <h1>STATUS DA QUALIFICAÇÃO DO PROVEDOR</h1>
                </div>
                <div id="status-info">
                    <h1>APROVADO</h1>
                </div>
            </div>

            <div className="status-toggle-wrap">
                <div>
                    <h1>STATUS DO PROVEDOR NO SISTEMA</h1>
                </div>
                <div id="status-toggle" style={{ zoom: 1.2 }}>
                    <span className="list-item">
                        <div className="group-input spaced-btw switch-container">
                            <label className="toggle-switch">
                                <input
                                    type="checkbox"
                                    checked={true}
                                    value={true}
                                    onChange={() => console.log('true')}
                                />
                                <span className="switch">
                                    <p style={{ fontWeight: 'bold' }}>
                                        {true ? "Ativo" : "Inativo"}
                                    </p>
                                </span>
                            </label>
                        </div>
                    </span>
                </div>
            </div>
        </div>
    )
}

export default memo(Status)