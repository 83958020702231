import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DebounceInput } from "react-debounce-input";
import { faSearch } from "@fortawesome/free-solid-svg-icons";

import AcessarClientesService from "../../services/AcessarClientesService";
import Loading from "../../components/Loading";

import './styles.scss'


export default function AcessarClientes(props) {
    const { token } = props.state;

    const [loading, setLoading] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [clientes, setClientes] = useState([]);


    const init = async () => {
        setLoading(true);
        const response = await AcessarClientesService.get(token);
        montarLista(response.compradores, response.provedores);
        setLoading(false);
    }

    const search = async (textoPesquisa) => {
        setLoading(true)
        const response = await AcessarClientesService.get(token, textoPesquisa)
        montarLista(response.compradores, response.provedores);
        setLoading(false)
    };

    const montarLista = (compradores, provedores) => {
        if (!compradores && !provedores)
            return;
        let lista = [...compradores, ...provedores];
        setClientes(lista);
    }

    const handlePesquisa = (textoPesquisa) => {
        setSearchInput(textoPesquisa)
        search(textoPesquisa)
    };


    useEffect(() => {
        init();
    }, [])


    return (
        <>
            <div className="dashboard-container">
            <Loading loading={loading}/>
                <div className="clientes-container">
                    <div style={{ justifyContent: "flex-end", width: "100%" }} className="filter-search ">
                        <div className="user-list-search ">
                            <div className="user-list-search-item">
                                <DebounceInput
                                    type="text"
                                    name="search"
                                    className="search-input"
                                    placeholder={"Razão Social ou Nome Fantasia"}
                                    debounceTimeout={400}
                                    value={searchInput}
                                    onChange={(event) => handlePesquisa(event.target.value)}
                                />
                                <button className="search-btn" onClick={() => handlePesquisa(searchInput)}>
                                    <FontAwesomeIcon icon={faSearch} />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="card-container">
                        <div className="card subcard">
                            <div className="card-header">
                                <h1>Dados do Cliente</h1>
                            </div>
                            <div className="card-body">
                                <div className="list-container">
                                    <div className="list">
                                        <div className="list-header">
                                            <label style={{ maxWidth: "46%" }}>Razão Social</label>
                                            <label className="space-list" style={{ maxWidth: "48%", marginRight: 5 }}>Tipo</label>
                                            <label className="space-list" style={{ maxWidth: "48%", marginRight: 5 }}>Nome Fantasia</label>
                                            <label className="space-list" style={{ maxWidth: "48%", marginRight: 5 }}>CNPJ</label>
                                            <label style={{ flexGrow: 0 }}>Acessar</label>
                                        </div>
                                        {(clientes || []).map((data, i) => (
                                            <div className="list-body" key={i}>
                                                <span className="list-item ">{data.razaoSocial}
                                                </span>
                                                <span className="list-item space-list">{data.provedorCertificados ? "Provedor" : "Comprador"}</span>
                                                <span className="list-item space-list">{data.nomeFantasia}</span>
                                                <span className="list-item space-list">{data.cnpj}</span>
                                                <span className="list-item document-list" style={{ flexGrow: 0 }}>
                                                    <a className="list-button" onClick={() => {
                                                    }}>
                                                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M5 0.5C2.23858 0.5 0 2.73858 0 5.5V19.5C0 22.2614 2.23858 24.5 5 24.5H19C21.7614 24.5 24 22.2614 24 19.5V5.5C24 2.73858 21.7614 0.5 19 0.5H5ZM5.59868 19.5H18.3553C18.6776 19.5 19 19.0922 19 18.6165C19 18.1408 18.7237 17.733 18.4013 17.733H5.59868C5.27632 17.733 5 18.1408 5 18.6165C5 19.0922 5.27632 19.5 5.59868 19.5ZM5.59868 15.4223H18.3553C18.6776 15.4223 19 15.0146 19 14.5388C19 14.0631 18.7237 13.6553 18.4013 13.6553H5.59868C5.27632 13.6553 5 14.0631 5 14.5388C5 15.0146 5.27632 15.4223 5.59868 15.4223ZM5.59868 11.3447H18.3553C18.6776 11.3447 19 10.9369 19 10.4612C19 9.98544 18.7237 9.57767 18.4013 9.57767H5.59868C5.27632 9.57767 5 9.98544 5 10.4612C5 10.9369 5.27632 11.3447 5.59868 11.3447ZM5.59868 7.26699H18.3553C18.6776 7.26699 19 6.85922 19 6.38349C19 5.90777 18.7237 5.5 18.4013 5.5H5.59868C5.27632 5.5 5 5.90777 5 6.38349C5 6.85922 5.27632 7.26699 5.59868 7.26699Z" fill="#4B4B4B" />
                                                        </svg>
                                                    </a>
                                                </span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}