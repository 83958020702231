import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons";
import TypeDocumentLicenceService from "../../../services/TypeDocumentLicenceService";
import { toast } from "react-toastify";
import { setMaxListeners } from "process";
import * as msgs from "../../../components/Common/Constantes";

export default function DocumentLicenceForm({token, send, currentDocument}) {
    const [loading, setLoading] = useState(false)
    const [nome, setNome] = useState("");

  const handleChange = (value) => {
    setNome(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (nome === "") {
        return toast.error("Preencha o campo nome.")
    }
    if (currentDocument && currentDocument.id > 0) {
      return handleUpdate();
    }
    handleRegister();
  };

  const handleRegister = () => {
    setLoading(true);
    let resp;
    TypeDocumentLicenceService.registerDocumentLicence(token, nome)
      .then((response) => {
        if (response && response.data) {
          toast.success(msgs.MSG_SUCESSO_CADASTRO);
          resp = true;
        }
      })
      .catch((reason) => {
        resp = false;
        toast.error(reason.response.data || msgs.MSG_ERRO_CADASTRO);
      })
      .finally(() => {
      setLoading(false);
      setNome("");
      send(resp);
      });
  };

  const handleUpdate = () => {
    setLoading(true);
    TypeDocumentLicenceService.updateDocumentLicences(token, nome, currentDocument.id)
      .then((response) => {
        if (response) 
          toast.success(msgs.MSG_SUCESSO_EDICAO);
      })
      .catch((reason) => {
        toast.error(reason.response.data || msgs.MSG_ERRO_ATUALIZAR);
      })
      .finally(() => {
        setLoading(false);
        setNome("");
        send(true);
        });;
  };
  useEffect(() => {
    if (currentDocument) {
      setNome(currentDocument.nome)
    }

  }, [currentDocument])
  const history = useHistory();

  return (
    <>
      <div className="user-config-container">
        <div className="card-container">
          <div className="card">
            <div className="card-header">
              <h1>CADASTRAR NOVO DOCUMENTO / LICENÇA</h1>
            </div>
            <div className="card-body">
              <form className="form-container" onSubmit={handleSubmit}>
                <div className="group-input created-form">
                  <label>O que</label>
                  <input
                    className="global-input"
                    type="text"
                    id="name"
                    placeholder="Novo Documento"
                    name="name"
                    value={nome}
                    onChange={(e) => handleChange(e.target.value)}
                  />
                  <button className="global-btn">
                    {currentDocument && currentDocument.id > 0 ?
                        !loading && "SALVAR" : !loading && "CRIAR"
                    }
                    
                    {loading && <FontAwesomeIcon icon={faCircleNotch} pulse />}
                  </button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
