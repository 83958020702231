import React, { useState } from "react";
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import "../forget.scss";

export default function ForgetForm({
  initialValues,
  title,
  send,
  error,
  loading,
}) {
  const [emailVerification, setEmailVerification] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [values, setValues] = useState(initialValues);

  const handleChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    setEmailVerification(false);
    localStorage.setItem("email", values.email);

    let emailVerification = new RegExp(
      /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/g
    ).test(values.email);

    if (emailVerification === false) {
      setEmailVerification(true);
    }

    send(values);
  };

  return (
    <div className="forget-container">
      {error && <span className="error-list">{error && error}</span>}
      <form className="form-container" onSubmit={handleSubmit}>
        <div className="header-container">
          <div className="public-app-item item-left">
            <h1>{title}</h1>
          </div>
          <div className="public-app-item item-rigth">
            <p>
              {" "}
              ou <Link to="/login">login</Link>{" "}
            </p>
          </div>
        </div>
        <div className="group-input">
          {/* <label>Email</label> */}
          <input
            placeholder="Email"
            className={`global-input ${
              submitted && !values.email ? "error" : ""
            }`}
            id="email"
            name="email"
            value={values.email}
            onChange={handleChange}
          />
          {submitted && !values.email && (
            <span className="error-msg">Esse campo é necessario</span>
          )}
          {emailVerification && (
            <span className="error-msg">Insira um e-mail válido</span>
          )}
        </div>
        <button className="global-btn">
          Enviar {loading && <FontAwesomeIcon icon={faCircleNotch} pulse />}
        </button>
      </form>
    </div>
  );
}
