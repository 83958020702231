import React, { useState } from "react";

import List from "./List";

export default function Index(props) {
  const { token } = props.state;

  return (
    <div className="dashboard-container">
      {/* {loading && (
        <div className={"loading-mask"}>
          <FontAwesomeIcon icon={faCircleNotch} pulse />
        </div>
      )} */}
      <div>
        <List token={token} valueInitial={[]} back={props.back || null}/>
      </div>
    </div>
  );
}