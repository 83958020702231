import { render } from 'react-dom';
import React, { Component } from 'react';

import './materiais-chart.scss'
import { Doughnut } from 'react-chartjs-2';

class MateriaisChart extends Component {

    render() {
        return (
            <>
                <div className="chart" style={{ height: '122px', width: '122px' }}>
                    <Doughnut
                        data={{
                            datasets: [
                                {
                                    data: [
                                        this.props.materialServicoCount
                                            ? this.props.materialServicoCount.materiais
                                            : 0,
                                        this.props.materialServicoCount
                                            ? this.props.materialServicoCount.servicos
                                            : 0
                                    ],
                                    backgroundColor: ['#FEBD59', '#FF7461'],
                                    cutout: 49
                                }
                            ],
                        }}
                        options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            aspectRatio: 1,
                            plugins: {
                                legend: {
                                    display: false
                                },
                                tooltip: { enabled: false }
                            }
                        }}
                        legend={{ display: false }}
                    />
                </div>
            </>
        );
    }
}

render(<MateriaisChart />, document.getElementById('root'));

export default MateriaisChart;