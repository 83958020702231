import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import { toast } from "react-toastify";

import ListModulesUser from "./List/ListModulesUser";

import ModuleService from "../../services/ModuleService";
import BuyerService from "../../services/BuyerService";

export default function EditAccessUser(props) {
  const { token } = props;

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modules, setModules] = useState(false);
  const [user, setUser] = useState({});

  const [id] = useState(props.id || "");

  const getModules = () => {
    ModuleService.getModules(token)
      .then((response) => {
        setModules(response);
      })
      .catch((error) => {
        toast.error("Erro ao buscar módulos!");
        setError(true);
        setLoading(false);
      });
  };

  const getBuyer = () => {
    setLoading(true);
    BuyerService.getBuyer(token, id)
      .then((response) => {
        setUser(response);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Erro ao buscar dados do usuário!");
        setError(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    getBuyer();
    getModules();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    modules &&
      modules.length > 0 &&
      modules.map((module) => {
        let result =
          user &&
          user.modules &&
          user.modules.length > 0 &&
          user.modules.filter((value) => module.id === value.moduleId);
        if (result && result.length > 0) {
          module.active = true;
        }
        return module;
      });
    setModules(modules);
    // eslint-disable-next-line
  }, [modules, user]);

  const handleModule = (idModule, status) => {
    ModuleService.handleStatusModuleUser(token, idModule, status, id)
      .then((response) => {
        if (response) {
          getBuyer();
          getModules();
        }
        setLoading(false);
        setError(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
      });
  };

  return (
    <div className="dashboard-container">
      {loading && (
        <div className={"loading-mask"}>
          <FontAwesomeIcon icon={faCircleNotch} pulse />
        </div>
      )}

      <h1>Gestão de acessos / Configuração de usuario</h1>
      <div className="module-container">
        <div className="module-item">
          <ListModulesUser
            initicialModules={modules}
            initicialUser={user}
            loading={loading}
            error={error}
            name={true}
            handleModule={handleModule}
            goBackManageAccess={e => props.goBackManageAccess(e)}
          />
        </div>
        <div className="module-item"></div>
      </div>
    </div>
  );
}
