import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  INFORMATION_TYPE: BASE_URL + "InformationType",
};

export default class InformationService {
  static getInformationTypes = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.INFORMATION_TYPE;

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getInformationTypeByID = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.INFORMATION_TYPE + "/" + id;

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  // delete
  static deleteInformationType = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.INFORMATION_TYPE + "/" + id;

    try {
      const { data } = await axios.delete(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  // update
  static updateInformationType = async (token, informationType) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.INFORMATION_TYPE;

    try {
      const { data } = await axios.put(url, informationType, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  // create
  static postInformationType = async (token, informationType) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.INFORMATION_TYPE;

    try {
      const { data } = await axios.post(url, informationType, config);
      return data;
    } catch (err) {
      return err;
    }
  };
}
