import React from "react";

import "./cardCompradorLogo.scss"
import compradorIcon from "../../../../../assets/icons/dashboard-comprador-logo.svg";

export default function CardComprador(props) {
  return (
    <>
      <div className="d-flex flex-column align-self-center align-items-center mr-4" >
        <span className="card-comprador-title">
          COMPRADOR
        </span>
        <div className="card-comprador d-flex align-items-center justify-content-center">
          <img src={compradorIcon} />
        </div>
      </div>
    </>
  );
}
