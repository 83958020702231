import React, { useState } from "react";
import { Redirect } from "react-router-dom";

import ForgetFormReset from "./Form/ForgetFormReset";

import AuthService from "../../services/AuthService";

export default function Index() {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);

  let initialValues = {
    code: "",
    password: "",
    passwordConfirm: "",
    email: localStorage.getItem("email"),
  };

  const send = (values) => {
    setLoading(true);

    AuthService.changePassword(values)
      .then((response) => {
        let status =
          response && response.response && response.response.status
            ? response.response.status
            : "";

        if (status !== "") {
          if (
            response &&
            response.response &&
            response.response.data &&
            response.response.data.message
          ) {
            setError(response.response.data.message);
          }

          setSuccess(false);
          setLoading(false);
          setRedirect(false);
          return false;
        }

        setLoading(false);
        setSuccess(true);
        setError(false);
        setRedirect(true);
        localStorage.removeItem("email");
      })
      .catch((error) => {
        setLoading(false);
        setSuccess(false);
        setError(true);
        setRedirect(false);
      });
  };

  if (redirect) {
    return <Redirect to={{ pathname: "/login" }} />;
  }

  return (
    <>
      <ForgetFormReset
        title="Nova senha"
        initialValues={initialValues}
        send={send}
        error={error}
        success={success}
        loading={loading}
      />
    </>
  );
}
