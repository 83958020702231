import React from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";

import "../private-app/dashboard/dashboard.scss";

export default function ModalExit({ title, handleDelete, handleModal, alert, styles }) {
  return (
    <>
      <div className="modal-mask">
        <div className="modal-container" style={styles}>
          <div className="modal-header">
            <h1>{title ? title : 'Tem certeza que deseja excluir este item?'}</h1>
          </div>
          <div className="modal-body">
            <div className="btn-container">
              <button
                className="global-white-btn"
                onClick={() => handleModal(false)}
              >
                {alert ? 'Ok' : 'Cancelar'}
              </button>
            </div>

            {!alert &&
              <div className="btn-container">
                <button className="global-white-btn" onClick={() => handleDelete()}>
                  Sim
              </button>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
}
