import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import QuestionForm from "./Form/questionForm";
import ThemeForm from "./Form/themeForm";
import List from "./List/";
import EscopoAvaliacaoTema from "../../../services/EscopoAvaliacaoTema";
import EscopoAvaliacaoPerguntas from "../../../services/EscopoAvaliacaoPerguntas";
import Modal from "../../../components/ModalExit";

import "./style.scss";

export default function Index({ token, escopo, handleEscopo, comprador }) {
  const [loading, setLoading] = useState(false);
  const [deleteThemeID, setDeleteThemeID] = useState(0);
  const [deleteQuestionID, setDeleteQuestionID] = useState(0);
  const [temas, setTemas] = useState([]);
  const [perguntas, setPerguntas] = useState([]);
  const [showTheme, setShowTheme] = useState(0);

  const defaultCurrentQuestion = {
    temaId: "",
    descricao: "",
    suporte: "",
    critico: false,
    uploadObrigatorio: false,
    status: true,
    auditorias: [
      {
        nome: "",
      }
    ],
    idPerguntaPai: 0
  };
  const defaultCurrentTheme = {
    name: '',
    isEnable: true,
    escopoAvaliacaoId: escopo.id
  }
  const [currentTheme, setCurrentTheme] = useState(defaultCurrentTheme);
  const [currentQuestion, setCurrentQuestion] = useState(defaultCurrentQuestion);

  //Theme
  const getTheme = (reload) => {
    setShowTheme(comprador ? 2 : 0);

    EscopoAvaliacaoTema.getByEscopoId(token, escopo.id)
      .then((response) => {
        if (response) {
          setTemas(response);
          getQuestion();
        }
      })
      .catch((error) => {
        // setError(true);
      });
  }

  const enableTheme = (data) => {
    setTemas((themesList) => {
      return themesList.map((elem) => {
        if (elem.id === data.id) {
          let newEnable = !elem.isEnable;
          elem.isEnable = newEnable;
          EscopoAvaliacaoTema.update(token, elem)
            .then((response) => {
              if (response) {
                toast.success("Item " + (newEnable ? 'ativado' : 'desativado') + " com sucesso!");
                elem.isEnable = newEnable;
                getQuestion();
              }
            })
            .catch((error) => {
              toast.error("Erro ao " + (newEnable ? 'ativar' : 'desativar') + " item!");
              elem.isEnable = !newEnable;
            });
        }
        return elem;
      })
    });
 
  }

  const editTheme = (data) => {
    createBy(1, data);
  }

  const removeTheme = (id) => {
    setDeleteThemeID(0);

    EscopoAvaliacaoTema.delete(token, id)
      .then((response) => {
        if (response) {
          toast.success("Item excluído com sucesso!");
          getTheme();
        }
      })
      .catch((error) => {
        toast.error("Erro ao excluir item!");
        setLoading(false);
      });
  }

  //Question
  const getQuestion = () => {
    if (comprador) {
      EscopoAvaliacaoPerguntas.getByComprador(token, escopo.id)
        .then((response) => {
          if (response) {
            setPerguntas(response);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
    else {
      EscopoAvaliacaoPerguntas.get(token)
        .then((response) => {
          if (response) {
            setPerguntas(response);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
        });
    }
  }

  const enableQuestion = (data) => {
    setPerguntas((questionsList) => {
      return questionsList.map((elem) => {
        if (elem.id === data.id) {
          let newEnable = !elem.status;
          elem.status = newEnable;

          EscopoAvaliacaoPerguntas.update(token, elem)
            .then((response) => {
              if (response) {
                toast.success("Item " + (newEnable ? 'ativado' : 'desativado') + " com sucesso!");
                elem.status = newEnable;
                getTheme()
              }
            })
            .catch((error) => {
              toast.error("Erro ao " + (newEnable ? 'ativar' : 'desativar') + " item!");
              elem.status = !newEnable;
            });
        }

        return elem;
      })
    });
  }

  const editQuestion = (data) => {
    createBy(2, data);
  }

  const removeQuestion = (id) => {
    setDeleteQuestionID(0);

    EscopoAvaliacaoPerguntas.delete(token, id)
      .then((response) => {
        if (response) {
          toast.success("Item excluído com sucesso!");
          getTheme();
        }
      })
      .catch((error) => {
        toast.error("Erro ao excluir item!");
        setLoading(false);
      });
  }

  const createQuestion = (data) => {
    createBy(2, {
      ...defaultCurrentQuestion,
      temaId: data.temaId ? data.temaId : data.id,
      idPerguntaPai: data.temaId ? data.id : 0
    });
  }

  //Utils
  const createBy = (tab, data) => {
    if (tab === 1) {
      setCurrentTheme(data ? data : defaultCurrentTheme);
    } else if (tab === 2) {
      setCurrentQuestion(data ? data : defaultCurrentQuestion);
    } else {
      setCurrentTheme(defaultCurrentTheme);
      setCurrentQuestion(defaultCurrentQuestion);
    }

    setShowTheme(tab);
  }

  useEffect(() => {
    setLoading(true);
    getTheme();
  }, [escopo]);

  const themeContainer = useRef(null);

  const scrollToTop = () => themeContainer.current.scrollIntoView({ behavior: "smooth" })

  return (
    <div className="dashboard-container questionTheme-container" ref={themeContainer}>
      {deleteThemeID > 0 && (
        <Modal title="Tem certeza que deseja excluir este tema?" handleDelete={() => removeTheme(deleteThemeID)} handleModal={() => { setDeleteThemeID(0) }} />
      )}
      {deleteQuestionID > 0 && (
        <Modal title="Tem certeza que deseja excluir essa pergunta?" handleDelete={() => removeQuestion(deleteQuestionID)} handleModal={() => { setDeleteQuestionID(0) }} />
      )}
      {loading && !currentQuestion.id && (
        <div className={"loading-mask"}>
          <FontAwesomeIcon icon={faCircleNotch} pulse />
        </div>
      )}

      <button
        className={`global-btn btn-includeEscopo mr-2 ${showTheme === 1 ? 'active' : ''}`}
        onClick={() => createBy(showTheme ? 0 : 1, '')}
      >
        <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M14.5799 10.5H10.4142V14.5H4.16567V10.5H0V4.5H4.16567V0.5H10.4142V4.5H14.5799V10.5Z" fill="#FFF" />
        </svg>
        Criar novo tema
      </button>

      {(showTheme === 1) && (
        <ThemeForm
          token={token}
          defaultCurrent={defaultCurrentTheme}
          current={currentTheme}
          callback={getTheme}
        />
      )}
      {(showTheme === 2) && (
        <QuestionForm
          token={token}
          defaultCurrent={defaultCurrentQuestion}
          current={currentQuestion}
          callback={getTheme}
          temas={temas}
          cancel={() => createBy(0, null)}
        />
      )}
      <div className="mt-0">
        <List
          token={token}
          loading={loading}
          escopo={escopo}
          temas={temas}
          perguntas={perguntas}
          create={createQuestion}
          comprador={comprador}
          enableTheme={enableTheme}
          editTheme={editTheme}
          removeTheme={setDeleteThemeID}

          enableQuestion={enableQuestion}
          editQuestion={editQuestion}
          removeQuestion={setDeleteQuestionID}
          getTheme={getTheme}
          scrollToTop={scrollToTop}
        />
      </div>
      {(!comprador) && (
        <button
          type="button"
          className="global-btn btnVoltar"
          onClick={() => handleEscopo(null)}
        >
          <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.47211 5.05216L5.10332 9.09257L4.31819 9.69863L0 5.05216L4.31819 0.304688L5.00518 1.01176L1.47211 5.05216ZM9.71593 1.01176L9.02894 0.304688L4.71075 5.05216L9.02894 9.69863L9.71593 8.99156L6.18286 5.05216L9.71593 1.01176Z" fill="white" />
          </svg>
          VOLTAR
        </button>
      )}
    </div>
  );
}