import React from "react";

import "../../dashboard/dashboard.scss";
import Form from './Form/Form'
import './style.scss'

export default function Index(props) {
  const { token } = props.state;

  return (
    <>
      <div className="dashboard-container novo-provedor">
        <Form 
          token={token}
        />
      </div>
    </>
  );
}
