import React from "react";

import "./PublicAppContainer.scss";

export default function PublicAppContainer(props) {
  return (
    <div id="public-app-wrapper" className="public-app">
      <div id="public-app-content" className="public-app-content">
        <div className="public-app-item">{props.children}</div>
        <div className="public-app-item">
          <div className="bg-img"></div>
        </div>
      </div>
    </div>
  );
}
