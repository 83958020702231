import React, { useEffect, useState } from "react";
import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";

import {
  RelacaoCompradores, MinhasNotificacoesProvedor
} from "../../routes/Pages";

import "./submodulesProvedor.scss";

export default function SubmodulosProvedor(props) {
  const [submodulos, setSubmodulos] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const currentUrl = "/gerenciamento/";
  const state = { ...props };

  const changeSubmodule = (moduleUrl) => {
    history.push(currentUrl + moduleUrl);
  };

  useEffect(() => {
    const menuStorage = JSON.parse(localStorage.getItem("menuStorage"));
    const provedoresModulo = menuStorage.find(
      (a) => a.name == "Gerenciamento"
    );
    setSubmodulos(provedoresModulo.subModules);
  }, []);

  const activeSubmodulos = (url) => {
    return (location.pathname == currentUrl + url) ? 'active' : '';
  }

  return (
    <section>
      <nav className="submodules-container">
        {submodulos &&
          submodulos.length > 0 &&
          submodulos.map((s, i) => (
            <button key={i} data-url={currentUrl + s.url} onClick={() => changeSubmodule(s.url)} className={activeSubmodulos(s.url)}>
              <div>
                <img
                  src={require(`../../assets/icons/${s.icon}`)}
                />
                <a><b>{s.name}</b></a>
              </div>
            </button>
          ))}
      </nav>
      <div>
        <Switch>
          <Route
            path={currentUrl + "relacao-clientes"}
            render={() => <RelacaoCompradores {...props} />}
            state={state}
          />
          <Route
            path={currentUrl + "minhas-notificacoes"}
            render={() => <MinhasNotificacoesProvedor {...props} />}
            state={state}
          />
        </Switch>
      </div>
    </section>
  );
}