import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import { toast } from "react-toastify";

import ListModules from "./List/ListModules";

import ModuleService from "../../services/ModuleService";
import ProfileService from "../../services/ProfileService";

export default function EditBuyer(props) {
  const { token } = props;

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [success, setSuccess] = useState(false);

  const [modules, setModules] = useState(false);
  const [profile, setProfile] = useState({});

  const [id] = useState(props.id || "");

  const getModules = () => {
    ModuleService.getModules(token)
      .then((response) => {
        setModules(response);
      })
      .catch((error) => {
        toast.error("Erro ao buscar módulos!");
        setError(true);
        setLoading(false);
      });
  };

  const getProfile = () => {
    ProfileService.getProfile(token, id)
      .then((response) => {
        setProfile(response);
      })
      .catch((error) => {
        toast.error("Erro ao buscar perfis!");
        setError(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    getProfile();
    getModules();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    modules &&
      modules.length > 0 &&
      modules.map((module) => {
        let result =
          profile &&
          profile.modules &&
          profile.modules.length > 0 &&
          profile.modules.filter((value) => module.id === value.moduleId);
        if (result && result.length > 0) {
          module.active = true;
        }
        return module;
      });
    setModules(modules);
    // eslint-disable-next-line
  }, [modules, profile]);

  const handleModule = (idModule, status) => {
    ModuleService.handleStatusModule(token, idModule, status, id)
      .then((response) => {
        if (response) {
          getProfile();
          getModules();
        }
        setLoading(false);
        setError(false);
      })
      .catch((error) => {
        // setSuccess(false);
        setLoading(false);
        setError(true);
      });
  };

  return (
    <div className="dashboard-container">
      {loading && (
        <div className={"loading-mask"}>
          <FontAwesomeIcon icon={faCircleNotch} pulse />
        </div>
      )}

      <h1>Gestão de acessos / Configuração de perfil</h1>
      <div className="module-container">
        <div className="module-item">
          <ListModules
            initicialModules={modules}
            initialProfile={profile}
            loading={loading}
            error={error}
            name={true}
            handleModule={handleModule}
            goBackManageAccess={e => props.goBackManageAccess(e)}
          />
        </div>
        <div className="module-item"></div>
      </div>
    </div>
  );
}
