import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  PROVEDORES: BASE_URL + "Provedor",
};

export default class ProvedoresService {
  static create = async (token, payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data } = await axios.post(URLS.PROVEDORES, payload, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static get = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        },
      };
        
    let url = URLS.PROVEDORES

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getRelacao = async (token, provedorId) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        },
      };
        
    let url = `${URLS.PROVEDORES}/${provedorId}/relacao`

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getRelacaoClienteComprador = async (token, compradorId) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        },
      };
        
    let url = `${URLS.PROVEDORES}/${compradorId}/relacao-cliente-comprador`

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static update = async (token, payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
          
    let url = URLS.PROVEDORES
    
    try {
      const { data } = await axios.put(url, payload, config);
      return data;
    } catch (err) {
      return err;
    }
  }

  static delete = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
      
    let url = URLS.PROVEDORES + "/" + id

    try {
      const { data } = await axios.delete(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getCountHomologacoes = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data } = await axios.post(
        // TO DO remover mock na integracao
        'https://run.mocky.io/v3/c612b867-54c9-4906-abf4-7be0011344d8',
        // URLS.PROVEDORES,
        {},
        config
      );
      return data;
    } catch (err) {
      return err;
    }
  };

  static getResultadosEscopo = async (token, provedorId) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        },
      };
        
    let url = `${URLS.PROVEDORES}/GetResultadosEscopo?provedorId=${provedorId}`;

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getResultadosEscopoPorComprador = async (token, compradorId) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        },
      };
        
    let url = `${URLS.PROVEDORES}/GetResultadosEscopoPorComprador?compradorId=${compradorId}`;

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getMateriaisServicosPorComprador = async (token, provedorId) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        },
      };
        
    let url = `${URLS.PROVEDORES}/GetMateriaisServicosPorComprador?provedorId=${provedorId}`;

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  }

    static vincularMateriaisServicos = async (token, payload) => {
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
      let url = `${URLS.PROVEDORES}/VincularMateriaisServicos`;

      try {
        const { data } = await axios.post(url, payload, config);
        return data;
      } catch (err) {
        return err;
      }
    };
  
}