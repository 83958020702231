import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import NumberFormat from "react-number-format";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons";

import "../private-app/dashboard/dashboard.scss";

export default function NewCriteria({ props, loading, initialAudit, send }) {
  const [audit, setAudit] = useState(initialAudit);

  const handleChange = (event) => {
    event.persist();
    setAudit((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  useEffect(() => {
    setAudit(initialAudit);
    // eslint-disable-next-line
  }, [initialAudit]);

  const handleSubmit = (e) => {
    e.preventDefault();
    send(audit);
  };

  const history = useHistory();

  return (
    <>
      <div className="user-config-container">
        <div className="card-container">
          <div className="card">
            <div className="card-header">
              <h1>Novo Critério</h1>
            </div>
            <div className="card-body">
              <form className="form-container" onSubmit={handleSubmit}>
                <div className="group-input">
                  <label>Nome</label>
                  <input
                    className="global-input"
                    // className={`global-input ${
                    // submitted && !values.name ? "error" : ""
                    // }`}
                    type="text"
                    id="name"
                    name="name"
                    value={audit.name || ""}
                    onChange={handleChange}
                  />
                  {/* {submitted && !values.name && (
                            <span className="error-msg">Esse campo é necessario</span>
                        )} */}
                </div>
                <div className="group-input">
                  <label>Aprovado</label>

                  <NumberFormat
                    decimalSeparator="."
                    decimalScale={1}
                    format="##.#"
                    id="approved"
                    name="approved"
                    value={audit.approved || ""}
                    className="global-input small"
                    onChange={handleChange}
                  />
                  {/* {submitted && !values.email && (
                            <span className="error-msg">Esse campo é necessario</span>
                        )} */}
                </div>
                <div className="group-input">
                  <label>Aprovação com restrição</label>
                  <NumberFormat
                    decimalSeparator="."
                    decimalScale={1}
                    format="##.#"
                    id="approvedRestriction"
                    name="approvedRestriction"
                    value={audit.approvedRestriction || ""}
                    className="global-input small"
                    onChange={handleChange}
                  />
                  {/* {submitted && !values.company && (
                            <span className="error-msg">Esse campo é necessario</span>
                        )} */}
                </div>
                <button className="global-btn">
                  SALVAR{" "}
                  {loading && <FontAwesomeIcon icon={faCircleNotch} pulse />}
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="back-container">
          <button
            className="global-btn"
            onClick={() => {
              history.goBack();
            }}
          >
            <FontAwesomeIcon icon={faAngleDoubleLeft} /> VOLTAR{" "}
            {loading && <FontAwesomeIcon icon={faCircleNotch} pulse />}
          </button>
        </div>
      </div>
    </>
  );
}
