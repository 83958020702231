import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import InputRadio from "../../../../components/InputRadio/InputRadio";

import EscopoAvaliacaoPerguntas from "../../../../services/EscopoAvaliacaoPerguntas";

export default function QuestionForm({ token, defaultCurrent, current, temas, callback, cancel }) {
  const [pergunta, setPergunta] = useState(current);
  const [sending, setSending] = useState(false);

  const create = (payload) => {
    if (!payload.temaId) {
      toast.error("Por favor, selecione um tema");
      return;
    }
    if (!payload.descricao) {
      toast.error("Por favor, informe a pergunta");
      return;
    }
    setSending(true);

    EscopoAvaliacaoPerguntas.create(token, payload)
      .then((response) => {
        if (response) {
          toast.success("Item cadastrado com sucesso!");
          setPergunta(defaultCurrent);
          setSending(false);
          callback();
        }
      })
      .catch((error) => {
        toast.error("Erro ao cadastrar item!");
        setSending(false);
      });
  }

  const update = (payload) => {
    if (!payload.temaId) {
      toast.error("Por favor, selecione um tema");
      return;
    }
    if (!payload.descricao) {
      toast.error("Por favor, informe a pergunta");
      return;
    }
    setSending(true);

    EscopoAvaliacaoPerguntas.update(token, payload)
      .then((response) => {
        if (response) {
          toast.success("Item editado com sucesso!");
          setPergunta(defaultCurrent);
          setSending(false);
          callback();
        }
      })
      .catch((error) => {
        toast.error("Erro ao editar item!");
        setSending(false);
      });
  }

  const handleChange = (e) => {
    e.persist();

    setPergunta((values) => ({
      ...values,
      [e.target.name]: (e.target.type == 'radio') ? (e.target.value == '1' ? true : false) : e.target.value,
    }));
  };

  const handleTheme = (e) => {
    e.persist();

    setPergunta((values) => ({
      ...values,
      [e.target.name]: parseInt(e.target.value),
    }));
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if (pergunta.id) {
      update(pergunta);
    } else {
      create(pergunta);
    }
    setPergunta(defaultCurrent);
  }

  const handleAuditoria = () => {
    let newArr = {...pergunta};
    newArr.auditorias = [
      ...(newArr.auditorias || []), {
        nome: "",
      }
    ];

    setPergunta(newArr);
  }

  const changeAuditoria = index => e => {
    let newObj = {...pergunta};
    newObj.auditorias[index].nome = e.target.value;

    setPergunta(newObj);
  }

  const handleCancelForm = () => {
    cancel();
  }

  useEffect(() => {
    if (current) {
      setPergunta(current)
    }
  }, [current]);

  return (
    <>
      <div className="card-container mb-2">
        <div className="card">
          <div className="card-header">
            <h1>CRIAR NOVA PERGUNTA</h1>
          </div>
          <div className="card-body p-0">
            <form className="form-container" onSubmit={handleSubmit}>
              <div className="group-input created-form m-0">
                <div className="row equal">
                  <div className="col-md-6">
                    <label>Tema</label>
                    <select
                      className="global-input"
                      type="text"
                      id="temaId"
                      placeholder="Tema"
                      name="temaId"
                      onChange={handleTheme}
                      disabled={pergunta.temaId}
                    >
                      <option value="">Selecione</option>
                      {(temas || []).map((data, i) => (
                        <option value={data.id} selected={pergunta.temaId == data.id ? true : false}>{data.name}</option>
                      ))}
                    </select>
                  </div>
                  <div className="col-md-6">
                    <label>Pergunta</label>
                    <textarea
                      className="global-input align-self-start"
                      id="descricao"
                      value={pergunta.descricao || ''}
                      placeholder="Pergunta"
                      name="descricao"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row equal">
                  <div className="col-md-6">
                    <label className="align-self-start">Suporte</label>
                    <textarea
                      className="global-input align-self-start"
                      id="pergunta"
                      value={pergunta.suporte || ''}
                      placeholder="Texto de suporte sobre essa pergunta."
                      name="suporte"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6">
                    <label className={"align-self-start labelEditAuditoria"}>Auditoria</label>
                    <button 
                      type="button"
                      className={"align-self-start global-btn auditoriaBtn"}
                      onClick={handleAuditoria}
                    >
                      <svg width="18" height="18" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.5799 10.5H10.4142V14.5H4.16567V10.5H0V4.5H4.16567V0.5H10.4142V4.5H14.5799V10.5Z" fill="#FFF"/>
                      </svg>
                    </button>
                    <div className={"align-self-start auditoriaFields"}>
                      {(pergunta.auditorias || []).map((data, i) => (
                        <input
                          className="global-input"
                          type="text"
                          id="pergunta"
                          value={data.nome || ''}
                          placeholder={"Padrão de pergunta "+(i+1)}
                          onChange={changeAuditoria(i)}
                        />
                      ))}
                    </div>
                  </div>
                </div>
                <div className="row equal">
                  <div className="col-md-6">
                    <label>Crítico ou não Crítico</label>
                    <div className="InputRadioGroup">
                      <InputRadio name="critico" label="Sim" value="1" checked={pergunta.critico} onChange={handleChange} />
                      <InputRadio name="critico" label="Não" value="0" checked={!pergunta.critico ? '0' : pergunta.critico} onChange={handleChange} />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label>Upload Obrigatório</label>
                    <div className="InputRadioGroup">
                      <InputRadio name="uploadObrigatorio" label="Sim" value="1" checked={pergunta.uploadObrigatorio} onChange={handleChange} />
                      <InputRadio name="uploadObrigatorio" label="Não" value="0" checked={!pergunta.uploadObrigatorio ? '0' : pergunta.uploadObrigatorio} onChange={handleChange} />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <button 
                      type="button"
                      className="global-btn pull-left mb-2"
                      disabled={sending && true}
                      onClick={handleCancelForm}
                    >
                      {"VOLTAR"}
                    </button>
                    <button 
                      type="submit"
                      className="global-btn pull-right mb-2"
                      disabled={sending && true}
                    >
                      {sending ? '' : pergunta.id ? "SALVAR" : "CRIAR"}
                      {sending && <FontAwesomeIcon icon={faCircleNotch} pulse />}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
} 