import axios from "axios";

import { BASE_URL } from "./settings";

const URLS = {
  LOGIN: BASE_URL + "users/authenticate/",
  // VERIFY: BASE_URL + 'token/refresh',
  FORGOT: BASE_URL + "users/password/reset/",
  RESET: BASE_URL + "users/password/reset/confirm/",
  SIGNUP: BASE_URL + "users",
  RECOVER: BASE_URL + "users/forgot-password/",
  CHANGE_PASSWORD: BASE_URL + "users/change-password/",
  VALIDATE_RECOVER: BASE_URL + "user/validate-recover-password",
  NEW_PASSWORD: BASE_URL + "auth/confirm-code",
  GET_CURRENT: BASE_URL + "organization/current",
  ACCESS: BASE_URL + "access/@mine",
};

const LOCAL_STORAGE_KEY_TOKEN = btoa("token");
const LOCAL_STORAGE_KEY_USER = btoa("user");

export default class AuthService {
  static login(email, password) {
    let default_error_message = "Authentication failed.";

    return new Promise((resolve, reject) => {
      axios
        .post(URLS.LOGIN, { email, password })
        .then((response) => {
          this.save_token_storage(response.data.token);
          this.save_user_storage(response.data);
          resolve(response.data);
          // return response.data;
        })
        .catch((error) => {
          // If the server responded with status 500, there's a message.
          if (
            error &&
            error.response &&
            error.response.status === 500 &&
            error.response.data
          ) {
            return reject({ message: error.response.data.message ? error.response.data.message : error.response.data });
          }

          if (
            error &&
            error.response &&
            error.response.status === 400 &&
            error.response.data 
          ) {
            return reject({ message: error.response.data.message ? error.response.data.message : error.response.data });
          }

          if (
            error &&
            error.response &&
            error.response.status === 404 &&
            error.response.data &&
            error.response.data.message
          ) {
            return reject({ message: error.response.data.message });
          }

          // Otherwise, just send the default message
          return reject({ default_error_message });
        });
    });
  }

  static save_token_storage(token) {
    window.localStorage.setItem(LOCAL_STORAGE_KEY_TOKEN, btoa(token));
  }

  static save_user_storage(user) {
    window.localStorage.setItem(
      LOCAL_STORAGE_KEY_USER,
      btoa(JSON.stringify(user))
    );
  }

  static get_token_storage() {
    let token = window.localStorage.getItem(LOCAL_STORAGE_KEY_TOKEN);
    return token ? atob(token) : null;
  }

  static get_user_storage() {
    let user = window.localStorage.getItem(LOCAL_STORAGE_KEY_USER);
    return user ? JSON.parse(atob(user)) : null;
  }

  static get_context() {
    let context = window.localStorage.getItem("context");
    return context ? context : null;
  }

  static get_plan() {
    let plan = window.localStorage.getItem("plan");
    return plan ? plan : null;
  }

  static clear_token_and_user_storage() {
    window.localStorage.clear();
  }

  static forgot_password(email) {
    return new Promise((resolve, reject) => {
      axios
        .post(URLS.FORGOT, { email: email })
        .then((res) => resolve(res.data))
        .catch((error) => {
          // If the server responded with status 400, there's a message.
          if (
            error &&
            error.response &&
            error.response.status === 400 &&
            error.response.data
          )
            return reject(error.response.data);

          return reject({
            non_field_errors: ["There is an error. Please try again."],
          });
        });
    });
  }

  static reset_password(new_password1, new_password2, uid, token) {
    let data = {
      new_password1,
      new_password2,
      uid,
      token,
    };

    return new Promise((resolve, reject) => {
      axios
        .post(URLS.RESET, data)
        .then((res) => resolve(res.data))
        .catch((error) => {
          // If the server responded with status 400, there's a message.
          if (
            error &&
            error.response &&
            error.response.status === 400 &&
            error.response.data
          )
            return reject(error.response.data);
          return reject({
            non_field_errors: ["There is an error. Please try again."],
          });
        });
    });
  }

  // recover password, send code to the email provide
  static recover = async (email) => {
    let url = URLS.RECOVER + email;

    try {
      const { data } = await axios.get(url);
      return data;
    } catch (err) {
      return err;
    } finally {
      // this.setState({ loading: false });
    }
  };

  // reset password, send code to the email provide
  static changePassword = async (values) => {
    let url = URLS.CHANGE_PASSWORD;

    try {
      const { data } = await axios.post(url, values);
      return data;
    } catch (err) {
      return err;
    } finally {
      // this.setState({ loading: false });
    }
  };

  static validateRecover(token) {
    let url = URLS.VALIDATE_RECOVER + "/" + token;

    return new Promise((resolve, reject) => {
      axios
        .get(url, token)
        .then((response) => {
          return resolve(response.data);
        })
        .catch((error) => {
          if (error) {
            return reject({
              non_field_errors: "Erro ao tentar recuperar senha.",
            });
          }
        });
    });
  }

  static newPass(code, password) {
    let url = URLS.NEW_PASSWORD;

    let data = {
      password,
      code,
    };

    return new Promise((resolve, reject) => {
      axios
        .post(url, data)
        .then((response) => {
          return resolve(response.data);
        })
        .catch((error) => {
          if (error) {
            return reject({
              non_field_errors: "Erro ao tentar atualizar senha.",
            });
          }
        });
    });
  }
}
