export class PrintPDF {

    constructor(data) {
        this.data = data;
    }

    async PrepareDocument() {
        const documentBody = this.CreateBodyDocument();
        const document = this.GenerateDocument(documentBody);
        return document;
    }

    CreateBodyDocument() {
        const header = [
            { text: 'Código', style: [ 'headerStyle' ] },
            { text: 'Material/Serviço', style: [ 'headerStyle' ] },
            { text: 'Nome', style: [ 'headerStyle' ] },
            { text: 'Status', style: [ 'headerStyle' ] },
            { text: 'Resultado', style: [ 'headerStyle' ] }
        ]
        const body = this.data.map((prod) => {
            let material = prod.materialServices.map(mat => mat.name );
            let status = (prod.status === 1) ? 'Ativo' : 'Inativo';

            return [
                { text: prod.codigo },
                { ul: material },
                { text: prod.nome },
                { text: status },
                { text: prod.resultado }

            ]
        });

        let content = [header];
        content = [...content, ...body];
        return content;
    }

    GenerateDocument(documentBody) {
        const document = {
            pageSize: 'A4',
            pageMargins: [14, 53, 14, 48],
            header: function () {
                return {
                    margin: [14, 12, 14, 0],
                    layout: 'noBorders',
                    table: {
                        widths: ['*'],
                        body: [
                            [
                                { text: 'Relação de Compradores', style: 'reportName' }
                            ]
                        ],
                    },
                };
            },
            content: [{
                style: 'tableStyle',
                table: {
                    widths: [60, '*', '*', 40, 80],
                    body: documentBody
                }
            }],
            footer(currentPage, pageCount) {
                return {
                    layout: 'noBorders',
                    margin: [14, 0, 14, 22],
                    table: {
                        widths: ['auto'],
                        body: [
                            [
                                {
                                    text:
                                        '_________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________________',
                                    alignment: 'center',
                                    fontSize: 5,
                                },
                            ],
                            [
                                [
                                    {
                                        text: `Página ${currentPage.toString()} de ${pageCount}`,
                                        fontSize: 7,
                                        alignment: 'right',
                                        margin: [3, 0],
                                    },
                                    {
                                        text: 'Supply Compliance',
                                        fontSize: 7,
                                        alignment: 'center',
                                    },
                                ],
                            ],
                        ],
                    },
                };
            },
            styles: {
                headerStyle: {
                    fontSize: 11, 
                    bold: true
                },
                reportName: {
                    fontSize: 16,
                    bold: true,
                    alignment: 'center',
                    margin: [0, 4, 0, 0],
                },
                tableStyle: {
                    fontSize: 9,
                },
            },

        };
        return document;
    }
}