import React, { useEffect, useState } from "react";

import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faSortDown,
  faCircleNotch,
  faSearch,
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";


import Pagination from "react-js-pagination";
import Modal from "../../../components/ModalExit";
import MaterialsServicesService from "../../../services/MaterialsServicesService";
import { toast } from "react-toastify";

export default function ListMaterialService({ initialMaterialsService, token, updated }) {
  const [materialService, setMaterialsService] = useState(initialMaterialsService);
  const [modal, setModal] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [currentId, setCurrentId] = useState("");


  const getMaterialsServices = () => {
    setLoading(true);
    MaterialsServicesService.getMaterialsServices(token)
      .then((response) => {
        if (response) {
          if (response.valores.length > 0) {
            setMaterialsService(response.valores); 
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
      });
  };

  const handleMaterial = (name, status, id) => {
    MaterialsServicesService.updateStatusMaterialsService(token, name, !status, id)
      .then((response) => {
        if (response) {
          getMaterialsServices();
        }
        setLoading(false);
        setError(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
      });
  };



  const handleDelete = () => {
    setLoading(true);
    setError(false);
    MaterialsServicesService.deleteMaterialService(token, currentId)
      .then((response) => {
        if (response) {
          toast.success("Item excluído com sucesso!");
          setLoading(false);
          setError(false);
          getMaterialsServices();
          handleModal(false);
        }
        setLoading(false);
        setError(false);
      })
      .catch((error) => {
        toast.error("Erro ao excluir item!");
        setLoading(false);
        setError(true);
      });
  };

  const handleModal = (value) => {
    setModal(value);
  };

  const handleId = (id) => {
    setCurrentId(id);
    handleModal(true);
  };
  
  useEffect(() => {
    getMaterialsServices();
    // eslint-disable-next-line
  }, []);
  
  useEffect(() => {
    getMaterialsServices();
    // eslint-disable-next-line
  }, [updated]);


  if (redirect) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  return (
    <>
      {modal && (
          <Modal handleDelete={handleDelete} handleModal={handleModal} />
      )}
      <div className="user-list-container">
        {loading && (
          <div className={"loading-mask"}>
            <FontAwesomeIcon icon={faCircleNotch} pulse />
          </div>
        )}

        <div className="list-container">
          <div className="list">
            <div className="list-header">
              <label className="space-list">Nome do Campo</label>
              <label>Ativar / Desativar</label>
              <label>Excluir</label>
            </div>

            {materialService &&
              materialService.length > 0 &&
              materialService.map((materialService, index) => (
                <div className="list-body" key={index}>
                  <span className="list-item space-list">{materialService.name}</span>
                  <span  className="list-item">
                    <div className="switch-container" key={index}>
                      <label className="toggle-switch">
                        <input
                          type="checkbox"
                          checked={materialService.isEnable ? materialService.isEnable : false}
                          value={materialService.isEnable || false}
                          onChange={() => handleMaterial(materialService.name, materialService.isEnable, materialService.id)}
                        />
                        <span className="switch">
                          <p>{materialService.isEnable ? "Ativo" : "Inativo"}</p>
                        </span>
                      </label>
                    </div>
                  </span>
                  <span className="list-item">
                    <a onClick={()=>handleId(materialService.id)}>
                        <svg 
                          width="24" 
                          height="24" 
                          viewBox="0 0 24 24" 
                          fill="none" 
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path 
                            d="M17.2096 6.32797H13.5054V6.17363C13.5054 5.09325 12.6566 4.24437 11.5762 4.24437H11.4218C10.3414 4.24437 9.49257 5.09325 9.49257 6.17363V6.32797H5.71122C5.47971 6.32797 5.2482 6.55949 5.2482 6.791V8.72026C5.32537 8.95177 5.47971 9.10611 5.71122 9.10611H5.94273L7.25463 18.5209C7.25463 18.7524 7.48614 18.9068 7.71765 18.9068H15.2804C15.5119 18.9068 15.6662 18.7524 15.7434 18.5209L16.9781 9.10611H17.2096C17.4411 9.10611 17.6726 8.8746 17.6726 8.64309V6.791C17.6726 6.55949 17.4411 6.32797 17.2096 6.32797ZM10.2643 6.17363C10.2643 5.55627 10.7273 5.09325 11.3447 5.09325H11.499C12.1164 5.09325 12.5794 5.55627 12.5794 6.17363V6.32797H10.2643V6.17363ZM14.8173 18.0579H8.02633L6.86878 9.10611H16.0521L14.8173 18.0579ZM16.7466 8.25723C16.6694 8.25723 6.25141 8.25723 6.17424 8.25723V7.25402H16.7466V8.25723ZM9.56974 16.8232C9.80125 16.8232 9.95559 16.5916 9.95559 16.3601L9.4154 10.5723C9.4154 10.3408 9.18389 10.1865 8.95238 10.1865C8.72087 10.1865 8.56653 10.418 8.56653 10.6495L9.10672 16.4373C9.10672 16.6688 9.33823 16.8232 9.56974 16.8232ZM13.3511 16.8232C13.5826 16.8232 13.8141 16.6688 13.8141 16.4373L14.3543 10.6495C14.3543 10.418 14.2 10.1865 13.9685 10.1865C13.7369 10.1865 13.5054 10.3408 13.5054 10.5723L12.9652 16.3601C12.9652 16.5916 13.1196 16.8232 13.3511 16.8232ZM11.4218 16.8232C11.6533 16.8232 11.8849 16.5916 11.8849 16.3601V10.5723C11.8849 10.3408 11.6533 10.1093 11.4218 10.1093C11.1903 10.1093 10.9588 10.3408 10.9588 10.5723V16.3601C11.036 16.6688 11.1903 16.8232 11.4218 16.8232ZM24.0006 17.1318C24.0006 20.9132 20.991 24 17.1324 24H6.86878C3.08743 24 0.000610352 20.9904 0.000610352 17.1318V6.86817C0.000610352 3.08682 3.01026 0 6.86878 0H17.1324C20.9138 0 24.0006 3.00965 24.0006 6.86817V17.1318Z" 
                            fill="#4B4B4B"
                          />
                        </svg>
                    </a>
                  </span>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
