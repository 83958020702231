import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Provider } from "react-redux";
import Routes from "./routes/Routes";
import store from "./redux/store";

import { ToastContainer } from "react-toastify";

import './private-app/dashboard/dashboard.scss'
import "./variables.scss"
import "react-toastify/dist/ReactToastify.css";

ReactDOM.render(
  <Provider store={store}>
    <Routes />
    <ToastContainer autoClose={3000} />
  </Provider>,
  document.getElementById("root")
);
