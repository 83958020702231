import React, { useState } from "react";
import { Link } from 'react-router-dom'

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import "../forget.scss";

export default function ForgetForm({
  initialValues,
  title,
  send,
  error,
  loading,
}) {
  const [passwordVerification, setPasswordVerification] = useState(false);
  const [passwordValidation, setPasswordValidation] = useState(false);
  const [submitted, setSubmitted] = useState(false);

  const [values, setValues] = useState(initialValues);

  const handleChange = (event) => {
    event.persist();
    setValues((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    setPasswordVerification(false);
    setPasswordValidation(false);

    let passwordValidation = new RegExp(
      /^(?=.*\d)(?=.*[a-z])(?=.*[a-zA-Z]).{8,}$/gm
    ).test(values.password);

    if (passwordValidation === false) {
      setPasswordValidation(true);
    }

    if (values.password !== values.passwordConfirm) {
      setPasswordVerification(true);
    }

    send(values);
  };

  return (
    <div className="forget-container">
      {error && <span className="error-list">{error && error}</span>}
      <form className="form-container" onSubmit={handleSubmit}>
        <div className="header-container">
          <div className="public-app-item">
            <h1>{title}</h1>
          </div>
          <div className="public-app-item item-rigth">
            <p>
              {" "}
              ou <Link to="/login">login</Link>{" "}
            </p>
          </div>
        </div>
        <div className="group-input">
          <input
            placeholder="Código *"
            type="text"
            className={`global-input ${
              submitted && !values.code ? "error" : ""
            }`}
            id="code"
            name="code"
            value={values.code}
            onChange={handleChange}
          />
          {submitted && !values.code && (
            <span className="error-msg">Esse campo é necessario</span>
          )}
        </div>
        <div className="group-input">
          <input
            type="password"
            placeholder="Senha *"
            className={`global-input ${
              submitted && !values.password ? "error" : ""
            }`}
            id="password"
            name="password"
            value={values.password}
            onChange={handleChange}
          />
          {submitted && !values.password && (
            <span className="error-msg">Esse campo é necessario</span>
          )}
        </div>
        <div className="group-input">
          <input
            type="password"
            placeholder="Confirmar senha *"
            className={`global-input ${
              submitted && !values.passwordConfirm ? "error" : ""
            }`}
            id="passwordConfirm"
            name="passwordConfirm"
            value={values.passwordConfirm}
            onChange={handleChange}
          />
          {submitted && !values.passwordConfirm && (
            <span className="error-msg">Esse campo é necessario</span>
          )}

          {passwordVerification && (
            <span className="error-msg">Os campos devem ser iguais</span>
          )}

          {passwordValidation && (
            <span className="error-msg">
              Os campos senha devem conter no mínimo de 8 caracteres alternados
              com letras, números e caracteres
            </span>
          )}
        </div>
        <button className="global-btn">
          Enviar {loading && <FontAwesomeIcon icon={faCircleNotch} pulse />}
        </button>
      </form>
    </div>
  );
}
