import React, { useState, useEffect } from "react";

import CardHeader from "./components/CardHeader";
import Status from "./components/Status";
import Nav from "./components/Nav/index";

import Resultados from "./Pages/Resultados";
import IdoneidadeIntegridade from "./Pages/IdoneidadeIntegridade";
import DocumentosLicencas from "./Pages/DocumentosLicencas";
import ProdutosHomologados from "./Pages/ProdutosHomologados";
import HistoricoNotificacao from "./Pages/HistoricoNotificacao";
import HistoricoRecebimento from "./Pages/HistoricoRecebimento";
import ConsultarCnpj from "./Pages/ConsultarCnpj";
import ProvedoresService from "../../../services/ProvedoresService"

export default function Index({ token, handleBanck, provedorId }) {
    const initialValues = 
        {provedor:{provedor:
            {nome:"", telefone:"", contato:"", 
             email:"", webSite:"", logo:"",logradouro:"",
             numero:"", nomeSocial:"", IncricaoEstadual:"",
             razaoSocial:"", nomeEmpresa:"", cnpj:"", cargo:""}}}
    
    const [module, setModule] = useState('resultados')
    const [isShow, setIsShow] = useState(true)
    const [provedor, setProvedor] = useState(initialValues)

    const handleShow = (e) => {
        setIsShow(e)
    }

    const _init = async () => {
        const provedorResult = await ProvedoresService.getRelacao(token, provedorId);
        setProvedor(provedorResult);
    }

    useEffect(() => {
        _init()
    }, [])

    useEffect(() => {
        handleShow(true)
    }, [module])

    return (
        <>
            <CardHeader provedor={provedor.provedor} />
            <Status />
            <Nav handleModule={setModule} />

            {module === 'resultados' &&
                <Resultados token={token} createQuestion={handleShow} provedorId={provedorId} />
            }

            {module === 'idoneidade-e-integridade' &&
                <IdoneidadeIntegridade token={token} />
            }

            {module === 'documentos-e-Licencas' &&
                <DocumentosLicencas token={token} provedor={provedor} />
            }

            {module === 'produtos-homologados' &&
                <ProdutosHomologados token={token} provedor={provedor.provedor} />
            }

            {module === 'historico-de-Notificacao' &&
                <HistoricoNotificacao token={token} />
            }

            {module === 'historico-de-recebimento' &&
                <HistoricoRecebimento token={token} />
            }
            {module === 'consultar-cnpj' &&
                <ConsultarCnpj cnpj={provedor.provedor.cnpj} />
            }

            {isShow &&
                <div className={`row mx-0`}>
                    <div className="col-12 px-0 justify-content-between">
                        <button
                            type="button"
                            className="global-btn w-auto"
                            onClick={() => handleBanck(true)}
                        >
                            <svg className="mr-1 back-icon" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.47211 5.05021L5.10332 9.09061L4.31819 9.69667L0 5.05021L4.31819 0.302734L5.00518 1.00981L1.47211 5.05021ZM9.71593 1.00981L9.02894 0.302734L4.71075 5.05021L9.02894 9.69667L9.71593 8.9896L6.18286 5.05021L9.71593 1.00981Z" fill="white" />
                            </svg>
                            VOLTAR
                        </button>
                    </div>
                </div>
            }
        </>
    );
}
