import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  BUYER: BASE_URL + "users",
  MODULES: BASE_URL + "modules",
};

export default class BuyerService {
  static register = async (token, user) => {
    let config = token
    ? {
        headers: {
          Authorization: token == '' ? '' : `Bearer ${token}`
        }
      }
    : {}

    try {
      const { data } = await axios.post(URLS.BUYER, user, config);
      return data;
    } catch (err) {
      return err;
      // throw new Error(err);
    } finally {
      // this.setState({ loading: false });
    }
  };

  static getBuyers = async (token, page) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.BUYER + "/compradores/" + page;

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static searchBuyers = async (token, page, input) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.BUYER + "/compradores/" + page + "/" + input;

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getBuyer = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.BUYER + "/" + id;

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static handleBuyer = async (token, id, isActive) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.BUYER;

    if (isActive) {
      url = url + "/activate";
    } else {
      url = url + "/deactivate";
    }

    url = url + "/" + id;

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };
  

  static getModules = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.MODULES;

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };
}
