import React, { useState } from 'react';

import { DebounceInput } from 'react-debounce-input';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown } from '@fortawesome/free-solid-svg-icons';
import './styles.module.scss';

export default function DropList({
  uniqid,
  label,
  input,
  options,
  active,
  onClick,
  selectable,
  selectedOption,
  defaultValue,
  multSelect,
  onChange,
  handleChange,
  placeholder,
  values,
}) {
  const [selected, setSelected] = useState([]);

  return (
    <>
      <div className={'DropList ' + (active ? 'active' : '')}>
        <div className="DropList-container">
          <span className="DropList-header">
            <FontAwesomeIcon
              icon={faSortDown}
              onClick={() => {
                onClick(uniqid);
              }}
            />
            {label ? <p className="DropList-label">{label}</p> : ``}
            {input && active && 'active' ? (
              <DebounceInput
                style={{
                  borderRadius: '10px',
                  border: 'none',
                  outline: '0',
                  paddingL: '3px',
                  height: '29px'
                }}
                placeholder={placeholder}
                debounceTimeout={300}
                value={values}
                onChange={(event) => onChange(event.target.value)}
              />
            ) : (
              ``
            )}
          </span>
          <ul
            className={`DropList-options selectable-${selectable} multSelect-${multSelect}`}
          >
            {(options || []).map((option, i) => {
              return (
                <li
                  class="cursor-pointer"
                  key={i}
                  onClick={() => {
                    onClick(uniqid);
                    handleChange(option.id);
                  }}
                >
                  {option.name}
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}
