import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import "../dashboard/dashboard.scss";

import InformationTypeForm from "./Form/InformationTypeForm";

import InformationTypeService from "../../services/InformationTypeService";

export default function AddInformationType(props) {
  const { token } = props.state;
  const [informationType] = useState({
    name: "",
  });

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const send = (informationType) => {
    setLoading(true);
    setError(false);
    setSuccess(false);
    setRedirect(false);

    InformationTypeService.postInformationType(token, informationType)
      .then((response) => {
        if (response) {
          toast.success("Dados salvos com sucesso!");
          setSuccess(true);
          setRedirect(true);
        }
        setLoading(false);
        setError(false);
      })
      .catch((error) => {
        toast.error("Erro ao salvar dados!");
        setSuccess(false);
        setLoading(false);
        setError(true);
        setRedirect(false);
      });
  };

  if (redirect) {
    return <Redirect to={{ pathname: "/tipo-informacao" }} />;
  }

  return (
    <>
      <div className="dashboard-container">
        {loading && (
          <div className={"loading-mask"}>
            <FontAwesomeIcon icon={faCircleNotch} pulse />
          </div>
        )}
        <h1>Cadastro de tipos de informações</h1>
        
        <div className="tipo-informacao-container">
          <InformationTypeForm
            loading={loading}
            error={error}
            send={send}
            initialInformationType={informationType}
          />
        </div>
      </div>
    </>
  );
}
