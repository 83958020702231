import React from 'react';
import { render } from 'react-dom';

import './provedores-chart.scss'
import { Doughnut } from 'react-chartjs-2';

class ProvedorChart extends React.Component {

    render() {
        return (
            <>
                <div className="chart" style={{ height: '100px', width: '100px' }}>
                    <Doughnut
                        data={{
                            datasets: [
                                {
                                    data: [
                                        this.props.provedoresCount
                                            ? this.props.provedoresCount.homologados
                                            : 0,
                                        this.props.provedoresCount
                                            ? this.props.provedoresCount.emHomologacao
                                            : 0,
                                        this.props.provedoresCount
                                            ? this.props.provedoresCount.naoHomologados
                                            : 0
                                    ],
                                    backgroundColor: ['#00D17E', '#FEBD59', '#FF7461'],
                                    cutout: 40
                                }
                            ],
                            labels: ['Red', 'Yellow', 'Blue'],
                        }}
                        options={{
                            responsive: true,
                            maintainAspectRatio: false,
                            aspectRatio: 1,
                            plugins: {
                                legend: {
                                    display: false
                                },
                                tooltip: { enabled: false }
                            }
                        }}
                        legend={{ display: false }}
                    />
                </div>
            </>
        );
    }
}

render(<ProvedorChart />, document.getElementById('root'));

export default ProvedorChart;