import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";

import CardInfoHorizontal from "../../components/CardInfoHorizontal";

import IdoneidadeIntegridadeService from './../../../../../services/IdoneidadeIntegridadeService';

import './styles.scss'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

export default function IdoneidadeIntegridade({ token }) {
    const [loading, setLoading] = useState(false);
    const [idoneidadeIntegridades, setIdoneidadeIntegridades] = useState([]);
    const [error, setError] = useState(false);

    useEffect(() => {
        getIdoneidadeIntegridade();
    }, []);

    const getIdoneidadeIntegridade = () => {
        setLoading(true);
        IdoneidadeIntegridadeService.getIdoneidadeIntegridade(token)
            .then((response) => {
                if (response) {
                    if (response.valores.length > 0) {
                        setIdoneidadeIntegridades(response.valores);
                    }
                }
                setLoading(false);
            })
            .catch((error) => {
                setError(true);
                setLoading(false);
            });
    };

    return (
        <div className="idoneidade-e-integridade-container">
            {loading && (
                <div className={"loading-mask"}>
                    <FontAwesomeIcon icon={faCircleNotch} pulse />
                </div>
            )}
            <CardInfoHorizontal
                data01={{ title: 'DOCUMENTOS CADASTRADOS', value: 4 }}
                data02={{ title: 'REGULARIDADES CONFIRMADAS', value: 3 }}
                data03={{ title: 'ÍNDICE', value: 75 }}
            />

            <div className="card-container">
                <div className="card subcard">
                    <div className="card-header">
                        <h1>IDONEIDADE  E INTEGRIDADE</h1>

                        <div className="validate-data-wrap">
                            <b>Aceitar com pendência</b>

                            <div>
                                <button
                                    className="global-btn baby-blue"
                                >
                                    Sim
                                </button>

                                <button
                                    className="global-btn baby-blue"
                                >
                                    Não
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        <div className="list-container">
                            <div className="list">
                                <div className="list-header">
                                    <label className="space-list">Avaliação realizada</label>
                                    <label>Status</label>
                                    <label style={{ flexGrow: 0 }}>Ver documento</label>
                                </div>

                                {idoneidadeIntegridades && idoneidadeIntegridades.map((data, i) => (
                                    <div className="list-body" key={data.id}>
                                        <span className="list-item space-list">{data.nome}</span>
                                        <span className={`list-item staus-color-${'Regular' === 'Regular' ? 'green' : 'red'}`}>
                                            {'Regular'}
                                        </span>
                                        <span className="list-item document-list">
                                            <a className="list-button" onClick={() => console.log('click')}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M19 0H5C2.3 0 0 2.2 0 5V19C0 21.7 2.2 24 5 24H19C21.8 24 24 21.8 24 19V5C24 2.3 21.8 0 19 0ZM21.3 12.3L21.2 12.4C20.1 14 18.6 15.3 16.9 16.3C13.9 18 10.1 18 7.1 16.3C5.4 15.3 3.9 14 2.8 12.4L2.7 12.3C2.6 12.1 2.6 11.8 2.7 11.6L2.8 11.5C3.9 10 5.4 8.7 7.1 7.7C10.1 6 13.9 6 16.9 7.7C18.6 8.7 20.1 10 21.2 11.6L21.3 11.7C21.5 11.8 21.5 12.2 21.3 12.3ZM12 13.2C11.4 13.2 10.9 12.7 10.9 12.1C10.9 11.5 11.4 11 12 11C12.6 11 13.1 11.5 13.1 12.1C13.1 12.7 12.6 13.2 12 13.2ZM12 8.7C10.2 8.7 8.7 10.2 8.7 12C8.7 13.8 10.2 15.3 12 15.3C13.9 15.4 15.4 13.9 15.3 12C15.3 10.2 13.8 8.7 12 8.7Z" fill="#4B4B4B" />
                                                </svg>
                                            </a>
                                        </span>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="card-header-right">
                            <div className="header-right">
                                <label>VERIFICAÇÕES AUTOMÁTICAS</label>
                            </div>
                            <div className="card-right">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}