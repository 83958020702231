import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import OrganismosCertificadoresService from "../../../services/OrganismosCertificadoresService";
import { toast } from "react-toastify";

export default function OrganismosCertificadoresForm({
  token,
  send,
  currentOrganismoCertificador,
  updated,
}) {
  const [loading, setLoading] = useState(false);
  const [organismoCertificador, setOrganismoCertificador] = useState({
    name: "",
    responsible: "",
    email: "",
    site: "",
    details: "",
  });

  const handleChange = (event) => {
    event.persist();
    setOrganismoCertificador((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if (currentOrganismoCertificador && currentOrganismoCertificador.id > 0) {
      return handleUpdate();
    }
    handleRegister();
  };

  const handleRegister = () => {
    setLoading(true);
    OrganismosCertificadoresService.registerOrganismosCertificadores(token, organismoCertificador)
      .then((response) => {
        setLoading(false);
        setOrganismoCertificador({
          name: "",
          responsible: "",
          email: "",
          site: "",
          details: "",
        });
        send(response)
      })
      .catch((reason) => {
        let response = reason.response || {};
        toast.error(response.data || "Erro ao cadastrar item! Verifique os campos obrigatórios");
        setLoading(false);
      });
  };

  const handleUpdate = () => {
    setLoading(true);
    OrganismosCertificadoresService.updateOrganismoCertificador(token, organismoCertificador)
      .then((response) => {
        if (response) {
          setLoading(false);
        }
        setLoading(false);
        setOrganismoCertificador({
          name: "",
          responsible: "",
          email: "",
          site: "",
          details: "",
        })
        send(response)
      })
      .catch((error) => {
        toast.error(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    if (currentOrganismoCertificador) {
      setOrganismoCertificador(currentOrganismoCertificador)
    }


  }, currentOrganismoCertificador)

  const history = useHistory();

  return (
    <>
      <div className="user-config-container-oc">
        <div className="card-container-oc">
          <div className="card">
            <div className="card-header-oc">
              <h1>CADASTRAR NOVO ORGANISMO DE CERTIFICAÇÃO</h1>
            </div>
            <div className="card-body-oc">
              <form className="form-container-oc" onSubmit={handleSubmit}>
                <div className="group-input created-form gt">
                  <div className="organismo-cert-form-oc">
                    <label>Nome do Orgão</label>
                    <input
                      className="global-input"
                      type="text"
                      id="name"
                      placeholder=""
                      name="name"
                      value={organismoCertificador.name}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="line-center-height"></div>
                  <div class="organismo-cert-form-oc">
                    <label>Responsável</label>
                    <input
                      className="global-input"
                      type="text"
                      id="responsible"
                      placeholder=""
                      name="responsible"
                      value={organismoCertificador.responsible}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="group-input created-form gt">
                  <div className="organismo-cert-form-oc">
                    <label>Email</label>
                    <input
                      className="global-input"
                      type="text"
                      id="email"
                      placeholder=""
                      name="email"
                      value={organismoCertificador.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div class="line-center-height"></div>
                  <div className="organismo-cert-form-oc">
                    <label>Site</label>
                    <input
                      className="global-input"
                      type="text"
                      id="site"
                      placeholder=""
                      name="site"
                      value={organismoCertificador.site}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="group-input created-form lt">
                  <label>Detalhes</label>
                  <textarea
                    className="global-input"
                    type="text"
                    id="details"
                    placeholder=""
                    name="details"
                    value={organismoCertificador.details}
                    onChange={handleChange}
                  />
                  <button className="global-btn organismo-cert">
                    {organismoCertificador &&
                      organismoCertificador.id > 0
                      ? !loading && "SALVAR"
                      : !loading && "CRIAR"}

                    {loading && <FontAwesomeIcon icon={faCircleNotch} pulse />}
                  </button>
                  <div class="height-line" ></div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
