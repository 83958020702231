import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import OrganismosCertificadoresService from "../../../services/OrganismosCertificadoresService";
import { toast } from "react-toastify";

export default function OrganismosCertificadoresForm({
  token,
  send,
  currentOrganismoCertificador,
}) {
  const [loading, setLoading] = useState(false);
  const [organismoCertificador, setOrganismoCertificador] = useState({
    name: "",
    responsible: "",
    email: "",
    site: "",
    details: "",
  });

  const handleChange = (event) => {
    event.persist();
    setOrganismoCertificador((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (currentOrganismoCertificador && currentOrganismoCertificador.id > 0) {
      return handleUpdate();
    }
    handleRegister();
  };

  const handleRegister = () => {
    setLoading(true);
    OrganismosCertificadoresService.registerOrganismosCertificadores(token, organismoCertificador)
      .then((response) => {
        if (response) {
          setLoading(false);
        }
        setLoading(false);
        send(response)
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleUpdate = () => {
    setLoading(true);
    OrganismosCertificadoresService.updateOrganismoCertificador(token, organismoCertificador, currentOrganismoCertificador.id)
      .then((response) => {
        if (response) {
          setLoading(false);
        }
        setLoading(false);
        setOrganismoCertificador({})
        send(response)
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const history = useHistory();

  return (
    <>
      <div className="user-config-container">
        <div className="card-container">
          <div className="card">
            <div className="card-header">
              <h1>CADASTRAR NOVO ORGANISMO DE CERTIFICAÇÃO</h1>
            </div>
            <div className="card-body">
              <form className="form-container" onSubmit={handleSubmit}>
                <div className="group-input created-form gt">
                  <div className="organismo-cert-form">
                    <label>Nome do Orgão</label>
                    <input
                      className="global-input"
                      type="text"
                      id="name"
                      placeholder=""
                      name="name"
                      value={organismoCertificador.name}
                      onChange={handleChange}
                    />
                  </div>

                  <div class="organismo-cert-form">
                    <label>Responsável</label>
                    <input
                      className="global-input"
                      type="text"
                      id="responsible"
                      placeholder=""
                      name="responsible"
                      value={organismoCertificador.responsible}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="group-input created-form gt">
                  <div className="organismo-cert-form">
                    <label>Email</label>
                    <input
                      className="global-input"
                      type="text"
                      id="email"
                      placeholder=""
                      name="email"
                      value={organismoCertificador.email}
                      onChange={handleChange}
                    />
                  </div>
                  <div  className="organismo-cert-form">
                    <label>Site</label>
                    <input
                      className="global-input"
                      type="text"
                      id="site"
                      placeholder=""
                      name="site"
                      value={organismoCertificador.site}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="group-input created-form lt">
                  <label>Detalhes</label>
                  <input
                    className="global-input"
                    type="text"
                    id="details"
                    placeholder=""
                    name="details"
                    value={organismoCertificador.details}
                    onChange={handleChange}
                  />
                  <button className="global-btn organismo-cert">
                    {organismoCertificador &&
                    organismoCertificador.id > 0
                      ? !loading && "SALVAR"
                      : !loading && "CRIAR"}

                    {loading && <FontAwesomeIcon icon={faCircleNotch} pulse />}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
