import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import InputRadio from "../../../components/InputRadio/InputRadio";
import Modal from "../../../components/ModalExit";

import CriticidadeAvaliacaoCompradorService from "../../../services/CriticidadeAvaliacaoCompradorService";

import CertificacaoObrigatoria from '../components/CertificacaoObrigatoria'
import Auditoria from '../components/Auditoria'

export default function CriticidadeAvaliacaoForm({ token, current, callback, handleFromList, back }) {
  const [modal, setModal] = useState({ open: false, msg: '' });

  const [criticidade, setCriticidade] = useState(current);
  const [sending, setSending] = useState(false);

  const [isCriticidade, setIsCriticidade] = useState(false);
  const [isAuditoria, setIsAuditoria] = useState(false);

  useEffect(() => {
    if (isAuditoria) {
      setIsAuditoria(false)
    }
    setIsCriticidade(criticidade.certificacaoObrigatoria)
    // eslint-disable-next-line
  }, [criticidade.certificacaoObrigatoria])

  useEffect(() => {
    if (isCriticidade) {
      setIsCriticidade(false)
    }
    setIsAuditoria(criticidade.auditoria2Parte)
    // eslint-disable-next-line
  }, [criticidade.auditoria2Parte])

  useEffect(() => {

    if (!criticidade.auditoria2Parte) {
      setCriticidade((values) => ({
        ...values,
        auditoria2ParteCustoProcesso: 0,
        escoposAvaliacoes: []
      }));
    }

    if (!criticidade.certificacaoObrigatoria) {
      setCriticidade((values) => ({
        ...values,
        tiposCertificacoes: []
      }));
    }
    // eslint-disable-next-line
  }, [criticidade.auditoria2Parte, criticidade.certificacaoObrigatoria])

  const handleChangeComponent = (data) => {
    setCriticidade((values) => ({
      ...values,
      ...data
    }));
  }

  const create = (payload) => {
    setSending(true);

    CriticidadeAvaliacaoCompradorService.create(token, payload)
      .then((response) => {
        if (response) {
          toast.success("Item cadastrado com sucesso!");
          setCriticidade({
            nome: '',
            autoAvaliacao: false,
            auditoria2Parte: false,
            auditoria2ParteCustoProcesso: 0,
            certificacaoObrigatoria: false,
            escoposAvaliacoes: [],
            tiposCertificacoes: []
          });
          setSending(false);
          callback();
        }
      })
      .catch((error) => {
        toast.error("Erro ao cadastrar item!");
        setSending(false);
      });
  }

  const update = (payload) => {
    if (!payload.id && !payload.nome) return;
    setSending(true);

    CriticidadeAvaliacaoCompradorService.update(token, payload)
      .then((response) => {
        if (response) {
          toast.success("Item editado com sucesso!");
          setCriticidade({});
          setSending(false);
          callback();
        }
      })
      .catch((error) => {
        toast.error("Erro ao editar item!");
        setSending(false);
      });
  }

  const handleChange = (e) => {
    e.persist();

    setCriticidade((values) => ({
      ...values,
      [e.target.name]: (e.target.type == 'radio') ? (e.target.value == '1' ? true : false) : e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (criticidade.nome === '') {
      setModal({ open: true, msg: 'Forneça um nome para concluir o cadastro' })
      return
    }

    if (criticidade.auditoria2Parte && criticidade.escoposAvaliacoes.length === 0) {
      setModal({
        open: true,
        msg: 'Você abilitou "Auditoria de 2ª parte" como sim, então escolha suas listagens dos escopos de avaliação ou abilite como não'
      })
      return
    }

    if (criticidade.certificacaoObrigatoria && criticidade.tiposCertificacoes.length === 0) {
      setModal({
        open: true,
        msg: 'Você abilitou "Certificação Obrigatória" como sim, então escolha suas listagens das certificações ou abilite como não'
      })
      return
    }

    if (criticidade.auditoria2Parte && criticidade.auditoria2ParteCustoProcesso === 0) {
      setModal({
        open: true,
        msg: 'Você abilitou "Auditoria de 2ª parte" como sim mas não escolheu o custo do processo'
      })
      return
    }

    if (criticidade.id) {
      update(criticidade);
    } else {
      create(criticidade);
    }
  }

  useEffect(() => {
    if (current) {
      setCriticidade(current)
    }
  }, [current]);

  return (
    <>
      {modal.open > 0 && (
        <Modal
          title={modal.msg}
          handleDelete={null}
          handleModal={() => setModal({ open: false, msg: '' })}
          alert
        />
      )}

      <div className="card-container">
        <div className="card">
          <div className="card-header">
            <h1>CADASTRO DE NOVO PADRÃO DE CRITICIDADE POR TIPO DE AVALIAÇÃO</h1>
          </div>
          <div className="card-body p-0">
            <form className="form-container" onSubmit={handleSubmit}>
              <div className="group-input created-form m-0">
                <div className="row equal" id={`row1-noborder-${isCriticidade ? 'sim' : 'nao'}`}>
                  <div className="col-md-6">
                    <label>Nome</label>
                    <input
                      className="global-input"
                      type="text"
                      id="nome"
                      value={criticidade.nome || ''}
                      placeholder="Nome"
                      name="nome"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-md-6" id={`certificacaoObrigatoria-${isCriticidade ? 'sim' : 'nao'}`}>
                    <label>Certificação Obrigatória</label>
                    <div className="InputRadioGroup">
                      <InputRadio name="certificacaoObrigatoria" label="Sim" value="1" checked={(criticidade.certificacaoObrigatoria === true) ? '1' : criticidade.certificacaoObrigatoria} onChange={handleChange} />
                      <InputRadio name="certificacaoObrigatoria" label="Não" value="0" checked={!criticidade.certificacaoObrigatoria ? '0' : criticidade.certificacaoObrigatoria} onChange={handleChange} />
                    </div>
                  </div>
                </div>

                {isCriticidade &&
                  <CertificacaoObrigatoria
                    token={token}
                    handleChangeComponent={handleChangeComponent}
                    currentEdit={
                      criticidade.id && {
                        tiposCertificacoes: criticidade.tiposCertificacoes,
                      }}

                  />
                }

                <div className="row equal" id={`row2-noborder-${isAuditoria ? 'sim' : 'nao'}`}>
                  <div className="col-md-6">
                    <label>Auto Avaliação</label>
                    <div className="InputRadioGroup">
                      <InputRadio name="autoAvaliacao" label="Sim" value="1" checked={criticidade.autoAvaliacao} onChange={handleChange} />
                      <InputRadio name="autoAvaliacao" label="Não" value="0" checked={!criticidade.autoAvaliacao ? '0' : criticidade.autoAvaliacao} onChange={handleChange} />
                    </div>
                  </div>
                  <div className="col-md-6" id={`auditoria-${isAuditoria ? 'sim' : 'nao'}`}>
                    <label>Auditoria de 2ª parte</label>
                    <div className="InputRadioGroup">
                      <InputRadio name="auditoria2Parte" label="Sim" value="1" checked={criticidade.auditoria2Parte} onChange={handleChange} />
                      <InputRadio name="auditoria2Parte" label="Não" value="0" checked={!criticidade.auditoria2Parte ? '0' : criticidade.auditoria2Parte} onChange={handleChange} />
                    </div>
                  </div>
                </div>

                {isAuditoria &&
                  <Auditoria
                    token={token}
                    handleChangeComponent={handleChangeComponent}
                    currentEdit={
                      criticidade.id && {
                        auditoria2ParteCustoProcesso: criticidade.auditoria2ParteCustoProcesso,
                        escoposAvaliacoes: criticidade.escoposAvaliacoes
                      }}
                  />
                }

                <div className="row mx-0">
                  <div className="col-12 px-0 justify-content-between" style={{ textAlign: 'end' }}
                  >
                    {handleFromList &&
                      /* SE ESTIVER EM CADASTRO */
                      <button
                        type="button"
                        className="global-btn w-auto"
                        disabled={sending && true}
                        onClick={back}
                      >
                        <svg className="mr-1" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1.47211 5.05021L5.10332 9.09061L4.31819 9.69667L0 5.05021L4.31819 0.302734L5.00518 1.00981L1.47211 5.05021ZM9.71593 1.00981L9.02894 0.302734L4.71075 5.05021L9.02894 9.69667L9.71593 8.9896L6.18286 5.05021L9.71593 1.00981Z" fill="white" />
                        </svg>
                        VOLTAR
                      </button>
                    }

                    <button
                      className="global-btn"
                      disabled={sending && true}
                    >
                      {sending ? '' : criticidade.id ? "SALVAR" : "CRIAR"}
                      {sending && <FontAwesomeIcon icon={faCircleNotch} pulse />}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}