import { action_types } from "./actions";

export function getReducer(
  initialAuthenticationToken,
  initialUser,
  logoutService
) {
  const initialState = {
    // Authentication and user related state
    
    token: initialAuthenticationToken,
    isAuthenticated: !!initialAuthenticationToken,
    user: initialUser || {},
    // profiles: initialProfiles || [],
  };

  return function reducer(state = initialState, action) {
    let keepLoggedStorage = localStorage.getItem('user_logged_storage');
    if ((!keepLoggedStorage || keepLoggedStorage === 'false') && action.type != 'SET_AUTHENTICATION' && window.location.pathname == '/'){
      action.type = 'LOGOUT';
    }
    switch (action.type) {
      case action_types.SET_AUTHENTICATION:
        return {
          ...state,
          token: action.data.token,
          isAuthenticated: true,
          user: {
            name: action.data.name,
            email: action.data.email,
            profiles: action.data.profiles,
          },
          profiles: action.data.profiles,
        };
      case action_types.LOGOUT:
        // logoutService();
        return {
          ...state,
          token: null,
          isAuthenticated: false,
          user: null,
          profiles: [],
        };
      default:
        return state;
    }
  };
}
