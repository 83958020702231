import React, { useEffect, useState, memo } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import InputRadio from "../../../../components/InputRadio/InputRadio";

function DocumentedInformationComponent({ current, documented, change, edit }) {
    const [options, setOptions] = useState(documented);
    const [sending, setSending] = useState(false);

    const handleChange = (e) => {
        e.persist();

        let newArray = [...options];
        let indexKey = parseInt(e.target.name.toString().replace('docinfo-', ''));
        newArray[indexKey] = {
            ...newArray[indexKey],
            ...{
                enableGroupMaterial: (e.target.value == '1' ? true : false)
            }
        }

        setOptions(newArray);
        change(newArray);
    };

    const checkEnable = (id) => {
        return current.tiposInformacoesDocumentadas.find(elem => (elem.id === id)) || false;
    }

    useEffect(() => {
        if (documented) {
            setOptions(documented.map((document) => (
                { ...document, ...{ enableGroupMaterial: (checkEnable(document.id)) ? true : false } }
            )));
        }
    }, [documented]);

    const randomKey = () => {
        const key = (Math.random()*0xFFFFFF<<0).toString(16) 
        return `${key}`
    }

    return (
        <>
            <div className="card-container">
                <div className="card">
                    <div className="card-header">
                        <h1>Informações documentadas a vincular ao grupo</h1>
                    </div>
                    <div className="card-body">
                        <section className="form-container">
                            <div className="group-input m-0">
                                <div className="d-flex flex-wrap">
                                    <div className="row equal">
                                        {(options || []).map((data, i) => (
                                            <div className="col-md-12 col-Itens" key={randomKey()}>
                                                <label className="itemName">{data.nome}</label>
                                                <div className="InputRadioGroup">
                                                    <InputRadio
                                                        key={randomKey()}
                                                        name={`docinfo-${i}`}
                                                        label="Sim"
                                                        value="1"
                                                        checked={(data.enableGroupMaterial === true) ? '1' : data.enableGroupMaterial}
                                                        onChange={handleChange}
                                                    />
                                                    <InputRadio
                                                        key={randomKey()}
                                                        name={`docinfo-${i}`}
                                                        label="Não"
                                                        value="0"
                                                        checked={!data.enableGroupMaterial ? '0' : data.enableGroupMaterial}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="row equal w-100">
                                        <div className="col-md-12 col-Itens">
                                            <button
                                                type="button"
                                                className="global-btn w-100"
                                                disabled={sending && true}
                                                onClick={edit}
                                            >
                                                {!sending && "Criar/editar informação documentada"}
                                                {!sending &&
                                                    (
                                                        <svg width="31" height="30" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M24.5 6.94534L23.1452 5.78778C22.371 5.11254 21.3064 5.209 20.629 5.88424L7.37097 18.8103C7.27419 18.9068 7.08065 19.0997 7.08065 19.2926L5.82258 24.0193C5.72581 24.4051 6.01613 24.6945 6.40323 24.5981L11.0484 23.3441C11.2419 23.2476 11.4355 23.1511 11.5323 23.0547L24.6935 9.6463C25.371 8.8746 25.371 7.71704 24.5 6.94534ZM6.98387 23.537L7.85484 20.2572L10.1774 22.6688L6.98387 23.537ZM11.0484 22.1865L8.24194 19.2926L17.9194 9.93569L20.5323 12.5402L11.0484 22.1865ZM21.3064 11.7685L18.6935 9.16399L19.371 8.48875L21.9839 11.0932L21.3064 11.7685ZM23.9194 8.97106L22.7581 10.2251L20.2419 7.71704L21.3064 6.65595C21.5968 6.36656 22.0806 6.36656 22.4677 6.65595L23.9194 7.90997C24.3064 8.10289 24.3064 8.68167 23.9194 8.97106ZM10.1774 19.4855C9.98387 19.6785 9.98387 20.0643 10.1774 20.3537C10.371 20.5466 10.7581 20.5466 11.0484 20.3537L18.5968 12.8296C18.7903 12.6367 18.7903 12.2508 18.5968 11.9614C18.4032 11.7685 18.0161 11.7685 17.7258 11.9614L10.1774 19.4855ZM30.5 21.4148C30.5 26.1415 26.7258 30 21.8871 30H9.1129C4.37097 30 0.5 26.2379 0.5 21.4148V8.58521C0.5 3.85852 4.27419 0 9.1129 0H21.9839C26.629 0 30.5 3.76206 30.5 8.58521V21.4148Z" fill="white" />
                                                        </svg>
                                                    )
                                                }
                                                {sending && <FontAwesomeIcon icon={faCircleNotch} pulse />}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    );
}

export default memo(DocumentedInformationComponent)