import React, { useEffect, useState } from "react";

import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import "./login.scss";

import LoginForm from "./Form/LoginForm";
import { toast } from "react-toastify";

import AuthService from "../../services/AuthService";
import { actions } from "../../redux/actions";

export default function Login(props) {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const location = useLocation();
  const data = location && location.state && location.state.params;

  let initialValues = {
    email: "",
    password: "",
    user_logged_storage: false,
  };

  useEffect(() => {
    localStorage.setItem("user_logged_storage", false);
  }, []);

  const send = (values) => {
    setLoading(true);
    let { email, password, user_logged_storage } = values;

    AuthService.login(email, password)
      .then((response) => {
        let message =
          response && response.response && response.response.data
            ? response.response.data
            : "";
        if (message !== "") {
          if (
            response &&
            response.response &&
            response.response.data &&
            response.response.data.message
          ) {
            setError(response.message);
          }
          return false;
        }

        if (user_logged_storage) {
          localStorage.setItem("user_logged_storage", user_logged_storage);
        }

        setLoading(false);
        dispatch(actions.setAuthentication(response));
      })
      .catch((error) => {        
        if (error && error.message) {
          toast.error(error.message);
          // setError(error.message);
        }else{
          toast.error("Erro ao logar. Verifique seu usuario e senha");
        }

        setLoading(false);
      });
  };

  return (
    <LoginForm
      initialValues={initialValues}
      send={send}
      error={error}
      loading={loading}
      success={data}
      title="Acessar conta"
    />
  );
}
