import React from "react";

import Login from "../public-app/login";

import AddUser from "../public-app/user/AddUser";

import Forget from "../public-app/forget/Forget";
import ForgetReset from "../public-app/forget/ForgetReset";

import Dashboard from "../private-app/dashboard";
import ApprovalOpportunity from '../private-app/approvalOpportunity';
import Buyers from "../private-app/buyers/index";
import ManageAccess from "../private-app/manageAccess/index";
import AuditCriteria from "../private-app/auditCriteria/index";
import EditAuditCriteria from "../private-app/auditCriteria/editAuditCriteria";
import AddAuditCriteria from "../private-app/auditCriteria/addAuditCriteria";
import CriticalityTypeInformation from "../private-app/criticalityTypeInformation";
import GestaoDeIdoneidade from "../private-app/gestaoDeIdoneidade/index";
import AddGestaoDeIdoneidade from "../private-app/gestaoDeIdoneidade/addDoc";
import EditGestaoDeIdoneidade from "../private-app/gestaoDeIdoneidade/editDoc";
import InformationType from "../private-app/informationType";
import AddInformationType from "../private-app/informationType/addInformationType";
import EditInformationType from "../private-app/informationType/editInformationType";
import CriticidadeAvaliacao from "../private-app/criticidadeAvaliacao/index";
import CertifyingCompanies from "../private-app/certifyingCompanies/index";
import MaterialService from "../private-app/materialService";
import GroupMaterialService from "../private-app/groupMaterialService/index";
import DocumentLicence from "../private-app/documentLicence";
import TypeDocumentLicence from "../private-app/typeDocumentLicence";
import IdoneidadeIntegridade from "../private-app/idoneidadeIntegridade";
import OrganimosCertificadores from "../private-app/organismosCertificadores";
import EscopoAvaliacao from "../private-app/escopoAvaliacao";
import IndicadoresDesempenho from "../private-app/indicadoresDesempenho";
import TiposInformacoesDocumentadas from "../private-app/tiposInformacoesDocumentadas";
import IncluirProvedores from '../private-app/provedores/IncluirProvedores';
import RegistrationMaterialsServices from "../private-app/materialService/Comprador/registrationMaterialsServices";
import RelacaoProvedores from "../private-app/provedores/relacaoProvedores";
import RelacaoCompradores from '../private-app/provedores/relacaoCompradores';
import CertificationTypes from "../private-app/certificationTypes/index";
import InformacaoEntrada from "../private-app/informacaoEntrada/index";
import MeusDados from "../private-app/meusDados/index";
import NotificacaoProvedores from "../private-app/provedores/notificacaoProvedores/index"; 
import DashboardComprador from "../private-app/dashboard/comprador";
import PadraoDesempenho from "../private-app/padraoDesempenho/index";
import DocumentosLicenca from "../private-app/DocumentosLicenca/index";
import MinhasNotificacoesProvedor from "../private-app/minhasNotificacoesProvedor/index";
import MeusCertificados from "../private-app/meusCertificados/index";
import AcessarClientes from "../private-app/AcessarClientes";
import MaterialServicoProvedor from '../private-app/MateriaisServicos/index';

export const Index = (props) => (
  <>
    <Login title="Login Admin" />
  </>
);

export {
  Login,
  AddUser,
  Forget,
  ForgetReset,
  Dashboard,
  ApprovalOpportunity,
  Buyers,
  ManageAccess,
  AuditCriteria,
  CriticalityTypeInformation,
  EditAuditCriteria,
  AddAuditCriteria,
  GestaoDeIdoneidade,
  AddGestaoDeIdoneidade,
  EditGestaoDeIdoneidade,
  InformationType,
  AddInformationType,
  EditInformationType,
  CriticidadeAvaliacao,
  CertifyingCompanies,
  MaterialService,
  GroupMaterialService,
  DocumentLicence,
  TypeDocumentLicence,
  IdoneidadeIntegridade,
  OrganimosCertificadores,
  EscopoAvaliacao,
  IndicadoresDesempenho,
  TiposInformacoesDocumentadas,
  IncluirProvedores,
  RegistrationMaterialsServices,
  RelacaoProvedores,
  RelacaoCompradores,
  CertificationTypes,
  InformacaoEntrada,
  NotificacaoProvedores,
  MeusDados,
  DashboardComprador,
  PadraoDesempenho,
  DocumentosLicenca as List,
  MinhasNotificacoesProvedor,
  MeusCertificados,
  AcessarClientes,
  MaterialServicoProvedor
};