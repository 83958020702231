import React, { useEffect, useState, useRef } from "react";

import Modal from "../../../components/ModalExit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import MaterialsServicesService from "../../../services/MaterialsServicesService";

import DropList from "../../../components/DropList/DropList";

export default function MateriaisServicosForm({ token, current, callback }) {
  const [data, setData] = useState({});
  const [sending, setSending] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalMsg, setModalMsg] = useState('');

  const fileFichaTecnica = useRef(null);
  const fileImagem = useRef(null);

  const [imagem, setImagem] = useState();
  const [fichaTecnica, setFichaTecnica] = useState();

  const [categorias, setCategorias] = useState();
  const [materiais, setMateriais] = useState();

  const [categoria, setCategoria] = useState();
  const [material, setMaterial] = useState();

  const [activeCategoria, setActiveCategoria] = useState(false);
  const [activeMaterial, setActiveMaterial] = useState(false);

  const handleChange = ({target: { name, value }}) => {
    setData((values) => ({
      ...values,
      [name]: value
    }));
  };

  const changeImagemMaterial = (event) => {
    const file = event.target.files[0]

    if (file) {
      const type = file.type;
      if (type !== "application/svg" && type !== "image/png" && type !== "image/jpeg") {
        setModalMsg('Tipo de arquivo inválido, selecione um arquivo válido do tipo JPG, PNG ou SVG')
        setModal(true)
        return
      }

      if (file.size > 500000) {
        setModalMsg('O arquivo deve ser igual ou menor a 500 KB')
        setModal(true)
        return
      }

      let reader = new FileReader();

      reader.onload = () => {
        const img = new Image();
        img.src = reader.result;

        img.onload = function () {
          const imgWidth = img.naturalWidth;
          const imgHeight = img.naturalHeight;

          if (imgWidth != 800 && imgHeight != 800) {
            setModalMsg('O dimensionamento da imagem deve ser de 800px por 800px')
            setModal(true)
            return
          }

          setImagem(file);
        };
      };

      reader.onerror = (error) => {
        setModalMsg('Erro desconhecido ao selecionar a imagem, por favor, tente novamente')
        setModal(true)
        return
      };

      reader.readAsDataURL(file);
    } else {
      setImagem(file);
    }  
  };

  const changeFichaTecnica = (event) => {
    const file = event.target.files[0]

    if (file) {
      const type = file.type;
      if (type !== "application/pdf" && type !== "image/png" && type !== "image/jpeg") {
        setModalMsg('Tipo de arquivo inválido, selecione um arquivo válido do tipo PDF, JPG ou PNG')
        setModal(true)
        return
      }

      if (file.size > 10000000) {
        setModalMsg('O arquivo deve ser igual ou menor a 10 MB')
        setModal(true)
        return
      }
    }

    setFichaTecnica(file);
  };

  const renderFileImagem = () => {
    if (imagem) {
      return <p><strong>Imagem selecionada:</strong> {imagem.name}</p>
    }

    return null
  }

  const renderFileFichaTecnica = () => {
    if (fichaTecnica) {
      return <p><strong>Ficha selecionada:</strong> {fichaTecnica.name}</p>
    }

    return null
  }

  const handleSubmit = async () => {
    try {
      setSending(true);

      if (data) {
        const formData = new FormData();

        for(let prop in data) {
          formData.append(prop, data[prop]);
        }

        if (imagem) {
          formData.append('imagemMaterial', imagem);
        }

        if (fichaTecnica) {
          formData.append('fichaTecnica', fichaTecnica);
        }

        const response =  await MaterialsServicesService.registerMaterialServiceProvedor(token, formData);
        
        if (response.data) {
          toast.success("Dados salvo com sucesso!");
          callback(true);
        }
      }
    } catch (error){
      const message = error && error.response && error.response.data ? error.response.data : '';
      const errors = message.split("\n");

      if (errors.length > 0 && errors[0].length > 0) {
        errors.forEach((msg) => {
          toast.error(msg);
        })
      } else if (error && error.message){
        toast.error(error.message);
      } else {
        toast.error("Erro ao salvar os dados!");
      }
    } finally {
      setSending(false);
    }
  }
  
  const handleActiveCategoria = () => setActiveCategoria(!activeCategoria);

  const handleActiveMaterial = () => setActiveMaterial(!activeMaterial);

  const handleSelectCategoria = (idCategoria) => {
    const categoriaSelecionada = categorias.find(({ id }) => id == idCategoria);

    handleGetMateriais(idCategoria);

    setCategoria(categoriaSelecionada);
    setData((values) => ({
      ...values,
      categoria: idCategoria
    }));
  }

  const handleSelectMaterial = (idMaterial) => {
    const materialSelecionado = materiais.find(({ id }) => id == idMaterial);

    setMaterial(materialSelecionado);
    setData((values) => ({
      ...values,
      material: idMaterial
    }));
  }

  const handleGetCategorias = async () => {
    const response =  await MaterialsServicesService.getCategoriaServiceProvedor(token);

    setCategorias(response);
  }

  const handleGetMateriais = async (categoria) => {
    const response =  await MaterialsServicesService.getCategoriaMaterialServiceProvedor(token, { categoria });

    setMateriais(response);
  }

  useEffect(() => {
    handleGetCategorias();
  }, [])

  return (
    <>
      {modal &&
        <Modal
          title={modalMsg}
          handleDelete={null}
          handleModal={setModal}
          alert
        />
      }
      <div className="card-container">
        <div className="card">
          <div className="card-header">
            <h1>NOVO MATERIAL / SERVIÇO</h1>
          </div>
          <div className="card-body">
            <div className="form-container">
              <div className="row">
                <div className="col-xs-12">
                  <div className="form-info">
                    <div>
                      <label>Bem vindo a Supply Compliance!</label>
                      <p>
                        Para cadastrar um produto primeiramente escolha uma categoria para seu novo material / serviço 
                        e depois escolha o material / serviço que deseja incluir em sua conta. Para garantir a qualidade da 
                        plataforma, todos os cadastros são cuidadosamente conferidos pela nossa equipe. Esse processo de 
                        aprovação pode levar até 48 horas.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-input created-form">
                <div className="input-group-container">
                  <label>Material / Serviço</label>
                  <div className="input-group-span">
                    <div className="input-list-wrap">
                      <input
                        id="categoriaMaterial"
                        name="categoriaMaterial"
                        type="text"
                        disabled={true}
                        className="global-input"
                        onChange={handleChange}
                      />
                      <div className="list-item d-inline-flex">
                        {!categoria && <span>Selecione a categoria do seu material/serviço</span>}
                        {categoria && <span>{categoria.name}</span>}
                        <DropList
                          uniqid='categoria-material-servico'
                          label="Categorias"
                          options={categorias}
                          selectable={true}
                          active={activeCategoria}
                          selectedOption={handleSelectCategoria}
                          onClick={handleActiveCategoria}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="input-group-span">
                    <div className="input-list-wrap">
                      <input
                        id="material"
                        name="material"
                        type="text"
                        disabled={true}
                        className="global-input"
                        onChange={handleChange}
                      />
                      <div className="list-item d-inline-flex">
                        {!material && <span>Selecione o material/serviço</span>}
                        {material && <span>{material.name}</span>}
                        <DropList
                          uniqid='material-servico'
                          label="Materiais / Serviços"
                          options={materiais}
                          selectable={true}
                          active={activeMaterial}
                          selectedOption={handleSelectMaterial}
                          onClick={handleActiveMaterial}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-input created-form">
                <div className="input-group-container">
                  <label>Sentiu falta de alguma categoria?</label>
                  <div className="input-group-span">
                    <div className="input-list-wrap">
                      <input
                        id="categoriaAvaliar"
                        name="categoriaAvaliar"
                        placeholder="Digite aqui a(s) categoria(s) que não achou em nosso banco de dados e envie para nossa equipe avaliar e adicionar no sistema."
                        type="text"
                        className="global-input"
                        onChange={handleChange}
                      />
                      <button
                      className="enviar-btn"
                    >
                      ENVIAR
                    </button>
                    </div>
                  </div>
                  
                </div>
              </div>
              <div className="row upload-form">
                <div className="col-xs-6 border-right">
                  <div className="upload-content">
                    <div>
                      <label>Upload de imagem do material / serviço</label>
                      <p>
                        É recomendável imagem com a dimensão de 800px por 800px. 
                        São aceitos arquivos em jpg, png e svg com no máximo 500 KB. 
                        Para converte sua imagem na dimensãoe tamanho necessário acesse esse link e 
                        converta gratuitamente.
                      </p>
                      {renderFileImagem()}
                    </div>
                    <div className="upload-button" onClick={() => fileImagem.current.click()}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#fff" class="bi bi-upload" viewBox="0 0 16 16">
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                        <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"/>
                      </svg>
                      <div>Selecione sua imagem</div>
                      <input
                        ref={fileImagem}
                        type="file"
                        accept=",.jpg,.png,.svg"
                        id="imagemMaterial"
                        name="imagemMaterial"
                        onChange={changeImagemMaterial}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-xs-6">
                  <div className="upload-content">
                    <div>
                      <label>Upload de imagem do material / serviço</label>
                      <p>
                        <strong>Faça o upload da sua ficha técnica</strong><br/>
                        Clique e selecione o arquivo em pdf, jpg ou png do tipo
                        de informação, somente serão aceitos documentos
                        legíveis e com no máximo 10 MB de tamanho.
                      </p>
                      {renderFileFichaTecnica()}
                    </div>
                    <div className="upload-button" onClick={() => fileFichaTecnica.current.click()}>
                      <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="#fff" class="bi bi-upload" viewBox="0 0 16 16">
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z"/>
                        <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z"/>
                      </svg>
                      <div>Selecione o arquivo</div>
                      <input
                        ref={fileFichaTecnica}
                        type="file"
                        accept=".pdf,.jpg,.png"
                        id="fichaTecnica"
                        name="fichaTecnica"
                        onChange={changeFichaTecnica}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="subtitle-form">
                Descrição do material / serviço
              </div>
              <div className="group-input created-form">
                <div className="input-group-container without-border">
                  <label>Características</label>
                  <div className="input-group-span">
                    <div className="input-list-wrap">
                      <input
                        id="caracteristica"
                        name="caracteristica"
                        placeholder="(biológicas, químicas e físicas)"
                        type="text"
                        className="global-input"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-input created-form">
                <div className="input-group-container without-border">
                  <label>Composição</label>
                  <div className="input-group-span">
                    <div className="input-list-wrap">
                      <input
                        id="composicao"
                        name="composicao"
                        placeholder="(ingrdientes formulados, incluindo aditivos e coadjuvantes de tecnologia)"
                        type="text"
                        className="global-input"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-input created-form">
                <div className="input-group-container without-border">
                  <label>Origem</label>
                  <div className="input-group-span">
                    <div className="input-list-wrap">
                      <input
                        id="origem"
                        name="origem"
                        placeholder="(por exemplo animal, mineral ou vegetal)"
                        type="text"
                        className="global-input"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-input created-form">
                <div className="input-group-container without-border">
                  <label>Local de Origem</label>
                  <div className="input-group-span">
                    <div className="input-list-wrap">
                      <input
                        id="localOrigem"
                        name="localOrigem"
                        placeholder="(procedência)"
                        type="text"
                        className="global-input"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-input created-form">
                <div className="input-group-container without-border">
                  <label>Método de Produção</label>
                  <div className="input-group-span">
                    <div className="input-list-wrap">
                      <textarea
                        id="metodoProducao"
                        name="metodoProducao"
                        type="text"
                        className="global-input"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-input created-form">
                <div className="input-group-container without-border">
                  <label>Métodos de Acondicionamento e Entrega</label>
                  <div className="input-group-span">
                    <div className="input-list-wrap">
                      <textarea
                        id="acondicionamentoEntrega"
                        name="acondicionamentoEntrega"
                        type="text"
                        className="global-input"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-input created-form">
                <div className="input-group-container without-border">
                  <label>Condições de Armazenagem</label>
                  <div className="input-group-span">
                    <div className="input-list-wrap">
                      <textarea
                        id="armazenagem"
                        name="armazenagem"
                        type="text"
                        className="global-input"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-input created-form">
                <div className="input-group-container without-border">
                  <label>Vida de Prateleira</label>
                  <div className="input-group-span">
                    <div className="input-list-wrap">
                      <input
                        id="vidaPrateleira"
                        name="vidaPrateleira"
                        type="text"
                        className="global-input"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-input created-form">
                <div className="input-group-container without-border">
                  <label>Preparação e/ou manipulação antes do uso ou processamento</label>
                  <div className="input-group-span">
                    <div className="input-list-wrap">
                      <textarea
                        id="preparacaoAntesUso"
                        name="preparacaoAntesUso"
                        type="text"
                        className="global-input"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="group-input created-form">
                <div className="input-group-container without-border">
                  <label>Critérios de Aceitação</label>
                  <div className="input-group-span">
                    <div className="input-list-wrap">
                      <textarea
                        id="criterioAceitacao"
                        name="criterioAceitacao"
                        type="text"
                        className="global-input"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="global-btn"
                disabled={sending && true}
                onClick={handleSubmit}
              >
                {sending ? '' : "SALVAR"}
                {sending && <FontAwesomeIcon icon={faCircleNotch} pulse />}
              </button>
            </div>
          </div>
        </div>
      </div>
      <button
          type="button"
          className="global-btn btnVoltar"
          onClick={() => {}}
        >
          <svg width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.47211 5.05216L5.10332 9.09257L4.31819 9.69863L0 5.05216L4.31819 0.304688L5.00518 1.01176L1.47211 5.05216ZM9.71593 1.01176L9.02894 0.304688L4.71075 5.05216L9.02894 9.69863L9.71593 8.99156L6.18286 5.05216L9.71593 1.01176Z" fill="white" />
          </svg>
          VOLTAR
        </button>
    </>
  )
}
