import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  IDONEIDADE: BASE_URL + "IdoneidadeIntegridades",
};

export default class GestaoIdoneidadeService {
  static getGestaoIdoneidade = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.IDONEIDADE;

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getGestaoIdoneidadeByID = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.IDONEIDADE + "/" + id;

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  // delete
  static deleteGestaoIdoneidade = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.IDONEIDADE + "/" + id;

    try {
      const { data } = await axios.delete(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  // update
  static updateGestaoIdoneidade = async (token, doc) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.IDONEIDADE;

    try {
      const { data } = await axios.put(url, doc, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  // create
  static postGestaoIdoneidade = async (token, doc) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.IDONEIDADE;

    try {
      const { data } = await axios.post(url, doc, config);
      return data;
    } catch (err) {
      return err;
    }
  };
}
