import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import MateriaisServicosForm from "./Form/MateriaisServicosForm";
import MeusDadosService from "../../services/MeusDadosService";
import RedesSociaisService from "../../services/RedesSociaisService";

import "./style.scss";

const MateriaisServicos = ({ state }) => {
  const { token } = state;
  const [loading, setLoading] = useState(false);

  const defaultCurrent = {
    razaoSocial: "",
    nomeFantasia: "",
    cnpj: "",
    inscricaoEstadual: "",
    logradouro: "",
    numero: "",
    bairro: "",
    cidade: "",
    cep: "",
    uf: "",
    pais: "",
    telefone: "",
    site: "",
    nome: "",
    cargo: "",
    email: ""
  }
  const [current, setCurrent] = useState(defaultCurrent);
  const [redesSociais, setRedesSociais] = useState([]);

  const get = async (reload = false) => {
    if (!reload) setCurrent(defaultCurrent);
    if (!reload) setLoading(true);

    await MeusDadosService.get(token, isComprador())
      .then((response) => {
        console.log('ressss', response)
        setCurrent(response.data || []);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });

    await RedesSociaisService.get(token)
      .then((response) => {
        setRedesSociais(response || []);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  useEffect(() => {
    get();
  }, []);

  const isComprador = () => {
    const profile = JSON.parse(localStorage.getItem("profile"));
    return (profile.profileId === 2);
  };

  return (
    <div className="dashboard-container materiais-servicos-container">
      {loading && (
        <div className={"loading-mask"}>
          <FontAwesomeIcon icon={faCircleNotch} pulse />
        </div>
      )}
      <MateriaisServicosForm
        token={token}
        current={current}
        callback={get}
        redesSociais={redesSociais}
      />
    </div>
  );
}

export default MateriaisServicos;