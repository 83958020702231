import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  GROUP_MATERIAL_SERVICES: BASE_URL + "grupoMaterialServico",
};

export default class GroupMaterialServicesService {
  static get = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.GROUP_MATERIAL_SERVICES;

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };
  
  // delete
  static delete = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.GROUP_MATERIAL_SERVICES + "/" + id;

    try {
      return await axios.delete(url, config);
    } catch (err) {
      return err;
    }
  };

  // update
  static update = async (token, groupMeterial) => {
    if(!groupMeterial.nome) return;

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.GROUP_MATERIAL_SERVICES;

    try {
      const { data } = await axios.put(url, groupMeterial, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  // create
  static create = async (token, groupMeterial) => {
    if(!groupMeterial.nome) return;
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.GROUP_MATERIAL_SERVICES;

    try {
      const { data } = await axios.post(url, groupMeterial, config);
      return data;
    } catch (err) {
      return err;
    }
  };
}
