import React from "react";

import "./cardSituacaoCadastral.scss"
import cardUserIcon from "../../../../../assets/icons/card_user.svg";
import { useHistory } from "react-router-dom";

export default function CardSituacaoCadastral(props) {

  const history = useHistory();

  const navigate = (url) => history.push(url)

  return (
    <>
      <div className="mr-4">
        <div className="card-situacao">
          <div className="card-situacao-body">
            <img src={cardUserIcon} />
            <span className="situacao-title">
              Situação Cadastral
            </span>
            <span className="meus-dados d-flex align-items-center justify-content-center" >
              Meus dados:
            </span>
            <span className="situacao-cadastro cursor-pointer" onClick={() => navigate('/cadastro/meus-dados')}>
              Cadastro <span>completo</span>
            </span>
            <span className="status-pagamento d-flex align-items-center justify-content-center">
              Status do pagamento:
            </span>
            <span className="situacao">
              {/* TO DO obter status do pagamento */}
              Ativo
            </span>
          </div>
        </div>
      </div>
    </>
  );
}
