import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import Form from "./Form/groupMaterialServiceForm";
import List from "./List/groupMaterialServiceList";
import GroupMaterialServicesService from "../../services/groupMaterialServicesService";
import Modal from "../../components/ModalExit";

import "./style.scss";

export default function GroupMaterialService(props) {
  const { token } = props.state;
  const [loading, setLoading] = useState(false);
  const [deleteID, setDeleteID] = useState(0);

  const [showForm, setShowForm] = useState(false);
  const [list, setList] = useState([]);

  const defaultCurrent = {
    nome: '',
    escoposAvaliacao: [],
    indicadoresDesempenho: [],
    tiposDocumentosLicencas: [],
    tiposInformacoesDocumentadas: [],
    idoneidadeIntegridades: []
  };
  const [current, setCurrent] = useState(defaultCurrent);

  const initComponent = () => {
    setShowForm(false);
    get();
  }

  const get = () => {
    setCurrent(defaultCurrent);
    setLoading(true);

    GroupMaterialServicesService.get(token)
      .then((response) => {
        console.log('response', response)
        if (response) {
          setList(response);         
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  const remove = (id) => {
    setDeleteID(0);

    GroupMaterialServicesService.delete(token, id)
      .then((response) => {
        if (response) {
          if (response.data.success) {
            toast.success("Item excluído com sucesso!");
            get();
          }
          else {
            toast.error(response.data.message);
            setLoading(false);
          }
         
        }
      })
      .catch((error) => {
        toast.error("Erro ao excluir item!");
        setLoading(false);
      });
  }

  useEffect(() => {
    initComponent();
  }, []);

  useEffect(() => {
    if (current.id) {
      setShowForm(true);
    }
  }, [current]);

  return (
    <div className="dashboard-container" id="groupMaterialService-container">
      {deleteID > 0 && (
        <Modal handleDelete={() => remove(deleteID)} handleModal={() => { setDeleteID(0) }} />
      )}
      {loading && (
        <div className={"loading-mask"}>
          <FontAwesomeIcon icon={faCircleNotch} pulse />
        </div>
      )}

      {showForm && (
        <>
          {props.handleShowFrom &&
            <button className={`global-btn btn-groupMaterial mr-2`} id="fake-btn-groupMaterial">
              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14.5799 10.5H10.4142V14.5H4.16567V10.5H0V4.5H4.16567V0.5H10.4142V4.5H14.5799V10.5Z" fill="#FFF" />
              </svg>
              Criar novo grupo de materiais / serviços
            </button>
          }

          <Form
            token={token}
            current={current}
            back={() => {
              initComponent()
              if (props.handleShowFrom)
                props.handleShowFrom()
            }}
          />
        </>
      )}
      {!showForm && (
        <>
          <button
            className={`global-btn btn-groupMaterial mr-2`}
            onClick={() => {
              setShowForm(!showForm)
              if (props.handleShowFrom)
                props.handleShowFrom()
            }}
          >
            <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M14.5799 10.5H10.4142V14.5H4.16567V10.5H0V4.5H4.16567V0.5H10.4142V4.5H14.5799V10.5Z" fill="#FFF" />
            </svg>
            {props.handleShowFrom
              ? 'Criar novo grupo de materiais / serviços'
              : 'Criar novo Grupo'}
          </button>
          <div className="mt-2">
            <List
              list={list}
              remove={setDeleteID}
              edit={setCurrent}
              handleShowFrom={() => props.handleShowFrom && props.handleShowFrom()}
            />
          </div>
        </>
      )}
    </div>
  );
}