import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import "../dashboard/dashboard.scss";

import GestaoDeIdoneidadeForm from "./Form/gestaoDeIdoneidadeForm";

import GestaoIdoneidadeService from "../../services/GestaoIdoneidadeService";

export default function Index(props) {
  const { token } = props.state;
  const [id] = useState(props.match.params.id || "");

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [redirect, setRedirect] = useState(false);
  
  const [doc, setDoc] = useState({
    name: "",
    uploadRequired: false,
    answerRequired: false,
    id: parseInt(id),
  });

  const getGestaoIdoneidadeByID = () => {
    GestaoIdoneidadeService.getGestaoIdoneidadeByID(token, id)
      .then((response) => {
      let newDoc = {};

      let {
        name,
        uploadRequired,
        answerRequired,
        id,
      } = response;

      newDoc = {
        name,
        uploadRequired,
        answerRequired,
        id,
      };

      if (uploadRequired) {
        newDoc.uploadRequired = "yes";
      } else {
        newDoc.uploadRequired = "no";
      }

      if (answerRequired) {
        newDoc.answerRequired = "yes";
      } else {
        newDoc.answerRequired = "no";
      }

        setDoc(newDoc);
      })
      .catch((error) => {
        toast.error("Erro ao buscar dados!");
        setError(true);
      });
  };

  useEffect(() => {
    getGestaoIdoneidadeByID();
    // eslint-disable-next-line
  }, []);

  
  const send = (doc) => {
    setLoading(true);
    setError(false);
    setSuccess(false);
    setRedirect(false);

    let newDoc = {};

    let {
      name,
      uploadRequired,
      answerRequired,
      id,
    } = doc;

    let newUploadRequired = uploadRequired === "yes" ? true : false;
    let newAnswerRequired = answerRequired === "yes" ? true : false;

    newDoc = {
      name,
      uploadRequired: newUploadRequired,
      answerRequired: newAnswerRequired,
      id
    };

    GestaoIdoneidadeService.updateGestaoIdoneidade(token, newDoc)
      .then((response) => {
        if (response) {
          toast.success("Dados salvos com sucesso!");
          setSuccess(true);
          setDoc(response);
          setRedirect(true);          
        }
        setLoading(false);
        setError(false);
        setRedirect(false);
        
      })
      .catch((error) => {
        toast.error("Erro ao salvar dados!");
        setSuccess(false);
        setLoading(false);
        setError(true);
        setRedirect(false);
        
      });
  };

  if (redirect) {
    return <Redirect to={{ pathname: "/gestao-de-idoneidade" }} />;
  }

  return (
    <>
      <div className="dashboard-container">
        {loading && (
          <div className={"loading-mask"}>
            <FontAwesomeIcon icon={faCircleNotch} pulse />
          </div>
        )}
        <h1>Gestão de idoneidade e integridade</h1>
        <div className="gestao-container">
          <GestaoDeIdoneidadeForm
            loading={loading}
            error={error}
            send={send}
            initialDoc={doc}
          />
        </div>
      </div>
    </>
  );
}
