import React from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { faDumpsterFire } from "@fortawesome/free-solid-svg-icons";

export default function GestaoDeIdoneidadeList({
  loading,
  error,
  gestao,
  handleId,
}) {
  return (
    <>
      <div className="btn-container">
        <Link to="/criar-gestao-de-idoneidade" className="global-white-btn">
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 10H10V14H4V10H0V4H4V0H10V4H14V10Z" fill="white"/>
          </svg>
          Criar novo documento
        </Link>
      </div>
      <div className="user-list-container">
        <div className="list-container">
          <div className="list">
            <div className="list-header">
              <label>Nome do Documento</label>
              <label>Upload Obrigatório</label>
              <label>Resposta por Texto Obrigatório</label>
              <label></label>
            </div>

            {gestao &&
              gestao.length > 0 &&
              gestao.map((a, index) => (
                <div className="list-body" key={index}>
                  <span className="list-item">{a.name}</span>
                  <span
                    className={
                      "list-item " + (a.uploadRequired ? "green" : "red")
                    }
                  >
                    {a.uploadRequired ? "Sim" : "Não"}
                  </span>
                  <span
                    className={
                      "list-item " + (a.answerRequired ? "green" : "red")
                    }
                  >
                    {a.answerRequired ? "Sim" : "Não"}
                  </span>
                  <span className="list-item">
                    <a href={`edit-gestao-de-idoneidade/${a.id}`}>
                      <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M24.8 7.2L23.4 6C22.6 5.3 21.5 5.4 20.8 6.1L7.1 19.5C7 19.6 6.8 19.8 6.8 20L5.5 24.9C5.4 25.3 5.7 25.6 6.1 25.5L10.9 24.2C11.1 24.1 11.3 24 11.4 23.9L25 10C25.7 9.2 25.7 8 24.8 7.2ZM6.7 24.4L7.6 21L10 23.5L6.7 24.4ZM10.9 23L8 20L18 10.3L20.7 13L10.9 23ZM21.5 12.2L18.8 9.5L19.5 8.8L22.2 11.5L21.5 12.2ZM24.2 9.3L23 10.6L20.4 8L21.5 6.9C21.8 6.6 22.3 6.6 22.7 6.9L24.2 8.2C24.6 8.4 24.6 9 24.2 9.3ZM10 20.2C9.8 20.4 9.8 20.8 10 21.1C10.2 21.3 10.6 21.3 10.9 21.1L18.7 13.3C18.9 13.1 18.9 12.7 18.7 12.4C18.5 12.2 18.1 12.2 17.8 12.4L10 20.2ZM31 22.2C31 27.1 27.1 31.1 22.1 31.1H8.9C4 31.1 0 27.2 0 22.2V8.9C0 4 3.9 0 8.9 0H22.2C27 0 31 3.9 31 8.9V22.2Z" fill="#4B4B4B"/>
                       </svg>
                    </a>
                    <button
                      style={{
                        backgroundColor: "#fff",
                        border: "none",
                      }}
                      onClick={(e) => {
                        handleId(a.id);
                      }}
                    >
                      <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M22.3 8.2H17.5V8C17.5 6.6 16.4 5.5 15 5.5H14.8C13.4 5.5 12.3 6.6 12.3 8V8.2H7.4C7.1 8.2 6.8 8.5 6.8 8.8V11.3C6.9 11.6 7.1 11.8 7.4 11.8H7.7L9.4 24C9.4 24.3 9.7 24.5 10 24.5H19.8C20.1 24.5 20.3 24.3 20.4 24L22 11.8H22.3C22.6 11.8 22.9 11.5 22.9 11.2V8.8C22.9 8.5 22.6 8.2 22.3 8.2ZM13.3 8C13.3 7.2 13.9 6.6 14.7 6.6H14.9C15.7 6.6 16.3 7.2 16.3 8V8.2H13.3V8ZM19.2 23.4H10.4L8.9 11.8H20.8L19.2 23.4ZM21.7 10.7C21.6 10.7 8.1 10.7 8 10.7V9.4H21.7V10.7ZM12.4 21.8C12.7 21.8 12.9 21.5 12.9 21.2L12.2 13.7C12.2 13.4 11.9 13.2 11.6 13.2C11.3 13.2 11.1 13.5 11.1 13.8L11.8 21.3C11.8 21.6 12.1 21.8 12.4 21.8ZM17.3 21.8C17.6 21.8 17.9 21.6 17.9 21.3L18.6 13.8C18.6 13.5 18.4 13.2 18.1 13.2C17.8 13.2 17.5 13.4 17.5 13.7L16.8 21.2C16.8 21.5 17 21.8 17.3 21.8ZM14.8 21.8C15.1 21.8 15.4 21.5 15.4 21.2V13.7C15.4 13.4 15.1 13.1 14.8 13.1C14.5 13.1 14.2 13.4 14.2 13.7V21.2C14.3 21.6 14.5 21.8 14.8 21.8ZM31.1 22.2C31.1 27.1 27.2 31.1 22.2 31.1H8.9C4 31.1 0 27.2 0 22.2V8.9C0 4 3.9 0 8.9 0H22.2C27.1 0 31.1 3.9 31.1 8.9V22.2Z" fill="#4B4B4B"/>
                      </svg>
                    </button>
                  </span>
                </div>
              ))}
          </div>
        </div>
      </div>
    </>
  );
}
