import React, { useEffect, useState } from "react";
import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";

import {
  OrganimosCertificadores,
  IdoneidadeIntegridade,
  ManageAccess,
  MaterialService,
  EscopoAvaliacao,
  IndicadoresDesempenho,
  DocumentLicence,
  TypeDocumentLicence,
  Buyers,
  CertificationTypes,
  CriticidadeAvaliacao,
} from "../../routes/Pages";

import "./submodules.scss";

export default function Submodulos(props) {
  const [submodulos, setSubmodulos] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const currentUrl = "/parametrizacao/";
  const state = { ...props };

  const changeSubmodule = (moduleUrl) => {
    history.push(currentUrl + moduleUrl);
  };

  useEffect(() => {
    const menuStorage = JSON.parse(localStorage.getItem("menuStorage"));
    const provedoresModulo = menuStorage.find(
      (a) => a.name == "Parametrização"
    );
    setSubmodulos(provedoresModulo.subModules);
  }, []);

  const activeSubmodulos = (url) => {
    return (location.pathname == currentUrl + url) ? 'active' : '';
  }

  return (
    <section>
      <nav className="submodules-container">
        {submodulos &&
          submodulos.length > 0 &&
          submodulos.map((s, i) => (
            <button key={i} data-url={currentUrl + s.url} onClick={() => changeSubmodule(s.url)} className={activeSubmodulos(s.url)}>
                <div>
                  <img
                    // src={require(`../../assets/icons/iconeGestaoAcesso.png`)}
                    src={require(`../../assets/icons/${s.icon}`)}
                  />
                  <a><b>{s.name}</b></a>
                </div>
            </button>
          ))}
      </nav>
      <div>
        <Switch>
          <Route
            path={currentUrl + "organismos-certificadores"}
            render={() => <OrganimosCertificadores {...props} />}
            state={state}
          />
          <Route
            path={currentUrl + "gestao-acesso"}
            render={() => <ManageAccess {...props} />}
            state={state}
          />
          <Route
            path={currentUrl + "idoneidade-integridade"}
            render={() => <IdoneidadeIntegridade {...props} />}
            state={state}
          />
          <Route
            path={currentUrl + "descricao-materiais"}
            render={() => <MaterialService {...props} />}
            state={state}
          />
          <Route
            path={currentUrl + "escopo-avaliacao"}
            render={() => <EscopoAvaliacao {...props} />}
            state={state}
          />
          <Route
            path={currentUrl + "tipo-documento-licenca"}
            render={() => <TypeDocumentLicence {...props} />}
            state={state}
          />
          <Route
            path={currentUrl + "documentos-licencas"}
            render={() => <DocumentLicence {...props} />}
            state={state}
          />
          <Route
            path={currentUrl + "gestao-usuario"}
            render={() => <Buyers {...props} />}
            state={state}
          />
          <Route
            path={currentUrl + "tipo-certificacoes"}
            render={() => <CertificationTypes {...props} />}
            state={state}
          />

          <Route
            path={currentUrl + "indicadores-desempenho"}
            render={() => <IndicadoresDesempenho {...props} />}
            state={state}
          />
          <Route
            path={currentUrl + "criticidade-tipo-avaliacao"}
            render={() => <CriticidadeAvaliacao {...props} />}
            state={state}
          />
          <Route
            path={currentUrl + "idoneidade-integridade"}
            render={() => <IdoneidadeIntegridade {...props} />}
            state={state}
          />
        </Switch>
      </div>
    </section>
  );
}
