import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import DropList from "../../../../../components/DropList/DropList";
import CardInfoHorizontal from "../../components/CardInfoHorizontal";
import ModalGeneric from "../../../../../components/ModalGeneric";
import * as constantes from '../../../../../components/Common/Constantes';
import Loading from "../../../../../components/Loading";

import RegistrationMaterialsService from "../../../../../services/registrationMaterialsService";
import ProvedoresService from "../../../../../services/ProvedoresService";

import './styles.scss'


export default function ProdutosHomologados({ token, isLoading, provedor }) {
    const [searchInput, setSearchInput] = useState("");
    const [handleList, setHandleList] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [materiaisComprador, setMateriaisComprador] = useState([]);
    const [dataListItemSelect, setDataListItemSelect] = useState([]);
    const [dropListSelect, setDropListSelect] = useState(null)
    const [materiaisProvedor, setMateriaisProvedor] = useState([]);
    const [listaAindaNaoCadastrados, setListaAindaNaoCadastrados] = useState(null);
    const [listaParaCadastrar, setListaParaCadastrar] = useState([]);
    const [inputValid, setInputValid] = useState({ materialServices: null })
    const [loading, setLoading] = useState(false);

    const handleDropList = (key) => {
        setDropListSelect((dropListSelect !== key) ? key : '')
    }

    const handleGetSelectedOption = (option) => {
        const itemSelected = materiaisComprador.find(item =>
            item.id === option ? { id: item.id, name: item.name } : null
        )
        const index = dataListItemSelect.findIndex(item => item.id === option)

        if (index !== -1) {
            dataListItemSelect.splice(index, 1)
            setDataListItemSelect([...dataListItemSelect])
        } else {
            dataListItemSelect.push(itemSelected)
            setDataListItemSelect([...dataListItemSelect])
        }

        const materialIds = dataListItemSelect.map(item => (item.id))
        setListaParaCadastrar(materialIds)
        // setListaParaCadastrar((values => ({
        //     ...values,
        //     materialServices: materialIds
        // })))
    }

    const [dataList, setDataList] = useState([
        { id: 1, name: 'Material 1', status: 'Homologado' },
        { id: 2, name: 'Material 2', status: 'Em Homologação' },
        { id: 3, name: 'Serviço 1', status: 'Reprovado' }
    ])

    const [dataList2, setDataList2] = useState([
        { id: 1, name: 'Provedor de Cal' },
        { id: 2, name: 'Provedor de produto 1' },
        { id: 3, name: 'Provedor de produto 2' },
    ])

    const handleAddMaterialServico = () => {
        setShowModal(true);
    }

    const handleModal = (value) => {
        setShowModal(value);
        if (value === false) {
            setDataListItemSelect([]);
            setListaParaCadastrar([]);
            setDropListSelect(null);
        }
    };

    const search = (value) => {
        console.log(value);
    }

    const fillList = () => {
        RegistrationMaterialsService.getAll(token)
            .then(response => {
                setMateriaisComprador(response.valores)
            })
    }

    const getMateriaisServicos = async () => {
        const response = await ProvedoresService.getMateriaisServicosPorComprador(token, provedor.id)
        setMateriaisProvedor(response)
    }

    const materiaisAindaNaoCadastrados = () => {
        const materiais = materiaisComprador.filter(item => !materiaisProvedor.find(material => material.id === item.id));
        setListaAindaNaoCadastrados(materiais);
    }

    const handleEnviarSolicitacao = async () => {
        if (!listaParaCadastrar.length > 0)
            setInputValid({ materialServices: false })
        else
            setInputValid({ materialServices: true })

        if (inputValid.materialServices) {
            const data = {
                provedorId: provedor.id,
                materialServicesIds: listaParaCadastrar
            }
            setLoading(true);
            const response = await ProvedoresService.vincularMateriaisServicos(token, data);

            if (response) {
                if (response.response && constantes.statusError.includes(response.response.status)) {
                    toast.error(response.response.data.title || "Erro ao enviar solicitação")
                    setLoading(false);
                    return
                }

                toast.success("Solicitação enviada com sucesso!")
                setDataListItemSelect([]);
                setListaParaCadastrar([]);
                setDropListSelect(null);
            } else {
                toast.error("Erro ao enviar solicitação")
            }
            setLoading(false);
            handleModal(false);
        }
    }

    useEffect(() => {
        if (showModal) {
            fillList();
            getMateriaisServicos();
        }
    }, [showModal])

    useEffect(() => {
        if (materiaisProvedor.length > 0 && materiaisComprador.length > 0) {
            materiaisAindaNaoCadastrados();
        }
    }, [materiaisProvedor, materiaisComprador]);

    return (
        <div className="produtos-homologados-container">
            <Loading loading={loading} />

            <CardInfoHorizontal
                data01={{ title: 'MATERIAL/SERVIÇO CADASTRADOS', value: 3 }}
                data02={{ title: 'MATERIAL/SERVIÇO HOMOLOGADOS', value: 1 }}
                data03={{ title: 'ÍNDICE', value: 33.33 }}
            />
            {showModal &&
                <ModalGeneric
                    title={"Vincular Material/Serviço ao provedor"}
                    handleModal={handleModal}>
                    <div style={{ display: "flex !important", justifyContent: "center", alignItems: "center" }}>
                        <div className={"list-item d-inline-flex"} style={{ justifyContent: "center" }}>
                            <span>{(dataListItemSelect.length > 0)
                                ? dataListItemSelect.map(item => item.name).join(', ')
                                : 'Escolha um material / serviço'}
                            </span>
                            <DropList
                                uniqid={'vincular-grupo'}
                                label="Escolha o(s) grupo(s)"
                                selectable
                                multSelect
                                defaultValue={(dataListItemSelect.length === 0) && []}
                                options={listaAindaNaoCadastrados}
                                selectedOption={handleGetSelectedOption}
                                active={(dropListSelect === 'vincular-grupo')}
                                onClick={handleDropList}
                            />
                        </div>
                        {<span>{inputValid.materialServices === false && 'Campo obrigatório'}</span>}
                        <div className="btn-container" style={{ marginTop: "20%", marginRight: "0px" }}>
                            <button
                                className="global-white-btn"
                                onClick={() => handleModal(false)}
                            >
                                CANCELAR
                            </button>
                            <button
                                className="global-btn"
                                onClick={() => handleEnviarSolicitacao()}
                                disabled={false}
                            >
                                ENVIAR SOLICITAÇÃO
                            </button>
                        </div>
                    </div>
                </ModalGeneric>}

            <div className="nav-list-container">
                <div id="nav-list-button-1">
                    <button className={`- ${handleList ? 'selected' : ''}`}
                        onClick={() => setHandleList(true)}
                    >
                        <h1>MATERIAL / SERVIÇO HOMOLOGADOS</h1>
                    </button>
                </div>
                <div id="nav-list-button-2">
                    <button className={`- ${!handleList ? 'selected' : ''}`}
                        onClick={() => setHandleList(false)}
                    >
                        <h1>GRUPOS DE MATERIAL / SERVIÇO HOMOLOGADOS</h1>
                    </button>
                </div>
            </div>

            {handleList
                ? <div className="card-container">
                    <div className="card subcard">
                        <div className="card-header">
                            <h1></h1>
                            <div className="user-list-btn-container">
                                <button
                                    className="global-white-btn"
                                    onClick={handleAddMaterialServico}>
                                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.5799 10.5H10.4142V14.5H4.16567V10.5H0V4.5H4.16567V0.5H10.4142V4.5H14.5799V10.5Z" fill="#FFF" />
                                    </svg>
                                    Adicionar Material / Serviço
                                </button>
                            </div>
                            <div className="validate-data-wrap">
                                <b>Aceitar com pendência</b>

                                <div>
                                    <button
                                        className="global-btn baby-blue"
                                    >
                                        Sim
                                    </button>

                                    <button
                                        className="global-btn baby-blue"
                                    >
                                        Não
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="filter-wrap form-container group-input">
                            <div className="input-group-container">
                                <label><b>Filtro por Grupo de material / serviço:</b></label>
                                <div className="input-group-span">
                                    <div className="input-list-wrap">
                                        <input
                                            disabled={true}
                                            type="text"
                                            name="materialServices"
                                            id="materialServices"
                                            className="global-input"
                                        />
                                        <div className={"list-item d-inline-flex"}>
                                            <span>{'Escolha um grupo de material / serviço'}
                                            </span>
                                            <DropList
                                                uniqid={'material-serviço'}
                                                label="Grupos de materias / serviços"
                                                selectable
                                                options={[]}
                                                active={false}
                                                onClick={() => console.log('material-serviço')}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="user-list-search">
                                <div className="user-list-search-item">
                                    <input
                                        type="text"
                                        placeholder="Pesquisar"
                                        className="search-input"
                                        name="search"
                                        value={searchInput}
                                        onChange={(e) => setSearchInput(e.target.value)}
                                    />
                                    <button className="search-btn" onClick={() => search(searchInput)}>
                                        {isLoading && <FontAwesomeIcon icon={faCircleNotch} pulse />}
                                        <FontAwesomeIcon icon={faSearch} />
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="list-container">
                                <div className="list">
                                    <div className="list-header">
                                        <label className="space-list header-item-01">Nome do Material / Serviço</label>
                                        <label className="header-item-02">Status</label>
                                        <label className="header-item-03">Ficha Técnica</label>
                                        <label className="header-item-04">Descrição</label>
                                        <label className="header-item-05">Editar</label>
                                    </div>
                                    {dataList.map((data, i) => (
                                        <div className="list-body" key={data.id}>
                                            <span className="list-item space-list list-item-01"> {data.name}</span>
                                            <span
                                                className={`list-item list-item-02 staus-color-${(data.status === 'Homologado') ? 'green' : (data.status === 'Reprovado') ? 'red' : 'yellow'}`}
                                            >
                                                {data.status}
                                            </span>
                                            <span className="list-item document-list list-item-03">
                                                <a className="list-button" onClick={() => console.log('click')}>
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M19 0H5C2.3 0 0 2.2 0 5V19C0 21.7 2.2 24 5 24H19C21.8 24 24 21.8 24 19V5C24 2.3 21.8 0 19 0ZM21.3 12.3L21.2 12.4C20.1 14 18.6 15.3 16.9 16.3C13.9 18 10.1 18 7.1 16.3C5.4 15.3 3.9 14 2.8 12.4L2.7 12.3C2.6 12.1 2.6 11.8 2.7 11.6L2.8 11.5C3.9 10 5.4 8.7 7.1 7.7C10.1 6 13.9 6 16.9 7.7C18.6 8.7 20.1 10 21.2 11.6L21.3 11.7C21.5 11.8 21.5 12.2 21.3 12.3ZM12 13.2C11.4 13.2 10.9 12.7 10.9 12.1C10.9 11.5 11.4 11 12 11C12.6 11 13.1 11.5 13.1 12.1C13.1 12.7 12.6 13.2 12 13.2ZM12 8.7C10.2 8.7 8.7 10.2 8.7 12C8.7 13.8 10.2 15.3 12 15.3C13.9 15.4 15.4 13.9 15.3 12C15.3 10.2 13.8 8.7 12 8.7Z" fill="#4B4B4B" />
                                                    </svg>
                                                </a>
                                            </span>
                                            <span className="list-item document-list list-item-04">
                                                <a className="list-button" onClick={() => console.log('click')}>
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M19 0H5C2.3 0 0 2.2 0 5V19C0 21.7 2.2 24 5 24H19C21.8 24 24 21.8 24 19V5C24 2.3 21.8 0 19 0ZM21.3 12.3L21.2 12.4C20.1 14 18.6 15.3 16.9 16.3C13.9 18 10.1 18 7.1 16.3C5.4 15.3 3.9 14 2.8 12.4L2.7 12.3C2.6 12.1 2.6 11.8 2.7 11.6L2.8 11.5C3.9 10 5.4 8.7 7.1 7.7C10.1 6 13.9 6 16.9 7.7C18.6 8.7 20.1 10 21.2 11.6L21.3 11.7C21.5 11.8 21.5 12.2 21.3 12.3ZM12 13.2C11.4 13.2 10.9 12.7 10.9 12.1C10.9 11.5 11.4 11 12 11C12.6 11 13.1 11.5 13.1 12.1C13.1 12.7 12.6 13.2 12 13.2ZM12 8.7C10.2 8.7 8.7 10.2 8.7 12C8.7 13.8 10.2 15.3 12 15.3C13.9 15.4 15.4 13.9 15.3 12C15.3 10.2 13.8 8.7 12 8.7Z" fill="#4B4B4B" />
                                                    </svg>
                                                </a>
                                            </span>

                                            <span className="list-item document-list list-item-05">
                                                <a onClick={() => console.log('excluir')}>
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M17.2096 6.32797H13.5054V6.17363C13.5054 5.09325 12.6566 4.24437 11.5762 4.24437H11.4218C10.3414 4.24437 9.49257 5.09325 9.49257 6.17363V6.32797H5.71122C5.47971 6.32797 5.2482 6.55949 5.2482 6.791V8.72026C5.32537 8.95177 5.47971 9.10611 5.71122 9.10611H5.94273L7.25463 18.5209C7.25463 18.7524 7.48614 18.9068 7.71765 18.9068H15.2804C15.5119 18.9068 15.6662 18.7524 15.7434 18.5209L16.9781 9.10611H17.2096C17.4411 9.10611 17.6726 8.8746 17.6726 8.64309V6.791C17.6726 6.55949 17.4411 6.32797 17.2096 6.32797ZM10.2643 6.17363C10.2643 5.55627 10.7273 5.09325 11.3447 5.09325H11.499C12.1164 5.09325 12.5794 5.55627 12.5794 6.17363V6.32797H10.2643V6.17363ZM14.8173 18.0579H8.02633L6.86878 9.10611H16.0521L14.8173 18.0579ZM16.7466 8.25723C16.6694 8.25723 6.25141 8.25723 6.17424 8.25723V7.25402H16.7466V8.25723ZM9.56974 16.8232C9.80125 16.8232 9.95559 16.5916 9.95559 16.3601L9.4154 10.5723C9.4154 10.3408 9.18389 10.1865 8.95238 10.1865C8.72087 10.1865 8.56653 10.418 8.56653 10.6495L9.10672 16.4373C9.10672 16.6688 9.33823 16.8232 9.56974 16.8232ZM13.3511 16.8232C13.5826 16.8232 13.8141 16.6688 13.8141 16.4373L14.3543 10.6495C14.3543 10.418 14.2 10.1865 13.9685 10.1865C13.7369 10.1865 13.5054 10.3408 13.5054 10.5723L12.9652 16.3601C12.9652 16.5916 13.1196 16.8232 13.3511 16.8232ZM11.4218 16.8232C11.6533 16.8232 11.8849 16.5916 11.8849 16.3601V10.5723C11.8849 10.3408 11.6533 10.1093 11.4218 10.1093C11.1903 10.1093 10.9588 10.3408 10.9588 10.5723V16.3601C11.036 16.6688 11.1903 16.8232 11.4218 16.8232ZM24.0006 17.1318C24.0006 20.9132 20.991 24 17.1324 24H6.86878C3.08743 24 0.000610352 20.9904 0.000610352 17.1318V6.86817C0.000610352 3.08682 3.01026 0 6.86878 0H17.1324C20.9138 0 24.0006 3.00965 24.0006 6.86817V17.1318Z"
                                                            fill="#4B4B4B"
                                                        />
                                                    </svg>
                                                </a>

                                                <a onClick={() => console.log('editar')}>
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M19.2006 5.55627L18.1167 4.63023C17.4974 4.09003 16.6458 4.1672 16.1038 4.7074L5.49738 15.0482C5.41996 15.1254 5.26513 15.2797 5.26513 15.4341L4.25867 19.2154C4.18126 19.5241 4.41351 19.7556 4.72319 19.6785L8.43932 18.6752C8.59416 18.5981 8.749 18.5209 8.82642 18.4437L19.3554 7.71704C19.8974 7.09968 19.8974 6.17363 19.2006 5.55627ZM5.18771 18.8296L5.88448 16.2058L7.74255 18.135L5.18771 18.8296ZM8.43932 17.7492L6.19416 15.4341L13.9361 7.94855L16.0264 10.0322L8.43932 17.7492ZM16.6458 9.41479L14.5554 7.33119L15.0974 6.791L17.1877 8.8746L16.6458 9.41479ZM18.7361 7.17685L17.8071 8.18006L15.7942 6.17363L16.6458 5.32476C16.878 5.09325 17.2651 5.09325 17.5748 5.32476L18.7361 6.32797C19.0458 6.48231 19.0458 6.94534 18.7361 7.17685ZM7.74255 15.5884C7.58771 15.7428 7.58771 16.0514 7.74255 16.283C7.89738 16.4373 8.20706 16.4373 8.43932 16.283L14.478 10.2637C14.6329 10.1093 14.6329 9.80064 14.478 9.56913C14.3232 9.41479 14.0135 9.41479 13.7813 9.56913L7.74255 15.5884ZM24.0006 17.1318C24.0006 20.9132 20.9813 24 17.1103 24H6.89093C3.09738 24 0.000610352 20.9904 0.000610352 17.1318V6.86817C0.000610352 3.08682 3.01996 0 6.89093 0H17.1877C20.9038 0 24.0006 3.00965 24.0006 6.86817V17.1318Z"
                                                            fill="#4B4B4B"
                                                        />
                                                    </svg>
                                                </a>
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                : <div className="card-container">
                    <div className="card subcard">
                        <div className="card-header">
                            <h1></h1>

                            <div className="user-list-btn-container">
                                <button className="global-white-btn">
                                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M14.5799 10.5H10.4142V14.5H4.16567V10.5H0V4.5H4.16567V0.5H10.4142V4.5H14.5799V10.5Z" fill="#FFF" />
                                    </svg>
                                    Criar novo Grupo
                                </button>
                            </div>
                        </div>

                        <div className="card-body">
                            <div className="list-container">
                                <div className="list">
                                    <div className="list-header">
                                        <label className="space-list">Nome do Grupo</label>
                                        <label>Editar</label>
                                    </div>
                                    {dataList2.map((data, i) => (
                                        <div className="list-body" key={data.id}>
                                            <span className="list-item space-list">{data.name}</span>
                                            <span className="list-item document-list" style={{ flex: '0 0 0%' }}>
                                                <a onClick={() => console.log('excluir')}>
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M17.2096 6.32797H13.5054V6.17363C13.5054 5.09325 12.6566 4.24437 11.5762 4.24437H11.4218C10.3414 4.24437 9.49257 5.09325 9.49257 6.17363V6.32797H5.71122C5.47971 6.32797 5.2482 6.55949 5.2482 6.791V8.72026C5.32537 8.95177 5.47971 9.10611 5.71122 9.10611H5.94273L7.25463 18.5209C7.25463 18.7524 7.48614 18.9068 7.71765 18.9068H15.2804C15.5119 18.9068 15.6662 18.7524 15.7434 18.5209L16.9781 9.10611H17.2096C17.4411 9.10611 17.6726 8.8746 17.6726 8.64309V6.791C17.6726 6.55949 17.4411 6.32797 17.2096 6.32797ZM10.2643 6.17363C10.2643 5.55627 10.7273 5.09325 11.3447 5.09325H11.499C12.1164 5.09325 12.5794 5.55627 12.5794 6.17363V6.32797H10.2643V6.17363ZM14.8173 18.0579H8.02633L6.86878 9.10611H16.0521L14.8173 18.0579ZM16.7466 8.25723C16.6694 8.25723 6.25141 8.25723 6.17424 8.25723V7.25402H16.7466V8.25723ZM9.56974 16.8232C9.80125 16.8232 9.95559 16.5916 9.95559 16.3601L9.4154 10.5723C9.4154 10.3408 9.18389 10.1865 8.95238 10.1865C8.72087 10.1865 8.56653 10.418 8.56653 10.6495L9.10672 16.4373C9.10672 16.6688 9.33823 16.8232 9.56974 16.8232ZM13.3511 16.8232C13.5826 16.8232 13.8141 16.6688 13.8141 16.4373L14.3543 10.6495C14.3543 10.418 14.2 10.1865 13.9685 10.1865C13.7369 10.1865 13.5054 10.3408 13.5054 10.5723L12.9652 16.3601C12.9652 16.5916 13.1196 16.8232 13.3511 16.8232ZM11.4218 16.8232C11.6533 16.8232 11.8849 16.5916 11.8849 16.3601V10.5723C11.8849 10.3408 11.6533 10.1093 11.4218 10.1093C11.1903 10.1093 10.9588 10.3408 10.9588 10.5723V16.3601C11.036 16.6688 11.1903 16.8232 11.4218 16.8232ZM24.0006 17.1318C24.0006 20.9132 20.991 24 17.1324 24H6.86878C3.08743 24 0.000610352 20.9904 0.000610352 17.1318V6.86817C0.000610352 3.08682 3.01026 0 6.86878 0H17.1324C20.9138 0 24.0006 3.00965 24.0006 6.86817V17.1318Z"
                                                            fill="#4B4B4B"
                                                        />
                                                    </svg>
                                                </a>

                                                <a onClick={() => console.log('editar')}>
                                                    <svg
                                                        width="24"
                                                        height="24"
                                                        viewBox="0 0 24 24"
                                                        fill="none"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                    >
                                                        <path
                                                            d="M19.2006 5.55627L18.1167 4.63023C17.4974 4.09003 16.6458 4.1672 16.1038 4.7074L5.49738 15.0482C5.41996 15.1254 5.26513 15.2797 5.26513 15.4341L4.25867 19.2154C4.18126 19.5241 4.41351 19.7556 4.72319 19.6785L8.43932 18.6752C8.59416 18.5981 8.749 18.5209 8.82642 18.4437L19.3554 7.71704C19.8974 7.09968 19.8974 6.17363 19.2006 5.55627ZM5.18771 18.8296L5.88448 16.2058L7.74255 18.135L5.18771 18.8296ZM8.43932 17.7492L6.19416 15.4341L13.9361 7.94855L16.0264 10.0322L8.43932 17.7492ZM16.6458 9.41479L14.5554 7.33119L15.0974 6.791L17.1877 8.8746L16.6458 9.41479ZM18.7361 7.17685L17.8071 8.18006L15.7942 6.17363L16.6458 5.32476C16.878 5.09325 17.2651 5.09325 17.5748 5.32476L18.7361 6.32797C19.0458 6.48231 19.0458 6.94534 18.7361 7.17685ZM7.74255 15.5884C7.58771 15.7428 7.58771 16.0514 7.74255 16.283C7.89738 16.4373 8.20706 16.4373 8.43932 16.283L14.478 10.2637C14.6329 10.1093 14.6329 9.80064 14.478 9.56913C14.3232 9.41479 14.0135 9.41479 13.7813 9.56913L7.74255 15.5884ZM24.0006 17.1318C24.0006 20.9132 20.9813 24 17.1103 24H6.89093C3.09738 24 0.000610352 20.9904 0.000610352 17.1318V6.86817C0.000610352 3.08682 3.01996 0 6.89093 0H17.1877C20.9038 0 24.0006 3.00965 24.0006 6.86817V17.1318Z"
                                                            fill="#4B4B4B"
                                                        />
                                                    </svg>
                                                </a>
                                            </span>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}