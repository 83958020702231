import React, { useState, memo } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";

import "./DropList.scss";

function DropList({ uniqid, label, options, active, onClick, selectable, selectedOption, defaultValue, multSelect }) {
    const [selected, setSelected] = useState([])
    
    return (
        <>
            <div className={'DropList ' + (active ? 'active' : '')}>
                <div className="DropList-container">
                    <span className="DropList-header">
                        <FontAwesomeIcon
                            icon={faSortDown}
                            onClick={() => {
                                onClick(uniqid)
                                if (Number(defaultValue) || typeof defaultValue === Array() || defaultValue === 0) {
                                    setSelected(defaultValue)
                                }
                            }}
                        />
                        {label ? (
                            <p className="DropList-label">{label}</p>
                        ) : ``}
                    </span>
                    <ul
                        className={`DropList-options selectable-${selectable} multSelect-${multSelect}`}
                    >
                        {(options || []).map((option, i) => {
                            
                            if (selectable) {
                                if (multSelect) {
                                    return (
                                        <li
                                            className={"multSelect " + ((selected.indexOf(option.id) !== -1) ? 'selected' : '')}
                                            id={option.id}
                                            key={option.id}
                                            onClick={() => {
                                                onClick(uniqid)
                                                selectedOption(option.id || option.nome)

                                                const index = selected.indexOf(option.id)
                                                if (index !== -1) {
                                                    selected.splice(index, 1)
                                                    return selected
                                                } else {
                                                    selected.push(option.id)
                                                    setSelected([...selected])
                                                    return selected
                                                }
                                            }}>
                                            {option.nome || option.name}
                                        </li>
                                    )
                                } else {
                                    return (
                                        <li
                                            className={selected === option.id ? 'selected' : ''}
                                            id={option.id}
                                            key={option.id}
                                            onClick={() => {
                                                onClick(uniqid)
                                                selectedOption(option.id || option.nome)
                                                setSelected(option.id)
                                            }}>
                                            {option.nome || option.name}
                                        </li>
                                    )
                                }
                            } else {
                                return <li key={i}>{option}</li>
                            }
                        })}
                    </ul>
                </div>
            </div>
        </>
    );
}

export default memo(DropList)