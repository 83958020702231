import React, { useEffect, useState, useRef } from "react";

import Modal from "../../../components/ModalExit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import TypeDocumentLicenceService from "../../../services/TypeDocumentLicenceService";
import ProvedorDocumentoLicencaService from "../../../services/ProvedorDocumentoLicencaService";
import DropList from "../../../components/DropList/DropList"
import { toast } from "react-toastify";

export default function Form({
    token,
    valoresJaCadastrados,
    back,
    currentValue,
}) {
    const [modal, setModal] = useState(false);
    const [modalMsg, setModalMsg] = useState('');
    const [loading, setLoading] = useState(false);
    const [dataList, setDataList] = useState([]);
    const [dataListItemSelect, setDataListItemSelect] = useState([]);
    const [dropListSelect, setDropListSelect] = useState(null);
    const [onSubmitLoad, setOnSubmitLoad] = useState(false);
    const [inputValid, setInputValid] = useState({ documentoLicenca: null, numero: null, dataValidade: null, tipoDocumentoLicencaId: null })
    const [selectedFile, setSelectedFile] = useState([{ name: "" }]);
    const [selectedFileDocAssociado, setSelectedFileDocAssociado] = useState([{ name: "" }]);
    const [data, setData] = useState({
        id: 0,
        tipoDocumentoLicencaId: 0,
        dataValidade: "",
        numero: 0,
        observacao: ""
    });
    const inputFile = useRef(null);
    const inputFileAssociado = useRef(null)
    const [arquivoDocAlterado, setArquivoDocAlterado] = useState(false)
    const [arquivoDocAssociadoAlterado, setArquivoDocAssociadoAlterado] = useState(false)

    const handleDropList = (key) => {
        setDropListSelect((dropListSelect !== key) ? key : '')
    }

    const handleGetSelectedOption = (option) => {
        const itemSelected = dataList.find(item =>
            item.id === option ? { id: item.id, name: item.name } : null
        )
        const index = dataListItemSelect.findIndex(item => item.id === option)

        dataListItemSelect.splice(index, 1)
        dataListItemSelect.push(itemSelected);
        setDataListItemSelect([...dataListItemSelect]);

        const docIds = dataListItemSelect.map(item => (item.id))

        setData((values => ({
            ...values,
            tipoDocumentoLicencaId: docIds[0]
        })))
        setInputValid(values => ({
            ...values,
            tipoDocumentoLicencaId: true
        }))
    }

    const handlerChangeFile = (event) => {
        const file = event.target.files[0]
        if (file) {

            const type = file.type
            if (type !== "application/pdf" && type !== "image/png" && type !== "image/jpeg" && type !== "image/jpg") {
                setModalMsg('Tipo de arquivo inválido, selecione um arquivo válido do tipo PDF, PNG ou JPG')
                setModal(true)
                return
            }

            if (file.size > 10000000) {
                setModalMsg('O arquivo deve ser igual ou menor a 10MB')
                setModal(true)
                return
            }

            if (event.target.id == "file") {
                setSelectedFile([file]);
                setArquivoDocAlterado(true);
                setInputValid(values => ({
                    ...values,
                    documentoLicenca: true
                }))
            }
            else {
                setSelectedFileDocAssociado([file]);
                setArquivoDocAssociadoAlterado(true);
            }
        }
    };

    const handleChangeNumero = (event) => {
        event.persist();

        setData(values => ({
            ...values,
            numero: event.target.value,
        }));

        setInputValid(values => ({
            ...values,
            numero: true
        }))
    };

    const handleChangeObservacao = (event) => {
        event.persist();

        setData(values => ({
            ...values,
            observacao: event.target.value,
        }));
    };

    const handleChangeData = (event) => {
        event.persist();

        const dateMask = () => {
            var data = event.target.value;

            if (data.length === 2) {
                return event.nativeEvent.inputType === 'deleteContentBackward'
                    ? data
                    : data = data + '/';
            }
            if (data.length === 5) {
                return event.nativeEvent.inputType === 'deleteContentBackward'
                    ? data
                    : data = data + '/';
            }

            return event.target.value;
        }

        setData(values => ({
            ...values,
            dataValidade: dateMask()
        }));

        setInputValid(values => ({
            ...values,
            dataValidade: true
        }))
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (data) {
            if (data.tipoDocumentoLicencaId === 0) {
                setInputValid(values => ({
                    ...values,
                    tipoDocumentoLicencaId: false
                }))
                return
            }

            if (data.numero === 0 || data.numero === "") {
                setInputValid(values => ({
                    ...values,
                    numero: false
                }))
                return
            }

            if (data.dataValidade === "") {
                setInputValid(values => ({
                    ...values,
                    dataValidade: false
                }))
                return
            }

            if (selectedFile[0].name === "") {
                setInputValid(values => ({
                    ...values,
                    documentoLicenca: false
                }))
                return
            }

            let editarArquivos = false;
            const formData = new FormData();
            if (arquivoDocAlterado || arquivoDocAssociadoAlterado) {
                editarArquivos = true;

                if (arquivoDocAlterado)
                    formData.append("arquivoDoc", selectedFile[0]);
                if (arquivoDocAssociadoAlterado)
                    formData.append("arquivoDocAssociado", selectedFileDocAssociado[0]);
            }


            if (currentValue && currentValue.id > 0) {
                return handleUpdate(editarArquivos, formData);
            }
            handleRegister(formData);
        }
    };

    const buscaTipoDocumentoSelecionado = (id) => {
        let itemSelecionado = [];
        itemSelecionado = dataList.map((item) => {
            if (id === item.id)
                return item;
        })
        itemSelecionado = itemSelecionado.filter((i) => {
            return i;
        })
        return itemSelecionado;
    }

    function fillList() {
        TypeDocumentLicenceService.getDocumentLicences(token)
            .then(response => {
                setDataList(removeJaCadastrados(response.valores));
            })
    }

    const removeJaCadastrados = (lista) => {
        let listaAjustada = [];
        let ids = [];
        ids = valoresJaCadastrados.map((item) => {
            return item.tipoDocumentoLicencaId;
        })

        listaAjustada = lista.filter((item) => {
            if (!ids.includes(item.id))
                return item;
        })

        if (currentValue) {
            let atual = lista.filter((item) => {
                if (currentValue.tipoDocumentoLicencaId == item.id)
                    return item;
            })
            listaAjustada.push(atual[0]);
        }

        return listaAjustada;
    }

    const dataFormat = () => {
        const dd = data.dataValidade.split('/')
        return `${dd[2]}-${dd[1]}-${dd[0]}`
    }

    const handleRegister = async (formData) => {
        setLoading(true);

        const response = await ProvedorDocumentoLicencaService.registerDocumentLicence(token, { ...data, dataValidade: dataFormat() }, formData);

        if (response) {
            if (response.response && response.response.status === 400) {
                toast.error(response.response.data);
                setLoading(false);
                return
            }

            toast.success("Cadastro realizado com sucesso!");
            setLoading(false);

            back();
        } else {
            toast.error("Erro ao cadastrar item!");
            setLoading(false);
        }
    };

    const handleUpdate = async (editarArquivos, formData) => {
        setLoading(true);

        const response = await ProvedorDocumentoLicencaService.updateDocumentLicences(token, { ...data, dataValidade: dataFormat() }, editarArquivos, formData);

        if (response) {
            if (response.response && response.response.status === 400) {
                toast.error(response.response.data);
                setLoading(false);
                return
            }

            toast.success("Item atualizado com sucesso!");
            setLoading(false);

            back();
        } else {
            toast.error("Erro ao atualizar item!");
            setLoading(false);
        }
    };

    const handleModal = (value) => {
        setModal(value);
    };

    useEffect(() => {
        fillList();
    }, []);

    useEffect(() => {
        if (currentValue) {
            setDataListItemSelect(buscaTipoDocumentoSelecionado(currentValue.tipoDocumentoLicencaId));
            setLoading(false);
        }
    }, [dataList])

    useEffect(() => {
        if (currentValue) {
            setLoading(true);
            setData(currentValue);
            setSelectedFile([{ name: `${currentValue.nomeArquivoDocumentoLicenca ? currentValue.nomeArquivoDocumentoLicenca + currentValue.extensaoDocumentoLicenca : ""}` }])
            setSelectedFileDocAssociado([{ name: `${currentValue.nomeArquivoDocumentoAssociado ? currentValue.nomeArquivoDocumentoAssociado + currentValue.extensaoDocumentoAssociado : ""}` }]);
        }
    }, [currentValue]);

    return (
        <>
            {modal &&
                <Modal
                    title={modalMsg}
                    handleDelete={null}
                    handleModal={handleModal}
                    alert
                />
            }
            <div>
                <div className="card-container">
                    <div className="card">
                        <div className="card-header">
                            <h1>NOVO DOCUMENTO/LICENÇA</h1>
                        </div>
                        {loading && (
                            <div className={"loading-mask"}>
                                <FontAwesomeIcon icon={faCircleNotch} pulse />
                            </div>
                        )}
                        <div className="card-body">
                            <form className="form-container" onSubmit={handleSubmit}>
                                <div className="group-input  created-form">
                                    <div className="input-group-container">
                                        <div className="content-input " style={{ width: "70%" }}>
                                            <label htmlFor="nome" style={{ maxwidth: "50px !important" }}>Qual?</label>
                                            <div className="input-group-span">
                                                <div className="input-list-wrap">
                                                    <input
                                                        disabled={true}
                                                        type="text"
                                                        name="doc"
                                                        id="doc"
                                                        className="global-input"
                                                    />
                                                    <div className={"list-item d-inline-flex"}>
                                                        <span>{(dataListItemSelect.length > 0)
                                                            ? dataListItemSelect[0].nome
                                                            : 'Escolha um documento/licença cadastrado no sistema'}
                                                        </span>
                                                        <DropList
                                                            uniqid={'vincular-doc'}
                                                            label="Documentos e Licenças cadastradas"
                                                            selectable
                                                            defaultValue={dataListItemSelect.length === 0 ? [] : dataListItemSelect[0].id}
                                                            options={dataList}
                                                            selectedOption={handleGetSelectedOption}
                                                            active={(dropListSelect === 'vincular-doc')}
                                                            onClick={handleDropList}
                                                        />
                                                    </div>
                                                </div>
                                                {<span>{inputValid.tipoDocumentoLicencaId === false && 'Campo obrigatório'}</span>}
                                            </div>

                                        </div>
                                        <div className="content-input required" style={{ width: "30% !important" }}>
                                            <label htmlFor="numero">Número</label>
                                            <input
                                                className="global-input"
                                                type="text"
                                                id="numero"
                                                placeholder=""
                                                name="numero"
                                                value={data.numero}
                                                onChange={handleChangeNumero}
                                                style={{
                                                    marginLeft: 0
                                                }}
                                            />
                                            {<span>{inputValid.numero === false && 'Campo obrigatório'}</span>}

                                        </div>
                                    </div>

                                </div>
                                <div className="group-input  created-form">
                                    <div className="input-group-container">
                                        <div className="content-input required" style={{ width: "245px" }}>
                                            <label
                                                htmlFor="data"
                                                style={{
                                                    marginRight: '0px',
                                                    marginLeft: "0px"
                                                }}
                                            >Data de Validade</label>

                                            <input
                                                style={{ width: "115px" }}
                                                className="global-input"
                                                type="text"
                                                id="data"
                                                placeholder="--/--/----"
                                                name="data"
                                                maxLength="10"
                                                value={data.dataValidade}
                                                onChange={handleChangeData}
                                            />
                                            {<span>{inputValid.dataValidade === false && 'Campo obrigatório'}</span>}
                                        </div>
                                        <div className="upload-wrapp required"
                                            style={{
                                                display: "grid",
                                                gap: "10px",
                                                alignItems: "center",
                                                gridTemplateColumns: '100px 54px 0.91fr'
                                            }}>
                                            <label htmlFor="file">Upload do documento/licença</label>
                                            <div id="input-file-wrapp" style={{ marginLeft: "60px", marginRight: "5px" }} >
                                                <div onClick={() => inputFile.current.click()}
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M10 0C4.47715 0 0 4.47715 0 10V44C0 49.5228 4.47715 54 10 54H44C49.5228 54 54 49.5228 54 44V10C54 4.47715 49.5228 0 44 0H10ZM9.125 32C9.125 31.4477 8.67729 31 8.125 31C7.57272 31 7.125 31.4477 7.125 32V38.6667C7.125 39.889 7.72129 41.0087 8.6886 41.7983C9.65031 42.5834 10.9184 43 12.2083 43H40.7917C42.0816 43 43.3497 42.5834 44.3114 41.7983C45.2787 41.0087 45.875 39.889 45.875 38.6667V32C45.875 31.4477 45.4273 31 44.875 31C44.3227 31 43.875 31.4477 43.875 32V38.6667C43.875 39.2124 43.6109 39.7884 43.0466 40.249C42.4768 40.7142 41.6677 41 40.7917 41H12.2083C11.3323 41 10.5232 40.7142 9.95336 40.249C9.38913 39.7884 9.125 39.2124 9.125 38.6667V32ZM37.4826 20.9657C37.1333 21.3935 36.5034 21.4572 36.0755 21.108L27.4992 14.1069L27.4992 32.0013C27.4992 32.5536 27.0515 33.0013 26.4992 33.0013C25.9469 33.0013 25.4992 32.5536 25.4992 32.0013L25.4992 14.1075L16.9236 21.108C16.4958 21.4572 15.8658 21.3935 15.5166 20.9657C15.1673 20.5379 15.231 19.9079 15.6589 19.5587L25.8672 11.2253C26.2353 10.9249 26.7639 10.9249 27.132 11.2253L37.3403 19.5587C37.7681 19.9079 37.8318 20.5379 37.4826 20.9657Z" fill="#4068C8" />
                                                    </svg>
                                                </div>
                                                <input
                                                    type="file"
                                                    accept=".pdf,.jpg,.png"
                                                    id="file"
                                                    name="file"
                                                    className="file"
                                                    ref={inputFile}
                                                    onChange={handlerChangeFile}
                                                    style={{ display: "none" }}
                                                />
                                            </div>
                                            <span style={{
                                                maxWidth: '600px',
                                                padding: '20px 0',
                                                marginLeft: '100px'
                                            }}>Clique e selecione o arquivo em pdf, jpg ou png do documento/licença, somente serão aceitos documentos legíveis e com no máximo 10 MB de tamanho.</span>
                                            {<span>{selectedFile ? selectedFile[0].name : ""}</span>}
                                            {<span>{inputValid.documentoLicenca === false && 'Campo obrigatório'}</span>}
                                        </div>

                                    </div>

                                </div>
                                <div className="group-input  created-form">
                                    <div className="input-group-container">
                                        <div className="upload-wrapp"
                                            style={{
                                                display: "grid",
                                                gap: "10px",
                                                alignItems: "center",
                                                gridTemplateColumns: '100px 54px 0.91fr'
                                            }}>
                                            <label htmlFor="fileAssociado">Documento Associado</label>
                                            <div id="input-file-wrapp" style={{ marginLeft: "20px", marginRight: "20px" }} >
                                                <div onClick={() => inputFileAssociado.current.click()}
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M10 0C4.47715 0 0 4.47715 0 10V44C0 49.5228 4.47715 54 10 54H44C49.5228 54 54 49.5228 54 44V10C54 4.47715 49.5228 0 44 0H10ZM9.125 32C9.125 31.4477 8.67729 31 8.125 31C7.57272 31 7.125 31.4477 7.125 32V38.6667C7.125 39.889 7.72129 41.0087 8.6886 41.7983C9.65031 42.5834 10.9184 43 12.2083 43H40.7917C42.0816 43 43.3497 42.5834 44.3114 41.7983C45.2787 41.0087 45.875 39.889 45.875 38.6667V32C45.875 31.4477 45.4273 31 44.875 31C44.3227 31 43.875 31.4477 43.875 32V38.6667C43.875 39.2124 43.6109 39.7884 43.0466 40.249C42.4768 40.7142 41.6677 41 40.7917 41H12.2083C11.3323 41 10.5232 40.7142 9.95336 40.249C9.38913 39.7884 9.125 39.2124 9.125 38.6667V32ZM37.4826 20.9657C37.1333 21.3935 36.5034 21.4572 36.0755 21.108L27.4992 14.1069L27.4992 32.0013C27.4992 32.5536 27.0515 33.0013 26.4992 33.0013C25.9469 33.0013 25.4992 32.5536 25.4992 32.0013L25.4992 14.1075L16.9236 21.108C16.4958 21.4572 15.8658 21.3935 15.5166 20.9657C15.1673 20.5379 15.231 19.9079 15.6589 19.5587L25.8672 11.2253C26.2353 10.9249 26.7639 10.9249 27.132 11.2253L37.3403 19.5587C37.7681 19.9079 37.8318 20.5379 37.4826 20.9657Z" fill="#4068C8" />
                                                    </svg>
                                                </div>
                                                <input
                                                    type="file"
                                                    accept=".pdf,.jpg,.png"
                                                    id="fileAssociado"
                                                    name="fileAssociado"
                                                    className="file"
                                                    ref={inputFileAssociado}
                                                    onChange={handlerChangeFile}
                                                    style={{ display: "none" }}
                                                />
                                            </div>
                                            <span style={{
                                                maxWidth: '600px',
                                                padding: '20px 0',
                                                marginLeft: '65px'
                                            }}>Clique e selecione o arquivo em pdf, jpg ou png do documento associado, somente serão aceitos documentos legíveis e com no máximo 10 MB de tamanho.</span>
                                            {<span>{selectedFileDocAssociado ? selectedFileDocAssociado[0].name : ""}</span>}
                                        </div>
                                    </div>

                                </div>
                                <div className="group-input  created-form">
                                    <div className="input-group-container">
                                        <div className="content-input" style={{ width: "100%" }}>
                                            <label htmlFor="observacao">Observação</label>
                                            <textarea
                                                className="global-input"
                                                type="textarea"
                                                id="observacao"
                                                placeholder=""
                                                name="observacao"
                                                value={data.observacao}
                                                onChange={handleChangeObservacao}
                                                style={{
                                                    width: '200%',
                                                    marginLeft: 30
                                                }}
                                            />

                                        </div>
                                    </div>

                                </div>

                            </form>
                        </div>
                    </div>

                </div>
                <div className="row mx-0">
                    <div className="col-12 px-0 justify-content-between">
                        <button
                            type="button"
                            className="global-btn w-auto"
                            disabled={loading && true}
                            onClick={back}
                        >
                            <svg className="mr-1" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.47211 5.05021L5.10332 9.09061L4.31819 9.69667L0 5.05021L4.31819 0.302734L5.00518 1.00981L1.47211 5.05021ZM9.71593 1.00981L9.02894 0.302734L4.71075 5.05021L9.02894 9.69667L9.71593 8.9896L6.18286 5.05021L9.71593 1.00981Z" fill="white" />
                            </svg>
                            VOLTAR
                        </button>
                        <button
                            type="button"
                            className="global-btn w-auto"
                            disabled={loading && true}
                            onClick={handleSubmit}
                        >
                            {loading ? '' : currentValue ? "SALVAR" : "CRIAR"}
                            {loading && <FontAwesomeIcon icon={faCircleNotch} pulse />}
                        </button>
                    </div>
                </div>
            </div>
        </>
    );
}
