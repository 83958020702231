import React, { useEffect, useState, useRef } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import DropList from "../../../components/DropList/DropList";
import { toast } from "react-toastify";

import Modal from "../../../components/ModalExit";
import InputRadio from "../../../components/InputRadioMeusCertificados/InputRadio";

import TipoCertificacaoService from "../../../services/TipoCertificacaoService";
import OrganismosCertificadoresService from "../../../services/OrganismosCertificadoresService";

import { ListCertificados, ListUser } from "../ListCertificados/listaCertificados";

export default function Form({
    token,
    valoresJaCadastradosOrganismosCertificadores,
    valoresJaCadastradosTipoCertificados,
    listMateriaisServicos,
    setDeleteID,
    setCurrent,
    initComponent,
    create,
    listUser,
    currentValue,
    handleRegister,
    handleUpdate
}) {
    const [modal, setModal] = useState(false);
    const [modalMsg, setModalMsg] = useState('');
    const [loading, setLoading] = useState(true);

    const [dataListTiposCertificados, setDataListTiposCertificados] = useState([]);
    const [dropListSelectTiposCertificados, setDropListSelectTiposCertificados] = useState(null);
    const [dataListItemSelectTiposCertificados, setDataListItemSelectTiposCertificados] = useState([]);

    const [dataListOrganismosCertificadores, setDataListOrganismosCertificadores] = useState([]);
    const [dropListSelectOrganismosCertificadores, setDropListSelectOrganismosCertificadores] = useState(null);
    const [dataListItemSelectOrganismosCertificadores, setDataListItemSelectOrganismosCertificadores] = useState([]);

    const inputFile = useRef(null);
    const [inputValid, setInputValid] = useState({ certificado: null, organismoCertificadorId: null, numeroCertificado: null, dataValidade: null, tipoCertificacaoId: null })
    const [certificadoAlterado, setCertificadoAlterado] = useState(false)

    const [selectedFile, setSelectedFile] = useState([{ name: "" }]);
    const [isToggled, setIsToggled] = useState(false);

    const [data, setData] = useState({
        id: 0,
        tipoCertificacaoId: 0,
        organismoCertificadorId: 0,
        dataValidade: "",
        numeroCertificado: 0,
        escopo: ""
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!isToggled) {
            toast.error('É obrigatório confirmar a autorização!');
            return;
        }

        if (data) {
            if (data.tipoCertificacaoId === 0) {
                setInputValid(values => ({
                    ...values,
                    tipoCertificacaoId: false
                }))
                return
            }

            if (data.organismoCertificadorId === 0) {
                setInputValid(values => ({
                    ...values,
                    organismoCertificadorId: false
                }))
                return
            }

            if (data.numeroCertificado === 0 || data.numeroCertificado === "") {
                setInputValid(values => ({
                    ...values,
                    numeroCertificado: false
                }))
                return
            }

            if (data.dataValidade === "") {
                setInputValid(values => ({
                    ...values,
                    dataValidade: false
                }))
                return
            }

            if (selectedFile[0].name === "") {
                setInputValid(values => ({
                    ...values,
                    certificado: false
                }))
                return
            }

            let editarArquivos = false;
            const formData = new FormData();
            if (certificadoAlterado) {
                editarArquivos = true;
                formData.append("certificado", selectedFile[0]);
            }

            if (currentValue && currentValue.id > 0)
                return handleUpdate(editarArquivos, data, formData);

            handleRegister(data, formData);
        }
    }

    const handleGetSelectedOption = (option) => {
        const itemSelected = dataListTiposCertificados.find(item =>
            item.id === option ? { id: item.id, name: item.name } : null
        );

        const index = dataListItemSelectTiposCertificados.findIndex(item => item.id === option);

        dataListItemSelectTiposCertificados.splice(index, 1);
        dataListItemSelectTiposCertificados.push(itemSelected);
        setDataListItemSelectTiposCertificados([...dataListItemSelectTiposCertificados]);

        const docIds = dataListItemSelectTiposCertificados.map(item => (item.id));

        setData((values => ({
            ...values,
            tipoCertificacaoId: docIds[0]
        })));

        setInputValid(values => ({
            ...values,
            tipoCertificacaoId: true
        }));
    }

    const handleGetSelectedOptionOrganismosCertificadores = (option) => {
        const itemSelected = dataListOrganismosCertificadores.find(item =>
            item.id === option ? { id: item.id, name: item.name } : null
        );

        const index = dataListItemSelectOrganismosCertificadores.findIndex(item => item.id === option);

        dataListItemSelectOrganismosCertificadores.splice(index, 1);
        dataListItemSelectOrganismosCertificadores.push(itemSelected);
        setDataListItemSelectOrganismosCertificadores([...dataListItemSelectOrganismosCertificadores]);

        const docIds = dataListItemSelectOrganismosCertificadores.map(item => (item.id));

        setData((values => ({
            ...values,
            organismoCertificadorId: docIds[0]
        })));

        setInputValid(values => ({
            ...values,
            organismoCertificadorId: true
        }));
    }

    const handleDropListOrganismosCertificadores = (key) => {
        setDropListSelectOrganismosCertificadores((dropListSelectOrganismosCertificadores !== key) ? key : '')
    }

    const handleDropList = (key) => {
        setDropListSelectTiposCertificados((dropListSelectTiposCertificados !== key) ? key : '')
    }

    const handleModal = (value) => {
        setModal(value);
    };

    const handleChangenumeroCertificado = (event) => {
        event.persist();

        setData(values => ({
            ...values,
            numeroCertificado: event.target.value,
        }));

        setInputValid(values => ({
            ...values,
            numeroCertificado: true
        }))
    };

    const handleChangeData = (event) => {
        event.persist();

        const dateMask = () => {
            var data = event.target.value;

            if (data.length === 2) {
                return event.nativeEvent.inputType === 'deleteContentBackward'
                    ? data
                    : data = data + '/';
            }
            if (data.length === 5) {
                return event.nativeEvent.inputType === 'deleteContentBackward'
                    ? data
                    : data = data + '/';
            }

            return event.target.value;
        }

        setData(values => ({
            ...values,
            dataValidade: dateMask()
        }));

        setInputValid(values => ({
            ...values,
            dataValidade: true
        }))
    };

    const handlerChangeFile = (event) => {
        const file = event.target.files[0]
        if (file) {

            const type = file.type
            if (type !== "application/pdf" && type !== "image/png" && type !== "image/jpeg" && type !== "image/jpg") {
                setModalMsg('Tipo de arquivo inválido, selecione um arquivo válido do tipo PDF, PNG ou JPG')
                setModal(true)
                return
            }

            if (file.size > 10000000) {
                setModalMsg('O arquivo deve ser igual ou menor a 10MB')
                setModal(true)
                return
            }

            if (event.target.id == "file") {
                setSelectedFile([file]);
                setCertificadoAlterado(true);
                setInputValid(values => ({
                    ...values,
                    certificado: true
                }))
            }
        }
    };

    const handleChangeEscopo = (event) => {
        event.persist();

        setData(values => ({
            ...values,
            escopo: event.target.value,
        }));
    };

    function getTiposCertificacao() {
        TipoCertificacaoService.get(token)
            .then(response => {
                setLoading(!loading)
                setDataListTiposCertificados(removeJaCadastradosTiposCertificados(response.valores));
            })
    }

    const buscaTiposCertificadosSelecionado = (id) => {
        let itemSelecionado = [];
        itemSelecionado = dataListTiposCertificados.map((item) => {
            if (id === item.id)
                return item;
        });

        itemSelecionado = itemSelecionado.filter((i) => {
            return i;
        });

        return itemSelecionado;
    }

    const buscaOrganismosCertificadoresSelecionado = (id) => {
        let itemSelecionado = [];
        itemSelecionado = dataListOrganismosCertificadores.map((item) => {
            if (id === item.id)
                return item;
        })
        itemSelecionado = itemSelecionado.filter((i) => {
            return i;
        })
        return itemSelecionado;
    }

    function getOrganismosCertificadores() {
        OrganismosCertificadoresService.getOrganismosCertificadores(token)
            .then(response => {
                setDataListOrganismosCertificadores(removeJaCadastradosOrganismosCertificadores(response.valores));
            });
    }

    const removeJaCadastradosTiposCertificados = (lista) => {
        let listaAjustada = [];
        let ids = [];
        ids = valoresJaCadastradosTipoCertificados.map((item) => {
            return item.tipoCertificacaoId;
        });

        if (currentValue) {
            listaAjustada = [];

            let atual = lista.filter((item) => {
                if (currentValue.tipoCertificacaoId == item.id)
                    return item;
            });

            listaAjustada.push(atual[0]);

            return listaAjustada;
        }

        listaAjustada = lista.filter((item) => {
            if (!ids.includes(item.id))
                return item;
        });

        return listaAjustada;
    }

    const removeJaCadastradosOrganismosCertificadores = (lista) => {
        let listaAjustada = [];
        let ids = [];

        ids = valoresJaCadastradosOrganismosCertificadores.map((item) => {
            return item.organismoCertificadorId;
        });

        if (currentValue) {
            listaAjustada = [];

            let atual = lista.filter((item) => {
                if (currentValue.organismoCertificadorId == item.id)
                    return item;
            });

            listaAjustada.push(atual[0]);

            return listaAjustada;
        }

        listaAjustada = lista.filter((item) => {
            if (!ids.includes(item.id))
                return item;
        });

        return listaAjustada;
    }

    const onToggle = (e) => {
        e.preventDefault();
        setIsToggled(!isToggled)
    };

    useEffect(() => {
        getTiposCertificacao();
        getOrganismosCertificadores();
        if (currentValue && currentValue.id > 0)
            setIsToggled(true);
    }, []);

    useEffect(() => {
        if (currentValue) {
            setDataListItemSelectTiposCertificados(buscaTiposCertificadosSelecionado(currentValue.tipoCertificacaoId));
            setDataListItemSelectOrganismosCertificadores(buscaOrganismosCertificadoresSelecionado(currentValue.organismoCertificadorId));
        }

    }, [dataListTiposCertificados, dataListOrganismosCertificadores]);

    useEffect(() => {
            if (currentValue) {
            setData(currentValue);
            setSelectedFile([{ name: `${currentValue.nomeArquivo ? currentValue.nomeArquivo + currentValue.extensao : ""}` }])
        }
    }, [currentValue]);

    return (
        <>
            {modal &&
                <Modal
                    title={modalMsg}
                    handleDelete={null}
                    handleModal={handleModal}
                    alert
                />
            }
            <div>
                <div className="card-container">
                    <div className="card">
                        <div className="card-header">
                            <h1>NOVO CERTIFICADO</h1>
                        </div>
                        {loading && (
                            <div className={"loading-mask"}>
                                <FontAwesomeIcon icon={faCircleNotch} pulse />
                            </div>
                        )}
                        <div className="card-body">
                            <form className="form-container" onSubmit={handleSubmit}>
                                <div className="group-input created-form">
                                    <div className="input-group-container">
                                        <div className="content-input" style={{ width: "100%" }}>
                                            <label htmlFor="tipoCertificado" style={{ maxwidth: "50px !important" }}>Qual?</label>
                                            <div className="input-group-span">
                                                <div className="input-list-wrap">
                                                    <input
                                                        disabled={true}
                                                        type="text"
                                                        name="tipoCertificado"
                                                        id="tipoCertificado"
                                                        className="global-input"
                                                    />
                                                    <div className={"list-item d-inline-flex"}>
                                                        <span>{(dataListItemSelectTiposCertificados.length > 0)
                                                            ? dataListItemSelectTiposCertificados[0].nome
                                                            : 'Escolha um tipo certificação cadastrada no sistema'}
                                                        </span>
                                                        <DropList
                                                            uniqid={'tipoCertificado'}
                                                            label="Qual"
                                                            selectable
                                                            defaultValue={dataListItemSelectTiposCertificados.length === 0 ? [] : dataListItemSelectTiposCertificados[0].id}
                                                            options={dataListTiposCertificados}
                                                            selectedOption={handleGetSelectedOption}
                                                            active={(dropListSelectTiposCertificados === 'tipoCertificado')}
                                                            onClick={handleDropList}
                                                        />
                                                    </div>
                                                </div>
                                                {<span>{inputValid.tipoCertificacaoId === false && 'Campo obrigatório'}</span>}
                                            </div>
                                        </div>
                                        <div className="content-input" style={{ width: "100%" }}>
                                            <label htmlFor="nome" style={{ maxwidth: "50px !important" }}>Organismo certificador</label>
                                            <div className="input-group-span">
                                                <div className="input-list-wrap">
                                                    <input
                                                        disabled={true}
                                                        type="text"
                                                        name="organismo"
                                                        id="organismo"
                                                        className="global-input"
                                                    />
                                                    <div className={"list-item d-inline-flex"}>
                                                        <span style={{ width: "100%" }}>{(dataListItemSelectOrganismosCertificadores.length > 0)
                                                            ? dataListItemSelectOrganismosCertificadores[0].name
                                                            : 'Escolha um organismo certificador cadastrado no sistema'}
                                                        </span>
                                                        <DropList
                                                            uniqid={'organismo'}
                                                            label="Organismo certificador"
                                                            selectable
                                                            defaultValue={dataListItemSelectOrganismosCertificadores.length === 0 ? [] : dataListItemSelectOrganismosCertificadores[0].id}
                                                            options={dataListOrganismosCertificadores}
                                                            selectedOption={handleGetSelectedOptionOrganismosCertificadores}
                                                            active={(dropListSelectOrganismosCertificadores === 'organismo')}
                                                            onClick={handleDropListOrganismosCertificadores}
                                                        />
                                                    </div>
                                                </div>
                                                {<span>{inputValid.organismoCertificadorId === false && 'Campo obrigatório'}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="group-input created-form" style={{ borderBottom: " 1px solid #D1D1D1" }}>
                                    <div className="input-group-container" style={{ borderBottom: "none", width: "15%" }}>
                                        <div className="content-input required">
                                            <label htmlFor="numeroCertificado">Número</label>
                                            <div className="input-group-span">
                                                <div className="input-list-wrap">
                                                    <input
                                                        className="global-input"
                                                        type="text"
                                                        id="numeroCertificado"
                                                        placeholder=""
                                                        name="numeroCertificado"
                                                        value={data.numeroCertificado}
                                                        onChange={handleChangenumeroCertificado}
                                                    />
                                                    {<span>{inputValid.numeroCertificado === false && 'Campo obrigatório'}</span>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="input-group-container" style={{ borderBottom: "none", width: "8%" }}>
                                        <div className="content-input required" style={{ width: "245px" }}>
                                            <label
                                                htmlFor="data"
                                                style={{
                                                    marginRight: '0px',
                                                    marginLeft: "0px"
                                                }}
                                            >Data de Validade</label>
                                            <input
                                                style={{ width: "115px" }}
                                                className="global-input"
                                                type="text"
                                                id="data"
                                                placeholder="--/--/----"
                                                name="data"
                                                maxLength="10"
                                                value={data.dataValidade}
                                                onChange={handleChangeData}
                                            />
                                            {<span>{inputValid.dataValidade === false && 'Campo obrigatório'}</span>}
                                        </div>
                                    </div>
                                    <div className="input-group-container" style={{ borderBottom: "none", width: "0%" }}>
                                        <div className="upload-wrapp required"
                                            style={{
                                                display: "grid",
                                                gap: "10px",
                                                alignItems: "center",
                                                gridTemplateColumns: '100px 54px 0.91fr',
                                                width: "30%"
                                            }}>
                                            <label htmlFor="file">Upload do certificado</label>
                                            <div id="input-file-wrapp" style={{ marginLeft: "60px", marginRight: "5px" }} >
                                                <div onClick={() => inputFile.current.click()}
                                                    style={{
                                                        cursor: 'pointer'
                                                    }}
                                                >
                                                    <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path fillRule="evenodd" clipRule="evenodd" d="M10 0C4.47715 0 0 4.47715 0 10V44C0 49.5228 4.47715 54 10 54H44C49.5228 54 54 49.5228 54 44V10C54 4.47715 49.5228 0 44 0H10ZM9.125 32C9.125 31.4477 8.67729 31 8.125 31C7.57272 31 7.125 31.4477 7.125 32V38.6667C7.125 39.889 7.72129 41.0087 8.6886 41.7983C9.65031 42.5834 10.9184 43 12.2083 43H40.7917C42.0816 43 43.3497 42.5834 44.3114 41.7983C45.2787 41.0087 45.875 39.889 45.875 38.6667V32C45.875 31.4477 45.4273 31 44.875 31C44.3227 31 43.875 31.4477 43.875 32V38.6667C43.875 39.2124 43.6109 39.7884 43.0466 40.249C42.4768 40.7142 41.6677 41 40.7917 41H12.2083C11.3323 41 10.5232 40.7142 9.95336 40.249C9.38913 39.7884 9.125 39.2124 9.125 38.6667V32ZM37.4826 20.9657C37.1333 21.3935 36.5034 21.4572 36.0755 21.108L27.4992 14.1069L27.4992 32.0013C27.4992 32.5536 27.0515 33.0013 26.4992 33.0013C25.9469 33.0013 25.4992 32.5536 25.4992 32.0013L25.4992 14.1075L16.9236 21.108C16.4958 21.4572 15.8658 21.3935 15.5166 20.9657C15.1673 20.5379 15.231 19.9079 15.6589 19.5587L25.8672 11.2253C26.2353 10.9249 26.7639 10.9249 27.132 11.2253L37.3403 19.5587C37.7681 19.9079 37.8318 20.5379 37.4826 20.9657Z" fill="#4068C8" />
                                                    </svg>
                                                </div>
                                                <input
                                                    type="file"
                                                    accept=".pdf,.jpg,.png"
                                                    id="file"
                                                    name="file"
                                                    className="file"
                                                    ref={inputFile}
                                                    onChange={handlerChangeFile}
                                                    style={{ display: "none" }}
                                                />
                                            </div>
                                            {<span>{selectedFile ? selectedFile[0].name : ""}</span>}
                                        </div>
                                    </div>
                                    <div className="input-group-container" style={{ borderBottom: "none", width: "43%" }}>
                                        <span style={{
                                            maxWidth: '600px',
                                            padding: '20px 0',
                                            marginLeft: '100px'
                                        }}>Clique e selecione o arquivo em pdf, jpeg ou png do Certificado, somente serão aceitos
                                            documentos legíveis e com no máximo 10 MB de tamanho.
                                        </span>
                                    </div>
                                </div>
                                <div className="group-input created-form">
                                    <div className="input-group-container">
                                        <div className="content-input" style={{ width: "100%" }}>
                                            <label htmlFor="escopo">Escopo</label>
                                            <textarea
                                                className="global-input"
                                                type="textarea"
                                                id="escopo"
                                                placeholder=""
                                                name="escopo"
                                                value={data.escopo}
                                                onChange={handleChangeEscopo}
                                                style={{
                                                    width: '200%',
                                                    marginLeft: 30
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="group-input created-form">
                                    <div className="input-group-container" style={{ height: '205px', display: 'flex', alignItems: 'baseline' }}>
                                        <div style={{ display: 'flex', width: '160px', position: 'absolute', bottom: '55px' }} onClick={onToggle}>
                                            <InputRadio
                                                key={1}
                                                name={'aceitar-informacao'}
                                                label="Aceitar"
                                                checked={isToggled}
                                                value={true}
                                            />
                                        </div>
                                        <div className="content-input" style={{ width: "100%" }}>
                                            <label htmlFor="observacao">Informação</label>
                                            <textarea
                                                disabled={true}
                                                className="global-input"
                                                type="textarea"
                                                id="observacao-black"
                                                placeholder="Estou ciente e autorizo a verificação de autenticidade deste certificado, que visa evitar qie certificados falsos ou adulterados sejam utilizados. Estou ciente de que esta 
                                                    prática é ilegal e configura crime de falsidade ideológica (Art. 299 do Código Penal - Decreto Lei 2848/40)."
                                                name="observacao"
                                                style={{
                                                    width: '200%',
                                                    marginLeft: 30,
                                                    backgroundColor: '#e3e3e3',
                                                    color: "black",
                                                    height: "90px"
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-6">
                        <ListCertificados
                            token={token}
                            list={listMateriaisServicos}
                            include={create} />
                    </div>
                    <div className="col-md-6">
                        <ListUser
                            token={token}
                            loading={loading}
                            list={listUser}
                            remove={setDeleteID}
                            edit={setCurrent}
                        />
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 40 }} className="col-12 px-0 justify-content-between">
                <button
                    style={{ width: 'auto' }}
                    type="button"
                    className="global-btn w-auto"
                    disabled={loading && true}
                    onClick={() => { initComponent() }}
                >
                    <svg className="mr-1" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.47211 5.05021L5.10332 9.09061L4.31819 9.69667L0 5.05021L4.31819 0.302734L5.00518 1.00981L1.47211 5.05021ZM9.71593 1.00981L9.02894 0.302734L4.71075 5.05021L9.02894 9.69667L9.71593 8.9896L6.18286 5.05021L9.71593 1.00981Z" fill="white" />
                    </svg>
                    VOLTAR
                </button>
                <button
                    style={{ width: 'auto' }}
                    type="button"
                    className="global-btn w-auto"
                    disabled={loading && true}
                    onClick={handleSubmit}
                >
                    {(currentValue && currentValue.id > 0) ? "SALVAR" : "CRIAR"}
                    {loading && <FontAwesomeIcon icon={faCircleNotch} pulse />}
                </button>
            </div>
        </>
    )
}