import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  DOCUMENT_LICENCE: BASE_URL + "TipoDocumentoLicenca",
};

export default class TypeDocumentLicenceService {
  static registerDocumentLicence = async (token, nome) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let body = {
      nome: nome
    }
    
      return await axios.post(URLS.DOCUMENT_LICENCE, body, config);
  };

  static getDocumentLicences = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        },
      };
        
    let url = URLS.DOCUMENT_LICENCE

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static updateDocumentLicences = async (token, name, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
          
    let body = {
      nome: name,
      id: id
    }

    let url = URLS.DOCUMENT_LICENCE
    
      return await axios.put(url, body, config);    
  }

  static deleteDocumentLicence = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
      
    let url = URLS.DOCUMENT_LICENCE + "/" + id

    try {
      const { data } = await axios.delete(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };
}