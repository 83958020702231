import React, { useEffect, useState, useRef } from "react";

import Modal from "../../../components/ModalExit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import MeusDadosService from "../../../services/MeusDadosService";

import LogotipoEmpresa from "../../../assets/img/logotipo-empresa.jpg";
import addIcon from "../../../assets/icons/Subtract.svg";
import Icon7 from "../../../assets/icons/lupaVazia.svg";
import { formataCnpj } from "../../../components/Common/FormataCamposComMascara";
import ConsultarCnpj from "../../provedores/relacaoProvedores/Pages/ConsultarCnpj";

export default function MeusDadosForm({ token, current, callback, redesSociais }) {
  const [dados, setDados] = useState(current);
  const [sending, setSending] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [modal, setModal] = useState(false);
  const [modalMsg, setModalMsg] = useState('');
  const [imgLogo, setImageLogo] = useState(null);
  const [isConsultaCnpj, setIsConsultaCnpj] = useState(false);

  const inputFile = useRef(null);

  const handleChange = (e) => {
    e.persist();

    setDados((values) => ({
      ...values,
      [e.target.name]: e.target.name === "cnpj" ? formataCnpj(e.target.value) : e.target.value
    }));
  };

  const addRedeSocial = (event) => {
    event.preventDefault();

    if (isComprador()) {
      dados.compradorRedesSociais.push({ redeSocial: redesSociais[0], url: '' })
    }
    else {
      dados.provedorRedesSociais.push({ redeSocial: redesSociais[0], url: '' })
    }

    setDados({ ...dados });
  };

  const removeRedeSocial = (event, index) => {
    event.preventDefault()

    if (isComprador()) {
      dados.compradorRedesSociais.splice(index, 1)
    } else {
      dados.provedorRedesSociais.splice(index, 1)
    }

    setDados({ ...dados });
  };

  const handleChangeRedeSocialUrl = (event, index) => {
    event.persist();

    if (isComprador()) {
      dados.compradorRedesSociais[index].url = event.target.value
    } else {
      dados.provedorRedesSociais[index].url = event.target.value
    }

    setDados({ ...dados });
  };

  const handleChangeRedeSocial = (event, index) => {
    event.persist();

    if (isComprador()) {
      dados.compradorRedesSociais[index].redeSocial = redesSociais[+event.target.value]
    } else {
      dados.provedorRedesSociais[index].redeSocial = redesSociais[+event.target.value]
    }

    setDados({ ...dados });
  };

  const handlerChangeFile = (event) => {
    const file = event.target.files[0]

    const type = file.type;
    if (type !== "application/svg" && type !== "image/png" && type !== "image/jpeg") {
      setModalMsg('Tipo de arquivo inválido, selecione um arquivo válido do tipo JPG, PNG ou SVG')
      setModal(true)
      return
    }

    if (file.size > 400000) {
      setModalMsg('O arquivo deve ser igual ou menor a 400KB')
      setModal(true)
      return
    }

    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      const img = new Image();
      img.src = reader.result;

      img.onload = function () {
        const imgWidth = img.naturalWidth;
        const imgHeight = img.naturalHeight;

        if (imgWidth != 200 && imgHeight != 200) {
          setModalMsg('O dimensionamento do logo deve ser de 200px X 200px')
          setModal(true)
          return
        }

        setImageLogo(reader.result);
      };
    };
    reader.onerror = function (error) {
      setModalMsg('Erro desconhecido ao selecionar logo, por favor, tente novamente')
      setModal(true)
    };

    setSelectedFile(file);
  };

  const downloadLogo = async () => {
    const ehComprador = isComprador();
    const img = await MeusDadosService.download(token, ehComprador).then(response => response);

    if (img) {
      const reader = new FileReader();
      reader.onloadend = function () {
        setImageLogo(reader.result);
      }
      reader.readAsDataURL(img);
    } else {
      toast.error("Erro ao baixar logo!");
      setImageLogo(null);
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    update(dados);
  }

  const isComprador = () => {
    const profile = JSON.parse(localStorage.getItem("profile"));
    return (profile.profileId === 2);
  };

  const update = (payload) => {
    setSending(true);
    payload.tipoChave = 1; // fixo tipo 1 cnpj
    let archive = null;

    if (selectedFile) {
      archive = new FormData()
      archive.append("arquivo", selectedFile);
    }

    const ehComprador = isComprador();

    MeusDadosService.update(token, payload, ehComprador)
      .then((response) => {
        if (archive) {
          MeusDadosService.updateLogo(token, archive, ehComprador)
            .then((response) => {
              if (response.data) {
                toast.success("Dados atualizados com sucesso!");
                setSending(false);
                callback(true);
              }
            })
            .catch((error) => {
              setSending(false);
              toast.error("Erro ao atualizar logo!");
            });
        } else {
          toast.success("Dados atualizados com sucesso!");
          setSending(false);
          callback(true);
        }
      })
      .catch((error) => {
        setSending(false);

        let message = error && error.response && error.response.data ? error.response.data : '';
        let errors = message.split("\n");

        if (errors.length > 0 && errors[0].length > 0) {
          errors.map((elem) => {
            toast.error(elem);
          })
        } else {
          toast.error("Erro ao atualizar dados!");
        }
      });
  }

  useEffect(() => {
    if (current) {
      //só chama o download caso o current esteja preenchido, para evitar de
      //fazer o download 2 vezes ao renderizar o componente, se o cnpj estiver preenchido é pq o current tbm está.
      if (current.cnpj !== "")
        downloadLogo();

      setDados(current);
    }
  }, [current]);

  return (
    <>
      {modal &&
        <Modal
          title={modalMsg}
          handleDelete={null}
          handleModal={setModal}
          alert
        />
      }
      {isConsultaCnpj &&
        <>
          <ConsultarCnpj cnpj={dados.cnpj} />
          <button className="global-btn"
            style={{ maxWidth: '182px', height: '50px' }}
            type="button"
            onClick={() => {
              setIsConsultaCnpj(false)
            }}
          >
            Voltar
          </button>
        </>
      }
      {!isConsultaCnpj &&
        <div className="card-container">
          <div className="card">
            <div className="card-header">
              <h1>DADOS DA SUA EMPRESA</h1>
            </div>
            <div className="card-body newPaddingCard">
              <form
                className="form-container"
                onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-xs-4 col-md-4 col-lg-2">
                    <label className="global-label">Logotipo da Empresa</label>
                  </div>
                  <div className="col-xs-8 col-md-8 col-lg-5">
                    <img src={imgLogo || dados.logo || LogotipoEmpresa} className="img-logo" style={{
                      cursor: 'pointer'
                    }} onClick={() => inputFile.current.click()}></img>
                    <p className="logo-info">A imagem do logotipo  deve estar com o dimensionamento de 200px X 200px. São aceitos arquivos em jpg, png e svg com no máximo 400 KB. Para converter sua imagem no tamanho e dimensão que precisa acesse esse <a href="https://www.img2go.com/pt/redimensionar-imagem" target="_blank">link</a> e converta gratuitamente.</p>
                    <input
                      type="file"
                      accept=".svg,.jpg,.png"
                      id="file"
                      name="file"
                      className="file-logo"
                      ref={inputFile}
                      onChange={handlerChangeFile}
                      style={{ display: "none" }}
                    />
                  </div>

                  <button className="global-btn"
                    style={{ maxWidth: '300px', height: '50px' }}
                    type="button"
                    onClick={() => {
                      setIsConsultaCnpj(true)
                    }}
                  >
                    <img src={Icon7} />
                    Consultar meu CNPJ
                  </button>
                </div>
                <div className="row">
                  <div className="col-xs-4 col-md-4 col-lg-2">
                    <label className="global-label">Razão Social</label>
                  </div>
                  <div className="col-xs-8 col-md-5 col-lg-5">
                    <input
                      className="global-input"
                      type="text"
                      id="razaoSocial"
                      value={dados.razaoSocial || ''}
                      placeholder="Insira a razão social da sua empresa"
                      name="razaoSocial"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-xs-4 col-md-2 col-lg-2">
                    <label className="global-label">Nome Fantasia</label>
                  </div>
                  <div className="col-xs-8 col-md-5 col-lg-3">
                    <input
                      className="global-input"
                      type="text"
                      id="nomeFantasia"
                      value={dados.nomeFantasia || ''}
                      placeholder="Insira o nome fantasia da sua empresa"
                      name="nomeFantasia"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-4 col-md-4 col-lg-2">
                    <label className="global-label">CNPJ</label>
                  </div>
                  <div className="col-xs-8 col-md-5 col-lg-4">
                    <input
                      className="global-input"
                      type="text"
                      id="cnpj"
                      value={dados.cnpj || ''}
                      placeholder="XX.XXX.XXX/0001-XX"
                      name="cnpj"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-xs-4 col-md-2 col-lg-2">
                    <label className="global-label">Inscrição Estadual</label>
                  </div>
                  <div className="col-xs-8 col-md-5 col-lg-4">
                    <input
                      className="global-input"
                      type="text"
                      id="inscricaoEstadual"
                      value={dados.inscricaoEstadual || ''}
                      placeholder="Insira a inscrição estadual da sua empresa"
                      name="inscricaoEstadual"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-4 col-md-4 col-lg-2">
                    <label className="global-label">Endereço</label>
                  </div>
                  <div className="col-xs-3 col-md-3 col-lg-3">
                    <input
                      className="global-input"
                      type="text"
                      id="logradouro"
                      value={dados.logradouro || ''}
                      placeholder="Insira o logradouro"
                      name="logradouro"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-xs-3 col-md-3 col-lg-2">
                    <input
                      className="global-input"
                      type="text"
                      id="numero"
                      value={dados.numero || ''}
                      placeholder="Insira o número"
                      name="numero"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-xs-3 col-md-3 col-lg-2">
                    <input
                      className="global-input"
                      type="text"
                      id="bairro"
                      value={dados.bairro || ''}
                      placeholder="Insira o bairro"
                      name="bairro"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-xs-3 col-md-3 col-lg-3">
                    <input
                      className="global-input"
                      type="text"
                      id="cidade"
                      value={dados.cidade || ''}
                      placeholder="Insira a cidade"
                      name="cidade"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-3 col-md-3 col-lg-2">
                    <label className="global-label">CEP / Estado / País</label>
                  </div>
                  <div className="col-xs-3 col-md-3 col-lg-3">
                    <input
                      className="global-input"
                      type="text"
                      id="cep"
                      value={dados.cep || ''}
                      placeholder="Insira o CEP do endereço cadastrado"
                      name="cep"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-xs-3 col-md-3 col-lg-3">
                    <input
                      className="global-input"
                      type="text"
                      id="uf"
                      value={dados.uf || ''}
                      placeholder="Insira o estado onde sua empresa está localizada"
                      name="uf"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-xs-3 col-md-3 col-lg-4">
                    <input
                      className="global-input"
                      type="text"
                      id="pais"
                      value={dados.pais || ''}
                      placeholder="Insira o país onde sua empresa está localizada"
                      name="pais"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-4 col-md-4 col-lg-2">
                    <label className="global-label">Telefone</label>
                  </div>
                  <div className="col-xs-8 col-md-5 col-lg-4">
                    <input
                      className="global-input"
                      type="text"
                      id="telefone"
                      value={dados.telefone || ''}
                      placeholder="+55 (16) XXXX-XXXX"
                      name="telefone"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-xs-4 col-md-2 col-lg-1">
                    <label className="global-label">Site</label>
                  </div>
                  <div className="col-xs-8 col-md-5 col-lg-5">
                    <input
                      className="global-input"
                      type="text"
                      id="site"
                      value={dados.site || ''}
                      placeholder="www.suaempresa.com.br"
                      name="site"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-4 col-md-4 col-lg-2">
                    <label className="global-label">Nome</label>
                  </div>
                  <div className="col-xs-8 col-md-5 col-lg-4">
                    <input
                      className="global-input"
                      type="text"
                      id="nome"
                      value={dados.nome || ''}
                      placeholder="Insira seu nome completo"
                      name="nome"
                      onChange={handleChange}
                    />
                  </div>
                  <div className="col-xs-4 col-md-2 col-lg-1">
                    <label className="global-label">Cargo</label>
                  </div>
                  <div className="col-xs-8 col-md-5 col-lg-5">
                    <input
                      className="global-input"
                      type="text"
                      id="cargo"
                      value={dados.cargo || ''}
                      placeholder="Insira seu cargo dentro da empresa"
                      name="cargo"
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-4 col-md-4 col-lg-2">
                    <label className="global-label">E-mail</label>
                  </div>
                  <div className="col-xs-8 col-md-8 col-lg-10">
                    <input
                      className="global-input"
                      type="email"
                      id="email"
                      value={dados.email || ''}
                      placeholder="seuemail@email.com"
                      name="email"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-xs-4 col-md-4 col-lg-2">
                    <label className="global-label">Redes Sociais</label>
                    <div>
                      <button className="cursor-pointer" onClick={(event) => addRedeSocial(event)} style={{ background: 'transparent', border: 'transparent' }}>
                        <img src={addIcon} />
                      </button>
                    </div>
                  </div>
                </div>

                <div className="row">
                  {(dados.compradorRedesSociais || dados.provedorRedesSociais || []).map((dataRedeSocial, i) =>

                    <div style={{ display: 'flex' }} key={i}>

                      <div className="col-xs-4 col-md-4 col-lg-4">
                        <img
                          src={dataRedeSocial && dataRedeSocial.redeSocial ? dataRedeSocial.redeSocial.logoURL : ''}
                          className="cursor-pointer"
                          style={{ width: 30, marginRight: 16 }}
                          alt="Logo"
                        />

                        <select
                          className="global-input"
                          type="text"
                          id="padraoDesempenho"
                          placeholder="Padrão de Desempenho"
                          style={{ width: "380px", margin: 0 }}
                          name="padraoDesempenho"
                          disabled={false}
                          onChange={(event) => handleChangeRedeSocial(event, i)}
                        >
                          <option value="0">Selecione</option>
                          {(redesSociais || []).map((data, i) =>
                          (
                            <option
                              key={i}
                              value={i}
                              selected={
                                dataRedeSocial.redeSocial.id == data.id ? true : false}
                            >{data.nome}</option>
                          )
                          )}
                        </select>
                      </div>

                      <div className="col-xs-4 col-md-4 col-lg-4">
                        <label className="global-label" >Url</label>

                        <input
                          className="global-input"
                          type="text"
                          value={dataRedeSocial.url || ''}
                          placeholder="Url"
                          name="url"
                          disabled={false}
                          onChange={(event) => handleChangeRedeSocialUrl(event, i)}
                        />
                      </div>

                      <div className="col-xs-4 col-md-4 col-lg-4">

                        <span className="list-item document-list cursor-pointer">
                          <a className="list-button" onClick={(event) => removeRedeSocial(event, i)}>
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M17.2096 6.32797H13.5054V6.17363C13.5054 5.09325 12.6566 4.24437 11.5762 4.24437H11.4218C10.3414 4.24437 9.49257 5.09325 9.49257 6.17363V6.32797H5.71122C5.47971 6.32797 5.2482 6.55949 5.2482 6.791V8.72026C5.32537 8.95177 5.47971 9.10611 5.71122 9.10611H5.94273L7.25463 18.5209C7.25463 18.7524 7.48614 18.9068 7.71765 18.9068H15.2804C15.5119 18.9068 15.6662 18.7524 15.7434 18.5209L16.9781 9.10611H17.2096C17.4411 9.10611 17.6726 8.8746 17.6726 8.64309V6.791C17.6726 6.55949 17.4411 6.32797 17.2096 6.32797ZM10.2643 6.17363C10.2643 5.55627 10.7273 5.09325 11.3447 5.09325H11.499C12.1164 5.09325 12.5794 5.55627 12.5794 6.17363V6.32797H10.2643V6.17363ZM14.8173 18.0579H8.02633L6.86878 9.10611H16.0521L14.8173 18.0579ZM16.7466 8.25723C16.6694 8.25723 6.25141 8.25723 6.17424 8.25723V7.25402H16.7466V8.25723ZM9.56974 16.8232C9.80125 16.8232 9.95559 16.5916 9.95559 16.3601L9.4154 10.5723C9.4154 10.3408 9.18389 10.1865 8.95238 10.1865C8.72087 10.1865 8.56653 10.418 8.56653 10.6495L9.10672 16.4373C9.10672 16.6688 9.33823 16.8232 9.56974 16.8232ZM13.3511 16.8232C13.5826 16.8232 13.8141 16.6688 13.8141 16.4373L14.3543 10.6495C14.3543 10.418 14.2 10.1865 13.9685 10.1865C13.7369 10.1865 13.5054 10.3408 13.5054 10.5723L12.9652 16.3601C12.9652 16.5916 13.1196 16.8232 13.3511 16.8232ZM11.4218 16.8232C11.6533 16.8232 11.8849 16.5916 11.8849 16.3601V10.5723C11.8849 10.3408 11.6533 10.1093 11.4218 10.1093C11.1903 10.1093 10.9588 10.3408 10.9588 10.5723V16.3601C11.036 16.6688 11.1903 16.8232 11.4218 16.8232ZM24.0006 17.1318C24.0006 20.9132 20.991 24 17.1324 24H6.86878C3.08743 24 0.000610352 20.9904 0.000610352 17.1318V6.86817C0.000610352 3.08682 3.01026 0 6.86878 0H17.1324C20.9138 0 24.0006 3.00965 24.0006 6.86817V17.1318Z"
                                fill="#4B4B4B"
                              />
                            </svg>
                          </a>
                        </span>

                      </div>

                    </div>)}
                </div>

                <button
                  className="global-btn"
                  disabled={sending && true}
                >
                  {sending ? '' : "SALVAR"}
                  {sending && <FontAwesomeIcon icon={faCircleNotch} pulse />}
                </button>
              </form>
            </div>
          </div>
        </div>
      }
    </>
  );
}
