import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import NotificacaoProvedoresForm from "./Form/index";
import List from "./List/notificacaoProvedoresList";
import NotificacaoProvedoresService from "../../../services/NotificacaoProvedoresService";
import ProvedoresService from "../../../services/ProvedoresService";
import Modal from "../../../components/ModalExit";

import { IF } from "../../../components/Condition/Condition";

import "./style.scss";

export default function Index(props) {
  const { token } = props.state;
  const [loading, setLoading] = useState(false);
  const [deleteID, setDeleteID] = useState(0);
  const [showForm, setShowForm] = useState(false);
  const [provedores, setProvedores] = useState([]);

  const defaultCurrent = {
    provedorId: 0,
    responsavel: '',
    cnpj: '',
    codigo: '',
    notaFiscal: '',
    pedidoCompra: '',
    lote: '',
    recebimento: "2021-07-22T23:50:33.634Z",
    provedorNotificacaoCategoriaId: 0,
    provedorNotificacaoIdentificacaoId: 0,
    provedorNotificacaoAcaoInternaId: 0,
    provedorNotificacaoNivelCriticidadeId: 0,
    provedorNotificacaoImpactoId: 0,
    reclamacao: '',
    documentoReclamacao: '',
    listaMaterialServices: [],
  }
  const [current, setCurrent] = useState(defaultCurrent);
  const [list, setList] = useState([]);

  const _init = async () => {
    const getProvedores = await ProvedoresService.get(token);
    setProvedores(getProvedores.valores);

    await NotificacaoProvedoresService.get(token)
      .then((response) => {
        setList(response || []);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  const get = (nome = '') => {
    setShowForm(false)
    setCurrent(defaultCurrent);
    setLoading(true);
    _init();
  }

  const remove = (id) => {
    setDeleteID(0);

    // NotificacaoProvedoresService.delete(token, id)
    //   .then((response) => {
    //     if (response) {
    //       toast.success("Item excluído com sucesso!");
    //       get();
    //     }
    //   })
    //   .catch((error) => {
    toast.error("No momento, não é possível excluir o item!");
    //     setLoading(false);
    //   });
  }

  useEffect(() => {
    setLoading(true);
    get();
  }, []);

  return (
    <div className="dashboard-container notificacaoProvedores-container">
      {deleteID > 0 && (
        <Modal handleDelete={() => remove(deleteID)} handleModal={() => { setDeleteID(0) }} />
      )}
      {loading && (
        <div className={"loading-mask"}>
          <FontAwesomeIcon icon={faCircleNotch} pulse />
        </div>
      )}
      <IF condition={showForm}>
        <NotificacaoProvedoresForm
          token={token}
          current={current}
          callback={get}
          provedores={provedores}
        />
        <button type="button" onClick={() => setShowForm(value => !value)} className={`global-btn mt-2`} style={{ width: 'auto' }}>
          <svg className="mr-1" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1.47211 5.05021L5.10332 9.09061L4.31819 9.69667L0 5.05021L4.31819 0.302734L5.00518 1.00981L1.47211 5.05021ZM9.71593 1.00981L9.02894 0.302734L4.71075 5.05021L9.02894 9.69667L9.71593 8.9896L6.18286 5.05021L9.71593 1.00981Z" fill="white" />
          </svg>
          VOLTAR
        </button>
      </IF>
      <IF condition={!showForm}>
        <button type="button" onClick={() => {
          setCurrent(defaultCurrent);
          setShowForm(value => !value);
        }} className={`global-btn mt-0 disabledBtn`} style={{ width: 'auto' }}>
          <svg className="mr-2" width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.5799 10.5H10.4142V14.5H4.16567V10.5H0V4.5H4.16567V0.5H10.4142V4.5H14.5799V10.5Z" fill="#000" />
          </svg>
          Criar nova notificação
        </button>
        <div className="row">
          <div className="col-md-12 mt-2">
            <List
              token={token}
              list={list}
              remove={setDeleteID}
              provedores={provedores}
              edit={(data) => {
                setCurrent(data);
                setShowForm(true);
              }}
            />
          </div>
        </div>
      </IF>
    </div>
  );
}