import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import Form from "./Form/certificationTypesForm";
import List from "./List/certificationTypesList";
import Description from "./Form/certificationTypesDescription";
import CertificationTypesService from "../../services/CertificationTypesService";
import Modal from "../../components/ModalExit";

import "./style.scss";

export default function Index(props) {
  const { token } = props.state;
  const [ loading, setLoading ] = useState(false);
  const [ deleteID, setDeleteID ] = useState(0);

  const [ current, setCurrent ] = useState({});
  const [ list, setList ] = useState([]);

  const get = (nome = '') => {
    setCurrent({});
    setLoading(true);

    CertificationTypesService.get(token, nome)
      .then((response) => {
        if (response) {
          setList(response.valores || []);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  const create = (payload) => {
    if(!payload.nome) return;

    CertificationTypesService.create(token, payload)
      .then((response) => {
        if (response) {
          toast.success("Item cadastrado com sucesso!");
          get();
        }
      })
      .catch((error) => {
        toast.error("Erro ao cadastrar item!");
      });
  }

  const remove = (id) => {
    setDeleteID(0);

    CertificationTypesService.delete(token, id)
      .then((response) => {
        if (response) {
          toast.success("Item excluído com sucesso!");
          get();
        }
      })
      .catch((error) => {
        toast.error("Erro ao excluir item!");
        setLoading(false);
      });
  }

  useEffect(() => {
    setLoading(true);
    get();
  }, []);

  return (
    <div className="dashboard-container certificationTypes-container">
      {deleteID > 0 && (
        <Modal handleDelete={() => remove(deleteID)} handleModal={() => {setDeleteID(0)}}/>
      )}
      {loading && (
        <div className={"loading-mask"}>
          <FontAwesomeIcon icon={faCircleNotch} pulse />
        </div>
      )}
      <Form 
        token={token} 
        current={current} 
        callback={get}
      />
      <div className="row mt-2">
        <div className="col-md-12">
          <List 
            token={token} 
            search={get}
            loading={loading}
            list={list}
            remove={setDeleteID}
            edit={setCurrent}
          />
        </div>
        <div className="col-md-12 mt-2">
        <Description token={token} /> 
        </div>
      </div>
    </div>
  );
}