import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  URL: BASE_URL + "MaterialService/Comprador",
};

export default class RegistrationMaterialsService {
  static register = async (token, body) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data } = await axios.post(URLS.URL, body, config);
      return data
    } catch (err) {
      return err;
    }
  };

  static getAll = async (token) => {

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.URL + '?Nome=&Pagination=1'

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static update = async (token, body) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.URL

    try {
      const { data } = await axios.put(url, body, config);
      return data;
    } catch (err) {
      return err;
    }
  }

  static delete = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.URL + "/" + id

    try {
      const { data } = await axios.delete(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getByID = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        responseType: 'blob',
      },
    };

    let url = URLS.URL + "/" + id

    try {
      const { data } = await axios.get(url, config)
      return data
    } catch (err) {
      return err;
    }
  };

}