import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  PROVEDOR_CERTIFICADO: BASE_URL + "ProvedorCertificado",
};

export default class ProvedorCertificadoService {
  static registerProvedorCertificado = async (token, params, formData) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let body = params;

    try {
      const { data } = await axios.post(URLS.PROVEDOR_CERTIFICADO, body, config);
      const provedorCertificadoId = data

      if (provedorCertificadoId) {
        let config = {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        };

        let url = `${URLS.PROVEDOR_CERTIFICADO}/Upload/${provedorCertificadoId}`
        const { data } = await axios.post(url, formData, config);

        return data;
      }

    } catch (err) {
      return err;
    }
  };

  static getCertificadosProvedor = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.PROVEDOR_CERTIFICADO

    try {
      console.log('token', token)
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static updateProvedorCertificado = async (token, params, editarArquivos, formData) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let body = params;
    let url = URLS.PROVEDOR_CERTIFICADO
    try {
      const { data } = await axios.put(url, body, config);
      if (editarArquivos) {
        const provedorCertificadoId = data

        if (provedorCertificadoId) {
          let config = {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${token}`,
            },
          };

          let url = `${URLS.PROVEDOR_CERTIFICADO}/Upload/${provedorCertificadoId}`
          const { data } = await axios.post(url, formData, config);
        }
      }

      return data;
    } catch (err) {
      return err;
    }
  }

  static deleteProvedorCertificado = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.PROVEDOR_CERTIFICADO + "/" + id

    try {
      const { data } = await axios.delete(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static download = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        responseType: 'blob',
      },
    };

    let url = `${URLS.PROVEDOR_CERTIFICADO}/Download/${id}`

    try {
      const blob = await fetch(url, config)
        .then(response => response.blob())
        .then(blob => blob)
      return blob
    } catch (err) {
      return err;
    }
  };
}