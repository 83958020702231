import React, { useState, useEffect } from "react";

import CardHeader from "./components/CardHeader";
import Status from "./components/Status";
import Nav from "./components/Nav/index";

import IdoneidadeIntegridade from "./Pages/IdoneidadeIntegridade";

import ProvedoresService from "../../../services/ProvedoresService"
import DocumentosLicencas from "./Pages/DocumentosLicencas";
import Resultados from "./Pages/Resultados";

export default function Index({ token, handleBanck, compradorId }) {
    const initialValues =
    {
        comprador: {
            comprador:
            {
                nome: "", telefone: "", contato: "",
                email: "", site: "", logo: "", logradouro: "",
                numero: "", nomeFantasia: "", inscricaoEstadual: "",
                razaoSocial: "", cnpj: "", cargo: ""
            }
        }
    }

    const [module, setModule] = useState('resultados')
    const [isShow, setIsShow] = useState(true)
    const [comprador, setComprador] = useState(initialValues)

    const handleShow = (e) => {
        setIsShow(e)
    }

    const _init = async () => {
        const compradorResult = await ProvedoresService.getRelacaoClienteComprador(token, compradorId);
        setComprador(compradorResult);
    }

    useEffect(() => {
        _init()
    }, [])

    useEffect(() => {
        handleShow(true)
    }, [module])

    return (
        <>
            <CardHeader comprador={comprador.comprador} />
            <Status />
            <Nav handleModule={setModule} />

            {module === 'resultados' &&
                <Resultados token={token} createQuestion={handleShow} compradorId={comprador.comprador.id} setIsShow={setIsShow} />
            }

            {module === 'idoneidade-e-integridade' &&
                <IdoneidadeIntegridade token={token} />
            }

            {module === 'documentos-e-Licencas' &&
                <DocumentosLicencas token={token} comprador={comprador} />
            }

            {/* {module === 'produtos-homologados' &&
                <ProdutosHomologados token={token} />
            }

            {module === 'historico-de-Notificacao' &&
                <HistoricoNotificacao token={token} />
            }

            {module === 'historico-de-recebimento' &&
                <HistoricoRecebimento token={token} />
            } */}

            {isShow &&
                <div className={`row mx-0`}>
                    <div className="col-12 px-0 justify-content-between">
                        <button
                            type="button"
                            className="global-btn w-auto"
                            onClick={() => handleBanck(true)}
                        >
                            <svg className="mr-1 back-icon" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.47211 5.05021L5.10332 9.09061L4.31819 9.69667L0 5.05021L4.31819 0.302734L5.00518 1.00981L1.47211 5.05021ZM9.71593 1.00981L9.02894 0.302734L4.71075 5.05021L9.02894 9.69667L9.71593 8.9896L6.18286 5.05021L9.71593 1.00981Z" fill="white" />
                            </svg>
                            VOLTAR
                        </button>
                    </div>
                </div>
            }
        </>
    );
}
