import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import UserForm from "./Form/userForm";
import BuyerService from "../../services/BuyerService";

import "./user.scss";

export default function AddUser() {
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [alert, setAlert] = useState('');

  const history = useHistory();

  let initialValues = {
    companyName: "",
    cnpj: "",
    name: "",
    email: "",
    password: "",
    passwordConfirm: "",
    profile: ""
  };

  const send = (buyer) => {
    setLoading(true);

    BuyerService.register('', buyer)
      .then((response) => {
        if (typeof response === "object") {
          if (!response.success) {
            setSuccess(false);
            setLoading(false);
            setRedirect(false);
            setAlert(response.message);
            return false;
          }
        }
        else {
          if (response) {
            setLoading(false);
            setSuccess(true);
            setError(false);
            setRedirect(true);
          }
        }
      })
      .catch((error) => {
        console.log('cath user', error)
        setLoading(false);
        setSuccess(false);
        setError(true);
        setRedirect(false);
      });
  };

  if (redirect) {
    history.push("/login", { params: redirect });
  }

  return (
    <UserForm
      title="Cadastro"
      initialValues={initialValues}
      send={send}
      error={error}
      success={success}
      loading={loading}
      alert={alert}
    />
  );
}
