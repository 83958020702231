import React, { memo } from 'react';

import './styles.scss'

function CardInfoHorizontal({ data01, data02, data03,}) {

    return (
        <div className="card-info-horizontal-wrap">
            <div id="info-wrap-1">
                <h1>{data01.title}</h1>
                <div className="output-text">
                    <h2>{data01.value}</h2>
                </div>
            </div>
            <div id="info-wrap-2">
                <h1>{data02.title}</h1>
                <div className="output-text">
                    <h2>{data02.value}</h2>
                </div>
            </div>
            <div id="info-wrap-3">
                <h1>{data03.title}</h1>
                <div className="output-text">
                    <h2>{data03.value + "%"}</h2>
                </div>
            </div>
        </div>
    )
}

export default memo(CardInfoHorizontal)