import React, { useEffect, useState, useRef } from "react";

import Modal from "../../../../components/ModalExit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { IF } from "../../../../components/Condition/Condition";
import InputRadio from "../../../../components/InputRadio/InputRadio";

import NotificacaoProvedoresRespostaService from "../../../../services/NotificacaoProvedoresRespostaService";

export default function RespostaProvedorForm({ token, current, callback, show }) {
  const defaultCurrent = {
    classificacaoReclamacao: 1,
    motivo: '',
    acaoImediata: '',
    descricaoCausa: '',
    provedorNotificacaoRespostaProvedorPlanoAcoes: [
      {
        acao: '',
        como: '',
        porQue: '',
        responsavel: '',
        prazo: ''
      },
      {
        acao: '',
        como: '',
        porQue: '',
        responsavel: '',
        prazo: ''
      },
      {
        acao: '',
        como: '',
        porQue: '',
        responsavel: '',
        prazo: ''
      }
    ],
    nome: '',
    cargo: '',
    telefone: '',
    email: '',
    data: ''
  }

  const [notificacao, setNotificacao] = useState(defaultCurrent);
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [imgDoc, setImageDoc] = useState(null);
  const [imgFoto1, setImageFoto1] = useState(null);
  const [imgFoto2, setImageFoto2] = useState(null);
  const inputDoc = useRef(null);
  const inputFoto1 = useRef(null);
  const inputFoto2 = useRef(null);
  const [modal, setModal] = useState(false);
  const [modalMsg, setModalMsg] = useState('');

  const formatData = (date, formatBR = false, time = false) => {
    if (!date) return '';
    let data = '';

    let dia = date.getDate();           // 1-31
    let dia_sem = date.getDay();            // 0-6 (zero=domingo)
    let mes = date.getMonth() + 1;      // 0-11 (zero=janeiro)
    let ano2 = date.getYear();           // 2 dígitos
    let ano4 = date.getFullYear();       // 4 dígitos
    let hora = date.getHours();          // 0-23
    let min = date.getMinutes();        // 0-59
    let seg = date.getSeconds();        // 0-59
    let mseg = date.getMilliseconds();   // 0-999
    let tz = date.getTimezoneOffset(); // em minutos

    if (!formatBR) {
      data = ("0" + ano4).slice(-4) + '-' + ("0" + mes).slice(-2) + '-' + ("0" + dia).slice(-2);
    } else {
      data = ("0" + dia).slice(-2) + '/' + ("0" + mes).slice(-2) + '/' + ("0" + ano4).slice(-4);

      if (time) {
        data += ' ' + ("0" + hora).slice(-2) + ':' + ("0" + min).slice(-2) + ':' + ("0" + seg).slice(-2);
      }
    }

    return data;
  }

  const handleChange = (e) => {
    e.persist();

    if (e.target.name === 'telefone') {
      setNotificacao((values) => ({
        ...values,
        telefone: formatPhoneNumber(e)
      }));

    } else {
      setNotificacao((values) => ({
        ...values,
        [e.target.name]: (e.target.type == 'radio') ?
          (e.target.value == '1' ? true : false) : e.target.value
      }));
    }

  };

  const handleChangeNum = (e) => {
    e.persist();

    setNotificacao((values) => ({
      ...values,
      [e.target.name]: (e.target.type == 'radio') ?
        (e.target.value == '1' ? 1 : 2) : e.target.value
    }));
  };

  const handleChangePlanoAcao = (e) => {
    e.persist();

    let data = e.target.id.split('-');
    let index = parseInt(data[1]);
    let name = data[0];
    let newValues = notificacao;

    newValues.provedorNotificacaoRespostaProvedorPlanoAcoes[index] = {
      ...newValues.provedorNotificacaoRespostaProvedorPlanoAcoes[index],
      [name]: e.target.value
    };

    setNotificacao((values) => ({
      ...values,
      ...newValues
    }));
  };

  const [files, setFiles] = useState([])

  const handlerChangeFile = (event) => {
    const file = event.target.files[0];
    const name = event.target.name;
    const tipoDocumento = Number(event.target.id)

    if (file) {
      const type = file.type;
      if (type !== "application/svg" && type !== "image/png" && type !== "image/jpeg") {
        setModalMsg('Tipo de arquivo inválido, selecione um arquivo válido do tipo JPG, PNG ou SVG')
        setModal(true)
        return
      }

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        const img = new Image();
        img.src = reader.result;

        img.onload = function () {
          if (name === 'doc') {
            setImageDoc(reader.result);
          } else if (name === 'foto1') {
            setImageFoto1(reader.result);
          } else if (name === 'foto2') {
            setImageFoto2(reader.result);
          }
        };
      };
      reader.onerror = function (error) {
        setModalMsg('Erro desconhecido ao selecionar logo, por favor, tente novamente')
        setModal(true)
      };
    } else {
      setFiles([])
    }

    files.push({ arquivo: file, tipoDocumento: tipoDocumento })
    setFiles(files)
  };

  const handleSubmitDescricao = (e) => {
    e.preventDefault();

    if ((notificacao.telefone && notificacao.telefone.length < 14) || (notificacao.telefone === undefined)) {
      setModalMsg('O Formato do número telefônico não corresponde a um formato válido!')
      setModal(true)
      return
    }

    if (notificacao.nome === ''
      || notificacao.telefone === ''
      || notificacao.email === ''
      || notificacao.cargo === ''
      || notificacao.data === '') return

    create(notificacao);
  }

  const create = (payload) => {
    setSending(true);

    NotificacaoProvedoresRespostaService.create(token, current.id, payload)
      .then((response) => {
        if (response) {
          toast.success("Resposta cadastrada com sucesso!");
          setNotificacao(defaultCurrent);

          if (files.length > 0) {
            const id = response.id
            toast.info("Fazendo upload dos arquivos!", { autoClose: false, closeButton: false });

            files.map((file, index, locateArray) => {
              const qdt = index + 1

              const formData = new FormData();
              formData.append('arquivo', file.arquivo);

              NotificacaoProvedoresRespostaService.upload(token, id, formData, file.tipoDocumento)
                .then(response => {
                  if (response) {
                    if (qdt === locateArray.length) {
                      toast.dismiss()
                      toast.success("Arquivos enviados com sucesso!");
                      setFiles([])
                      setImageDoc(null)
                      setImageFoto1(null)
                      setImageFoto2(null)
                      setSending(false);
                      callback();
                    }
                  }
                }).catch((error) => {
                  console.error(error);
                  toast.dismiss()
                  toast.error("Erro ao fazer upload de arquivos!");
                  setSending(false);
                });
            })
          } else {
            toast.dismiss()
            setSending(false);
            callback();
          }
        }
      })
      .catch((error) => {
        console.error(error);
        toast.dismiss()
        toast.error("Erro ao cadastrar resposta!");
        setSending(false);
      });
  }

  const _init = async () => {
    setLoading(true);

    setLoading(false);
  }

  useEffect(() => {
    _init();
  }, []);

  let formatPhoneNumber = (event) => {
    const str = event.target.value

    const cleaned = ('' + str).replace(/\D/g, '')

    let match2 = cleaned.match(/^(\d{2})$/)
    let match3 = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/)

    if (cleaned.length > 10) {
      match2 = cleaned.match(/^(\d{2})$/)
      match3 = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/)
    }

    if (cleaned.length < 10)
      if (event.nativeEvent.inputType === 'deleteContentBackward') return event.target.value

    if (match2)
      return ['(', match2[1], ') '].join('')
    if (match3)
      return ['(', match3[1], ') ', match3[2], '-', match3[3]].join('')
  }

  return (
    <>
      {loading && (
        <div className={"loading-mask"}>
          <FontAwesomeIcon icon={faCircleNotch} pulse />
        </div>
      )}

      {modal &&
        <Modal
          title={modalMsg}
          handleDelete={null}
          handleModal={setModal}
          alert
        />
      }

      <form
        className={`form-container ` + (show === 'resposta' ? 'tab-active' : '')}
        id="formRespostaProvedor"
        onSubmit={handleSubmitDescricao}>
        <div className="row newRow oldBg">
          <h1>RESPOSTA DO PROVEDOR</h1>
        </div>
        <div className="row rowCardPadding p-0">
          <div className="col-lg-12 flex-flow-wrap">
            <div className="row w-100 d-flex bg-col-bottom m-0">
              <div className="col-lg-2 br-none">
                <label className="global-label">Classificação da Reclamação</label>
              </div>
              <div className="col-lg-4 px-0">
                <div className="InputRadioGroup">
                  <InputRadio name="classificacaoReclamacao" label="Procedente" value="1" checked={(notificacao.classificacaoReclamacao === true) ? '1' : notificacao.classificacaoReclamacao} onChange={handleChangeNum} />
                  <InputRadio name="classificacaoReclamacao" label="Improcedente" value="2" checked={!notificacao.classificacaoReclamacao ? '2' : notificacao.classificacaoReclamacao} onChange={handleChangeNum} />
                </div>
              </div>
              <IF condition={notificacao.classificacaoReclamacao !== 1}>
                <div className="col-lg-6">
                  <input
                    className="global-input"
                    type="text"
                    id="motivo"
                    value={notificacao.motivo || ''}
                    placeholder=""
                    name="motivo"
                    onChange={handleChange}
                  />
                </div>
              </IF>
            </div>

            <div className="row w-100 d-flex bg-col-bottom m-0">
              <div className="col-lg-2 br-none">
                <label className="global-label">Ação Imediata</label>
              </div>
              <div className="col-lg-10">
                <input
                  className="global-input"
                  type="text"
                  id="acaoImediata"
                  value={notificacao.acaoImediata || ''}
                  placeholder="Descrição"
                  name="acaoImediata"
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="row w-100 d-flex bg-col-bottom m-0">
              <div className="col-lg-2 br-none">
                <label className="global-label">Descrição da Causa</label>
              </div>
              <div className="col-lg-10">
                <input
                  className="global-input"
                  type="text"
                  id="descricaoCausa"
                  value={notificacao.descricaoCausa || ''}
                  placeholder="Descrição da causa"
                  name="descricaoCausa"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="row newRow oldBg">
          <h1>PLANO DE AÇÃO</h1>
        </div>
        <div className="row rowCardPadding p-0">
          <div className="col-lg-12 flex-flow-wrap">
            <div className="col-md-3 planoAcao-title">O que será feito</div>
            <div className="col-md-3 planoAcao-title">Como</div>
            <div className="col-md-2 planoAcao-title">Por que</div>
            <div className="col-md-2 planoAcao-title">Responsável</div>
            <div className="col-md-2 planoAcao-title">Prazo</div>


            {notificacao.provedorNotificacaoRespostaProvedorPlanoAcoes.map((elem, i) => (
              <div className="row w-100 d-flex bg-col-bottom m-0">
                <div className="col-lg-3 br-none">
                  <input
                    key={`acao-${i}`}
                    className="global-input"
                    type="text"
                    id={`acao-${i}`}
                    value={notificacao.provedorNotificacaoRespostaProvedorPlanoAcoes[i].acao || ''}
                    placeholder=""
                    name={`provedorNotificacaoRespostaProvedorPlanoAcoes[${i}][acao]`}
                    onChange={handleChangePlanoAcao}
                  />
                </div>
                <div className="col-lg-3 br-none">
                  <input
                    key={`acao-${i}`}
                    className="global-input"
                    type="text"
                    id={`como-${i}`}
                    value={notificacao.provedorNotificacaoRespostaProvedorPlanoAcoes[i].como || ''}
                    placeholder=""
                    name={`provedorNotificacaoRespostaProvedorPlanoAcoes[${i}][como]`}
                    onChange={handleChangePlanoAcao}
                  />
                </div>
                <div className="col-lg-2 br-none">
                  <input
                    key={`acao-${i}`}
                    className="global-input"
                    type="text"
                    id={`porQue-${i}`}
                    value={notificacao.provedorNotificacaoRespostaProvedorPlanoAcoes[i].porQue || ''}
                    placeholder=""
                    name={`provedorNotificacaoRespostaProvedorPlanoAcoes[${i}][porQue]`}
                    onChange={handleChangePlanoAcao}
                  />
                </div>
                <div className="col-lg-2 br-none">
                  <input
                    key={`acao-${i}`}
                    className="global-input"
                    type="text"
                    id={`responsavel-${i}`}
                    value={notificacao.provedorNotificacaoRespostaProvedorPlanoAcoes[i].responsavel || ''}
                    placeholder=""
                    name={`provedorNotificacaoRespostaProvedorPlanoAcoes[${i}][responsavel]`}
                    onChange={handleChangePlanoAcao}
                  />
                </div>
                <div className="col-lg-2 br-none">
                  <input
                    key={`acao-${i}`}
                    className="global-input"
                    type="text"
                    id={`prazo-${i}`}
                    value={notificacao.provedorNotificacaoRespostaProvedorPlanoAcoes[i].prazo || ''}
                    placeholder=""
                    name={`provedorNotificacaoRespostaProvedorPlanoAcoes[${i}][prazo]`}
                    onChange={handleChangePlanoAcao}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="row newRow oldBg">
          <h1>RESPONSABILIDADE PELO PREENCHIMENTO</h1>
        </div>
        <div className="row rowCardPadding p-0">
          <div className="col-lg-9 flex-flow-wrap">
            <div className="row w-100 d-flex bg-col-bottom m-0 row-01">
              <div className="col-lg-2 br-none">
                <label className="global-label">Nome</label>
              </div>
              <div className="col-lg-5">
                <input
                  className="global-input"
                  type="text"
                  id="nome"
                  value={notificacao.nome || ''}
                  placeholder="Nome Completo do responsável"
                  name="nome"
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-2 br-none" style={{ width: '8%' }}>
                <label className="global-label">Cargo</label>
              </div>
              <div className="col-lg-3" style={{ width: '33%' }}>
                <input
                  className="global-input"
                  type="text"
                  id="cargo"
                  value={notificacao.cargo || ''}
                  placeholder="Cargo do responsável"
                  name="cargo"
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="row w-100 d-flex bg-col-bottom m-0 row-02">
              <div className="col-lg-2 br-none">
                <label className="global-label">Telefone</label>
              </div>
              <div className="col-lg-3">
                <input
                  className="global-input"
                  type="text"
                  id="telefone"
                  value={notificacao.telefone}
                  placeholder="(99) 99999-9999"
                  name="telefone"
                  onChange={handleChange}
                />
              </div>
              <div className="col-email br-none" style={{ width: '9%' }}>
                <label className="global-label">E-mail</label>
              </div>
              <div className="col-email-input">
                <input
                  className="global-input"
                  type="email"
                  id="email"
                  value={notificacao.email || ''}
                  placeholder="E-mail do responsável"
                  name="email"
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-1 br-none" style={{ width: '8%' }}>
                <label className="global-label">Data</label>
              </div>
              <div className="col-lg-2" style={{ width: '20%', marginLeft: -18 }}>
                <input
                  className="global-input"
                  type="date"
                  id="data"
                  value={formatData(new Date(notificacao.data || ''))}
                  placeholder="xx / xx / xxxx"
                  name="data"
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-3 flex-flow-wrap">
            <div className="row w-100 d-flex m-0 divDocumentos">
              <div className="col-lg-5 br-none">
                <label className="global-label">Upload de Documento / Registros</label>
              </div>
              <div className="col-lg-4 br-none">
                <img src={imgDoc || `https://via.placeholder.com/115x150?text=Documento`} className="doc" style={{
                  cursor: 'pointer'
                }} onClick={() => inputDoc.current.click()} />
                <input
                  type="file"
                  accept=".svg,.jpg,.png"
                  ref={inputDoc}
                  id="1"
                  name="doc"
                  onChange={handlerChangeFile}
                  style={{ display: "none" }} />
              </div>
              <div className="col-lg-3 br-none divFotos">
                <img src={imgFoto1 || `https://via.placeholder.com/115x80?text=Foto+1`} className="foto" style={{
                  cursor: 'pointer'
                }} onClick={() => inputFoto1.current.click()} />
                <input
                  type="file"
                  accept=".svg,.jpg,.png"
                  ref={inputFoto1}
                  id="2"
                  name="foto1"
                  onChange={handlerChangeFile}
                  style={{ display: "none" }} />
                <img src={imgFoto2 || `https://via.placeholder.com/115x80?text=Foto+2`} className="foto" style={{
                  cursor: 'pointer'
                }} onClick={() => inputFoto2.current.click()} />
                <input
                  type="file"
                  accept=".svg,.jpg,.png"
                  ref={inputFoto2}
                  id="3"
                  name="foto2"
                  onChange={handlerChangeFile}
                  style={{ display: "none" }} />
              </div>
            </div>
          </div>
        </div>
        <button
          className="global-btn btnToAnalise"
          type="submit"
          disabled={sending && true}
        >
          {sending ? '' : "Fazer análise crítica"}
          {sending && <FontAwesomeIcon icon={faCircleNotch} pulse />}
        </button>
      </form>
    </>
  );
}
