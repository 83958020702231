import React from "react";
import { Route, Redirect } from "react-router-dom";

const AppRoute = ({ component: Component, state, ...routeProps }) => (
  <Route
    {...routeProps}
    render={(props) => {
      const auth = routeProps.isAuthenticated;

      //PUBLIC ROUTES
      if (routeProps.public) {
        return !auth ? (
          <Component {...props} state={state} />
        ) : (
          <Redirect to={{ pathname: "/dashboard" }} />
        );

        //PRIVATE ROUTES
      } else if (routeProps.private) {
        return auth ? (
          <Component {...props} state={state} info={routeProps} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: props.location },
            }}
          />
        );

        // DEFAULT - REGULAR REACT-ROUTER OBJCET
      } else {
        return <Component {...props} />;
      }
    }}
  />
);

export default AppRoute;
