import React, { useEffect, useState, useRef } from "react";

import Modal from "../../../components/ModalExit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import Service from "../../../services/tiposInformacoesDocumentadasService";
import { toast } from "react-toastify";

import InputRadio from "../../../components/InputRadio/InputRadio";

export default function Form({
  token,
  send,
  currentValue,
}) {
  const inputFile = useRef(null)
  const [geraBloqueioHomologacao, setGeraBloqueioHomologacao] = useState(false);

  const [modal, setModal] = useState(false);
  const [modalMsg, setModalMsg] = useState('');

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    nome: "",
    data: "",
    geraBloqueioHomologacao: false
  });

  const [selectedFile, setSelectedFile] = useState([]);

  const handleGeraBloqueio = (e) => {
    e.persist();
    setData(values => ({
      ...values,
      geraBloqueioHomologacao: e.target.value == 1 ? true : false,
    }));
  }

  const handlerChangeFile = (event) => {  
    const file = event.target.files[0]

    const type = file.type
    if (type !== "application/pdf" && type !== "image/png" && type !== "image/jpeg") {
      setModalMsg('Tipo de arquivo inválido, selecione um arquivo válido do tipo PDF, PNG ou JPG')
      setModal(true)
      return
    }

    if (file.size > 10000000) {
      setModalMsg('O arquivo deve ser igual ou menor a 10MB')
      setModal(true)
      return
    }

    setSelectedFile([file]);
  };

  const handleChangeNome = (event) => {
    event.persist();

    setData(values => ({
      ...values,
      nome: event.target.value,
    }));
  };

  const handleChangeData = (event) => {
    event.persist();

    const dateMask = () => {
      var data = event.target.value;

      if (data.length === 2) {
        return event.nativeEvent.inputType === 'deleteContentBackward'
          ? data
          : data = data + '/';
      }
      if (data.length === 5) {
        return event.nativeEvent.inputType === 'deleteContentBackward'
          ? data
          : data = data + '/';
      }

      return event.target.value;
    }

    setData(values => ({
      ...values,
      data: dateMask()
    }));
  };

  const isComprador = () => {
    const profile = JSON.parse(localStorage.getItem("profile"));
    return (profile.profileId === 2);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (data.nome === ""
      || data.data === ""
      || data.data === undefined
      || data.data.length < 10
    ) return

    if (currentValue && currentValue.id > 0) {
      return handleUpdate();
    }
    handleRegister();
  };

  const handleRegister = async () => {

    if (selectedFile.length === 0) {
      setModalMsg('Selecione um arquivo para upload')
      setModal(true)
      return
    }

    setLoading(true);

    const archive = new FormData()
    archive.append("arquivo", selectedFile[0]);

    const dataFormat = () => {
      const dd = data.data.split('/')
      return `${dd[2]}-${dd[1]}-${dd[0]}`
    }

    const response = await Service.register(token, { ...data, data: dataFormat() }, archive)

    if (response) {
      if (response.response && response.response.status === 400) {
        toast.error(response.response.data);
        setLoading(false);
        return
      }

      toast.success("Cadastro realizado com sucesso!");
      setLoading(false);

      setData({
        nome: "",
        data: "",
      });

      send(response);
    } else {
      toast.error("Erro ao cadastrar item!");
      setLoading(false);
    }
  };

  const handleUpdate = () => {
    setLoading(true);

    const dataFormat = () => {
      const dd = data.data.split('/')
      return `${dd[2]}-${dd[1]}-${dd[0]}`
    }

    Service.update(token, { ...data, data: dataFormat() })
      .then((response) => {
        setLoading(false);
        send(response);

        setData({
          nome: "",
          data: "",
        });
      }).catch((error) => {
        toast.error("Erro ao atualizar item!");
        setLoading(false);
      });
  };

  useEffect(() => {
    if (currentValue)
      setData(currentValue)
  }, [currentValue]);

  const handleModal = (value) => {
    setModal(value);
  };

  return (
    <>
      {modal &&
        <Modal
          title={modalMsg}
          handleDelete={null}
          handleModal={handleModal}
          alert
        />
      }
      <div className="user-config-container">
        <div className="card-container">
          <div className="card">
            <div className="card-header">
              <h1>CADASTRO DE TIPO DE INFORMAÇÃO</h1>
            </div>
            <div className="card-body">
              <form className="form-container" onSubmit={handleSubmit}>
                <div className="group-input escopo-avaliacao created-form" style={{flexDirection:"column"}}>
                <div style={{display: "flex"}}>
                  <div className="content-input" style={{ width: "50%" }}>
                    <label htmlFor="nome">O que</label>
                    <input
                      className="global-input"
                      type="text"
                      id="nome"
                      placeholder=""
                      name="nome"
                      value={data.nome}
                      onChange={handleChangeNome}
                      style={{
                        width: '200%',
                        marginLeft: 0
                      }}
                    />
                  </div>
                  <div className="content-input" style={{ width: "200px" }}>
                    <label
                      htmlFor="data"
                      style={{
                        marginRight: '-60px'
                      }}
                    >Data</label>

                    <input
                      style={{ width: "115px" }}
                      className="global-input"
                      type="text"
                      id="data"
                      placeholder="--/--/----"
                      name="data"
                      maxLength="10"
                      value={data.data}
                      onChange={handleChangeData}
                    />
                  </div>
                      <div></div>
                  

                  <div className="upload-wrapp"
                    style={{
                      display: "grid",
                      gap: "10px",
                      alignItems: "center",
                      gridTemplateColumns: '100px 54px 0.91fr'
                    }}>
                    <label htmlFor="file" >Upload do arquivo</label>
                    <div id="input-file-wrapp" >
                      <div onClick={() => inputFile.current.click()}
                        style={{
                          cursor: 'pointer'
                        }}
                      >
                        <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M10 0C4.47715 0 0 4.47715 0 10V44C0 49.5228 4.47715 54 10 54H44C49.5228 54 54 49.5228 54 44V10C54 4.47715 49.5228 0 44 0H10ZM9.125 32C9.125 31.4477 8.67729 31 8.125 31C7.57272 31 7.125 31.4477 7.125 32V38.6667C7.125 39.889 7.72129 41.0087 8.6886 41.7983C9.65031 42.5834 10.9184 43 12.2083 43H40.7917C42.0816 43 43.3497 42.5834 44.3114 41.7983C45.2787 41.0087 45.875 39.889 45.875 38.6667V32C45.875 31.4477 45.4273 31 44.875 31C44.3227 31 43.875 31.4477 43.875 32V38.6667C43.875 39.2124 43.6109 39.7884 43.0466 40.249C42.4768 40.7142 41.6677 41 40.7917 41H12.2083C11.3323 41 10.5232 40.7142 9.95336 40.249C9.38913 39.7884 9.125 39.2124 9.125 38.6667V32ZM37.4826 20.9657C37.1333 21.3935 36.5034 21.4572 36.0755 21.108L27.4992 14.1069L27.4992 32.0013C27.4992 32.5536 27.0515 33.0013 26.4992 33.0013C25.9469 33.0013 25.4992 32.5536 25.4992 32.0013L25.4992 14.1075L16.9236 21.108C16.4958 21.4572 15.8658 21.3935 15.5166 20.9657C15.1673 20.5379 15.231 19.9079 15.6589 19.5587L25.8672 11.2253C26.2353 10.9249 26.7639 10.9249 27.132 11.2253L37.3403 19.5587C37.7681 19.9079 37.8318 20.5379 37.4826 20.9657Z" fill="#4068C8" />
                        </svg>
                      </div>
                      <input
                        type="file"
                        accept=".pdf,.jpg,.png"
                        id="file"
                        name="file"
                        className="file"
                        ref={inputFile}
                        onChange={handlerChangeFile}
                        style={{ display: "none" }}
                      />
                    </div>
                    <span style={{
                      maxWidth: '600px',
                      padding: '20px 0',
                      marginLeft: '16px'
                    }}>Clique e selecione o arquivo em pdf, jpg ou png do tipo de informação, somente serão aceitos documentos legíveis e com no máximo 10 MB de tamanho.</span>
                  </div>

                  
                  </div>
                  
                  { isComprador() &&
                  <div style={{width: "100%", display: "flex", alignItems: "center"}}>
                    <label style={{    width: "auto", whiteSpace: "nowrap", marginRight: "10px", paddingLeft: "22px"}}>Item Background check</label>
                    <div style={{ display: 'inline-flex',flexDirection: 'row', width: 400 }}>
                      <InputRadio
                        key={1}
                        name={'escopo-1'}
                        label="Sim"
                        value="1"
                        checked={(data.geraBloqueioHomologacao === true) ? '1' : data.geraBloqueioHomologacao}
                        onChange={handleGeraBloqueio}
                      />
                      <InputRadio
                        key={2}
                        name={'escopo-2'}
                        label="Não"
                        value="0"
                        checked={!data.geraBloqueioHomologacao ? '0' : data.geraBloqueioHomologacao}
                        onChange={handleGeraBloqueio}
                      />
                    </div>
                    <div style={{ marginLeft: "auto", display: "flex"}}>
                    <button className="global-btn" >
                      {currentValue && currentValue.id > 0
                        ? !loading && "SALVAR"
                        : !loading && "CRIAR"}

                      {loading && <FontAwesomeIcon icon={faCircleNotch} pulse />}
                      </button>
                    </div>
                  </div>
                  }
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
