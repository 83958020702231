import React, { useState, useEffect } from "react";

import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ProvedorCertificadoService from './../../services/ProvedorCertificadoService';
import MaterialsServicesService from './../../services/MaterialsServicesService';
import Modal from "../../components/ModalExit";
import { toast } from "react-toastify";

import List from "./List/meusCertificadosList";
import Form from "./Form/MeusCertificadosForm";
import FormVizualize from "./FormVizualize/MeusCertificadosForm";

import "./style.scss";

export default function Index(props) {
  const { token } = props.state;

  const [listMateriaisServicos, setListMateriaisServicos] = useState([]);
  const [listMateriaisServicosToInsert, setListMateriaisServicosToInsert] = useState([]);
  const [listUser, setListUser] = useState([]);
  const [provedorProvedorCertificadosList, setProvedorCertificadosList] = useState([]);
  const [current, setCurrent] = useState({});
  const [deleteID, setDeleteID] = useState(0);

  const [showForm, setShowForm] = useState(false);
  const [showFormVizualize, setShowFormVizualize] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentValue, setCurrentValue] = useState(null);
  const [currentValueVizualize, setCurrentValueVizualize] = useState(null);

  const [valoresJaCadastradosTipoCertificados, setValoresJaCadastradosTipoCertificados] = useState(null);
  const [valoresJaCadastradosOrganismosCertificadores, setValoresJaCadastradosOrganismosCertificadores] = useState(null);

  const initComponent = () => {
    setShowForm(false);
    setShowFormVizualize(false);
    setCurrentValue(null);
    getAll();
    setListUser([]);
  }

  const currentDocuments = (value) => {
    setCurrentValue(value);
    setShowForm(!showForm);
  }

  const seeDocuments = (value) => {
    setCurrentValueVizualize(value);
    setShowFormVizualize(!showFormVizualize);
  }

  const getAll = async () => {
    setLoading(true);
    const certificadosProvedor = await ProvedorCertificadoService.getCertificadosProvedor(token);
    const materiaisServicos = await MaterialsServicesService.getMaterialsServicesProvedor(token);

    setProvedorCertificadosList(certificadosProvedor);
    setListMateriaisServicos(materiaisServicos);
    setValoresJaCadastradosTipoCertificados(certificadosProvedor);
    setValoresJaCadastradosOrganismosCertificadores(certificadosProvedor);

    setLoading(false);
  }

  const create = (payload) => {
    if (!payload.name) return;
    if (listUser.some((currentValue) => { return currentValue.name === payload.name })) {
      toast.error("Este Material/Serviço já está cadastrado para este provedor e não pode ser incluído novamente!");
      return;
    }

    setListMateriaisServicosToInsert([...listMateriaisServicosToInsert, payload]);
    setListUser([...listUser, payload]);
  }

  const remove = async (id) => {
    setListMateriaisServicosToInsert(arrayRemove(listMateriaisServicosToInsert, id));
    setListUser(arrayRemove(listUser, id));
    setDeleteID(0)
  }

  function arrayRemove(arr, value) {
    return arr.filter(function (ele) {
      return ele.id != value;
    });
  }

  const handleRegister = async (data, formData) => {
    setLoading(true);

    const response = await ProvedorCertificadoService.registerProvedorCertificado(token, { ...data, dataValidade: dataFormat(data.dataValidade), materialServiceProvedorCertificados: listUser }, formData);

    if (response) {
      if (response.response && response.response.status === 400) {
        toast.error(response.response.data);
        setLoading(false);
        return
      }

      toast.success("Cadastro realizado com sucesso!");
      setLoading(false);

      initComponent();
    } else {
      toast.error("Erro ao cadastrar item!");
      setLoading(false);
    }
  };

  const handleUpdate = async (editarArquivos, data, formData) => {
    setLoading(true);

    const response = await ProvedorCertificadoService.updateProvedorCertificado(token, { ...data, dataValidade: dataFormat(data.dataValidade), materialServiceProvedorCertificados: listUser }, editarArquivos, formData);

    if (response) {
      if (response.response && response.response.status === 400) {
        toast.error(response.response.data);
        setLoading(false);
        return
      }

      toast.success("Item atualizado com sucesso!");
      setLoading(false);

      initComponent();
    } else {
      toast.error("Erro ao atualizar item!");
      setLoading(false);
    }
  };

  const dataFormat = (dataValidade) => {
    const dd = dataValidade.split('/');
    return `${dd[2]}-${dd[1]}-${dd[0]}`
  }

  const ehUpdate = () => {
    if (provedorProvedorCertificadosList &&
      provedorProvedorCertificadosList.length > 0) {
      const listMateriais = provedorProvedorCertificadosList.map(e => e.materialServiceProvedorCertificados)
      setListUser(listMateriais.reduce(e => e.materialServices).map(e => e.materialServices));
    }
  }

  useEffect(() => {
    initComponent();
  }, []);

  return (
    <>
      <div className="dashboard-container">
        {loading && (
          <div className={"loading-mask"}>
            <FontAwesomeIcon icon={faCircleNotch} pulse />
          </div>
        )}
        {deleteID > 0 && (
          <Modal handleDelete={() => remove(deleteID)} handleModal={() => { setDeleteID(0) }} />
        )}
        {showForm
          ?
          <>
            <div className="MeusCertificadosForm-container">
              <Form
                token={token}
                valoresJaCadastradosOrganismosCertificadores={valoresJaCadastradosOrganismosCertificadores}
                valoresJaCadastradosTipoCertificados={valoresJaCadastradosTipoCertificados}
                listMateriaisServicos={listMateriaisServicos}
                setDeleteID={setDeleteID}
                setCurrent={setCurrent}
                initComponent={initComponent}
                create={create}
                listUser={listUser}
                currentValue={currentValue}
                handleRegister={handleRegister}
                handleUpdate={handleUpdate}
              />
            </div>
          </>
          : !showFormVizualize?
          <>
            <div className="module-meus-certificados">
              <button
                className={"global-btn"}
                onClick={() => {
                  setShowForm(!showForm);
                  setListUser([]);
                }}
              >
                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M14.5799 10.5H10.4142V14.5H4.16567V10.5H0V4.5H4.16567V0.5H10.4142V4.5H14.5799V10.5Z" fill="#FFF" />
                </svg>
                Incluir novo certificado

              </button>
              <div className="dashboard-container">
                <div className="module-meus-certificados">
                  <List token={token} ehUpdate={ehUpdate} back={() => initComponent()} meusCertificados={provedorProvedorCertificadosList} seeDocuments={seeDocuments} currentDocuments={currentDocuments} />
                </div>
              </div>
            </div>
          </> : <></>
        }
        {showFormVizualize
          ?
          <>
            <div className="MeusCertificadosForm-container">
              <FormVizualize
                token={token}
                valoresJaCadastradosOrganismosCertificadores={valoresJaCadastradosOrganismosCertificadores}
                valoresJaCadastradosTipoCertificados={valoresJaCadastradosTipoCertificados}
                listMateriaisServicos={listMateriaisServicos}
                setDeleteID={setDeleteID}
                setCurrent={setCurrent}
                initComponent={initComponent}
                create={create}
                listUser={listUser}
                currentValue={currentValueVizualize}
                handleRegister={handleRegister}
                handleUpdate={handleUpdate}
              />
            </div>
          </>
          :
          <>
          </>
        }
      </div>
    </>
  );
}