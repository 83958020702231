import React from "react";

import "./CardInfoFlutuante.scss";

function CardInfoFlutuante({  label, options}) {

    return (
        <>
        <div className={'DropList ' + 'active' }>
            <div className="DropList-container">
                <span className="DropList-header">
                
                    {label ? (
                        <p className="DropList-label">{label}</p>
                    ) : ``}
                </span>
                <ul
                    className={`DropList-options`}
                >
                    {options || [].map((nomeMaterialservicoRelacionado) => (
                        <li>{nomeMaterialservicoRelacionado}</li>
                    ))}
                </ul>
            </div>
        </div>
    </>
    );
}

export default CardInfoFlutuante;