import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import { toast } from "react-toastify";

import "../dashboard/dashboard.scss";

import GestaoDeIdoneidadeList from "./List/gestaoDeIdoneidadeList";
import VerificacaoAutomatica from "./List/verificacaoAutomatica";
import Modal from "../../components/ModalExit";

import GestaoIdoneidadeService from "../../services/GestaoIdoneidadeService";

export default function Index(props) {
  const { token } = props.state;
  const [gestao, setGestao] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [modal, setModal] = useState(false);
  const [currentId, setCurrentId] = useState("");

  const getGestaoIdoneidade = () => {
    GestaoIdoneidadeService.getGestaoIdoneidade(token)
      .then((response) => {
        setGestao(response);
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
      });
  };

  useEffect(() => {
    getGestaoIdoneidade();
    // eslint-disable-next-line
  }, []);

  const handleModal = (value) => {
    setModal(value);
  };

  const handleId = (id) => {
    setCurrentId(id);
    handleModal(true);
  };

  const handleDelete = () => {
    setLoading(true);
    setError(false);
    setSuccess(false);
    GestaoIdoneidadeService.deleteGestaoIdoneidade(token, currentId)
      .then((response) => {
        if (response) {
          toast.success("Item excluído com sucesso!");
          setSuccess(true);
          setLoading(false);
          setError(false);
          getGestaoIdoneidade();
          handleModal(false);
        }
        setLoading(false);
        setError(false);
      })
      .catch((error) => {
        toast.error("Erro ao excluir item!");
        setSuccess(false);
        setLoading(false);
        setError(true);
      });
  };

  return (
    <>
      <div className="dashboard-container">
        {modal && (
          <Modal handleDelete={handleDelete} handleModal={handleModal} />
        )}
        {loading && (
          <div className={"loading-mask"}>
            <FontAwesomeIcon icon={faCircleNotch} pulse />
          </div>
        )}
        <h1>Gestão de idoneidade e integridade</h1>
        {/* <div className="gestao-idoneidade-container">
          <div className="gestao-idoneidade-item"> */}
            <GestaoDeIdoneidadeList
              loading={loading}
              error={error}
              gestao={gestao}
              handleId={handleId}
            />
          {/* </div>
          <div className="gestao-idoneidade-item">
            <VerificacaoAutomatica />
          </div>
        </div> */}
      </div>
    </>
  );
}
