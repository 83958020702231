import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { DebounceInput } from 'react-debounce-input';

import DropList from "../../../../components/DropList/DropList"
import Services from '../../../../services/IncluirProvedoresServices'
import RegistrationMaterialsService from '../../../../services/registrationMaterialsService'
import * as constantes from '../../../../components/Common/Constantes';
import FormataTelefone from '../../../../components/Common/FormataCamposComMascara';
import { ValidateEmail, formataCnpj } from "../../../../components/Common/FormataCamposComMascara";

export default function Form({ token }) {

const initialValues = {
  nome: '',
  materialServices: [],
  contato: '',
  email: '',
  telefone: '',
  status: 2,
  cnpj: "",
  matricula: "",
  tipoChave: "",
  materiaisEServicosDoProvedorBuscado: [],
}

  const [data, setData] = useState(initialValues);
  const [inputValid, setInputValid] = useState({ nome: null, materialServices: null, contato: null, email: null, telefone: null, cnpj: null })
  const [dataList, setDataList] = useState([])
  const [dataListItemSelect, setDataListItemSelect] = useState([])
  const [dataListItemSelectChave, setDataListItemSelectChave] = useState([])
  const [dropListSelect, setDropListSelect] = useState(null)
  const [onSubmitLoad, setOnSubmitLoad] = useState(false)
  const [loading, setLoading] = useState(false);
  const [isNovoProvedor, setIsNovoProvedor] = useState(false);
  const [tipoChave, setTipoChave] = useState(null);


  useEffect(() => {
    fillList()
  }, [])

  useEffect(() => {
    onSubmit()
  }, [inputValid])

  useEffect(() => {
    if (data.materiaisEServicosDoProvedorBuscado.length > 0) {
      let ids = data.materiaisEServicosDoProvedorBuscado.map((material) => {
        return material.id;
      });

      let novaListaDeMateriais = dataList.filter(function (item) {
        return !ids.includes(item.id);
      });

      setDataList(novaListaDeMateriais);
    }
  }, [data.materiaisEServicosDoProvedorBuscado]);
  
  
  const handleCnpjMatricula = (event) => {
    event.persist();
    if(event.target.name == "matricula") {
      setData((values => ({
        ...initialValues,
        tipoChave: tipoChave,
        [event.target.name]: event.target.value,
      })));
      search(event.target.value);
      return;
    }

    if (event.target.value.length < 19) {
      let cnpjComMascara = formataCnpj(event.target.value);
      setData((values => ({
        ...initialValues,
        tipoChave: tipoChave,
        [event.target.name]: cnpjComMascara,
      })));

      if (event.target.value.length == 18) {
        search(cnpjComMascara);
      }
    }
    return;
  }

  const search = async (textoPesquisa) => {
    if (textoPesquisa != "") {
      setLoading(true)
      let response = await Services.get(token, textoPesquisa, tipoChave)
      if (response) {
        setData({
          nome: response.nome,
          materiaisEServicosDoProvedorBuscado: response.materialServices,
          materialServices: [],
          contato: response.contato,
          email: response.email,
          telefone: FormataTelefone(response.telefone),
          status: 1,
          cnpj: response.cnpj,
          matricula: response.matricula,
          tipoChave: tipoChave
        });

        setIsNovoProvedor(true);
      }
      else {
        toast.warning("Nenhum provedor encontrado, ao preencher os dados será cadastrado um novo provedor!");
        fillList();
        setIsNovoProvedor(false);
      }
      setLoading(false);
    }
  };


  function handleChange(event) {
    event.persist();

    if (event.target.name === 'telefone') {
      setData((values => ({
        ...values,
        telefone: formatPhoneNumber(event)
      })))
    } else {
      setData((values => ({
        ...values,
        [event.target.name]: event.target.value,
      })))
    }
  }

  function handleSubmit(e) {
    e.preventDefault()

    setInputValid({
      nome: data.nome === '' ? false : true,
      materialServices: data.materialServices.length === 0 ? false : true,
      contato: data.contato === '' ? false : true,
      email: !ValidateEmail(data.email) ? false : true,
      telefone:
        ((data.telefone === '') || (data.telefone && data.telefone.length < 14) || (data.telefone === undefined)) ? false : true,
      cnpj: data.cnpj.length < 18 ? false : true 
    })
  }

  async function onSubmit() {

    if (inputValid.nome
      && inputValid.materialServices
      && inputValid.contato
      && inputValid.email
      && inputValid.telefone
    ) {

      setOnSubmitLoad(true)
      const response = await Services.post(token, data)

      if (response) {
        if (response.response && constantes.statusError.includes(response.response.status)) {
          toast.error(response.response.data.title || response.response.data || "Erro ao enviar solicitação")
          setOnSubmitLoad(false)
          return
        }

        toast.success("Solicitação enviada com sucesso!")
        setData(initialValues);
        setDataListItemSelect([])
        setDataListItemSelectChave([]);
        setTipoChave("");
        setOnSubmitLoad(false)
      } else {
        setOnSubmitLoad(false)
        toast.error("Erro ao enviar solicitação")
      }
    }
  }

  function fillList() {
    RegistrationMaterialsService.getAll(token)
      .then(response => {
        setDataList(response.valores)
      })
  }

  const handleDropList = (key) => {
    setDropListSelect((dropListSelect !== key) ? key : '')
  }

  const handleGetSelectedOption = (option) => {
    const itemSelected = dataList.find(item =>
      item.id === option ? { id: item.id, name: item.name } : null
    )
    const index = dataListItemSelect.findIndex(item => item.id === option)

    if (index !== -1) {
      dataListItemSelect.splice(index, 1)
      setDataListItemSelect([...dataListItemSelect])
    } else {
      dataListItemSelect.push(itemSelected)
      setDataListItemSelect([...dataListItemSelect])
    }

    const materialIds = dataListItemSelect.map(item => ({ id: item.id }))

    setData((values => ({
      ...values,
      materialServices: materialIds
    })))
  }

  const handleGetSelectedOptionTipoChave = (option) => {
    const itemSelected = constantes.eTipoChaveBuscaProvedor.find(item =>
      item.id === option ? { id: item.id, name: item.name } : null
    )
    const index = dataListItemSelectChave.findIndex(item => item.id === option)
    dataListItemSelectChave.pop();
    dataListItemSelectChave.push(itemSelected);
    setDataListItemSelectChave([...dataListItemSelectChave]);

    const tipoChaveSelecionado = dataListItemSelectChave.map(item => ({ id: item.id }))

    setTipoChave(tipoChaveSelecionado[0].id);
    setData({
      ...initialValues,
      tipoChave: tipoChaveSelecionado[0].id
    })
  }


  let formatPhoneNumber = (event) => {
    const str = event.target.value

    const cleaned = ('' + str).replace(/\D/g, '')

    let match2 = cleaned.match(/^(\d{2})$/)
    let match3 = cleaned.match(/^(\d{2})(\d{4})(\d{4})$/)

    if (cleaned.length > 10) {
      match2 = cleaned.match(/^(\d{2})$/)
      match3 = cleaned.match(/^(\d{2})(\d{5})(\d{4})$/)
    }

    if (cleaned.length < 10)
      if (event.nativeEvent.inputType === 'deleteContentBackward') return event.target.value

    if (match2)
      return ['(', match2[1], ') '].join('')
    if (match3)
      return ['(', match3[1], ') ', match3[2], '-', match3[3]].join('')
  }

  return (
    <>
      <div className="card-container">
      {loading && (
          <div className={"loading-mask"}>
            <FontAwesomeIcon icon={faCircleNotch} pulse />
          </div>
        )}
        <div className="card">
          <div className="card-header">
            <h1>NOVO PROVEDOR</h1>
          </div>
          <div className="card-body">
            <form
              className="form-container"
              onSubmit={handleSubmit}>
              <div className="group-input created-form">
              <div className="input-group-container">
                  <label><b>Tipo de chave</b></label>
                  <div className="input-group-span">
                    <div className="input-list-wrap">
                      <input
                        disabled={true}
                        type="text"
                        name="tipoChave"
                        id="tipoChave"
                        className="global-input"
                        onChange={handleChange}
                      />
                      <div className={"list-item d-inline-flex"}>
                        <span>{(dataListItemSelectChave.length > 0)
                          ? dataListItemSelectChave[0].name
                          : 'Chave para buscar o provedor'}
                        </span>
                        <DropList
                          uniqid={'vincular-tipoChave'}
                          label="Escolha o tipo de chave"
                          selectable
                          defaultValue={dataListItemSelectChave.length === 0 ? [] : dataListItemSelectChave[0].id}
                          options={constantes.eTipoChaveBuscaProvedor}
                          selectedOption={handleGetSelectedOptionTipoChave}
                          active={(dropListSelect === 'vincular-tipoChave')}
                          onClick={handleDropList}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {tipoChave == 1 && (
                <div className="input-group-container">
                  <label><b>Cnpj</b></label>
                  <div className="input-group-span">
                    <input
                      type="text"
                      id="cnpj"
                      name="cnpj"
                      className="global-input"
                      placeholder={"Ex: 99.999.999/9999-99"}
                      debounceTimeout={600}
                      value={data.cnpj}
                      readOnly={tipoChave == null}
                      onChange={handleCnpjMatricula}
                    />
                    {<span>{inputValid.cnpj === false && 'Formato Cnpj inválido'}</span>}
                  </div>
                </div>
                )}

                {tipoChave == 2 && (
                  <div className="input-group-container">
                  <label><b>Matricula</b></label>
                  <div className="input-group-span">
                    <DebounceInput
                      type="text"
                      id="matricula"
                      name="matricula"
                      className="global-input"
                      placeholder={"Ex: 999999999"}
                      debounceTimeout={600}
                      value={data.matricula}
                      readOnly={tipoChave == null}
                      onChange={handleCnpjMatricula}
                    />
                  </div>
                </div>
                )}                
                <div className="input-group-container">
                  <label><b>Provedor</b></label>
                  <div className="input-group-span">
                    <input
                      className="global-input"
                      type="text"
                      id="nome"
                      value={data.nome}
                      name="nome"
                      readOnly={(data.cnpj == "" && data.matricula == "") || isNovoProvedor == true}
                      onChange={handleChange}
                    />
                    {<span>{inputValid.nome === false && 'Campo obrigatório'}</span>}
                  </div>
                </div>
                <div className="input-group-container">
                  <label><b>Material / Serviço</b></label>
                  <div className="input-group-span">
                    <div className="input-list-wrap">
                      <input
                        disabled={true}
                        type="text"
                        name="materialServices"
                        id="materialServices"
                        className="global-input"
                        onChange={handleChange}
                      />
                      <div className={"list-item d-inline-flex"}>
                        <span>{(dataListItemSelect.length > 0)
                          ? dataListItemSelect.map(item => item.name).join(', ')
                          : 'Escolha um material / serviço'}
                        </span>
                        <DropList
                          uniqid={'vincular-grupo'}
                          label="Escolha o(s) grupo(s)"
                          selectable
                          multSelect
                          defaultValue={(dataListItemSelect.length === 0) && []}
                          options={dataList}
                          selectedOption={handleGetSelectedOption}
                          active={(dropListSelect === 'vincular-grupo')}
                          onClick={handleDropList}
                        />
                      </div>
                    </div>
                    {<span>{inputValid.materialServices === false && 'Campo obrigatório'}</span>}
                  </div>
                </div>
                <div className="input-group-container">
                  <label><b>Contato</b></label>
                  <div className="input-group-span">
                    <input
                      className="global-input"
                      type="text"
                      id="contato"
                      value={data.contato}
                      name="contato"
                      readOnly={(data.cnpj == "" && data.matricula == "") || isNovoProvedor == true}
                      onChange={handleChange}
                    />
                    {<span>{inputValid.contato === false && 'Campo obrigatório'}</span>}
                  </div>
                </div>
                <div className="input-group-container">
                  <label><b>E-mail</b></label>
                  <div className="input-group-span">
                    <input
                      className="global-input"
                      type="text"
                      id="email"
                      value={data.email}
                      name="email"
                      readOnly={(data.cnpj == "" && data.matricula == "") || isNovoProvedor == true}
                      onChange={handleChange}
                      placeholder="Ex: email@email.com"
                    />
                    {<span>{inputValid.email === false && 'Forneça um e-mail válido'}</span>}
                  </div>
                </div>
                <div className="input-group-container">
                  <label><b>Telefone</b></label>
                  <div className="input-group-span">
                    <input
                      className="global-input"
                      type="text"
                      id="telefone"
                      value={data.telefone}
                      name="telefone"
                      readOnly={(data.cnpj == "" && data.matricula == "") || isNovoProvedor == true}
                      onChange={handleChange}
                      placeholder="Ex: (99) 99999-9999"
                    />
                    {<span>{inputValid.telefone === false && 'Formato de número inválido'}</span>}
                  </div>
                </div>
                <button
                  className="global-btn"
                  disabled={false}
                >
                  ENVIAR SOLICITAÇÃO
                  {onSubmitLoad && <FontAwesomeIcon icon={faCircleNotch} pulse />}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
