import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import ListNotificacao from "./List/MinhasNotificacoesProvedor"

import MinhasNotificacoesProvedorService from "../../services/MinhasNotificacoesProvedorService";

export default function Index(props) {
    const { token } = props.state;
    const [loading, setLoading] = useState(false);
    const [notificacoes, setNotificacoes] = useState([]);

    const get = async () => {
        // MinhasNotificacoesProvedorService.get(token)
        //   .then((response) => {
        //     setLoading(false);
        //   })
        //   .catch((error) => {
        //     setLoading(false);
        //   });
        setLoading(false);
    }

    useEffect(() => {
        setLoading(true);
        get();
    }, []);

    return (
        <div className="dashboard-container documentLicense-container">
            {loading && (
                <div className={"loading-mask"}>
                    <FontAwesomeIcon icon={faCircleNotch} pulse />
                </div>
            )}

            <ListNotificacao
                token={token}
                notificacoes={notificacoes}
            />
        </div>
    );
}