import React, { useState } from "react";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faEdit } from "@fortawesome/free-solid-svg-icons";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";

import DropList from "../DropList/DropList";

import "../../private-app/dashboard/dashboard.scss";

export default function ModalGeneric({ title, handleDelete, handleModal, alert, styles, children }) {
  

  return (
    <>
      <div className="modal-mask">
        <div className="modal-container" style={styles}>
          <div className="modal-header">
            <h1>{title ? title : 'Opa?'}</h1>
          </div>
          <div className="modal-body">
            {children}
          </div>
          

          {/* {!alert &&
            <div className="btn-container">
              <button className="global-white-btn" onClick={() => handleDelete()}>
                Sim
              </button>
            </div>
          } */}
        </div>
      </div>
    </>
  );
}
