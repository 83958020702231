import React, { useEffect, useRef, useState } from "react";

import RespostasService from "../../../../../services/RespostasService";
import Loading from "../../../../../components/Loading";
import ImgRobo from "../../../../../components/ImgRobo";
import InputRadio from "../../../../../components/InputRadio/InputRadio";
import ToolTip from "../../../../../components/ToolTip";
import Modal from "../../../../../components/ModalExit";

import { toast } from "react-toastify";

import './styles.scss';


const initialValues = {
    id: 0,
    valorResposta: "",
    comentarios: "",
    perguntaId: 0
}

export default function RespostaPergunta({ token, nomeEscopoAvaliacao, pergunta, handleViewRespostaPergunta, reload }) {
    const [loading, setLoading] = useState(false);
    const [respostaPergunta, setRespostaPergunta] = useState(initialValues);
    const [selectedFile, setSelectedFile] = useState([{ name: "" }]);
    const inputFile = useRef(null);
    const [arquivoDocAlterado, setArquivoDocAlterado] = useState(false)
    const [modal, setModal] = useState(false);
    const [modalMsg, setModalMsg] = useState('');

    const _init = async () => {
        setLoading(true);
        let response;
        if (pergunta.perguntaComprador) {
            response = await RespostasService.getByPerguntaId(token, pergunta.id);
        } else {
            response = await RespostasService.getByTextoPergunta(token, pergunta.descricao);
        }
        if (response)
            if (response.id == 0) {
                setRespostaPergunta(initialValues);
                setRespostaPergunta((values) => ({
                    ...values,
                    perguntaId: pergunta.id,
                }));
            }
            else {
                setRespostaPergunta(response);
                setSelectedFile([{ name: `${response.nomeArquivo ? response.nomeArquivo + response.extensaoArquivo : ""}` }]);
            }
        
        setLoading(false);
    }

    const handleChangeResposta = (e) => {
        e.persist();

        setRespostaPergunta((values) => ({
            ...values,
            valorResposta: e.target.value,
        }));
    };
    const handleChangeComentarios = (e) => {
        e.persist();

        setRespostaPergunta((values) => ({
            ...values,
            comentarios: e.target.value,
        }));
    };

    const handlerChangeFile = (event) => {
        const file = event.target.files[0]
        if (file) {

            const type = file.type
            if (type !== "application/pdf" && type !== "image/png" && type !== "image/jpeg" && type !== "image/jpg") {
                setModalMsg('Tipo de arquivo inválido, selecione um arquivo válido do tipo PDF, PNG ou JPG')
                setModal(true)
                return
            }

            if (file.size > 10000000) {
                console.log("passou aqui");
                setModalMsg('O arquivo deve ser igual ou menor a 10MB')
                setModal(true)
                return
            }

            if (event.target.id == "file") {
                setSelectedFile([file]);
                setArquivoDocAlterado(true);
                
            }

        }
    };

    const handleModal = (value) => {
        setModal(value);
    };

    const handleSubmit = async () => {
        setLoading(true);

        let editarArquivos = false;
        let temAnexo = false;
        const formData = new FormData();
        if (arquivoDocAlterado) {
            editarArquivos = true;
            formData.append("arquivoDoc", selectedFile[0]);
            temAnexo = true;
        }


        let response;
        if (respostaPergunta.id === 0) {
            response = await RespostasService.create(token, respostaPergunta, formData, temAnexo);
        }
        else {
            response = await RespostasService.update(token, respostaPergunta, formData, editarArquivos);
        }

        if (response) {
            if (response.response && response.response.status === 400) {
                toast.error(response.response.data);
                setLoading(false);
                return
            }

            toast.success("Resposta salva com sucesso!");
            setLoading(false);

        } else {
            toast.error("Erro ao salvar resposta!");
            setLoading(false);
        }
        reload();
    }


    useEffect(() => {
        _init()
    }, [])



    return (
        <>
            {modal &&
                <Modal
                    title={modalMsg}
                    handleDelete={null}
                    handleModal={handleModal}
                    alert
                />
            }
            <Loading loading={loading} />
            <div className="resultados-container">
                <div className="card-container">
                    <div className="card subcard">
                        <div className="card-header">
                            <h1>{nomeEscopoAvaliacao}</h1>
                        </div>
                        <div className="card-body">
                            <div className="list-container">
                                <div className="list">
                                    <div className="list-header" style={{ display: "block" }}>
                                        <label>FOCO NO CLIENTE</label>
                                    </div>
                                    <>

                                        <>
                                            <div className="card">
                                                <div className="card-body p-0">
                                                    <form className="form-container" >
                                                        <div className="group-input created-form m-0">
                                                            <div className="row equal">

                                                                <div className="list-body">
                                                                    <span className="pergunta"><strong>{pergunta.descricao}</strong></span>
                                                                </div>
                                                            </div>
                                                            <div className="row equal">
                                                                <div className="col-md-9 rowParcial">
                                                                    <div className="row">
                                                                        <div className="col-md-12 center">
                                                                            <div className="col-md-2"><label className="labelCampo">Resposta*</label></div>
                                                                            <div className="InputGroup col-md-10">
                                                                                <InputRadio name="sim" label="Sim" value="0" checked={respostaPergunta.valorResposta} onChange={handleChangeResposta} />
                                                                                <InputRadio name="parcial" label="Parcial" value="1" checked={respostaPergunta.valorResposta} onChange={handleChangeResposta} />
                                                                                <InputRadio name="nao" label="Não" value="2" checked={respostaPergunta.valorResposta} onChange={handleChangeResposta} />
                                                                                <InputRadio name="naoAplicavel" label="Não Aplicável" value="3" checked={respostaPergunta.valorResposta} onChange={handleChangeResposta} />
                                                                            </div>
                                                                        </div>

                                                                    </div>
                                                                    <div className="row">
                                                                        <div className="col-md-12 center">
                                                                            <div className="col-md-2"><label className="labelCampo">Comentários</label></div>
                                                                            <div className="col-md-10">
                                                                                <textarea
                                                                                    className="global-input"
                                                                                    id="comentarios"
                                                                                    value={respostaPergunta.comentarios}
                                                                                    name="comentarios"
                                                                                    onChange={(event) => {
                                                                                        handleChangeComentarios(event);
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div className="row  ">
                                                                        <div className="col-md-12 center ">
                                                                            <div className="col-md-2"><label htmlFor="fileAssociado" className="labelCampo">Upload*</label></div>
                                                                            <div className="" id="input-file-wrapp">
                                                                                <div className="upload">
                                                                                    <div onClick={() => inputFile.current.click()}
                                                                                        style={{
                                                                                            cursor: 'pointer'
                                                                                        }}
                                                                                    >
                                                                                        <svg width="54" height="54" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                                            <path fillRule="evenodd" clipRule="evenodd" d="M10 0C4.47715 0 0 4.47715 0 10V44C0 49.5228 4.47715 54 10 54H44C49.5228 54 54 49.5228 54 44V10C54 4.47715 49.5228 0 44 0H10ZM9.125 32C9.125 31.4477 8.67729 31 8.125 31C7.57272 31 7.125 31.4477 7.125 32V38.6667C7.125 39.889 7.72129 41.0087 8.6886 41.7983C9.65031 42.5834 10.9184 43 12.2083 43H40.7917C42.0816 43 43.3497 42.5834 44.3114 41.7983C45.2787 41.0087 45.875 39.889 45.875 38.6667V32C45.875 31.4477 45.4273 31 44.875 31C44.3227 31 43.875 31.4477 43.875 32V38.6667C43.875 39.2124 43.6109 39.7884 43.0466 40.249C42.4768 40.7142 41.6677 41 40.7917 41H12.2083C11.3323 41 10.5232 40.7142 9.95336 40.249C9.38913 39.7884 9.125 39.2124 9.125 38.6667V32ZM37.4826 20.9657C37.1333 21.3935 36.5034 21.4572 36.0755 21.108L27.4992 14.1069L27.4992 32.0013C27.4992 32.5536 27.0515 33.0013 26.4992 33.0013C25.9469 33.0013 25.4992 32.5536 25.4992 32.0013L25.4992 14.1075L16.9236 21.108C16.4958 21.4572 15.8658 21.3935 15.5166 20.9657C15.1673 20.5379 15.231 19.9079 15.6589 19.5587L25.8672 11.2253C26.2353 10.9249 26.7639 10.9249 27.132 11.2253L37.3403 19.5587C37.7681 19.9079 37.8318 20.5379 37.4826 20.9657Z" fill="#4068C8" />
                                                                                        </svg>
                                                                                        <input
                                                                                            type="file"
                                                                                            accept=".pdf,.jpg,.png"
                                                                                            id="file"
                                                                                            name="file"
                                                                                            className="file"
                                                                                            ref={inputFile}
                                                                                            onChange={handlerChangeFile}
                                                                                            style={{ display: "none" }}
                                                                                        />

                                                                                    </div>
                                                                                    <span>
                                                                                        Clique e selecione o arquivo em pdf, jpg ou png, somente serão aceitos documentos legíveis e com no máximo 10 MB de tamanho.
                                                                                    </span>
                                                                                    {<span>{selectedFile ? selectedFile[0].name : ""}</span>}
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div className="suporte">
                                                                        <div className="balaoAjuda"><ToolTip textoAjuda={pergunta.suporte} /></div>
                                                                        <div className="texto"><ImgRobo /></div>
                                                                        <div className="texto">SUPORTE</div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </>

                                    </>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row rowButton">
                    <div className="pull-left">
                        <button
                            type="button"
                            className="global-btn"
                            onClick={() => handleViewRespostaPergunta()}
                        >
                            <svg className="mr-1 back-icon" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1.47211 5.05021L5.10332 9.09061L4.31819 9.69667L0 5.05021L4.31819 0.302734L5.00518 1.00981L1.47211 5.05021ZM9.71593 1.00981L9.02894 0.302734L4.71075 5.05021L9.02894 9.69667L9.71593 8.9896L6.18286 5.05021L9.71593 1.00981Z" fill="white" />
                            </svg>
                            VOLTAR
                        </button>
                    </div>

                    <div className="pull-right">
                        <button
                            type="submit"
                            className="global-btn"
                            onClick={handleSubmit}
                        >
                            SALVAR
                        </button>
                    </div>
                </div>
            </div>

        </>
    )
}