import React from "react";

import "./styles.scss";

export default function ToolTip({textoAjuda}) {
    return (
        <div className="balao">
            {textoAjuda ? textoAjuda :"Esta pergunta não possui texto de ajuda cadastrado!"}
        </div>
    )
}