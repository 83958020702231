import React, { useState } from "react";

import "../../dashboard/dashboard.scss";
import List from "./List";
import Form from "../Form/Form";

export default function Index({ valueInitial, token, back }) {
  const [updated, setUpdated] = useState(false);
  const [current, setCurrentValue] = useState();

  const send = (value) => {
    if(back){
      back();
    }
    if (value) {
      if (updated)
        setUpdated(false)

      setCurrentValue(null);
      return setUpdated(true)
    }

    return;
  }

  const currentDocuments = (value) => {
    setCurrentValue(value)
  }

  return (
    <>
      <Form
        token={token}
        send={send}
        currentValue={current}
      />
      <List
        token={token}
        updated={updated}
        valueInitial={valueInitial}
        currentDocuments={currentDocuments}
        back={back}
      />
    </>
  );
}
