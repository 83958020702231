import React, { useEffect, useState, useRef } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import DropList from "../../../components/DropList/DropList";
import { toast } from "react-toastify";

import Modal from "../../../components/ModalExit";

import TipoCertificacaoService from "../../../services/TipoCertificacaoService";
import OrganismosCertificadoresService from "../../../services/OrganismosCertificadoresService";
import ProvedorCertificadoService from "../../../services/ProvedorCertificadoService";

import { ListCertificados, ListUser } from "../ListCertificados/listaCertificados";

export default function FormVizualize({
    token,
    valoresJaCadastradosOrganismosCertificadores,
    valoresJaCadastradosTipoCertificados,
    listMateriaisServicos,
    setDeleteID,
    setCurrent,
    initComponent,
    create,
    listUser,
    currentValue,
    handleRegister,
    handleUpdate
}) {
    const [modal, setModal] = useState(false);
    const [modalMsg, setModalMsg] = useState('');
    const [loading, setLoading] = useState(true);

    const [dataListTiposCertificados, setDataListTiposCertificados] = useState([]);
    const [dropListSelectTiposCertificados, setDropListSelectTiposCertificados] = useState(null);
    const [dataListItemSelectTiposCertificados, setDataListItemSelectTiposCertificados] = useState([]);

    const [dataListOrganismosCertificadores, setDataListOrganismosCertificadores] = useState([]);
    const [dropListSelectOrganismosCertificadores, setDropListSelectOrganismosCertificadores] = useState(null);
    const [dataListItemSelectOrganismosCertificadores, setDataListItemSelectOrganismosCertificadores] = useState([]);

    const [inputValid, setInputValid] = useState({ certificado: null, organismoCertificadorId: null, numeroCertificado: null, dataValidade: null, tipoCertificacaoId: null })
    const [certificadoAlterado, setCertificadoAlterado] = useState(false);

    const [selectedFile, setSelectedFile] = useState([{ name: "" }]);
    const [isToggled, setIsToggled] = useState(false);

    const [certificadoFile, setCertificadoFile] = useState(null);

    const [data, setData] = useState({
        id: 0,
        tipoCertificacaoId: 0,
        organismoCertificadorId: 0,
        dataValidade: "",
        numeroCertificado: 0,
        escopo: ""
    });

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!isToggled) {
            toast.error('É obrigatório confirmar a autorização!');
            return;
        }

        if (data) {
            if (data.tipoCertificacaoId === 0) {
                setInputValid(values => ({
                    ...values,
                    tipoCertificacaoId: false
                }))
                return
            }

            if (data.organismoCertificadorId === 0) {
                setInputValid(values => ({
                    ...values,
                    organismoCertificadorId: false
                }))
                return
            }

            if (data.numeroCertificado === 0 || data.numeroCertificado === "") {
                setInputValid(values => ({
                    ...values,
                    numeroCertificado: false
                }))
                return
            }

            if (data.dataValidade === "") {
                setInputValid(values => ({
                    ...values,
                    dataValidade: false
                }))
                return
            }

            if (selectedFile[0].name === "") {
                setInputValid(values => ({
                    ...values,
                    certificado: false
                }))
                return
            }

            let editarArquivos = false;
            const formData = new FormData();
            if (certificadoAlterado) {
                editarArquivos = true;
                formData.append("certificado", selectedFile[0]);
            }

            if (currentValue && currentValue.id > 0)
                return handleUpdate(editarArquivos, data, formData);

            handleRegister(data, formData);
        }
    }

    const handleGetSelectedOption = (option) => {
        const itemSelected = dataListTiposCertificados.find(item =>
            item.id === option ? { id: item.id, name: item.name } : null
        );

        const index = dataListItemSelectTiposCertificados.findIndex(item => item.id === option);

        dataListItemSelectTiposCertificados.splice(index, 1);
        dataListItemSelectTiposCertificados.push(itemSelected);
        setDataListItemSelectTiposCertificados([...dataListItemSelectTiposCertificados]);

        const docIds = dataListItemSelectTiposCertificados.map(item => (item.id));

        setData((values => ({
            ...values,
            tipoCertificacaoId: docIds[0]
        })));

        setInputValid(values => ({
            ...values,
            tipoCertificacaoId: true
        }));
    }

    const handleGetSelectedOptionOrganismosCertificadores = (option) => {
        const itemSelected = dataListOrganismosCertificadores.find(item =>
            item.id === option ? { id: item.id, name: item.name } : null
        );

        const index = dataListItemSelectOrganismosCertificadores.findIndex(item => item.id === option);

        dataListItemSelectOrganismosCertificadores.splice(index, 1);
        dataListItemSelectOrganismosCertificadores.push(itemSelected);
        setDataListItemSelectOrganismosCertificadores([...dataListItemSelectOrganismosCertificadores]);

        const docIds = dataListItemSelectOrganismosCertificadores.map(item => (item.id));

        setData((values => ({
            ...values,
            organismoCertificadorId: docIds[0]
        })));

        setInputValid(values => ({
            ...values,
            organismoCertificadorId: true
        }));
    }

    const handleDropListOrganismosCertificadores = (key) => {
        setDropListSelectOrganismosCertificadores((dropListSelectOrganismosCertificadores !== key) ? key : '')
    }

    const handleDropList = (key) => {
        setDropListSelectTiposCertificados((dropListSelectTiposCertificados !== key) ? key : '')
    }

    const handleModal = (value) => {
        setModal(value);
    };

    const handleChangenumeroCertificado = (event) => {
        event.persist();

        setData(values => ({
            ...values,
            numeroCertificado: event.target.value,
        }));

        setInputValid(values => ({
            ...values,
            numeroCertificado: true
        }))
    };

    const handleChangeData = (event) => {
        event.persist();

        const dateMask = () => {
            var data = event.target.value;

            if (data.length === 2) {
                return event.nativeEvent.inputType === 'deleteContentBackward'
                    ? data
                    : data = data + '/';
            }
            if (data.length === 5) {
                return event.nativeEvent.inputType === 'deleteContentBackward'
                    ? data
                    : data = data + '/';
            }

            return event.target.value;
        }

        setData(values => ({
            ...values,
            dataValidade: dateMask()
        }));

        setInputValid(values => ({
            ...values,
            dataValidade: true
        }))
    };

    const handleChangeEscopo = (event) => {
        event.persist();

        setData(values => ({
            ...values,
            escopo: event.target.value,
        }));
    };

    function getTiposCertificacao() {
        TipoCertificacaoService.get(token)
            .then(response => {
                setLoading(!loading)
                setDataListTiposCertificados(removeJaCadastradosTiposCertificados(response.valores));
            })
    }

    function getCertificado() {
        async function getCertificadoAsync() {
            const img = await ProvedorCertificadoService.download(token, currentValue.id).then(response => response);

            if (img) {
                const reader = new FileReader();
                reader.onloadend = function () {
                    setCertificadoFile(reader.result);
                }
                reader.readAsDataURL(img);
            } else {
                toast.error("Erro ao baixar logo!");
                setCertificadoFile(null);
            }    
        }
        getCertificadoAsync();
    }

    const buscaTiposCertificadosSelecionado = (id) => {
        let itemSelecionado = [];
        itemSelecionado = dataListTiposCertificados.map((item) => {
            if (id === item.id)
                return item;
        });

        itemSelecionado = itemSelecionado.filter((i) => {
            return i;
        });

        return itemSelecionado;
    }

    const buscaOrganismosCertificadoresSelecionado = (id) => {
        let itemSelecionado = [];
        itemSelecionado = dataListOrganismosCertificadores.map((item) => {
            if (id === item.id)
                return item;
        })
        itemSelecionado = itemSelecionado.filter((i) => {
            return i;
        })
        return itemSelecionado;
    }

    function getOrganismosCertificadores() {
        OrganismosCertificadoresService.getOrganismosCertificadores(token)
            .then(response => {
                setDataListOrganismosCertificadores(removeJaCadastradosOrganismosCertificadores(response.valores));
            });
    }

    const removeJaCadastradosTiposCertificados = (lista) => {
        let listaAjustada = [];
        let ids = [];
        ids = valoresJaCadastradosTipoCertificados.map((item) => {
            return item.tipoCertificacaoId;
        });

        if (currentValue) {
            listaAjustada = [];

            let atual = lista.filter((item) => {
                if (currentValue.tipoCertificacaoId == item.id)
                    return item;
            });

            listaAjustada.push(atual[0]);

            return listaAjustada;
        }

        listaAjustada = lista.filter((item) => {
            if (!ids.includes(item.id))
                return item;
        });

        return listaAjustada;
    }

    const removeJaCadastradosOrganismosCertificadores = (lista) => {
        let listaAjustada = [];
        let ids = [];

        ids = valoresJaCadastradosOrganismosCertificadores.map((item) => {
            return item.organismoCertificadorId;
        });

        if (currentValue) {
            listaAjustada = [];

            let atual = lista.filter((item) => {
                if (currentValue.organismoCertificadorId == item.id)
                    return item;
            });

            listaAjustada.push(atual[0]);

            return listaAjustada;
        }

        listaAjustada = lista.filter((item) => {
            if (!ids.includes(item.id))
                return item;
        });

        return listaAjustada;
    }

    useEffect(() => {
        getCertificado();
        getTiposCertificacao();
        getOrganismosCertificadores();

        if (currentValue && currentValue.id > 0)
            setIsToggled(true);
    }, []);

    useEffect(() => {
        if (currentValue) {
            setDataListItemSelectTiposCertificados(buscaTiposCertificadosSelecionado(currentValue.tipoCertificacaoId));
            setDataListItemSelectOrganismosCertificadores(buscaOrganismosCertificadoresSelecionado(currentValue.organismoCertificadorId));
        }
    }, [dataListTiposCertificados, dataListOrganismosCertificadores]);

    useEffect(() => {
        if (currentValue) {
            setData(currentValue);
            setSelectedFile([{ name: `${currentValue.nomeArquivo ? currentValue.nomeArquivo + currentValue.extensao : ""}` }])
        }
    }, [currentValue]);

    return (
        <>
            {modal &&
                <Modal
                    title={modalMsg}
                    handleDelete={null}
                    handleModal={handleModal}
                    alert
                />
            }
            <div>
                <div className="card-container">
                    <div className="card">
                        <div className="card-header">
                            <h1>CERTIFICADO</h1>
                        </div>
                        {loading && (
                            <div className={"loading-mask"}>
                                <FontAwesomeIcon icon={faCircleNotch} pulse />
                            </div>
                        )}
                        <div className="card-body">
                            <form className="form-container" style={{ flexDirection: 'column', }} onSubmit={handleSubmit}>
                                <div className="group-input created-form">
                                    <div className="input-group-container">
                                        <div className="content-input" style={{ width: "50%" }}>
                                            <label htmlFor="tipoCertificado" style={{ maxwidth: "50px !important" }}>Qual?</label>
                                            <div className="input-group-span">
                                                <div className="input-list-wrap">
                                                    <input
                                                        disabled={true}
                                                        type="text"
                                                        name="tipoCertificado"
                                                        id="tipoCertificado"
                                                        className="global-input"
                                                    />
                                                    <div className={"list-item d-inline-flex"}>
                                                        <span>{(dataListItemSelectTiposCertificados.length > 0)
                                                            ? dataListItemSelectTiposCertificados[0].nome
                                                            : 'Escolha um tipo certificação cadastrada no sistema'}
                                                        </span>
                                                        <DropList
                                                            uniqid={'tipoCertificado'}
                                                            label="Qual"
                                                            selectable
                                                            defaultValue={dataListItemSelectTiposCertificados.length === 0 ? [] : dataListItemSelectTiposCertificados[0].id}
                                                            options={dataListTiposCertificados}
                                                            selectedOption={handleGetSelectedOption}
                                                            active={(dropListSelectTiposCertificados === 'tipoCertificado')}
                                                            onClick={handleDropList}
                                                        />
                                                    </div>
                                                </div>
                                                {<span>{inputValid.tipoCertificacaoId === false && 'Campo obrigatório'}</span>}
                                            </div>
                                        </div>
                                        <div className="content-input" style={{ width: "100%" }}>
                                            <label htmlFor="nome" style={{ maxwidth: "50px !important" }}>Organismo certificador</label>
                                            <div className="input-group-span">
                                                <div className="input-list-wrap">
                                                    <input
                                                        disabled={true}
                                                        type="text"
                                                        name="organismo"
                                                        id="organismo"
                                                        className="global-input"
                                                    />
                                                    <div className={"list-item d-inline-flex"}>
                                                        <span style={{ width: "100%" }}>{(dataListItemSelectOrganismosCertificadores.length > 0)
                                                            ? dataListItemSelectOrganismosCertificadores[0].name
                                                            : 'Escolha um organismo certificador cadastrado no sistema'}
                                                        </span>
                                                        <DropList
                                                            uniqid={'organismo'}
                                                            label="Organismo certificador"
                                                            selectable
                                                            defaultValue={dataListItemSelectOrganismosCertificadores.length === 0 ? [] : dataListItemSelectOrganismosCertificadores[0].id}
                                                            options={dataListOrganismosCertificadores}
                                                            selectedOption={handleGetSelectedOptionOrganismosCertificadores}
                                                            active={(dropListSelectOrganismosCertificadores === 'organismo')}
                                                            onClick={handleDropListOrganismosCertificadores}
                                                        />
                                                    </div>
                                                </div>
                                                {<span>{inputValid.organismoCertificadorId === false && 'Campo obrigatório'}</span>}
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <div style={{ display: 'flex' }}>
                                    <div style={{ width: "50%" }}>
                                        <div className="group-input created-form" style={{ borderBottom: " 1px solid #D1D1D1", width: '100%', justifyContent: 'none' }}>
                                            <div className="input-group-container" style={{ borderBottom: "none" }}>
                                                <div className="content-input required">
                                                    <label htmlFor="numeroCertificado">Número do certificado</label>
                                                    <div className="input-group-span">
                                                        <div className="input-list-wrap">
                                                            <input
                                                                className="global-input"
                                                                type="text" asdasda
                                                                id="numeroCertificado"
                                                                placeholder=""
                                                                name="numeroCertificado"
                                                                value={data.numeroCertificado}
                                                                onChange={handleChangenumeroCertificado}
                                                            />
                                                            {<span>{inputValid.numeroCertificado === false && 'Campo obrigatório'}</span>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="input-group-container" style={{ borderBottom: "none" }}>
                                                <div className="content-input required" style={{ width: "245px" }}>
                                                    <label
                                                        htmlFor="data"
                                                        style={{
                                                            marginRight: '0px',
                                                            marginLeft: "0px"
                                                        }}
                                                    >Data de Validade</label>
                                                    <input
                                                        style={{ width: "115px" }}
                                                        className="global-input"
                                                        type="text"
                                                        id="data"
                                                        placeholder="--/--/----"
                                                        name="data"
                                                        maxLength="10"
                                                        value={data.dataValidade}
                                                        onChange={handleChangeData}
                                                    />
                                                    {<span>{inputValid.dataValidade === false && 'Campo obrigatório'}</span>}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="group-input created-form" style={{ width: '100%' }}>
                                            <div className="input-group-container">
                                                <div className="content-input" style={{ width: "100%" }}>
                                                    <label htmlFor="escopo">Escopo</label>
                                                    <textarea
                                                        className="global-input"
                                                        type="textarea"
                                                        id="escopo"
                                                        placeholder=""
                                                        name="escopo"
                                                        value={data.escopo}
                                                        onChange={handleChangeEscopo}
                                                        style={{
                                                            width: '200%',
                                                            marginLeft: 30
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                        <div className="group-input created-form" style={{ width: "100%" }}>
                                            <div className="input-group-container" style={{ height: '205px', display: 'flex', alignItems: 'baseline' }}>

                                                <div className="content-input" style={{ width: "100%" }}>
                                                    <label htmlFor="observacao">Informação</label>
                                                    <textarea
                                                        disabled={true}
                                                        className="global-input"
                                                        type="textarea"
                                                        id="observacao-black"
                                                        placeholder="Estou ciente e autorizo a verificação de autenticidade deste certificado, que visa evitar qie certificados falsos ou adulterados sejam utilizados. Estou ciente de que esta 
                                                            prática é ilegal e configura crime de falsidade ideológica (Art. 299 do Código Penal - Decreto Lei 2848/40)."
                                                        name="observacao"
                                                        style={{
                                                            width: '200%',
                                                            marginLeft: 30,
                                                            backgroundColor: '#e3e3e3',
                                                            color: "black",
                                                            height: "90px"
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="container-certificado-wraper">
                                        <div className='container-certificado'>Certificado Enviado</div>
                                        <div className='container-validado'>Certificado Enviado</div>
                                        <div className='container-flutuante' >
                                            {currentValue.extensao === '.pdf' ?
                                                <object data={certificadoFile} style={{height: '100%', width: '100%'}} type="application/pdf" />
                                            :
                                            <img src={certificadoFile} style={{height: '100%', width: '100%'}} alt="Arrow Down" ></img>

                                            }
                                            </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-6">
                        <ListCertificados
                            token={token}
                            list={listMateriaisServicos}
                            include={create} />
                    </div>
                    <div className="col-md-6">
                        <ListUser
                            token={token}
                            loading={loading}
                            list={listUser}
                            remove={setDeleteID}
                            edit={setCurrent}
                        />
                    </div>
                </div>
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: 40 }} className="col-12 px-0 justify-content-between">
                <button
                    style={{ width: 'auto' }}
                    type="button"
                    className="global-btn w-auto"
                    disabled={loading && true}
                    onClick={() => { initComponent() }}
                >
                    <svg className="mr-1" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1.47211 5.05021L5.10332 9.09061L4.31819 9.69667L0 5.05021L4.31819 0.302734L5.00518 1.00981L1.47211 5.05021ZM9.71593 1.00981L9.02894 0.302734L4.71075 5.05021L9.02894 9.69667L9.71593 8.9896L6.18286 5.05021L9.71593 1.00981Z" fill="white" />
                    </svg>
                    VOLTAR
                </button>
                <button
                    style={{ width: 'auto' }}
                    type="button"
                    className="global-btn w-auto"
                    disabled={loading && true}
                    onClick={handleSubmit}
                >
                    {(currentValue && currentValue.id > 0) ? "SALVAR" : "CRIAR"}
                    {loading && <FontAwesomeIcon icon={faCircleNotch} pulse />}
                </button>
            </div>
        </>
    )
}