import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  ESCOPO_AVALIACAO: BASE_URL + "EscopoAvaliacao",
};

export default class EscopoAvaliacaoService {
  static registerEscopoAvaliacao = async (token, escopoAvaliacao) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let body = {
      nome: escopoAvaliacao.nome,
      aprovado: +escopoAvaliacao.aprovado,
      aprovadoComRestricao: +escopoAvaliacao.aprovadoComRestricao
    }

    return await axios.post(URLS.ESCOPO_AVALIACAO, body, config);
  };

  static getEscopoAvaliacoes = async (token, idGrupo = 0) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        },
      };
      
    let url = `${URLS.ESCOPO_AVALIACAO}?idGrupo=${idGrupo}`;

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static updateEscopoAvaliacao = async (token, escopoAvaliacao) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
        
    let url = URLS.ESCOPO_AVALIACAO
    return await axios.put(url, escopoAvaliacao, config);
  }

  static updateListEscopoAvaliacao = async (token, escopoAvaliacao) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
        
    let url = URLS.ESCOPO_AVALIACAO + "/List";
    return await axios.post(url, escopoAvaliacao, config);
  }

  static deleteEscopoAvaliacao = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
      
    let url = URLS.ESCOPO_AVALIACAO + "/" + id

    try {
      const { data } = await axios.delete(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };
}