import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  ESCOPO_AVALIACAO_PERGUNTAS: BASE_URL + "Perguntas",
};

export default class EscopoAvaliacaoPerguntas {
  static create = async (token, escopoAvaliacao) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data } = await axios.post(URLS.ESCOPO_AVALIACAO_PERGUNTAS, escopoAvaliacao, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static get = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        },
      };
        
    let url = URLS.ESCOPO_AVALIACAO_PERGUNTAS

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getByComprador = async (token, idEscopoAvaliacao) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        },
      };
        
    let url = URLS.ESCOPO_AVALIACAO_PERGUNTAS + "/" + "byComprador" + "/" + "escopoAvaliacao" + "/" + idEscopoAvaliacao

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getByCompradorEscopo = async (token, idEscopoAvaliacao, compradorId) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        },
      };
        
    let url = `${URLS.ESCOPO_AVALIACAO_PERGUNTAS}/GetByCompradorEscopo?idEscopoAvaliacao=${idEscopoAvaliacao}&compradorId=${compradorId}`

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getById = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        },
      };
        
    let url = URLS.ESCOPO_AVALIACAO_PERGUNTAS + "/" + id;

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static update = async (token, escopoAvaliacao) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
        
    let url = URLS.ESCOPO_AVALIACAO_PERGUNTAS
    try {
      const { data } = await axios.put(url, escopoAvaliacao, config);
      return data;
    } catch (err) {
      return err;
    }
  }

  static updateOrdem = async (token, escopoAvaliacao) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
        
    let url = URLS.ESCOPO_AVALIACAO_PERGUNTAS + "/" + "alterarOrdem"
    try {
      const { data } = await axios.put(url, escopoAvaliacao, config);
      return data;
    } catch (err) {
      return err;
    }
  }

  static delete = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
      
    let url = URLS.ESCOPO_AVALIACAO_PERGUNTAS + "/" + id

    try {
      const { data } = await axios.delete(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };
}