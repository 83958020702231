import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

export default function Loading({loading}) {

    return (
        <>
        {loading && (
            <div className={"loading-mask"}>
              <FontAwesomeIcon icon={faCircleNotch} pulse />
            </div>
          )}
        </>
    );
}