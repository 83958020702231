import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  URL: BASE_URL + "ProvedorNotificacaoRespostaProvedor",
};

export default class NotificacaoProvedoresAnaliseService {
  static create = async (token, notificacaoId, payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    
    try {
      const { data } = await axios.post(URLS.URL + '/' + notificacaoId + '/analise-critica', payload, config);
      return data;
    } catch (err) {
      return err;
    }
  };
}