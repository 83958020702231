import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

// import BuyerService from "../../../services/BuyerService";

export default function UserModule({
  loading,
  token,
  initicialModules,
  initicialUser,
  handleModule,
  name,
}) {
  const [modules, setModules] = useState(initicialModules);
  const [user, setUser] = useState(initicialUser);
  // const [isToggled, setIsToggled] = useState(false);

  // const onToggle = () => setIsToggled(!isToggled);

  useEffect(() => {
    setModules(initicialModules);
    // eslint-disable-next-line
  }, [initicialModules]);

  useEffect(() => {
    setUser(initicialUser);
    // eslint-disable-next-line
  }, [initicialUser]);

  return (
    <>
      <div className="user-module-container">
        {name && (
          <div className="user-module-header-container">
            <div className="user-module-header-content">
              <label>Nome:</label>
              <label className="user-module-header-item">{user.name}</label>
            </div>
          </div>
        )}

        <div className="card-container">
          <div className="card">
            <div className="card-header">
              <h1>Módulos disponíveis do usuário b</h1>
            </div>
            <div className="card-body">
              <form className="form-container">
                {modules &&
                  modules.length &&
                  modules.map((m, index) => (
                    <div
                      className="group-input spaced-btw switch-container"
                      key={m.id + index}
                    >
                      <label style={{ minWidth: "70%" }}>{m ? m.profiles[0] ? `(${m.profiles[0].profile.name}) ${m.name}` : m.name : m.name}</label>
                      <label className="toggle-switch">
                        <input
                          type="checkbox"
                          checked={m.active ? m.active : false}
                          value={m.active || false}
                          onChange={() => handleModule(m.id, m.active)}
                        />
                        <span className="switch">
                          {m.active ? "Ativo" : "Inativo"}
                        </span>
                      </label>
                    </div>
                  ))}

                <button className="global-btn">
                  SALVAR{" "}
                  {loading && <FontAwesomeIcon icon={faCircleNotch} pulse />}
                </button>
              </form>
            </div>
          </div>
        </div>
        {/* <div className="back-container">
          <button
            className="global-btn"
            onClick={() => {
              history.goBack();
            }}
          >
            <FontAwesomeIcon icon={faAngleDoubleLeft} /> VOLTAR{" "}
            {loading && <FontAwesomeIcon icon={faCircleNotch} pulse />}
          </button>
        </div> */}
      </div>
    </>
  );
}
