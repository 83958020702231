import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import EscopoAvaliacaoService from "../../../../services/EscopoAvaliacaoService";
import { toast } from "react-toastify";

export default function EscopoAvaliacaoForm({
  token,
  handleEditEscopo,
  currentEscopoAvaliacao,
  item,
  handleItem,
  back,
  embed
}) {
  const [loading, setLoading] = useState(false);
  const [sending, setSending] = useState(false);
  const [escopoAvaliacao, setEscopoAvaliacao] = useState(currentEscopoAvaliacao);

  const handleChange = (e, i) => {
    e.persist();

    setEscopoAvaliacao((values) => {
      let newArray = {...values};
      newArray.escoposAvaliacao[i] = {
        ...newArray.escoposAvaliacao[i], 
        ...{
          [e.target.name]: parseInt(e.target.value) > 100 ? 100 : parseInt(e.target.value),
        }
      }

      return newArray;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    
    if(currentEscopoAvaliacao.id) {
      handleUpdate(currentEscopoAvaliacao.escoposAvaliacao);
    }
  };

  const handleUpdate = (payload) => {
    setLoading(true);
    EscopoAvaliacaoService.updateListEscopoAvaliacao(token, payload)
      .then((response) => {
        if (response) {
          toast.success("Escopos de avaliação atualizados com sucesso!");
          setLoading(false);
          
          if (handleEditEscopo)
            handleEditEscopo();
        }
      })
      .catch((reason) => {
        let response = reason.response || {};
        toast.error(response.data || "Erro ao cadastrar item!");
        setLoading(false);
      });
  };
  useEffect(() => {
    if (currentEscopoAvaliacao) {
      setEscopoAvaliacao(currentEscopoAvaliacao);
    }
  }, [currentEscopoAvaliacao]);
  const history = useHistory();

  return (
    <>
      <div className="user-config-container module-EscopoAvaliacaoComprador">
        <div className="card-container pnew">
          <div className="card">
            <div className="card-header">
              <h1>{escopoAvaliacao.nome}</h1>
            </div>
            <div className="card-body card-newpading">
              <form className="form-container" onSubmit={handleSubmit}>
                <div className="row mx-0">
                  {(escopoAvaliacao.escoposAvaliacao || []).map((elem, i) => (
                    <div className="col-md-4 col-xl-3 justify-content-between" key={i}>
                      <div className="card-container">
                        <div className="card subcard">
                          <div className="card-header">
                            <h1>{elem.nome}</h1>
                          </div>
                          <div className="card-body">
                            <div className="group-input escopo-avaliacao created-form">
                              <div className="row">
                                <div className="col-md-6">
                                  <label>Aprovado</label>
                                </div>
                                <div className="col-md-6">
                                  <input
                                    className="global-input"
                                    type="number"
                                    style={{ width: "60px" }}
                                    id="aprovado"
                                    placeholder=""
                                    name="aprovado"
                                    value={elem.aprovado}
                                    onChange={(e) => handleChange(e, i)}
                                  />
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-6">
                                  <label>Aprovado com Restrição </label>
                                </div>
                                <div className="col-md-6">
                                  <input
                                    className="global-input"
                                    type="number"
                                    id="aprovadoComRestricao"
                                    placeholder=""
                                    name="aprovadoComRestricao"
                                    value={elem.aprovadoComRestricao}
                                    onChange={(e) => handleChange(e, i)}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="card-footer">
                            <button 
                              type="button"
                              className={`global-btn ${item == elem && 'active'}`}
                              disabled={false}
                              onClick={() => {handleItem(elem)}}
                            >
                              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19 0H5C2.3 0 0 2.2 0 5V19C0 21.7 2.2 24 5 24H19C21.8 24 24 21.8 24 19V5C24 2.3 21.8 0 19 0ZM21.3 12.3L21.2 12.4C20.1 14 18.6 15.3 16.9 16.3C13.9 18 10.1 18 7.1 16.3C5.4 15.3 3.9 14 2.8 12.4L2.7 12.3C2.6 12.1 2.6 11.8 2.7 11.6L2.8 11.5C3.9 10 5.4 8.7 7.1 7.7C10.1 6 13.9 6 16.9 7.7C18.6 8.7 20.1 10 21.2 11.6L21.3 11.7C21.5 11.8 21.5 12.2 21.3 12.3ZM12 13.2C11.4 13.2 10.9 12.7 10.9 12.1C10.9 11.5 11.4 11 12 11C12.6 11 13.1 11.5 13.1 12.1C13.1 12.7 12.6 13.2 12 13.2ZM12 8.7C10.2 8.7 8.7 10.2 8.7 12C8.7 13.8 10.2 15.3 12 15.3C13.9 15.4 15.4 13.9 15.3 12C15.3 10.2 13.8 8.7 12 8.7Z" fill="white"/>
                              </svg>
                              Itens de auto avaliação
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {((!item && embed) || !embed) && (
                <div className="row mx-0">
                  <div className={`col-md-12 ${!embed ? 'justify-content-between' : 'justify-content-end'}`}>
                    {!embed && (
                      <button 
                        type="button"
                        className={`global-btn w-auto`}
                        disabled={false}
                        onClick={back}
                      >
                        <svg className="mr-1" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M1.47211 5.05021L5.10332 9.09061L4.31819 9.69667L0 5.05021L4.31819 0.302734L5.00518 1.00981L1.47211 5.05021ZM9.71593 1.00981L9.02894 0.302734L4.71075 5.05021L9.02894 9.69667L9.71593 8.9896L6.18286 5.05021L9.71593 1.00981Z" fill="white"/>
                        </svg>
                        VOLTAR
                      </button>
                    )}
                    {escopoAvaliacao.id && <button 
                      type="button"
                      className="global-btn w-auto"
                      disabled={false}
                      onClick={handleSubmit}
                    >
                      {sending ? '' : "SALVAR"}
                      {sending && <FontAwesomeIcon icon={faCircleNotch} pulse />}
                    </button>}
                  </div>
                </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
