import React, { useEffect, useState } from "react";

import { Redirect } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEdit,
  faSortDown,
  faCircleNotch,
  faSearch,
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from "@fortawesome/free-solid-svg-icons";


import Pagination from "react-js-pagination";
import UserService from "../../../services/UserService";

export default function ListUsers({ initialUsers, token, filter, editManageAccess }) {
  const [opened, setOpened] = useState("");
  const [users, setUsers] = useState(initialUsers);
  const [currentUser, setCurrentUser] = useState({});

  const [activePage, setActivePage] = useState(1);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const getUser = (token, id) => {
    UserService.getUser(token, id)
      .then((response) => {
        if (response) {
          setCurrentUser(response);
        }
      })
      .catch((error) => {
        // setError(true);
      });
  };

  const getUsers = (filter) => {
    setLoading(true);
    UserService.getUsers(token, activePage, filter)
      .then((response) => {
        if (response) {
          setTotal(response.total);
          if (response.valores.length > 0) {
            setUsers(response.valores);
          }
        }

        setLoading(false);
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getUsers(filter);

    // eslint-disable-next-line
  }, [activePage, filter]);

const handlePageChange = (pageNumber, newFilter) => {
  setActivePage(pageNumber);
  getUsers(newFilter, pageNumber);
};


  const handleBox = (value) => {
    let item = value;

    if (opened === item) {
      setOpened("");
    } else {
      setOpened(value);
      getUser(token, item);
    }
  };

  return (
    <>
      <div className="user-list-container">
        {loading && (
          <div className={"loading-mask"}>
            <FontAwesomeIcon icon={faCircleNotch} pulse />
          </div>
        )}

        <div className="list-container">
          <div className="list">
            <div className="list-header">
              <label>Nome</label>
              <label>Empresas</label>
              <label>Quantidade de módulos</label>
              <label>Editar</label>
            </div>

            {users &&
              users.length > 0 &&
              users.map((user, index) => (
                <div className="list-body" key={index}>
                  <span className="list-item">{user.name}</span>
                  <span className="list-item">
                    {user && user.company && user.company.name}
                  </span>
                  <span
                    className="list-item"
                    // onClick={() => setMenuState(!menuActive)}
                    onClick={() => handleBox(user.id)}
                  >
                    {user && user.modules && user.modules.length} modulos{" "}
                    <FontAwesomeIcon
                      icon={faSortDown}
                      className="narrow-icon"
                    />
                    <ul
                      className={`dropdown-options ${
                        user.id === opened ? "" : "hide"
                      }`}
                    >
                      {currentUser &&
                        currentUser.modules &&
                        currentUser.modules.length > 0 &&
                        currentUser.modules.map((u, idx) => (
                          <li key={idx}>{u && u.module && u.module.name}</li>
                        ))}
                    </ul>
                  </span>
                  
                  <span className="list-item">
                    <button onClick={() => editManageAccess({ editType: "editar-gestao-de-acesso-user", id: user.id })}>
                    <svg 
                      width="24" 
                      height="24" 
                      viewBox="0 0 24 24" 
                      fill="none" 
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path 
                        d="M19.2006 5.55627L18.1167 4.63023C17.4974 4.09003 16.6458 4.1672 16.1038 4.7074L5.49738 15.0482C5.41996 15.1254 5.26513 15.2797 5.26513 15.4341L4.25867 19.2154C4.18126 19.5241 4.41351 19.7556 4.72319 19.6785L8.43932 18.6752C8.59416 18.5981 8.749 18.5209 8.82642 18.4437L19.3554 7.71704C19.8974 7.09968 19.8974 6.17363 19.2006 5.55627ZM5.18771 18.8296L5.88448 16.2058L7.74255 18.135L5.18771 18.8296ZM8.43932 17.7492L6.19416 15.4341L13.9361 7.94855L16.0264 10.0322L8.43932 17.7492ZM16.6458 9.41479L14.5554 7.33119L15.0974 6.791L17.1877 8.8746L16.6458 9.41479ZM18.7361 7.17685L17.8071 8.18006L15.7942 6.17363L16.6458 5.32476C16.878 5.09325 17.2651 5.09325 17.5748 5.32476L18.7361 6.32797C19.0458 6.48231 19.0458 6.94534 18.7361 7.17685ZM7.74255 15.5884C7.58771 15.7428 7.58771 16.0514 7.74255 16.283C7.89738 16.4373 8.20706 16.4373 8.43932 16.283L14.478 10.2637C14.6329 10.1093 14.6329 9.80064 14.478 9.56913C14.3232 9.41479 14.0135 9.41479 13.7813 9.56913L7.74255 15.5884ZM24.0006 17.1318C24.0006 20.9132 20.9813 24 17.1103 24H6.89093C3.09738 24 0.000610352 20.9904 0.000610352 17.1318V6.86817C0.000610352 3.08682 3.01996 0 6.89093 0H17.1877C20.9038 0 24.0006 3.00965 24.0006 6.86817V17.1318Z" 
                        fill="#4B4B4B"
                      />
                    </svg>
                    </button>
                  </span>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="user-list-pagination">
        <div className="user-list-pagination-item">
          {users && users.length > 0 && (
            <p>
              Mostrando {activePage * 10 > total ? total : activePage * 10} de {total} usuários
            </p>
          )}
        </div>
        <div className="user-list-pagination-item">
          <Pagination
            activePage={activePage}
            itemsCountPerPage={10}
            totalItemsCount={total}
            onChange={(pageNumber) => handlePageChange(pageNumber, filter)}
            itemClass="page-item"
            linkClass="page-link"
            prevPageText="<< Anterior"
            nextPageText="Próxima >>"
            hideFirstLastPages={true}
          />
        </div>
      </div>
    </>
  );
}
