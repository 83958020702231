import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  URL: BASE_URL + "ProvedorNotificacao",
  UPLOAD_DOCUMENTO: BASE_URL + "ProvedorNotificacaoRespostaProvedor",
};

export default class NotificacaoProvedoresRespostaService {
  static create = async (token, notificacaoId, payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data } = await axios.post(URLS.URL + '/' + notificacaoId + '/resposta-provedor', payload, config);
      return data
    } catch (error) {
      return error
    }
  };

  static upload = async (token, id, archive, tipoDocumento) => {
    let config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${token}`,
      },
    }

    try {
      let url = `${URLS.UPLOAD_DOCUMENTO}/${id}/documento/${tipoDocumento}`

      const { data } = await axios.post(url, archive, config)
      return data
    } catch (err) {
      return err;
    }
  };

}