import React, { useEffect, useState, memo } from "react";

import InputRadio from "../../../../components/InputRadio/InputRadio";

function DocumentLicenseComponent({ current, documents, change }) {
    const [options, setOptions] = useState(documents);

    const handleChange = (e) => {
        e.persist();

        let newArray = [...options];
        let indexKey = parseInt(e.target.name.toString().replace('docs-', ''));
        newArray[indexKey] = {
            ...newArray[indexKey],
            ...{
                enableGroupMaterial: (e.target.value == '1' ? true : false)
            }
        }

        setOptions(newArray);
        change(newArray);
    };

    const checkEnable = (id) => {
        return current.tiposDocumentosLicencas.find(elem => (elem.id === id)) || false;
    }

    useEffect(() => {
        if (documents) {
            setOptions(documents.map((document) => (
                { ...document, ...{ enableGroupMaterial: (checkEnable(document.id)) ? true : false } }
            )));
        }
    }, [documents]);

    const randomKey = () => {
        const key = (Math.random()*0xFFFFFF<<0).toString(16) 
        return `${key}`
    }

    return (
        <>
            <div className="card-container">
                <div className="card">
                    <div className="card-header">
                        <h1>Documentos e licenças a vincular ao grupo</h1>
                    </div>
                    <div className="card-body">
                        <section className="form-container">
                            <div className="group-input m-0">
                                <div className="d-flex flex-wrap">
                                    <div className="row equal">
                                        {(options || []).map((data, i) => (
                                            <div className="col-md-12 col-Itens" key={randomKey()}>
                                                <label className="itemName">{data.nome}</label>
                                                <div className="InputRadioGroup">
                                                    <InputRadio
                                                        key={randomKey()}
                                                        name={`docs-${i}`}
                                                        label="Sim"
                                                        value="1"
                                                        checked={(data.enableGroupMaterial === true) ? '1' : data.enableGroupMaterial}
                                                        onChange={handleChange}
                                                    />
                                                    <InputRadio
                                                        key={randomKey()}
                                                        name={`docs-${i}`}
                                                        label="Não"
                                                        value="0"
                                                        checked={!data.enableGroupMaterial ? '0' : data.enableGroupMaterial}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    );
}

export default memo(DocumentLicenseComponent)