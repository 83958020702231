import React, { useState } from "react";

import './styles.scss'
import AbaDocumentosLicencas from "./AbaDocumentosLicencas";
import AbaRelacaoCertificados from "./AbaRelacaoCertificados";
import AbaInformacoesDocumentadas from "./AbaInformacoesDocumentadas";
import CardInfoHorizontal from "../../components/CardInfoHorizontal";

export default function DocumentosLicencas({ token, provedor }) {

    const [selectedTab, setSelectedTab] = useState(0);

    return (
        <div className="documentos-licencas-container">

            <CardInfoHorizontal
                data01={{ title: 'DOCUMENTOS CADASTRADOS', value: provedor && provedor.documentoLicencas && provedor.documentoLicencas.documentosCadastrados || 0 }}
                data02={{ title: 'DOC. E LICENÇAS VIGENTES', value: provedor && provedor.documentoLicencas && provedor.documentoLicencas.documentosLicencasVigentes || 0 }}
                data03={{ title: 'ÍNDICE', value: provedor && provedor.documentoLicencas && provedor.documentoLicencas.indice || 0 }}
            />

            <div className="nav-list-container row">
                <div className="nav-list-button col-sm-4" style={{ zIndex: 3 }} >
                    <button className={`${selectedTab === 0 ? 'selected' : selectedTab === 2 ? 'bg-light' : ''}`}
                        onClick={() => setSelectedTab(0)}
                    >
                        <h1>Documentos e Licenças</h1>
                    </button>

                </div>

                <div className="nav-list-button col-sm-4" style={{ zIndex: 2, marginLeft: -20 }} >
                    <button className={`${selectedTab === 1 ? 'selected' : ''}`}
                        onClick={() => setSelectedTab(1)}
                    >
                        <h1 style={{ marginLeft: 20 }}>Informações Documentadas</h1>
                    </button>

                </div>

                <div className="nav-list-button col-sm-4" style={{ zIndex: 1, marginLeft: -40 }}>
                    <button className={`${selectedTab === 2 ? 'selected' : selectedTab === 0 ? 'bg-light' : ''}`}
                        onClick={() => setSelectedTab(2)}
                    >
                        <h1 style={{ marginLeft: 40 }}>Relação de Certificados</h1>
                    </button>

                </div>


            </div>

            <div className="tabs row">
                {selectedTab === 0
                    ? <>
                        <AbaDocumentosLicencas provedor={provedor}/>
                    </>
                    : selectedTab === 1
                        ? <>
                            <AbaInformacoesDocumentadas provedor={provedor} />
                        </>
                        : <>
                            <AbaRelacaoCertificados  provedor={provedor} />
                        </>}
            </div>

        </div>
    )
}