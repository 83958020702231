import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  PROFILES: BASE_URL + "profiles",
};

export default class ProfileService {
  static getProfiles = async (token, page, nome) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.PROFILES + "?Pagination=" + page
    
    if (nome)
      url = url + "&Nome=" + nome

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getTotalProfiles = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.PROFILES + "/total";

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getProfile = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.PROFILES + "/" + id;

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };
}
