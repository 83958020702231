import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import "../dashboard/dashboard.scss";

import CreateCriteria from "../../components/CreateCriteria";

import AuditService from "../../services/AuditService"

export default function Index(props) {
  const { token } = props.state;
  const [audit, setAudit] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const getAudit = () => {
    AuditService.getAudit(token)
      .then((response) => {
        setAudit(response);
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
      });
  };

  useEffect(() => {
    getAudit();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <div className="dashboard-container">
        {loading && (
          <div className={"loading-mask"}>
            <FontAwesomeIcon icon={faCircleNotch} pulse />
          </div>
        )}
          <h1>Critério de avaliação</h1>
          <CreateCriteria 
            audit={audit}
            error={error}
          />
      </div>
    </>
  );
}
