import React, { memo, useState, useEffect } from "react";

import { Bar, defaults } from 'react-chartjs-2';

import DropList from "../../../../../components/DropList/DropList";

defaults.plugins.legend.position = 'bottom'

function ChartResult() {
    const options = [
        {
            id: 1,
            name: 'Material 1'
        },
        {
            id: 2,
            name: 'Material 2'
        },
        {
            id: 3,
            name: 'Material 3'
        }
    ]

    const response = [
        {
            idMaterial: 1,
            labels: [
                "Saúde e Segurança",
                "Gestão Ambiental",
                "Segurança de Alimentos",
                "Gestão de Qualidade",
            ],
            resultado: [80, 85, 90, 95],
            meta: [20, 80, 85, 92]
        },
        {
            idMaterial: 2,
            labels: [
                "Saúde e Segurança",
                "Gestão Ambiental",
                "Segurança de Alimentos",
                "Gestão de Qualidade",
            ],
            resultado: [40, 90, 90, 65],
            meta: [40, 75, 75, 92]
        },
        {
            idMaterial: 3,
            labels: [
                "Saúde e Segurança",
                "Gestão Ambiental",
                "Segurança de Alimentos",
                "Gestão de Qualidade",
            ],
            resultado: [20, 80, 85, 92],
            meta: [80, 85, 90, 95]
        }
    ]

    const [selectedOption, setSelectedOption] = useState(options[0]);

    const [active, setActive] = useState(false);

    const [data, setData] = useState(false);

    const handleSelectOption = (value) => {
        const option = options.find(({id}) => id == value);
        setSelectedOption(option);
    }

    const handleActive = () => setActive(!active);

    const changeDataGraph = () => {
        if (response && selectedOption) {
            const dataMaterial = response.find(({idMaterial}) => idMaterial == selectedOption.id);

            if (dataMaterial) {
                const dataGraph = {
                    labels: dataMaterial.labels,
                    datasets: [
                        {
                            label: "Resultado",
                            borderWidth: 2,
                            backgroundColor: "#3f68c8",
                            borderColor: "#3f68c8",
                            hoverBackgroundColor: "#3f68c8",
                            hoverBorderColor: "#3f68c8",
                            data: dataMaterial.resultado,
                        },
                        {
                            label: "Meta",
                            borderWidth: 2,
                            backgroundColor: "#13b945",
                            borderColor: "#13b945",
                            hoverBackgroundColor: "#13b945",
                            hoverBorderColor: "#13b945",
                            data: dataMaterial.meta,
                        },
                    ],
                }

                setData(dataGraph);
            }
        }
    }

    useEffect(() => {
        changeDataGraph()
    }, [selectedOption])

    return (
        <div className="chart-wrap">
            <div className="chart-options">
                <div className="chart-options-label"><b>Materiais e serviços: </b></div>
                <div className="list-item d-inline-flex">
                    <b>{selectedOption.name}</b>
                    <DropList
                        uniqid={`chart-result-options-${selectedOption.id}`}
                        label="Materiais e serviços"
                        defaultValue={selectedOption.id}
                        options={options}
                        selectable={true}
                        active={active}
                        selectedOption={handleSelectOption}
                        onClick={handleActive}
                    />
                </div>
            </div>
            {data && 
                <Bar
                    data={data}
                    options={{
                        maintainAspectRatio: false,
                        indexAxis: 'y',
                        plugins: {
                            title: {
                                text: 'Gráfico de Resultado',
                                display: true,
                                font: {
                                    size: 16,
                                    weight: 'bold',
                                    lineHeight: 2
                                }
                            }
                        },

                        scales: {
                            y: {
                                ticks: {
                                    crossAlign: 'far',
                                }
                            }

                        }
                    }}
                />
            }
        </div>
    )
}

export default memo(ChartResult)