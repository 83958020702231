import React from "react";
import InputRadio from "../../../../components/InputRadio/InputRadio";


export default function CriterioAvaliacao({criterioAvaliacao,especificacoes, handleChange}) {

    return (
        <div className="col-22" style={{width: "auto"}}>
            <label className="global-label">{criterioAvaliacao.descricao}</label>
            <div className="InputRadioGroup">
                <InputRadio name={criterioAvaliacao.descricao} label="Conforme" value="1" checked={criterioAvaliacao.estaConformeIndicador === true ? '1' : '0'} onChange={handleChange} />
                <InputRadio name={criterioAvaliacao.descricao} label="Não Conforme" value="0" checked={criterioAvaliacao.estaConformeIndicador === false ? '0' : '1'} onChange={handleChange} />
            </div>
        </div>
    );
}