import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import Modules from './modules'

import List from './List/List'
import './style.scss'

export default function Index(props) {
  const { token } = props.state;
  const [loading, setLoading] = useState(false);
  const [isResults, setIsResults] = useState(true);
  const [provedorId, setProvedorId] = useState(null);

  const handleLoading = (params) => {
    setLoading(params)
  }

  return (
    <>
      <div className="dashboard-container relacao-provedor">
        {loading && (
          <div className={"loading-mask"}>
            <FontAwesomeIcon icon={faCircleNotch} pulse />
          </div>
        )}

        {isResults
          ? <List
            token={token}
            setLoading={handleLoading}
            handleBanck={setIsResults}
            setProvedorId={setProvedorId}
          />
          : <Modules
            token={token}
            handleBanck={setIsResults}
            provedorId={provedorId}
          />
        }
      </div>
    </>
  );
}
