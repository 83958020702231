import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  RESPOSTAS: BASE_URL + "Respostas",
};

export default class RespostasService {
  static create = async (token, resposta, formData, temAnexo) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data } = await axios.post(URLS.RESPOSTAS, resposta, config);
      if (temAnexo) {
        const respostaId = data

        if (respostaId) {
          let config = {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${token}`,
            },
          };

          let url = `${URLS.RESPOSTAS}/Upload?respostaId=${respostaId}`
          const { data } = await axios.post(url, formData, config);

        }
      }
      return data;

    } catch (err) {
      return err;
    }
  };

  static update = async (token, resposta, formData, editarArquivos) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data } = await axios.put(URLS.RESPOSTAS, resposta, config);
      if (editarArquivos) {
        const respostaId = data

        if (respostaId) {
          let config = {
            headers: {
              'Content-Type': 'multipart/form-data',
              Authorization: `Bearer ${token}`,
            },
          };

          let url = `${URLS.RESPOSTAS}/Upload?respostaId=${respostaId}`
          const { data } = await axios.post(url, formData, config);

        }
      }
      return data;

    } catch (err) {
      return err;
    }
  };

  static getByPerguntaId = async (token, perguntaId) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = `${URLS.RESPOSTAS}/GetByPerguntaId?perguntaId=${perguntaId}`;

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getByTextoPergunta = async (token, textoPergunta) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = `${URLS.RESPOSTAS}/GetByTextoPergunta?textoPergunta=${textoPergunta}`;

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

}