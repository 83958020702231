import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  AUDIT: BASE_URL + "AuditCriteria",
};

export default class UserService {
  static getAudit = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.AUDIT;

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getAuditByID = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.AUDIT + "/" + id;

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  // update
  static getAuditUpdate = async (token, audit) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.AUDIT;

    try {
      const { data } = await axios.put(url, audit, config);
      return data;
    } catch (err) {
      return err;
    }
  };

    // create
    static postAuditUpdate = async (token, audit) => {
      let config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };
  
      let url = URLS.AUDIT;
  
      try {
        const { data } = await axios.post(url, audit, config);
        return data;
      } catch (err) {
        return err;
      }
    };
}
