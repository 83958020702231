import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  IDONEIDADE_INTEGRIDADE: BASE_URL + "IdoneidadeIntegridades",
};

export default class IdoneidadeIntegridadeService {
  static registerIdoneidadeIntegridade = async (token, nome) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let body = {
      nome: nome
    }
    try {
      const { data } = await axios.post(URLS.IDONEIDADE_INTEGRIDADE, body, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getIdoneidadeIntegridade = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        },
      };
        
    let url = URLS.IDONEIDADE_INTEGRIDADE

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };
  
  static getIdoneidadeIntegridadeByComprador = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        },
      };
        
    let url = URLS.IDONEIDADE_INTEGRIDADE + "/" + "byComprador"

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getIdoneidadeIntegridadeAtivoComprador = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        },
      };
        
    let url = URLS.IDONEIDADE_INTEGRIDADE + "/" + "ativoComprador"

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static updateIdoneidadeIntegridade = async (token, name, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
          
    let body = {
      nome: name,
      id: id
    }

    let url = URLS.IDONEIDADE_INTEGRIDADE
    try {
      const { data } = await axios.put(url, body, config);
      return data;
    } catch (err) {
      return err;
    }
  }

  static updateIdoneidadeIntegridadeComprador = async (token, geraBloqueioHomologacao, ativoComprador, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
          
    let body = {
      geraBloqueioHomologacao: geraBloqueioHomologacao,
      ativoComprador: ativoComprador,
      id: id
    }

    let url = URLS.IDONEIDADE_INTEGRIDADE + "/" + "byComprador"
    try {
      const { data } = await axios.put(url, body, config);
      return data;
    } catch (err) {
      return err;
    }
  }

  static deleteIdoneidadeIntegridade = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
      
    let url = URLS.IDONEIDADE_INTEGRIDADE + "/" + id

    try {
      const { data } = await axios.delete(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };
}