import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  DOCUMENT_LICENCE: BASE_URL + "TipoDocumentoLicenca",
};

export default class DocumentLicenceService {
  static create = async (token, nome, geraBloqueioHomologacao) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let body = {
      nome: nome,
      geraBloqueioHomologacao: geraBloqueioHomologacao
    }
    try {
      const { data } = await axios.post(URLS.DOCUMENT_LICENCE, body, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static get = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.DOCUMENT_LICENCE

    try {
      const dataDefault = await axios.get(url + "/GetAllAdmin", config);
      const data = await axios.get(url, config);
      return {
        default: dataDefault.data,
        user: data.data
      };
    } catch (err) {
      return err;
    }
  };

  static getAll = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.DOCUMENT_LICENCE

    try {
      const {data} = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static update = async (token, name, geraBloqueioHomologacao, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let body = {
      nome: name,
      geraBloqueioHomologacao: geraBloqueioHomologacao,
      id: id
    }

    let url = URLS.DOCUMENT_LICENCE
    try {
      const { data } = await axios.put(url, body, config);
      return data;
    } catch (err) {
      return err;
    }
  }

  static delete = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.DOCUMENT_LICENCE + "/" + id

    try {
      const { data } = await axios.delete(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };
}