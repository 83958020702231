import React, { useEffect, useState } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons"
import { toast } from "react-toastify";
import InputRadio from '../../../../../components/InputRadio/InputRadio'
import DropList from "../../../../../components/DropList/DropList"
import Modal from "../../../../../components/ModalExit"

import Service from '../../../../../services/registrationMaterialsService'

import GroupMaterialService from '../../../../groupMaterialService/index'
import TiposInformacoesDocumentadas from '../../../../tiposInformacoesDocumentadas/index'
import EscopoAvaliacao from '../../../../escopoAvaliacao/index'
import CriticidadeAvaliacao from '../../../../criticidadeAvaliacao/index'

import GroupMaterialServicesService from "../../../../../services/groupMaterialServicesService"
import CriticidadeAvaliacaoCompradorService from "../../../../../services/CriticidadeAvaliacaoCompradorService"

import IconSubtract from '../../../../../assets/icons/Subtract.svg'
import IconEdit from '../../../../../assets/icons/edit-small-blue.svg'

export default function Form({ token, editCurrentValue, back }) {
  const [loading, setLoading] = useState(false)
  const [isModal, setIsModal] = useState(false)
  const [typeModal, setTypeModal] = useState({ title: '', type: '' })

  const isEditMode = editCurrentValue ? true : false

  const [defaultValueListGroupId, setDefaultValueListGroupId] = useState({
    defaultId: 0,
    defaultValue: ''
  })
  const [defaultValueListCriticidadeId, setDefaultValueListCriticidadeId] = useState({
    defaultId: 0,
    defaultValue: ''
  })

  // Renderizar componentes
  const [isGroupMaterialService, setIsGroupMaterialService] = useState(false)
  const [isTiposInformacoesDocumentadas, setIsTiposInformacoesDocumentadas] = useState(false)
  const [isEscopoAvaliacao, setIsEscopoAvaliacao] = useState(false)
  const [isCriticidadeAvaliacao, setIsCriticidadeAvaliacao] = useState(false)

  const [showForm, setShowForm] = useState(false)
  const [handleChange, setHandleChange] = useState('')
  const [dropListSelect, setDropListSelect] = useState('');

  const [tiposInformacoesDocumentadas, setTiposInformacoesDocumentadas] = useState([])
  const [listTiposInformacoesDocumentadas, setListTiposInformacoesDocumentadas] = useState([])
  const [listGroupMaterial, setListGroupMaterial] = useState([])
  const [listCriticidadeAvaliacaoComprador, setListCriticidadeAvaliacaoComprador] = useState([])

  // Grupo de materiais
  const [escoposDeAvaliacao, setEscoposDeAvaliacao] = useState([])

  const [escopoAvaliacaoIsActive, setEscopoAvaliacaoIsActive] = useState()

  const [checkboxActive, setCheckboxActive] = useState('1')

  useEffect(() => {
    (async () => {
      await getGroupMaterialServicesService()
      await getCriticidadeAvaliacaoCompradorService()
    })()
    // eslint-disable-next-line
  }, [])

  const [formData, setFormData] = useState({
    name: '',
    criticidadeTipoAvaliacaoCompradorId: 0,
    gradeDifficultyAcquisition: 1,
    listEscopaAvaliacaoId: [],
    listaInformacaoDocumentada: [],
    grupoMaterialServicoId: 0
  })

  // Editar um Material
  useEffect(() => {
    if (!isEditMode) return

    const {
      id,
      name,
      criticidadeTipoAvaliacaoCompradorId,
      gradeDifficultyAcquisition,
      listEscopaAvaliacaoId,
      listaInformacaoDocumentada,
      grupoMaterialServicoId,
    } = editCurrentValue

    setFormData({
      id,
      name,
      criticidadeTipoAvaliacaoCompradorId,
      gradeDifficultyAcquisition,
      listEscopaAvaliacaoId,
      listaInformacaoDocumentada,
      grupoMaterialServicoId
    })

    setCheckboxActive(String(gradeDifficultyAcquisition))

    // Escopos ativos
    if (listGroupMaterial) {
      const escopo = listGroupMaterial.filter(item => item.id === grupoMaterialServicoId)
      setEscoposDeAvaliacao(escopo)
      setEscopoAvaliacaoIsActive(listEscopaAvaliacaoId)

      const tipoInfoDoc = tiposInformacoesDocumentadas.filter(item => item.id === grupoMaterialServicoId)[0]

      if (tipoInfoDoc) {
        const newArray = tipoInfoDoc.tiposInformacoesDocumentadas.map(element => {
          if (listaInformacaoDocumentada.findIndex(doc => doc.id === element.id) !== -1) {
            return { ...element, selected: 'selected' }
          } else {
            return { ...element, selected: '' }
          }
        })
        setListTiposInformacoesDocumentadas(newArray)
      } else if (tipoInfoDoc === undefined && listaInformacaoDocumentada.length > 0) {
        const newArray = listaInformacaoDocumentada.map(element => {
          return { ...element, selected: 'selected' }
        })
        setListTiposInformacoesDocumentadas(newArray)
      }

      setDefaultValueListGroupId({
        defaultId: grupoMaterialServicoId,
        defaultValue: (escopo.length > 0) && escopo[0].nome
      })
    }

    // Preencha a lista de Padrão de Criticidade
    const nomeCriticidade = listCriticidadeAvaliacaoComprador.filter(item => item.id === criticidadeTipoAvaliacaoCompradorId)[0]
    if (nomeCriticidade) {
      setDefaultValueListCriticidadeId({
        defaultId: nomeCriticidade.id,
        defaultValue: nomeCriticidade.nome
      })
    }

    // eslint-disable-next-line
  }, [editCurrentValue, listCriticidadeAvaliacaoComprador])

  useEffect(() => {
    // Exibir ou esconder componentes do formulário
    if (showForm) setHandleChange('show-form')
    else setHandleChange('')
    // eslint-disable-next-line
  }, [showForm])

  function handleSubmit() {

    if (isEditMode) {
      handleUpdate()
    } else {
      handleRegister()
    }
  }

  async function handleRegister() {
    if (formData.name === ''
      || formData.criticidadeTipoAvaliacaoCompradorId === 0
      || formData.listEscopaAvaliacaoId.length === 0) {
      setTypeModal({
        title: '',
        type: 'alert'
      })
      setIsModal(true)
      return
    }

    setLoading(true)

    const response = await Service.register(token, formData)

    if (response) {
      if (response.isAxiosError) {
        toast.error(response.response.data || "Erro ao realizar cadastro!")
        setLoading(false)
        return
      }

      setLoading(false)
      toast.success('Cadastro realizado com sucesso')
      back()
    } else {
      setLoading(false)
      toast.error('Erro ao realizar cadastro')
    }
  }

  async function handleUpdate() {
    if (formData.name === ''
      || formData.criticidadeTipoAvaliacaoCompradorId === 0
      || formData.listEscopaAvaliacaoId.length === 0) {
      setTypeModal({
        title: '',
        type: 'alert'
      })
      setIsModal(true)
      return
    }

    setLoading(true)

    const response = await Service.update(token, formData)

    if (response) {
      if (response.isAxiosError) {
        toast.error(response.response.data || "Erro ao editar!")
        setLoading(false)
        return
      }

      setLoading(false)
      toast.success('Edição salva com sucesso!')
      back()
    } else {
      setLoading(false)
      toast.error('Erro ao salvar')
    }
  }

  // Grupo de materiais e serviços
  const getGroupMaterialServicesService = async () => {
    setLoading(true)

    await GroupMaterialServicesService.get(token)
      .then((response) => {
        if (response) {
          setListGroupMaterial(response);

          const infoDoc = response.map(item => ({
            id: item.id,
            tiposInformacoesDocumentadas: item.tiposInformacoesDocumentadas
          }))
          setTiposInformacoesDocumentadas(infoDoc)
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  // Criticidade / avaliação 
  const getCriticidadeAvaliacaoCompradorService = async () => {
    setLoading(true)

    await CriticidadeAvaliacaoCompradorService.get(token)
      .then((response) => {
        if (response) {
          if (response.valores) {
            setListCriticidadeAvaliacaoComprador(response.valores);
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  // Vincular a um grupo / escolha um grupo
  const handleSelectedOptionGrupoMaterial = (option) => {
    if (isEscopoAvaliacao) {
      setIsEscopoAvaliacao(false)
    }

    const grupo = listGroupMaterial.filter(item => item.id === option)
    setEscoposDeAvaliacao(grupo)
    const escipoId = grupo[0].escoposAvaliacao.map(item => item.id)

    setEscopoAvaliacaoIsActive(escipoId)

    setDefaultValueListGroupId({
      defaultId: option,
      defaultValue: grupo[0].nome
    })

    setFormData({
      ...formData,
      listEscopaAvaliacaoId: escipoId,
      grupoMaterialServicoId: option,
      listaInformacaoDocumentada: []
    })

    const tipoInfoDoc = tiposInformacoesDocumentadas.filter(item => item.id === option)[0]
    setListTiposInformacoesDocumentadas(tipoInfoDoc.tiposInformacoesDocumentadas.map(item => ({ ...item, selected: '' })))
  }

  // Padrão de criticidade / escolha o padrão de criticidade
  const handleSelectedOptionPadrãoCriticidade = (option) => {
    const nomeCriticidade = listCriticidadeAvaliacaoComprador.find(item => item.id === option)

    setDefaultValueListCriticidadeId({
      defaultId: option,
      defaultValue: nomeCriticidade.nome
    })

    setFormData({
      ...formData,
      criticidadeTipoAvaliacaoCompradorId: option
    })
  }

  const handleDropList = (key) => {
    setDropListSelect((dropListSelect !== key) ? key : '');
  };

  function handleShowFrom() {
    setShowForm(!showForm)
  }

  // Ativar/Desativar um escopo
  const [escopoID, setEscopoID] = useState()
  function handleEscopoAvaliacaoComfirm() {
    setIsModal(true)
    setTypeModal({
      title: 'Esta alteração irá modificar o padrão de avaliação deste material/serviço com relação ao padrão pré cadastrado para o grupo. Você confirma que esta se trata de uma mudança intencional?',
      type: 'confirme'
    })
  }
  function handleEscopoAvaliacaoIsActive(id) {
    setIsModal(false)

    const position = escopoAvaliacaoIsActive.indexOf(id)

    if (position !== -1) {
      escopoAvaliacaoIsActive.splice(position, 1)
    } else {
      escopoAvaliacaoIsActive.push(id)
    }

    setFormData({
      ...formData,
      listEscopaAvaliacaoId: escopoAvaliacaoIsActive,
    })

    setEscopoAvaliacaoIsActive([...escopoAvaliacaoIsActive])

  }

  // Grau de dificuldade para aquisição
  function handleChangeCheckbox(e) {
    e.persist()
    const value = e.target.value

    setCheckboxActive(value)

    setFormData({
      ...formData,
      gradeDifficultyAcquisition: parseInt(value)
    })
  }

  function handleChangeInfoDocumentadasCheckbox(item) {
    const InformacaoDocumentada = formData.listaInformacaoDocumentada
    const index = InformacaoDocumentada.findIndex(list => list.id === item.id)

    if (index === -1)
      InformacaoDocumentada.push(item)
    else
      InformacaoDocumentada.splice(index, 1)

    const newArray = listTiposInformacoesDocumentadas.map(element => {
      if (InformacaoDocumentada.findIndex(doc => doc.id === element.id) !== -1) {
        return { ...element, selected: 'selected' }
      } else {
        return { ...element, selected: '' }
      }
    })
    setListTiposInformacoesDocumentadas(newArray)

    setFormData((values) => ({
      ...values,
      listaInformacaoDocumentada: InformacaoDocumentada
    }))
  }

  const handleModal = (value) => {
    setIsModal(value);
  };

  return (
    <>
      {loading && (
        <div className={"loading-mask"}>
          <FontAwesomeIcon icon={faCircleNotch} pulse />
        </div>
      )}

      {isModal && (
        <Modal
          title={typeModal.type === 'confirme' ? typeModal.title : `Todos os campos são obrigatórios`}
          handleDelete={() => handleEscopoAvaliacaoIsActive(escopoID)}
          handleModal={handleModal}
          alert={typeModal.type === 'confirme' ? false : true}
          styles={typeModal.type === 'confirme' ? { width: '51%', maxWidth: 990 } : {}}
        />
      )}

      <div className="card-container boxshadow-none border-radius-bottom-lr-0">
        <div className="card">
          <div className="card-header boxshadow-default">
            <h1 className="big-bold-text">NOVO CADASTRO DE MATERIAL / SERVIÇO</h1>
          </div>

          <div className="card-body p-0 boxshadow-none bg-white">
            <form className="form-container" onSubmit={e => e.preventDefault()}>
              <div className="group-input created-form m-0" id="input-group">
                <div className="row-1">
                  <div className="col-md-6 col-m">
                    <label style={{ width: 200, whiteSpace: 'nowrap' }}>
                      <h1 className="big-bold-text">Material / Serviço</h1>
                    </label>
                    <input
                      type="text"
                      name="nome"
                      className="global-input"
                      value={formData.name}
                      onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    />
                  </div>
                  <div className="col-md-6 col-m" id={`isGroupMaterialService-is-active-${String(isGroupMaterialService)}`}>
                    <div className="label-wrap">
                      <label style={{ width: 111 }}>
                        <h1 className="big-bold-text">Vincular a um grupo</h1>
                      </label>
                      <a className={`option-hidden-${String(isGroupMaterialService)}`}
                        onClick={() => {
                          setIsTiposInformacoesDocumentadas(false)
                          setIsEscopoAvaliacao(false)
                          setIsCriticidadeAvaliacao(false)
                          setIsGroupMaterialService(!isGroupMaterialService)
                        }}>
                        <div className="option-tooltip">
                          <img src={IconSubtract} rel="iconSubtract" />
                          <span className="tooltiptext">Crie um novo grupo</span>
                        </div>
                      </a>
                    </div>

                    <div className="input-list-wrap">
                      <input
                        disabled={true}
                        type="text"
                        name="vincular-grupo"
                        className="global-input"
                        value={''}
                      />
                      <span className={"list-item d-inline-flex"}>
                        <span>{
                          defaultValueListGroupId.defaultValue
                            ? defaultValueListGroupId.defaultValue
                            : 'Escolha um grupo'}
                        </span>
                        <DropList
                          uniqid={'vincular-grupo'}
                          label="Escolha um grupo"
                          selectable
                          defaultValue={defaultValueListGroupId.defaultId}
                          options={listGroupMaterial}
                          selectedOption={handleSelectedOptionGrupoMaterial}
                          active={(dropListSelect === 'vincular-grupo')}
                          onClick={handleDropList}
                        />
                      </span>
                    </div>
                  </div>
                </div>

                <div id="container-vincular-grupo">
                  {isGroupMaterialService &&
                    <GroupMaterialService
                      {...{ state: { token: token } }}
                      handleShowFrom={handleShowFrom} />
                  }
                </div>

                <div className={`row-2 ${!isCriticidadeAvaliacao && handleChange}`}>
                  <div className={`col-md-6 col-m ${isCriticidadeAvaliacao && "option-select"}`}>

                    <div className="label-wrap" >
                      <label style={{ width: 120 }}>
                        <h1 className="big-bold-text">Padrão de Criticidade</h1>
                      </label>
                      <a className={`option-hidden-${String(isCriticidadeAvaliacao)}`}
                        onClick={() => {
                          setIsTiposInformacoesDocumentadas(false)
                          setIsGroupMaterialService(false)
                          setIsEscopoAvaliacao(false)
                          setIsCriticidadeAvaliacao(!isCriticidadeAvaliacao)
                        }}>
                        <div className="option-tooltip">
                          <img src={IconSubtract} rel="iconSubtract" />
                          <span className="tooltiptext">Crie um novo padrão de criticidade</span>
                        </div>
                      </a>
                    </div>

                    <div className="input-list-wrap">
                      <input
                        disabled={true}
                        type="text"
                        name="padrao-criticidade"
                        className="global-input"
                        value={''}
                      />
                      <span className={"list-item d-inline-flex"}>
                        <span>{
                          defaultValueListCriticidadeId.defaultValue
                            ? defaultValueListCriticidadeId.defaultValue
                            : 'Escolha o padrão de criticidade'}
                        </span>
                        <DropList
                          uniqid={'padrao-criticidade'}
                          label="padrao criticidade"
                          selectable
                          defaultValue={defaultValueListCriticidadeId.defaultId}
                          options={listCriticidadeAvaliacaoComprador}
                          selectedOption={handleSelectedOptionPadrãoCriticidade}
                          active={(dropListSelect === 'padrao-criticidade')}
                          onClick={handleDropList}
                        />
                      </span>
                    </div>

                  </div>
                  <div className="col-md-6 col-m">

                    <label style={{ width: 170, marginRight: 46 }}>
                      <h1 className="big-bold-text">Grau de dificuldade para aquisição</h1>
                    </label>

                    <div id="grau-input-radio-wrap">
                      <InputRadio
                        key={100}
                        name="alta"
                        checked={(checkboxActive === '1') ? '1' : false}
                        label="Alta"
                        value={'1'}
                        onChange={handleChangeCheckbox}
                      />
                      <InputRadio
                        key={200}
                        name="media"
                        checked={(checkboxActive === '2') ? '2' : false}
                        label="Média"
                        value={'2'}
                        onChange={handleChangeCheckbox}
                      />
                      <InputRadio
                        key={300}
                        name="baixa"
                        checked={(checkboxActive === '3') ? '3' : false}
                        label="Baixa"
                        value={'3'}
                        onChange={handleChangeCheckbox}
                      />
                    </div>
                  </div>
                </div>
              </div>

              {isCriticidadeAvaliacao &&
                <CriticidadeAvaliacao
                  {...{ state: { token: token } }}
                  inRegister={isCriticidadeAvaliacao}
                  handleShowFrom={handleShowFrom} />
              }

              <div className={`section-wrap ${handleChange}`}>
                <div className={isEscopoAvaliacao && "option-select"}>
                  <h1 className="big-bold-text">Escopos de Avaliação: </h1>

                  <a className={`option-hidden-${String(isEscopoAvaliacao)}`}
                    onClick={() => {
                      setIsTiposInformacoesDocumentadas(false)
                      setIsGroupMaterialService(false)
                      setIsCriticidadeAvaliacao(false)
                      setIsEscopoAvaliacao(!isEscopoAvaliacao)
                    }}>
                    <div className="option-tooltip">
                      <img src={IconEdit} rel="IconEdit" />
                      <span className="tooltiptext">Editar um escopo de avaliação</span>
                    </div>
                  </a>
                </div>
                <div className={isTiposInformacoesDocumentadas && "option-select"}>
                  <h1 className="big-bold-text">Informações Documentadas: </h1>
                  <a className={`option-hidden-${String(isTiposInformacoesDocumentadas)}`}
                    onClick={() => {
                      setIsGroupMaterialService(false)
                      setIsEscopoAvaliacao(false)
                      setIsCriticidadeAvaliacao(false)
                      setIsTiposInformacoesDocumentadas(!isTiposInformacoesDocumentadas)
                    }
                    }>
                    <div className="option-tooltip">
                      <img src={IconSubtract} rel="iconSubtract" />
                      <span className="tooltiptext">Crie uma informação documentada</span>
                    </div>
                  </a>
                </div>
              </div>

              <div className={`section-bottom-wrap ${handleChange}`}>
                <div className="section-left">
                  {escoposDeAvaliacao &&
                    escoposDeAvaliacao.length === 0
                    ? <h3 style={{ textAlign: 'center' }}>Escolha ao menos um grupo ativo para víncular</h3>
                    : (escoposDeAvaliacao[0].escoposAvaliacao || []).map((item, index) =>
                      <div className="section-item">
                        <h1 className="big-bold-text">{item.nome}</h1>
                        <div className="switch-container" key={String(item.nome + item.id)}>
                          <label className="toggle-switch">
                            <input
                              id={index}
                              type="checkbox"
                              checked={(escopoAvaliacaoIsActive.indexOf(item.id) !== -1) ? true : false}
                              value={item.id}
                              onChange={() => {
                                handleEscopoAvaliacaoComfirm()
                                setEscopoID(item.id)
                              }}
                            />
                            <span className="switch">
                              <p>{(escopoAvaliacaoIsActive.indexOf(item.id) !== -1) ? "Ativo" : "Inativo"}</p>
                            </span>
                          </label>
                        </div>
                      </div>
                    )}
                </div>

                <div className="section-right">
                  <div className="info-documents-list-wrap">
                    <header><h1 className="big-bold-text">LISTA DA RELAÇÃO DE INFORMAÇÕES DOCUMENTADAS</h1></header>
                    <section>
                      <ul>
                        {listTiposInformacoesDocumentadas &&
                          listTiposInformacoesDocumentadas.map(item =>
                            <li className={`checkbox ${item.selected === '' ? '' : item.selected}`}
                              onClick={() => handleChangeInfoDocumentadasCheckbox(item)}
                            >
                              {item.nome}
                            </li>
                          )}
                      </ul>
                    </section>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className={`row mx-0 ${handleChange}`}>

        <div id="container-isTiposInformacoesDocumentadas">
          {isTiposInformacoesDocumentadas &&
            <TiposInformacoesDocumentadas
              {...{ state: { token: token } }} />
          }
          {isEscopoAvaliacao &&
            <EscopoAvaliacao
              {...{ state: { token: token } }}
              currentEscopoAvaliacao={escoposDeAvaliacao[0]} embed={true} />
          }
        </div>

        <div className="col-12 px-0 justify-content-between">
          <button
            type="button"
            className="global-btn w-auto"
            disabled={false}
            onClick={back}
          >
            <svg className="mr-1" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.47211 5.05021L5.10332 9.09061L4.31819 9.69667L0 5.05021L4.31819 0.302734L5.00518 1.00981L1.47211 5.05021ZM9.71593 1.00981L9.02894 0.302734L4.71075 5.05021L9.02894 9.69667L9.71593 8.9896L6.18286 5.05021L9.71593 1.00981Z" fill="white" />
            </svg>
            VOLTAR
          </button>
          <button
            type="button"
            className="global-btn w-auto"
            disabled={false}
            onClick={handleSubmit}
          >
            SALVAR
          </button>
        </div>
      </div>
    </>
  );
}