import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import Form from "./Form/DocumentosLicencaForm";
import DocumentosLicencaList from "../DocumentosLicenca/List/DocumentosLicencaList"
import ProvedorDocumentoLicencaService from "../../services/ProvedorDocumentoLicencaService"
import "./style.scss";


export default function DocumentosLicenca(props) {
    const token = props.state.token;
    const [showForm, setShowForm] = useState(false);
    const [loading, setLoading] = useState(false);
    const [provedorDocumentoLicencaList, setProvedorDocumentoLicencaList] = useState();
    const [currentValue, setCurrentValue] = useState(null);

    const [valoresJacadastrados, setValoresJacadastrados] = useState(null)
    const initComponent = () => {
        setShowForm(false);
        setCurrentValue(null);
        get();
    }

    const get = async () => {
        setLoading(true);
        const resposnse = await ProvedorDocumentoLicencaService.getDocumentLicences(token);
        setProvedorDocumentoLicencaList(resposnse);
        setValoresJacadastrados(resposnse);
        setLoading(false);
    }

    const currentDocuments = (value) => {
        setCurrentValue(value);
        setShowForm(!showForm);
    }

    useEffect(() => {
        initComponent();
    }, [])

    return (
        <>
            <div className="dashboard-container">
                {loading && (
                    <div className={"loading-mask"}>
                        <FontAwesomeIcon icon={faCircleNotch} pulse />
                    </div>
                )}
                {showForm
                    ?
                    <div className="DocumentosLicencaForm-container">
                        <Form token={token}
                            valoresJaCadastrados={valoresJacadastrados}
                            back={() => {
                                initComponent()
                            }}
                            currentValue={currentValue} />
                    </div>
                    :
                    <>
                        <div className="module-provedor-doc-licenca">
                            <button
                                className={"global-btn"}
                                onClick={() => {
                                    setShowForm(!showForm)
                                }}
                            >
                                <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M14.5799 10.5H10.4142V14.5H4.16567V10.5H0V4.5H4.16567V0.5H10.4142V4.5H14.5799V10.5Z" fill="#FFF" />
                                </svg>
                                Incluir novo Documento/Licença

                            </button>
                            <div>
                                <DocumentosLicencaList
                                    token={token}
                                    provedorDocumentoLicencaList={provedorDocumentoLicencaList}
                                    back={() => { initComponent() }}
                                    currentDocuments={currentDocuments}
                                />
                            </div>
                        </div>
                    </>
                }
            </div>
        </>
    );
}
