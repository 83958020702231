import React from "react";

import "./dashboardComprador.scss"
import CardComprador from "./components/cardCompradorLogo"
import CardProvedores from "./components/cardProvedores";
import CardSituacaoCadastral from "./components/cardSituacaoCadastral";
import CardMateriaisServicos from "./components/cardMateriaisServicos";
import CardRegistroRecebimento from "./components/cardRegistroRecebimento";

export default function DashBoardComprador(props) {
  const { token } = props.state;

  return (
    <>
      <div className="local-bootstrap">
        <div className="dashboard d-flex flex-column align-items-end">
          <div className="d-flex justify-content-between align-items-center mb-4 w-100">
            <h1>DASHBOARD</h1>
            <strong>SEJA BEM VINDO(A) A SUPPLY  COMPLIANCE</strong>
          </div>

          <div className="row justify-content-end mb-4 w-100" style={{ marginLeft: 0 }}>

            <CardComprador />

            <CardSituacaoCadastral />

            <CardProvedores {...{ state: { token: token } }} />

          </div>

          <div className="row justify-content-end w-100" style={{ marginLeft: 0 }}>

            <CardRegistroRecebimento />

            <CardMateriaisServicos {...{ state: { token: token } }} s />

          </div>
        </div></div>
    </>
  );
}
