import React, { useEffect, useState } from "react";

import Pagination from "react-js-pagination";

import UserList from "./components/UserList";
import AddUser from "./components/addUser";
import EditBuyer from "./components/editBuyer";

import BuyerService from "../../services/BuyerService";
import UserService from "../../services/UserService";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import "./buyers.scss";
import "../dashboard/dashboard.scss";

export default function Index(props) {
  const { token } = props.state;
  const [users, setUsers] = useState([]);
  const [searchInput, setSearchInput] = useState("");

  const [navegationModule, setNavegationModule] = useState('');

  const [getId, setGetId] = useState();

  const [activePage, setActivePage] = useState(1);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const getTotalBuyers = () => {
    setLoading(true);

    UserService.getTotalBuyers(token)
      .then((response) => {
        let status =
          response && response.response && response.response.status
            ? response.response.status
            : "";

        if (status !== "") {
          return false;
        }

        setTotal(response);
        setLoading(false);
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
      });
  };

  const getBuyers = () => {
    setLoading(true);
    BuyerService.getBuyers(token, activePage)
      .then((response) => {
        if (response && response.length > 0) {
          setUsers(response);
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
      });
  };

  const searchBuyers = (input) => {
    setLoading(true);
    BuyerService.searchBuyers(token, activePage, input)
      .then((response) => {
        if (response && response.length > 0) {
          setUsers(response);
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
      });
  };

  useEffect(() => {
    getBuyers();
    getTotalBuyers();
  }, [navegationModule]);

  useEffect(() => {
    if (searchInput !== "") {
      searchBuyers(searchInput);
    } else {
      getBuyers();
    }
  }, [activePage]);

  const handlePageChange = (pageNumber) => {
    setActivePage(pageNumber);
  };

  const search = (value) => {
    setSearchInput(value);
    searchBuyers(value);
  };

  const handleNavegation = (type) => {
    setNavegationModule(type)
  }

  const handleGetId = (id) => {
    setGetId(id)
  }

  const updateList = () => {
    getTotalBuyers()
    getBuyers()
  }

  return (
    <div className="dashboard-container">
      {loading && (
        <div className={"loading-mask"}>
          <FontAwesomeIcon icon={faCircleNotch} pulse />
        </div>
      )}

      {navegationModule === ''
        ? <>
          <h1>Configuração de usuários</h1>
          <UserList handleNavegation={handleNavegation} users={users} error={error} search={search} handleGetId={handleGetId} />
          <div className="user-list-pagination">
            <div className="user-list-pagination-item">
              {users && users.length > 0 && (
                <p>
                  Mostrando {users && users.length} de {total} usuários
                </p>
              )}
            </div>
            <div className="user-list-pagination-item">
              <Pagination
                activePage={activePage}
                itemsCountPerPage={10}
                totalItemsCount={total}
                onChange={handlePageChange}
                itemClass="page-item"
                linkClass="page-link"
                prevPageText="<< Anterior"
                nextPageText="Próxima >>"
                hideFirstLastPages={true}
              />
            </div>
          </div>
        </>
        : navegationModule === 'create-user'
          ? <AddUser {...{ state: { token: token } }} handleNavegation={handleNavegation} updateList={updateList}/>
          : navegationModule === 'edit-user' &&
          <EditBuyer {...{ state: { token: token } }} handleNavegation={handleNavegation} handleGetId={getId} />
      }
    </div>
  );
}
