export const action_types = {
  SET_AUTHENTICATION: "SET_AUTHENTICATION",
  LOGOUT: "LOGOUT",
};

const logoutService = () => {
  localStorage.clear();
  window.location.reload();
};

export const actions = {
  setAuthentication: (data) => {
    return { type: action_types.SET_AUTHENTICATION, data };
  },
  logout: () => {
    logoutService();
    return { type: action_types.LOGOUT };
  },
};
