import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faSearch } from '@fortawesome/free-solid-svg-icons';
import { ProviderIncludeIsActive } from '../../components/ProviderInclude';

import styles from '../approvalOpportunity/styles.module.scss';

import { toast } from 'react-toastify';

import SelectWithInput from './components/SelectWithInput';
import MaterialsServicesService from '../../services/MaterialsServicesService';
import Service from '../../services/registrationMaterialsService';

export default function Index(props) {
  const { token } = props.state;

  const [isToggled, setToggled] = useState(false);
  const [dropListSelect, setDropListSelect] = useState(null);
  const [loading, setLoading] = useState(false);
  const [escopoHomolog, setEscopoHomolog] = useState([]);
  const [opportunityValues, setOpportunityValues] = useState([]);
  const [busca, setBusca] = useState('');
  const [error, setError] = useState(false);
  const [perfilComprador, setPerfilComprador] = useState(true);

  const handleDropList = (key) => {
    setDropListSelect(dropListSelect !== key ? key : '');
  };

  useEffect(() => {
    if (perfilComprador) {
      getMaterialServiceComprador();
    }
  }, [escopoHomolog]);

  const getMaterialServiceComprador = async () => {
    try {
      if (perfilComprador) {
        const { valores } = await Service.getAll(token);
        setEscopoHomolog(valores);
        setPerfilComprador(false);
      }
    } catch (error) {
      return console.log(error);
    }
  };

  const handleChange = async (id) => {
    setLoading(true);

    try {
      const response = await MaterialsServicesService.getApprovalOpportunity(
        token,
        id
      );
      if (response.map((p) => p.provedor)) {
        setOpportunityValues(response);
      }
      setLoading(false);
      setToggled(true);
    } catch (error) {
      setLoading(false);
      return toast.error('Erro ao buscar dados!');
    }
  };

  return (
    <>
      <div className={styles.cardContainer}>
        {loading && (
          <div className={styles.loadingMask}>
            <FontAwesomeIcon icon={faCircleNotch} pulse />
          </div>
        )}
        <h1 className={styles.headerTitle}>OPORTUNIDADES DE HOMOLOGAÇÃO</h1>
        <div className={styles.card}>
          <div
            className={
              !isToggled ? styles.cardHeader : styles.cardHeaderSmaller
            }
          >
            <h1 className={styles.headerList}>
              LISTA DE PROVEDORES CADASTRADOS
            </h1>
            {!isToggled && (
              <>
                <input
                  className={styles.headerInput}
                  type="text"
                  id="nome"
                  name="nome"
                />
                <button className={styles.searchBtn}>
                  <FontAwesomeIcon color="#4068C8" size="sm" icon={faSearch} />
                </button>
              </>
            )}
          </div>
          <div
            className={!isToggled ? styles.cardBody : styles.cardBodySmaller}
          >
            <div className={styles.containerSelect}>
              <label className={styles.cardTitle}>Provedor</label>
              <span className={styles.listProviderMaterials}>
                Lista de provedores de seus materiais / serviços cadastrados
                {escopoHomolog.map((data) => (
                  <SelectWithInput
                    className={styles.listItem}
                    uniqid={data.id + 'escopo-homolog'}
                    input
                    options={escopoHomolog.map((data) => {
                      return data;
                    })}
                    active={dropListSelect == data.id + 'escopo-homolog'}
                    onClick={handleDropList}
                    handleChange={handleChange}
                    values={busca}
                    onChange={setBusca}
                    error={error}
                  />
                ))}
              </span>
            </div>
          </div>
        </div>
      </div>
      {isToggled === true && (
        <>
          <div className={styles.providerFound}>
            <p>
              Foram encontrados 2 outros provedores com possibilidade de
              homologação
            </p>
          </div>
          {(opportunityValues || []).map((d, index) => (
            <div className={styles.containerHomolog} key={index}>
              <div className={styles.containerCompany}>
                <div className={styles.containerImg}>
                  <p>LOGO DO FORNECEDOR</p>
                </div>
                <div className={styles.containerContent}>
                  <h1>{d.provedor.nome}</h1>
                  <p>
                    {d.provedor.cidade} - {d.provedor.uf}
                  </p>
                  <span>
                    <strong>Tel.: </strong>
                    {d.provedor.telefone}
                  </span>
                  <span>
                    <strong>E-mail: </strong>
                    {d.provedor.email}
                  </span>
                  <span>
                    <strong>Website: </strong> {d.provedor.webSite}
                  </span>
                  <span>
                    <strong>Endereço: </strong> {d.provedor.logradouro},{' '}
                    {d.provedor.numero} -{d.provedor.bairro} -{' '}
                    {d.provedor.cidade}/{d.provedor.uf}
                  </span>
                  <span>
                    <strong>Facebook: </strong>{' '}
                    {d.provedor.provedorRedesSociais}
                  </span>
                </div>
                <ProviderIncludeIsActive
                  name={
                    d.provedorJaIncluso === true
                      ? 'Provedor já incluso'
                      : 'Incluir em seus provedores'
                  }
                  isActive={d.provedorJaIncluso}
                />
              </div>
              <div className={styles.materialServices}>
                <h1>Materiais / Serviços</h1>
                <div className={styles.materialsOptions}>
                  <span className={styles.active}>Material 1</span>
                  <span>Cal Virgem</span>
                  <span>Enxofre Agrícola</span>
                  <br />
                  <span>Enxofre Industrial</span>
                  <span>Óxido de Magnésio</span>
                  <br />
                  <span>Produtos Químicos</span>
                  <span>Serviço 1</span>
                </div>
              </div>
            </div>
          ))}
        </>
      )}
    </>
  );
}
