import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import { toast } from "react-toastify";

import UserConfig from "./UserConfig";
import UserModule from "./UserModule";

import BuyerService from "../../../services/BuyerService";
import ModuleService from "../../../services/ModuleService";

export default function EditBuyer(props) {
  const { token } = props.state;
  const [user, setUser] = useState({
    name: "",
    email: "",
    company: { cnpj: "", name: "" },
    companyId: "",
    isAuthorised: false,
    modules: [],
    profiles: [],
    id: "",
  });

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [modules, setModules] = useState(false);

  const id = props.handleGetId

  const getBuyer = () => {
    setLoading(true);
    BuyerService.getBuyer(token, id)
      .then((response) => {
        setUser(response);
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Erro ao buscar dados!");
        setError(true);
        setLoading(false);
      });
  };


  const getModules = () => {
    ModuleService.getModules(token)
      .then((response) => {
        setModules(response);
      })
      .catch((error) => {
        toast.error("Erro ao buscar módulos!");
        setError(true);
        setLoading(false);
      });
  };

  useEffect(() => {
    modules &&
      modules.length > 0 &&
      modules.map((module) => {
        let result =
          user &&
          user.modules &&
          user.modules.length > 0 &&
          user.modules.filter((value) => module.id === value.moduleId);
        if (result && result.length > 0) {
          module.active = true;
        }
        return module;
      });
    setModules(modules);
    // eslint-disable-next-line
  }, [modules, user]);


  useEffect(() => {
    getBuyer();
    getModules();
    // eslint-disable-next-line
  }, []);

  const handleModule = (idModule, status) => {
    ModuleService.handleStatusModuleUser(token, idModule, status, id)
      .then((response) => {
        if (response) {
          getBuyer();
          getModules();
        }
        setLoading(false);
        setError(false);
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
      });
  };

  const send = (user) => {
    setLoading(true);

    const { isAuthorised } = user;

    BuyerService.handleBuyer(token, id, isAuthorised)
      .then((response) => {
        if (response) {
          toast.success("Dados salvos com sucesso!");
          setSuccess(true);
        }
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Erro ao salvar dados!");
        setLoading(false);
        setError(true);
      });
  };

  return (
    <div className="dashboard-container">
      {loading && (
        <div className={"loading-mask"}>
          <FontAwesomeIcon icon={faCircleNotch} pulse />
        </div>
      )}

      <h1>Configuração de usuários</h1>
      <div className="module-container">
        <div className="module-item">
          <UserConfig
            initicialUser={user}
            error={error}
            send={send}
            loading={loading}
            success={success}
            handleNavegation={() => props.handleNavegation('')}
          />
        </div>
        <div className="module-item">
          <UserModule
            initicialModules={modules}
            initicialUser={user}
            handleModule={handleModule}
            error={error}
          />
        </div>
      </div>
    </div>
  );
}
