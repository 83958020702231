import React, { useEffect, useState, useRef } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { IF } from "../../../../components/Condition/Condition";
import Modal from "../../../../components/ModalExit";

import DropList from "../../../../components/DropList/DropList";

import NotificacaoProvedoresService from "../../../../services/NotificacaoProvedoresService";

export default function DescricaoNotificacaoForm({ token, current, callback, provedores, show }) {
  const inputFile = useRef(null);
  const [modal, setModal] = useState(false);
  const [modalMsg, setModalMsg] = useState('');

  const [notificacao, setNotificacao] = useState(current);
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dropListSelect, setDropListSelect] = useState(null);

  const [acoesInternas, setAcoesInternas] = useState([]);
  const [categorias, setCategorias] = useState([]);
  const [impactos, setImpactos] = useState([]);
  const [identificacoes, setIdentificacoes] = useState([]);
  const [niveisCriticidades, setNiveisCriticidades] = useState([]);
  const [materialServicos, setMaterialServicos] = useState([]);

  const formatData = (date, formatBR = false, time = false) => {
    if (!date) return '';
    let data = '';

    let dia = date.getDate();           // 1-31
    let mes = date.getMonth() + 1;      // 0-11 (zero=janeiro)
    let ano4 = date.getFullYear();       // 4 dígitos
    let hora = date.getHours();          // 0-23
    let min = date.getMinutes();        // 0-59
    let seg = date.getSeconds();        // 0-59

    if (!formatBR) {
      data = ("0" + ano4).slice(-4) + '-' + ("0" + mes).slice(-2) + '-' + ("0" + dia).slice(-2);
    } else {
      data = ("0" + dia).slice(-2) + '/' + ("0" + mes).slice(-2) + '/' + ("0" + ano4).slice(-4);

      if (time) {
        data += ' ' + ("0" + hora).slice(-2) + ':' + ("0" + min).slice(-2) + ':' + ("0" + seg).slice(-2);
      }
    }

    return data;
  }

  const handleDropList = (key) => {
    setDropListSelect((dropListSelect !== key) ? key : '')
  }

  const handleGetSelectedOption = (name, id) => {
    const obj = locateArray(name);
    const locate = obj.data.find(item => item.id === id);

    if (id != notificacao[obj.objectId]) {
      if (name === 'materialServicos') {
        const materiais = notificacao.listaMaterialServices
        const index = materiais.findIndex(item => item.id === id)

        if (index !== -1) {
          materiais.splice(index, 1)
        } else {
          materiais.push({ id: locate.id, name: locate.name })
        }
      } else {
        setNotificacao((values) => ({
          ...values,
          [obj.object]: locate,
          [obj.objectId]: locate.id,
        }));
      }
    } else {
      setNotificacao((values) => ({
        ...values,
        [obj.object]: {},
        [obj.objectId]: 0,
      }));
    }

  }

  const locateArray = (name) => {
    let list = {
      acoesInternas: {
        object: 'provedorNotificacaoAcaoInterna',
        objectId: 'provedorNotificacaoAcaoInternaId',
        data: acoesInternas
      },
      categorias: {
        object: 'provedorNotificacaoCategoria',
        objectId: 'provedorNotificacaoCategoriaId',
        data: categorias
      },
      impactos: {
        object: 'provedorNotificacaoImpacto',
        objectId: 'provedorNotificacaoImpactoId',
        data: impactos
      },
      identificacoes: {
        object: 'provedorNotificacaoIdentificacao',
        objectId: 'provedorNotificacaoIdentificacaoId',
        data: identificacoes
      },
      niveisCriticidades: {
        object: 'provedorNotificacaoNivelCriticidade',
        objectId: 'provedorNotificacaoNivelCriticidadeId',
        data: niveisCriticidades,
      },
      provedores: {
        object: 'provedor',
        objectId: 'provedorId',
        data: provedores,
      },
      materialServicos: {
        object: [],
        objectId: [],
        data: materialServicos,
      },

    }

    return list[name] || '';
  }

  const handleChange = (e) => {
    e.persist();

    setNotificacao((values) => ({
      ...values,
      [e.target.name]: (e.target.type == 'radio') ?
        (e.target.value == '1' ? true : false) : e.target.value
    }));
  };

  const handleSubmitDescricao = (e) => {
    e.preventDefault();

    if (notificacao.provedorId === 0
      || notificacao.responsavel === ''
      || notificacao.cnpj === ''
      || notificacao.codigo === ''
      || notificacao.notaFiscal === ''
      || notificacao.pedidoCompra === ''
      || notificacao.lote === ''
      || notificacao.recebimento === ''
      || notificacao.provedorNotificacaoCategoriaId === 0
      || notificacao.provedorNotificacaoIdentificacaoId === 0
      || notificacao.provedorNotificacaoAcaoInternaId === 0
      || notificacao.provedorNotificacaoNivelCriticidadeId === 0
      || notificacao.provedorNotificacaoImpactoId === 0
      || notificacao.reclamacao === ''
      || notificacao.listaMaterialServices.length === 0) {
        toast.warn('Todos os campos são obrigatórios!\nExceto o upload de documento.')
        return
      }

    if (!notificacao.compradorId) {
      if ('provedorNotificacaoAcaoInterna' in notificacao) delete notificacao['provedorNotificacaoAcaoInterna'];
      if ('provedorNotificacaoCategoria' in notificacao) delete notificacao['provedorNotificacaoCategoria'];
      if ('provedorNotificacaoIdentificacao' in notificacao) delete notificacao['provedorNotificacaoIdentificacao'];
      if ('provedorNotificacaoImpacto' in notificacao) delete notificacao['provedorNotificacaoImpacto'];
      if ('provedorNotificacaoNivelCriticidade' in notificacao) delete notificacao['provedorNotificacaoNivelCriticidade'];
      if ('provedor' in notificacao) delete notificacao['provedor'];
      if ('materialServicos' in notificacao) delete notificacao['materialServicos'];

      create(notificacao);
    }
  }

  const create = async (payload) => {
    setSending(true);

    // Upload
    const archive = new FormData();
    archive.append("arquivo", selectedFile[0]);

    const response = await NotificacaoProvedoresService.create(token, payload)

    if (response) {
      if (response.isAxiosError) {
        if (response.response.data.httpStatusCode === 500) {
          toast.error(response.response.data.message);
          setSending(false);
          setSelectedFile([])
          callback();
        } else {
          toast.error(response.response.data.message || "Erro ao cadastrar notificação!");
          setSending(false);
        }
        return
      }

      if (selectedFile) {
        const res = await NotificacaoProvedoresService.upload(token, archive, response)

        if (res) {
          if (res.isAxiosError) {
            toast.error(res.response.data || "Documento não selecionado para upload!");
          }
        } else {
          toast.error("Erro ao fazer upload do arquivo!");
        }
      }
      toast.success("Notificação cadastrada com sucesso!");
      setSending(false);
      setSelectedFile([])
      callback();
    } else {
      toast.error("Erro ao cadastrar notificação!");
      setSending(false);
    }
  }

  // Upload file
  const [selectedFile, setSelectedFile] = useState([]);

  const handlerChangeFile = (event) => {
    const file = event.target.files[0]
    const type = file.type

    if (type !== "application/pdf" && type !== "image/png" && type !== "image/jpeg") {
      setModalMsg('Tipo de arquivo inválido, selecione um arquivo válido do tipo PDF, PNG ou JPG')
      setModal(true)
      return
    }

    if (file.size > 10000000) {
      setModalMsg('O arquivo deve ser igual ou menor a 10MB')
      setModal(true)
      return
    }

    setSelectedFile([file]);
  };

  const _init = async () => {
    setLoading(true);
    const getAcoesInternas = await NotificacaoProvedoresService.getAcoesInternas(token);
    const getCategorias = await NotificacaoProvedoresService.getCategorias(token);
    const getIdentificacoes = await NotificacaoProvedoresService.getIdentificacoes(token);
    const getImpactos = await NotificacaoProvedoresService.getImpactos(token);
    const getNiveisCriticidades = await NotificacaoProvedoresService.getNiveisCriticidades(token);
    const getMaterialServicos = await NotificacaoProvedoresService.getMaterialServicos(token);

    setAcoesInternas(getAcoesInternas);
    setCategorias(getCategorias);
    setIdentificacoes(getIdentificacoes);
    setImpactos(getImpactos);
    setNiveisCriticidades(getNiveisCriticidades);
    setMaterialServicos(getMaterialServicos.valores)
    setLoading(false);
  }

  const getMaterialServicos = async (notficProvedorId) => {
    const response = await NotificacaoProvedoresService.getMaterialServicosById(token, notficProvedorId)

    if (response) {
      if (response.isAxiosError) {
        return []
      }
      return response.listaMaterialServices
    } else {
      return []
    }
  }

  useEffect(() => {
    _init();
  }, []);

  useEffect(() => {
    (async () => {
      if (current.id) {
        const listaMaterialServices = await getMaterialServicos(current.id)

        setNotificacao((value) => ({
          ...value,
          listaMaterialServices: listaMaterialServices,
          provedor: value.provedorId && !value.provedor ? provedores.find(item => item.id === value.provedorId) : {}
        }));
      }

    })()
  }, [current]);

  return (
    <>
      {loading && (
        <div className={"loading-mask"}>
          <FontAwesomeIcon icon={faCircleNotch} pulse />
        </div>
      )}

      {modal &&
        <Modal
          title={modalMsg}
          handleDelete={null}
          handleModal={setModal}
          alert
        />
      }

      <form
        className={`form-container ` + (show === 'descricao' ? 'tab-active' : '')}
        onSubmit={handleSubmitDescricao}>
        <div className="row newRow">
          <div className="col-md-6 p-0 d-grid aligncenter">
            <div className="column-title bg-gray">
              <h1>DADOS DO PROVEDOR / PRESTADOR DE SERVIÇO</h1>
            </div>
            <div className="column-content left">
              <div className="col-lg-3">
                <label className="global-label">Data/hora da consulta</label>
              </div>
              <div className="col-lg-3">
                <input
                  className="global-input"
                  type="text"
                  value={(notificacao.compradorId && 'createdDate' in notificacao) ?
                    formatData(new Date(notificacao.createdDate), true, true) : (notificacao.compradorId ? '-' : formatData(new Date(), true, true))}
                  placeholder="27/01/2021 - 16:56:29"
                  disabled={true}
                />
              </div>
              <div className="col-lg-3">
                <label className="global-label">Identificação de fornecedor</label>
              </div>
              <div className="col-lg-3">
                <div className="dropList-elem"
                  disabled={notificacao.compradorId && true}>
                  <span className="dropList-title">{notificacao.provedor && notificacao.provedor.nome || 'Fornecedores'}
                  </span>
                  <DropList
                    uniqid={'provedores'}
                    label="Provedores"
                    selectable
                    options={provedores}
                    defaultValue={notificacao.provedorId || null}
                    selectedOption={(id) => handleGetSelectedOption('provedores', id)}
                    active={(dropListSelect === 'provedores')}
                    onClick={handleDropList}
                  />
                </div>
              </div>
              <div className="col-lg-3">
                <label className="global-label">Responsável pela notificação</label>
              </div>
              <div className="col-lg-9">
                <input
                  className="global-input"
                  type="text"
                  id="responsavel"
                  value={notificacao.responsavel || ''}
                  placeholder="Nome do responsável"
                  name="responsavel"
                  disabled={notificacao.compradorId && true}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-3">
                <label className="global-label">CNPJ</label>
              </div>
              <div className="col-lg-3">
                <input
                  className="global-input"
                  type="text"
                  id="cnpj"
                  value={notificacao.cnpj || ''}
                  placeholder="XX.XXX.XXX/0001-XX"
                  name="cnpj"
                  disabled={notificacao.compradorId && true}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-3">
                <label className="global-label">Código de fornecedor</label>
              </div>
              <div className="col-lg-3">
                <input
                  className="global-input"
                  type="text"
                  id="codigo"
                  value={notificacao.codigo || ''}
                  placeholder="123456789"
                  name="codigo"
                  disabled={notificacao.compradorId && true}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-3">
                <label className="global-label">Material / Serviços</label>
              </div>
              <div className="col-lg-9">
                <div className="dropList-elem"
                  disabled={notificacao.compradorId && true}>
                  <span className="dropList-title">{
                    (notificacao.listaMaterialServices.length > 0)
                      ? notificacao.listaMaterialServices.map(item => item.name).join(', ')
                      : 'Lista de Material / serviço'
                  }
                  </span>
                  <DropList
                    uniqid={'materialServicos'}
                    label="Lista de Material / serviço"
                    selectable
                    multSelect
                    options={materialServicos}
                    defaultValue={(notificacao.listaMaterialServices.length === 0) && []}
                    selectedOption={(id) => handleGetSelectedOption('materialServicos', id)}
                    active={(dropListSelect === 'materialServicos')}
                    onClick={handleDropList}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 p-0 d-grid aligncenter">
            <div className="column-title bg-darkgray">
              <h1>INFORMAÇÕES PARA RASTREABILIDADE</h1>
            </div>
            <div className="column-content right">
              <div className="col-lg-3">
                <label className="global-label">Nº Nota Fiscal</label>
              </div>
              <div className="col-lg-9">
                <input
                  className="global-input"
                  type="text"
                  id="notaFiscal"
                  value={notificacao.notaFiscal || ''}
                  placeholder="número"
                  name="notaFiscal"
                  disabled={notificacao.compradorId && true}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-3">
                <label className="global-label">Pedido de Compra</label>
              </div>
              <div className="col-lg-4">
                <input
                  className="global-input"
                  type="text"
                  id="pedidoCompra"
                  value={notificacao.pedidoCompra || ''}
                  placeholder="número"
                  name="pedidoCompra"
                  disabled={notificacao.compradorId && true}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-2">
                <label className="global-label">Lote</label>
              </div>
              <div className="col-lg-3">
                <input
                  className="global-input"
                  type="text"
                  id="lote"
                  value={notificacao.lote || ''}
                  placeholder="número"
                  name="lote"
                  disabled={notificacao.compradorId && true}
                  onChange={handleChange}
                />
              </div>
              <div className="col-lg-3">
                <label className="global-label">Recebimento</label>
              </div>
              <div className="col-lg-4">
                <input
                  className="global-input"
                  type="date"
                  id="recebimento"
                  value={notificacao.recebimento ? formatData(new Date(notificacao.recebimento.replace(/-/g, '\/').split('T')[0])) : ''}
                  placeholder="XX/XX/XXXX"
                  name="recebimento"
                  disabled={notificacao.compradorId && true}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="row newRow oldBg">
          <h1>ORIGEM DA NÃO CONFORMIDADE</h1>
        </div>
        <div className="row rowCardPadding">
          <div className="col-lg-1">
            <label className="global-label">Categoria</label>
          </div>
          <div className="col-lg-3">
            <div className="dropList-elem"
              disabled={notificacao.compradorId && true}>
              <span>{notificacao.provedorNotificacaoCategoria && notificacao.provedorNotificacaoCategoria.nome || 'Lista de categoria'}
              </span>
              <DropList
                uniqid={'categorias'}
                label="Categorias"
                selectable
                options={categorias}
                defaultValue={notificacao.provedorNotificacaoCategoriaId || null}
                selectedOption={(id) => handleGetSelectedOption('categorias', id)}
                active={(dropListSelect === 'categorias')}
                onClick={handleDropList}
              />
            </div>
          </div>
          <div className="col-lg-1">
            <label className="global-label">Identificação</label>
          </div>
          <div className="col-lg-3">
            <div className="dropList-elem"
              disabled={notificacao.compradorId && true}>
              <span>{notificacao.provedorNotificacaoIdentificacao && notificacao.provedorNotificacaoIdentificacao.nome || 'Lista de identificação '}
              </span>
              <DropList
                uniqid={'identificacoes'}
                label="Identificações"
                selectable
                options={identificacoes}
                defaultValue={notificacao.provedorNotificacaoIdentificacaoId || null}
                selectedOption={(id) => handleGetSelectedOption('identificacoes', id)}
                active={(dropListSelect === 'identificacoes')}
                onClick={handleDropList}
              />
            </div>
          </div>
          <div className="col-lg-1">
            <label className="global-label">Ação Interna</label>
          </div>
          <div className="col-lg-3">
            <div className="dropList-elem"
              disabled={notificacao.compradorId && true}>
              <span>{notificacao.provedorNotificacaoAcaoInterna && notificacao.provedorNotificacaoAcaoInterna.nome || 'Lista de ação interna'}
              </span>
              <DropList
                uniqid={'acoesInternas'}
                label="Ações Internas"
                selectable
                options={acoesInternas}
                defaultValue={notificacao.provedorNotificacaoAcaoInternaId || null}
                selectedOption={(id) => handleGetSelectedOption('acoesInternas', id)}
                active={(dropListSelect === 'acoesInternas')}
                onClick={handleDropList}
              />
            </div>
          </div>
          <div className="col-lg-1">
            <label className="global-label">Nível de criticidade</label>
          </div>
          <div className="col-lg-5">
            <div className="dropList-elem"
              disabled={notificacao.compradorId && true}>
              <span>{notificacao.provedorNotificacaoNivelCriticidade && notificacao.provedorNotificacaoNivelCriticidade.nome || 'Lista de nível de criticidade de não conformidade'}
              </span>
              <DropList
                uniqid={'niveisCriticidades'}
                label="Níveis de Criticidade"
                selectable
                options={niveisCriticidades}
                defaultValue={notificacao.provedorNotificacaoNivelCriticidadeId || null}
                selectedOption={(id) => handleGetSelectedOption('niveisCriticidades', id)}
                active={(dropListSelect === 'niveisCriticidades')}
                onClick={handleDropList}
              />
            </div>
          </div>
          <div className="col-lg-1">
            <label className="global-label">Impacto</label>
          </div>
          <div className="col-lg-5">
            <div className="dropList-elem"
              disabled={notificacao.compradorId && true}>
              <span>{notificacao.provedorNotificacaoImpacto && notificacao.provedorNotificacaoImpacto.nome || 'Lista de impacto de não conformidade'}
              </span>
              <DropList
                uniqid={'impactos'}
                label="Impactos"
                selectable
                options={impactos}
                defaultValue={notificacao.provedorNotificacaoImpactoId || null}
                selectedOption={(id) => handleGetSelectedOption('impactos', id)}
                active={(dropListSelect === 'impactos')}
                onClick={handleDropList}
              />
            </div>
          </div>
        </div>
        <div className="row newRow newBg">
          <h1>DESCRIÇÃO DO PROBLEMA</h1>
        </div>
        <div className="row rowCardPadding">
          <div className="col-lg-9">
            <textarea
              className="global-input"
              type="text"
              id="reclamacao"
              value={notificacao.reclamacao || ''}
              placeholder="Descrição da reclamação"
              name="reclamacao"
              disabled={notificacao.compradorId && true}
              style={{ height: '100%' }}
              onChange={handleChange}></textarea>
          </div>
          <div className="col-lg-3">
            <div id="upload-label-container">
              <label className="global-label">Upload de Documento / Registros</label>
              <p>{selectedFile.length > 0 && selectedFile[0].name}</p>
            </div>
            <button
              className="global-btn btnUpload"
              type="button"
              onClick={() => inputFile.current.click()}
              disabled={notificacao.compradorId && true}
            >
              <svg width="52" height="52" viewBox="0 0 52 52" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M28.257 42.5239C27.9049 42.6819 27.5528 42.9977 27.2008 42.9977C26.3206 43.3136 25.2644 43.3136 24.3843 42.9977C24.0322 42.8398 23.6802 42.6819 23.5041 42.5239L4.66891 29.2574L1.32434 31.3106C-0.25993 32.2582 -0.25993 34.1534 1.14831 35.2589L24.3843 51.5262C25.4405 52.1579 26.6727 52.1579 27.7289 51.5262L50.9649 35.2589C52.3731 34.3113 52.3731 32.2582 50.7888 31.3106L47.4442 29.2574L28.257 42.5239ZM0.972281 18.202L24.2083 34.4692C25.2644 35.101 26.4967 35.101 27.5528 34.4692L50.7888 18.202C52.1971 17.2544 52.1971 15.2012 50.6128 14.2536L27.5528 0.355353C26.6727 -0.118451 25.4405 -0.118451 24.5603 0.355353L1.14831 14.2536C-0.25993 15.2012 -0.435961 17.0964 0.972281 18.202Z" fill="white" />
                <path d="M36.625 15.75V19.4167C36.625 19.9029 36.3879 20.3692 35.966 20.713C35.544 21.0568 34.9717 21.25 34.375 21.25H18.625C18.0283 21.25 17.456 21.0568 17.034 20.713C16.6121 20.3692 16.375 19.9029 16.375 19.4167V15.75" stroke="#89CDD3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M32.125 9.33398L26.5 4.75065L20.875 9.33398" stroke="#89CDD3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                <path d="M26.5 4.75195L26.5 15.752" stroke="#89CDD3" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
              </svg>
              <p>Selecione os Arquivos</p>
            </button>
            <input
              type="file"
              accept=".pdf,.jpg,.png"
              id="file"
              name="file"
              className="file"
              ref={inputFile}
              onChange={handlerChangeFile}
              style={{ display: "none" }}
            />
          </div>
        </div>
        <IF condition={!notificacao.compradorId}>
          <button
            className="global-btn"
            type="submit"
            disabled={notificacao.compradorId || sending && true}
          >
            {sending ? '' : "ENVIAR"}
            {sending && <FontAwesomeIcon icon={faCircleNotch} pulse />}
          </button>
        </IF>
      </form>
    </>
  );
}
