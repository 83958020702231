import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import GroupMaterialServicesService from "../../../services/groupMaterialServicesService";
import EscopoAvaliacaoService from "../../../services/EscopoAvaliacaoService";
import DocumentLicenceService from "../../../services/DocumentLicenceService";
import PerformanceIndicatorService from "../../../services/IndicadorDesempenho";
import DocumentedInformationService from "../../../services/tiposInformacoesDocumentadasService";
import IdoneidadeIntegridadeService from "../../../services/IdoneidadeIntegridadeService";

// Escopo
import EscopoAvaliacaoComponent from "./components/escopoAvaliacao";
import EscopoAvaliacao from "../../escopoAvaliacao/index";

// Documentos e Licensa
import DocumentLicensesComponent from "./components/documentLicenses";

// Idoneidade e Integridade
import IdoneidadeIntegridadeComponent from "./components/idoneidadeIntegridade";

// Informacoes Documentadas
import DocumentedInformationComponent from "./components/documentedInformation";
import DocumentedInformation from "../../tiposInformacoesDocumentadas/index";

// Indicadores de Desempenho
import PerformanceIndicatorComponent from "./components/performanceIndicator";
import PerformanceIndicator from "../../indicadoresDesempenho/index";

export default function GroupMaterialServiceForm({ token, current, back }) {
  const [groupMaterialService, setGroupMaterialService] = useState(current);
  const [sending, setSending] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadItens, setLoadItens] = useState(0);

  // Escopo
  const [escopos, setEscopos] = useState([]);
  const [escopoEdit, setEscoposEdit] = useState(false);

  // Idoneidade e Integridade
  const [idoneidadeIntegridades, setIdoneidadeIntegridades] = useState([]);

  const getEscopoAvaliacoes = () => {
    const idGrupo = current.id ? current.id : 0;
    EscopoAvaliacaoService.getEscopoAvaliacoes(token, idGrupo)
      .then((response) => {
        setLoadItens(value => value + 1);
        setEscopos(response || []);
      });
  }

  const getIdoneidadeIntegridades = () => {
    const idGrupo = current.id ? current.id : 0;
    IdoneidadeIntegridadeService.getIdoneidadeIntegridadeAtivoComprador(token, idGrupo)
      .then((response) => {
        setLoadItens(value => value + 1);
        setIdoneidadeIntegridades(response || []);
      });
  }

  const handleEditEscopo = () => {
    setEscoposEdit(!escopoEdit);
    setDocumentedEdit(false);
    setPerformanceEdit(false);
  }

  const changeIdoneidadeIntegridade = (data) => {
    let changeIdoneidadeIntegridades = data.filter((elem) => {
      return elem.enableGroupMaterial === true;
    });

    setGroupMaterialService((value) => {
      return {
        ...value, ...{
          idoneidadeIntegridades: changeIdoneidadeIntegridades
        }
      }
    });
  }

  const changeEscopo = (data) => {
    let changeEscopos = data.filter((elem) => {
      return elem.enableGroupMaterial === true;
    });

    setGroupMaterialService((value) => {
      return {
        ...value, ...{
          escoposAvaliacao: changeEscopos
        }
      }
    });
  }

  // Documentos e Licensas
  const [documents, setDocuments] = useState([]);
  const getDocumentLicenses = () => {
    DocumentLicenceService.get(token)
      .then((response) => {
        setLoadItens(value => value + 1);
        setDocuments(response.user.valores || []);
      })
  }
  const changeDocument = (data) => {
    let changeDocuments = data.filter((elem) => {
      return elem.enableGroupMaterial === true;
    });

    setGroupMaterialService((value) => {
      return {
        ...value, ...{
          tiposDocumentosLicencas: changeDocuments
        }
      }
    });
  }

  // Informacoes Documentadas
  const [documented, setDocumented] = useState([]);
  const [documentedEdit, setDocumentedEdit] = useState(false);
  const getDocumentedInformation = () => {
    DocumentedInformationService.getAll(token)
      .then((response) => {
        setDocumented(response);
        setLoadItens(value => value + 1);
      })
  }
  const handleEditDocumented = () => {
    setEscoposEdit(false);
    setDocumentedEdit(!documentedEdit);
    setPerformanceEdit(false);
  }
  const changeDocumented = (data) => {
    let changeDocumenteds = data.filter((elem) => {
      return elem.enableGroupMaterial === true;
    });

    setGroupMaterialService((value) => {
      return {
        ...value, ...{
          tiposInformacoesDocumentadas: changeDocumenteds
        }
      }
    });
  }

  // Indicadores de Desempenho
  const [listaIndicadores, setlistaIndicadores] = useState([]);
  const [listaPadroes, setlistaPadroes] = useState([]);
  const [performanceEdit, setPerformanceEdit] = useState(false);
  const getPerformanceIndicator = () => {
    PerformanceIndicatorService.get(token)
      .then((response) => {
        setLoadItens(value => value + 1);
        setlistaIndicadores(response || []);
        setlistaPadroes(response.map((performance) => {
          return performance.padraoDesempenho
        }));
      });
  }
  const handleEditPerformance = () => {
    setEscoposEdit(false);
    setDocumentedEdit(false);
    setPerformanceEdit(!performanceEdit);
  }
  const changePerformance = (data) => {
    let padroesSelecionados = data.filter((elem) => {
      return elem.enableGroupMaterial === true;
    });
    setGroupMaterialService((value) => {
      return {
        ...value, ...{
          indicadoresDesempenho: retornaIndicadoresDesempenhoVinculadosAoPadraoSelecionado(padroesSelecionados)
        }
      }
    });
  }

  const retornaIndicadoresDesempenhoVinculadosAoPadraoSelecionado = (padroes) => {
    let indicadoresSelecionados = [];
    for (let i = 0; i < padroes.length; i++) {
      for (let j = 0; j < listaIndicadores.length; j++) {
        const indicador = listaIndicadores[j];
        if (indicador.padraoDesempenho.id == padroes[i].id) {
          indicadoresSelecionados.push(indicador)
        }
      }
    }
    return indicadoresSelecionados;
  }
  // Form
  const create = (payload) => {
    if (!payload.nome) return;
    setSending(true);

    GroupMaterialServicesService.create(token, payload)
      .then((response) => {
        if (response) {
          toast.success("Item cadastrado com sucesso!");
          setSending(false);
          back();
        }
      })
      .catch((error) => {
        toast.error("Erro ao cadastrar item!");
        setSending(false);
      });
  }

  const update = (payload) => {
    if (!payload.id && !payload.nome) return;
    setSending(true);

    GroupMaterialServicesService.update(token, payload)
      .then((response) => {
        if (response) {
          toast.success("Item editado com sucesso!");
          setSending(false);
          back();
        }
      })
      .catch((error) => {
        toast.error("Erro ao editar item!");
        setSending(false);
      });
  }

  const handleChange = (e) => {
    e.persist();

    setGroupMaterialService((values) => ({
      ...values,
      [e.target.name]: (e.target.type == 'radio') ? (e.target.value == '1' ? true : false) : e.target.value,
    }));
  };

  const handleSubmit = () => {
    if (groupMaterialService.id) {
      update(groupMaterialService);
    } else {
      create(groupMaterialService);
    }
  }

  useEffect(() => {
    setLoadItens(0);
    setLoading(true);
    getEscopoAvaliacoes();
    getIdoneidadeIntegridades();
    getDocumentLicenses();
    getDocumentedInformation();
    getPerformanceIndicator();
  }, []);

  useEffect(() => {
    if (escopoEdit) {
      setEscoposEdit(false);
    }
  }, [groupMaterialService.escoposAvaliacao]);

  useEffect(() => {
    if (loadItens === 5) setLoading(false);
  }, [loadItens]);

  return (
    <>
      {loading && (
        <div className={"loading-mask"}>
          <FontAwesomeIcon icon={faCircleNotch} pulse />
        </div>
      )}
      <div className="card-container boxshadow-none">
        <div className="card">
          <div className="card-header boxshadow-default">
            <h1>CADASTRO DE NOVO GRUPO DE MATERIAL / SERVIÇO</h1>
          </div>
          <div className="card-body p-0 boxshadow-none bg-white">
            <form className="form-container" onSubmit={(e) => {
              e.preventDefault();
            }}>
              <div className="group-input created-form m-0">
                <div className="row equal">
                  <div className="col-md-12 d-flex">
                    <label><b>Nome do grupo</b></label>
                    <input
                      className="global-input"
                      type="text"
                      id="nome"
                      value={groupMaterialService.nome || ''}
                      placeholder="Nome"
                      name="nome"
                      onChange={handleChange}
                    />
                  </div>
                </div>

                <div className="row equal rowItens">
                  <div className="col-md-6">
                    <EscopoAvaliacaoComponent
                      current={groupMaterialService}
                      escopos={escopos}
                      handleEditEscopo={handleEditEscopo}
                      change={changeEscopo}
                    />
                  </div>
                  <div className="col-md-6">
                    <DocumentLicensesComponent
                      current={groupMaterialService}
                      documents={documents}
                      change={changeDocument}
                    />
                  </div>
                </div>
                <div className="row equal">

                  {escopoEdit && (
                    <div className="col-md-12 escopoExt-component">
                      <EscopoAvaliacao state={{ token: token }} currentEscopoAvaliacao={groupMaterialService} embed={true} handleEditEscopo={handleEditEscopo} />
                    </div>
                  )}
                </div>

                <div className="row equal rowItens">
                  <div className="col-md-6">
                    <DocumentedInformationComponent
                      current={groupMaterialService}
                      documented={documented}
                      edit={handleEditDocumented}
                      change={changeDocumented}
                    />
                  </div>
                  <div className="col-md-6">
                    <PerformanceIndicatorComponent
                      current={groupMaterialService}
                      listaPadroes={listaPadroes}
                      edit={handleEditPerformance}
                      change={changePerformance}
                    />
                  </div>
                </div>
                <div className="row equal">
                  {documentedEdit && (
                    <div className="col-md-12 documentedExt-component">
                      <DocumentedInformation state={{ token: token }} back={getDocumentedInformation} />
                    </div>
                  )}
                  {performanceEdit && (
                    <div className="col-md-12 performanceExt-component">
                      <PerformanceIndicator state={{ token: token }} back={getPerformanceIndicator} />
                    </div>
                  )}
                </div>
                <div className="row equal">
                  <div className="col-md-6">
                    <IdoneidadeIntegridadeComponent current={groupMaterialService} dados={idoneidadeIntegridades} change={changeIdoneidadeIntegridade} />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="row mx-0">
        <div className="col-12 px-0 justify-content-between">
          <button
            type="button"
            className="global-btn w-auto"
            disabled={sending && true}
            onClick={back}
          >
            <svg className="mr-1" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1.47211 5.05021L5.10332 9.09061L4.31819 9.69667L0 5.05021L4.31819 0.302734L5.00518 1.00981L1.47211 5.05021ZM9.71593 1.00981L9.02894 0.302734L4.71075 5.05021L9.02894 9.69667L9.71593 8.9896L6.18286 5.05021L9.71593 1.00981Z" fill="white" />
            </svg>
            VOLTAR
          </button>
          <button
            type="button"
            className="global-btn w-auto"
            disabled={sending && true}
            onClick={handleSubmit}
          >
            {sending ? '' : groupMaterialService.id ? "SALVAR" : "CRIAR"}
            {sending && <FontAwesomeIcon icon={faCircleNotch} pulse />}
          </button>
        </div>
      </div>
    </>
  );
}