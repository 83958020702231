import React from "react";

import CriterioAvaliacao from "../Form/CriterioAvaliacao";

export default function CriteriosAvaliacaoList({ informacao, handleChange, optionSelected, listaCriteriosavaliacao }) {
    return (
        <>
            <div className="row newRow">
                <h1>CRITÉRIOS DE AVALIAÇÃO</h1>
            </div>
            <div className="row newRowPadding border-bottom">
                {optionSelected.material.id === 0 && (
                    <h4>Selecione um Material/Serviço para carregar os indicadores de desempenho</h4>
                )}

                {optionSelected.material.id !== 0 && listaCriteriosavaliacao.length === 0 && (
                    <h4>Não existem indicadores de desempenho vinculados ao Material/Serviço</h4>
                )}
                <div>
                {optionSelected.material.id !== 0 && listaCriteriosavaliacao.length > 0 && (
                    listaCriteriosavaliacao.map((criterioAvaliacao, i) => {
                    return <CriterioAvaliacao style={{width: "auto"}} key={i} criterioAvaliacao={criterioAvaliacao} especificacoes={informacao} handleChange={handleChange} />
                    }
                ) )}

                </div>
                <div className="col-12">
                    <label className="global-label">Índice</label>
                    <input
                        className="global-input"
                        type="text"
                        id="indice"
                        value={(informacao.indice || '0') + "%"}
                        placeholder=""
                        name="indice"
                        disabled={true}
                    />
                </div>
            </div>
        </>
    );

}