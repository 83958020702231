import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import Profiles from "./ListProfiles";
import Users from "./ListUsers";

import EditAccessUser from '../editAccessUser'
import EditAccess from '../editAccess'

import PlusBranco from "../../../assets/icons/plusBranco.svg";

import "../../dashboard/dashboard.scss";

export default function List({ loading, users, token, profiles }) {
  const [tab, setTab] = useState("users");
  const [searchInput, setSearchInput] = useState("");
  const [filter, setFilter] = useState("");

  const [isEditer, setIsEditer] = useState({ editType: "", id: 0 });
  const [isGoBack, setIsGoBack] = useState(false);

  const search = (value) => {
    setFilter(value)
  }

  const Tabs = () => (
    <div className="user-list-btn-container">
      <button
        className={tab === "users" ? "global-btn" : "global-white-btn"}
        onClick={() => setTab("users")}
      >
        {/* <img src={PlusBranco} /> */}
        Lista de Usuários
      </button>
      <button
        className={tab === "profiles" ? "global-btn" : "global-white-btn"}
        onClick={() => setTab("profiles")}
      >
        {/* <img src={PlusBranco} /> */}
        Lista de Perfis
      </button>

    </div>
  );

  return (
    <>
      {isGoBack && (setIsEditer({ editType: "", id: 0 }), setIsGoBack(false))}

      {isEditer.editType === "" ?
        (
          <>
            <div className="filter-search">
              <Tabs />
              <div className="user-list-search">
                <div className="user-list-search-item">
                  <input
                    type="text"
                    placeholder="Pesquisar"
                    className="search-input"
                    name="search"
                    value={searchInput}
                    onChange={(e) => setSearchInput(e.target.value)}
                  />
                  <button className="search-btn" onClick={() => search(searchInput)}>
                    {loading && <FontAwesomeIcon icon={faCircleNotch} pulse />}
                    <FontAwesomeIcon icon={faSearch} />
                  </button>
                </div>
              </div>
            </div>
            {tab === "users" && <Users initialUsers={users} token={token} filter={filter} editManageAccess={object => setIsEditer(object)} />}
            {tab === "profiles" && (
              <Profiles initialProfiles={profiles} token={token} filter={filter} editManageAccess={object => setIsEditer(object)} />
            )}
          </>
        ) // Editar gestão de acesso Lista de Usuários
        : isEditer.editType === "editar-gestao-de-acesso-user" ? (
          <EditAccessUser token={token} id={isEditer.id} goBackManageAccess={isTrue => setIsGoBack(isTrue)} />
        ) // Editar gestão de acesso Lista de Perfis
        : isEditer.editType === "editar-gestao-de-acesso" && (
          <EditAccess token={token} id={isEditer.id} goBackManageAccess={isTrue => setIsGoBack(isTrue)} />
        )
      }
    </>
  );
}
