import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  ESCOPO_AVALIACAO_TEMA: BASE_URL + "Tema",
};

export default class EscopoAvaliacaoTema {
  static create = async (token, escopoAvaliacao) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data } = await axios.post(URLS.ESCOPO_AVALIACAO_TEMA, escopoAvaliacao, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static get = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        },
      };
        
    let url = URLS.ESCOPO_AVALIACAO_TEMA

    try {
      const { data } = await axios.get(url, config);
      return data.valores || [];
    } catch (err) {
      return err;
    }
  };

  static getByEscopoId = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        },
      };
        
    let url = URLS.ESCOPO_AVALIACAO_TEMA + "/escopoAvaliaco/" + id

    try {
      const { data } = await axios.get(url, config);
      return data || [];
    } catch (err) {
      return err;
    }
  };

  static getById = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        },
      };
        
    let url = URLS.ESCOPO_AVALIACAO_TEMA + "/" + id

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static update = async (token, escopoAvaliacao) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
        
    let url = URLS.ESCOPO_AVALIACAO_TEMA
    try {
      const { data } = await axios.put(url, escopoAvaliacao, config);
      return data;
    } catch (err) {
      return err;
    }
  }

  static delete = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
      
    let url = URLS.ESCOPO_AVALIACAO_TEMA + "/" + id

    try {
      const { data } = await axios.delete(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };
}