import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import logger from "redux-logger";
import { getReducer } from "./reducer";
import AuthService from "../services/AuthService";

const store = createStore(
  getReducer(
    AuthService.get_token_storage(),
    AuthService.get_user_storage(),
    AuthService.clear_token_and_user_storage
  ),
  applyMiddleware(thunk, logger)
);

export default store;
