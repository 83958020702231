import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  ORGANISMOS_CERTIFICADORES: BASE_URL + "OrganismosCertificadores",
};

export default class OrganismoCertificadoresService {
  static getOrganismosCertificadores = async (token, nome) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.ORGANISMOS_CERTIFICADORES;

    if (nome)
      url = url + "?Nome=" + nome

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getCertifyingCompaniesByID = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.ORGANISMOS_CERTIFICADORES + "/" + id;

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  // delete
  static deleteOrganismosCertificadores = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.ORGANISMOS_CERTIFICADORES + "/" + id;
    return await axios.delete(url, config);
  };

  // update
  static updateOrganismoCertificador = async (token, organismoCertificador) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.ORGANISMOS_CERTIFICADORES;
    return await axios.put(url, organismoCertificador, config);
  };

  // create
  static registerOrganismosCertificadores = async (token, organismoCertificador) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.ORGANISMOS_CERTIFICADORES;
    return await axios.post(url, organismoCertificador, config);
  };
}
