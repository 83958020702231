import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

import ProvedorDocumentoLicencaService from "../../../../../../services/ProvedorDocumentoLicencaService";
import DocumentLicenceService from "../../../../../../services/DocumentLicenceService";
import { dataIsMaiorQueHoje } from "../../../../../../components/Common/FormataCamposComMascara";
import { MSG_ERRO_VISUALIZAR_ARQUIVO, MSG_NAO_CADASTRADO } from "../../../../../../components/Common/Constantes";

import './styles.scss'
import Loading from "../../../../../../components/Loading";

export default function AbaDocumentosLicencas({ token, provedor, comprador, handleCounts }) {

    const [documentosLicencasProvedor, setDocumentosLicencasProvedor] = useState([]);
    const [documentosLicencasComprador, setDocumentosLicencasComprador] = useState([]);
    const [listaExibicao, setListaExibicao] = useState([]);
    const [loading, setLoading] = useState(false);

    const getDocumentosLicencaProvedor = async () => {
        let response = await ProvedorDocumentoLicencaService.getDocumentLicences(token);
        setDocumentosLicencasProvedor(response);
    }

    const getDocumentosLicencaComprador = async () => {
        let response = await DocumentLicenceService.getAll(token);
        setDocumentosLicencasComprador(response.valores);
    }

    const montaLista = () => {
        var lista = [];
        var countVigentes = 0;

        if (documentosLicencasComprador.length > 0 && documentosLicencasProvedor.length > 0) {

            documentosLicencasComprador.forEach(element => {
                var itemLista = {};
                itemLista.id = element.id;
                itemLista.nome = element.nome;
                documentosLicencasProvedor.forEach(item => {

                    if (element.id === item.tipoDocumentoLicencaId) {
                        itemLista.id = item.id;
                        itemLista.vigenteAte = item.dataValidade;
                        itemLista.status = dataIsMaiorQueHoje(item.dataValidade) ? "Vigente" : "Vencido";
                        itemLista.temDocAssociado = item.nomeArquivoDocumentoAssociado ? true : false;
                        if (itemLista.status === "Vigente")
                            countVigentes++;
                        itemLista.observacao = item.observacao;
                    }
                });
                lista.push(itemLista);
            });
            setListaExibicao(lista);
            handleCounts(documentosLicencasProvedor.length, countVigentes);
        }
    }

    const handleDownload = async (item, isDocAssociado = false) => {
        setLoading(true);
        try {
            const blob = await ProvedorDocumentoLicencaService.download(token, item.id, isDocAssociado);
            if (blob && blob.type !== 'text/plain') {
                setLoading(false);

                var url = window.URL.createObjectURL(blob);
                window.open(url, "_blank");
            }
            else {
                setLoading(false);
                const blobMsg = await blob.text();
                toast.error(blobMsg);
            }
        } catch (error) {
            toast.error(MSG_ERRO_VISUALIZAR_ARQUIVO);
            setLoading(false);
        }
    }


    useEffect(() => {
        montaLista();
    }, [documentosLicencasComprador, documentosLicencasProvedor]);

    useEffect(() => {
        setLoading(true);
        getDocumentosLicencaComprador();
        getDocumentosLicencaProvedor();
        setLoading(false);
    }, []);


    return (
        <>
        <Loading loading={loading}/>
            <div>
                <div className="card-body">
                    <div className="list-container">
                        <div className="list">
                            <div className="list-header">
                                <label style={{ width: "20% !important", marginRight: "50px !important" }}>Nome do Documento e Licença</label>
                                <label>Vigente até</label>
                                <label>Status</label>
                                <label>Ver documento</label>
                                <label>Documento Associado</label>
                                <label>Observação</label>
                            </div>
                            {(listaExibicao || []).map((data, i) => (
                                <div className="list-body" key={data.id}>
                                    <span className="list-item" style={{ width: "100px !important", marginRight: "50px !important" }}>{data.nome}</span>
                                    <span className="list-item">{data.vigenteAte || MSG_NAO_CADASTRADO}</span>
                                    <span className={"list-item " + (data.status === 'Vigente' ? "green" : data.status === 'Vencido' ? "red" : MSG_NAO_CADASTRADO)}>{data.status}</span>
                                    <span className="list-item document-list" id={data.id}>
                                        {data.vigenteAte
                                            ?
                                            <a className="list-button" onClick={() => handleDownload(data)}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M19 0H5C2.3 0 0 2.2 0 5V19C0 21.7 2.2 24 5 24H19C21.8 24 24 21.8 24 19V5C24 2.3 21.8 0 19 0ZM21.3 12.3L21.2 12.4C20.1 14 18.6 15.3 16.9 16.3C13.9 18 10.1 18 7.1 16.3C5.4 15.3 3.9 14 2.8 12.4L2.7 12.3C2.6 12.1 2.6 11.8 2.7 11.6L2.8 11.5C3.9 10 5.4 8.7 7.1 7.7C10.1 6 13.9 6 16.9 7.7C18.6 8.7 20.1 10 21.2 11.6L21.3 11.7C21.5 11.8 21.5 12.2 21.3 12.3ZM12 13.2C11.4 13.2 10.9 12.7 10.9 12.1C10.9 11.5 11.4 11 12 11C12.6 11 13.1 11.5 13.1 12.1C13.1 12.7 12.6 13.2 12 13.2ZM12 8.7C10.2 8.7 8.7 10.2 8.7 12C8.7 13.8 10.2 15.3 12 15.3C13.9 15.4 15.4 13.9 15.3 12C15.3 10.2 13.8 8.7 12 8.7Z" fill="#4B4B4B" />
                                                </svg>
                                            </a>
                                            : MSG_NAO_CADASTRADO}
                                    </span>
                                    <span className="list-item document-list">
                                        {data.temDocAssociado
                                            ?
                                            <a className="list-button" onClick={() => handleDownload(data, true)}>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M19 0H5C2.3 0 0 2.2 0 5V19C0 21.7 2.2 24 5 24H19C21.8 24 24 21.8 24 19V5C24 2.3 21.8 0 19 0ZM21.3 12.3L21.2 12.4C20.1 14 18.6 15.3 16.9 16.3C13.9 18 10.1 18 7.1 16.3C5.4 15.3 3.9 14 2.8 12.4L2.7 12.3C2.6 12.1 2.6 11.8 2.7 11.6L2.8 11.5C3.9 10 5.4 8.7 7.1 7.7C10.1 6 13.9 6 16.9 7.7C18.6 8.7 20.1 10 21.2 11.6L21.3 11.7C21.5 11.8 21.5 12.2 21.3 12.3ZM12 13.2C11.4 13.2 10.9 12.7 10.9 12.1C10.9 11.5 11.4 11 12 11C12.6 11 13.1 11.5 13.1 12.1C13.1 12.7 12.6 13.2 12 13.2ZM12 8.7C10.2 8.7 8.7 10.2 8.7 12C8.7 13.8 10.2 15.3 12 15.3C13.9 15.4 15.4 13.9 15.3 12C15.3 10.2 13.8 8.7 12 8.7Z" fill="#4B4B4B" />
                                                </svg>
                                            </a>
                                            : MSG_NAO_CADASTRADO}
                                    </span>
                                    <span className="list-item">{data.observacao}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}