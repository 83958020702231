import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  DASHBOARD: BASE_URL + "modules",
};

export default class DashboardService {
  static dashboardBuyer = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.DASHBOARD + "/" + id;

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    } finally {
      // this.setState({ loading: false });
    }
  };

  static dashboardBuyerAccess = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.DASHBOARD + "/" + id + "/dashboard";

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    } finally {
      // this.setState({ loading: false });
    }
  };
}
