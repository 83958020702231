import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons";
import MaterialsServicesService from "../../../services/MaterialsServicesService";
import { toast } from "react-toastify";
import { setMaxListeners } from "process";

export default function MaterialServiceForm({token, send}) {
    const [loading, setLoading] = useState(false)
    const [nome, setNome] = useState("");

  const handleChange = (value) => {
    setNome(value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (nome === "") {
        return toast.error("Preencha o campo nome.")
    }
    handleRegister();
  };

  const handleRegister = () => {
    setLoading(true);
    MaterialsServicesService.registerMaterialService(token, nome)
      .then((response) => {
        if (response) {
          setLoading(false);
        }
        setLoading(false);
        setNome("")
        send(response)
      })
      .catch((error) => {
        setLoading(false);
      });
  };


  const history = useHistory();

  return (
    <>
      <div className="user-config-container">
        <div className="card-container">
          <div className="card">
            <div className="card-header">
              <h1>CADASTRO DE "CAMPOS" PARA DESCRIÇÃO DE MATERIAIS / SERVIÇOS</h1>
            </div>
            <div className="card-body">
              <form className="form-container" onSubmit={handleSubmit}>
                <div className="group-input created-form">
                  <label>Nome do Campo</label>
                  <input
                    className="global-input"
                    type="text"
                    id="name"
                    placeholder="Nome do novo campo"
                    name="name"
                    value={nome}
                    onChange={(e) => handleChange(e.target.value)}
                  />
                  <button className="global-btn">
                    {!loading && "CRIAR"}
                    {loading && <FontAwesomeIcon icon={faCircleNotch} pulse />}
                  </button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
