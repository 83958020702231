import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  REDESSOCIAIS: BASE_URL + "RedesSociais",
};

export default class ProvedoresService {
  static create = async (token, payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data } = await axios.post(URLS.REDESSOCIAIS, payload, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static get = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.REDESSOCIAIS

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static update = async (token, payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.REDESSOCIAIS

    try {
      const { data } = await axios.put(url, payload, config);
      return data;
    } catch (err) {
      return err;
    }
  }

  static getByID = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.URL + "/" + id

    try {
      const { data } = await axios.get(url, config)
      return data
    } catch (err) {
      return err;
    }
  };

  static delete = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.REDESSOCIAIS + "/" + id

    try {
      const { data } = await axios.delete(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };
}