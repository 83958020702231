import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  INFORMACAO_ENTRADA: BASE_URL + "InformacaoEntrada",
};

export default class InformacaoEntradaService {
  static create = async (token, payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data } = await axios.post(URLS.INFORMACAO_ENTRADA + "/Criar", payload, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static get = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        },
      };
        
    let url = URLS.INFORMACAO_ENTRADA

   

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getCriteriosAvaliacao = async (token, materialId) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        },
      };
    let url = URLS.INFORMACAO_ENTRADA + `/GetCriteriosAvaliacao/${materialId}`;

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static update = async (token, payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
          
    let url = URLS.INFORMACAO_ENTRADA
    try {
      const { data } = await axios.put(url, payload, config);
      return data;
    } catch (err) {
      return err;
    }
  }

  static delete = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
      
    let url = URLS.INFORMACAO_ENTRADA + "/" + id
    try {
      const { data } = await axios.delete(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };
}