import React, {useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import arrowRightIcon from "../../../assets/icons/iconArrowRight.svg";
import arrowDownIcon from "../../../assets/icons/iconArrowDown.svg";
import "./sidebar.scss";

export default function Index({ menuStorage, handleSideMenu }) {
  const history = useHistory();
  const [sideMenuActive, setSideMenuState] = useState(false);

  const [expandedModuleIndex, setExpanded] = useState(null);

  const toggle = (index) => {
    if (index === expandedModuleIndex)
      setExpanded(null)
    else {
      setExpanded(index)
    }
  }

  let lastIndex =
    menuStorage && menuStorage.length > 0 ? menuStorage.length - 1 : 0;

  useEffect(() => {
    handleSideMenu(sideMenuActive);
    // eslint-disable-next-line
  }, [sideMenuActive]);

  const handleRedirect = (route) => {
    history.push(route);
  };

  return (
    <div className={`menu ${sideMenuActive ? "" : "slide"}`}>

      <div className="side-logo-container">
        <div className="side-logo" onClick={() => handleRedirect('/dashboard')}></div>
      </div>

      {menuStorage &&
        menuStorage.length > 0 &&
        menuStorage
          .filter((a) => a.name !== "Dashboard")
          .map((m, index) => {
            return (
              <>
                <div key={index}>

                  <div className="menu-module cursor-pointer" onClick={() => toggle(index)}>

                    <p>{m && m.name}</p>

                    <div>
                      {expandedModuleIndex === index
                        ? <img src={arrowDownIcon} alt="Arrow Down" />
                        : <img src={arrowRightIcon} alt="Arrow Right" />
                      }
                    </div>
                  </div>

                  {expandedModuleIndex !== index ? '' : m.subModules.map((s, i) => {
                    return (<>
                      <div className="sub-module cursor-pointer" key={i}>
                        <div className="text-center">
                          <img src={require(`../../../assets/icons/${s.icon}`)}
                            alt="Arrow Right" />
                        </div>

                        <span onClick={() => handleRedirect(`/${m.url}/${s.url}`)}>{s.name}</span>

                      </div>
                    </>)
                  })}

                </div>
                {lastIndex != index && <div className="menu-separator" />}
              </>
            );
          })}

    </div>
  );
}
