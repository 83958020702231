import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  TIPO_CERTIFICACAO: BASE_URL + "TipoCertificacao",
};

export default class TipoCertificacaoService {
  static create = async (token, tipoCertificacao) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data } = await axios.post(URLS.TIPO_CERTIFICACAO, tipoCertificacao, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static get = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        },
      };
        
    let url = URLS.TIPO_CERTIFICACAO

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getById = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        },
      };
        
    let url = URLS.TIPO_CERTIFICACAO + "/" + id;

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static update = async (token, tipoCertificacao) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
        
    let url = URLS.TIPO_CERTIFICACAO
    try {
      const { data } = await axios.put(url, tipoCertificacao, config);
      return data;
    } catch (err) {
      return err;
    }
  }

  static delete = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
      
    let url = URLS.TIPO_CERTIFICACAO + "/" + id

    try {
      const { data } = await axios.delete(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };
}