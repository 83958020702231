import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";

import DashboardService from "../services/DashboardService";

import { actions } from "../redux/actions";

import Navbar from "./layout/navbar";
import Sidebar from "./layout/sidebar";

import "./PrivateAppContainer.scss";

let menuStorage = JSON.parse(localStorage.getItem("menuStorage"));
let profile = JSON.parse(localStorage.getItem("profile"));
// let user_logged_storage = JSON.parse(
//   localStorage.getItem("user_logged_storage")
// );
export default function PrivateppContainer(props) {
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const [closedMenu, setClosedMenu] = useState(true);
  const state = useSelector((state) => state);
  const { token, user } = state;

  // useEffect(() => {
  //   return () => {
  //     if (user_logged_storage === false) {
  //       alert("user_logged_storage", user_logged_storage);
  //       localStorage.clear();
  //       window.location.reload();
  //     }
  //   };
  // }, []);
  // console.log(user_logged_storage);

  const dashboardBuyer = (id) => {
    DashboardService.dashboardBuyer(token, id)
      .then((response) => {
        localStorage.setItem("menuStorage", JSON.stringify(response));
        window.location.reload();
      })
      .catch((error) => {
        setError(error);
      });
  };

  const VerifyDashboardBuyer = () => {
    let id = "";

    if (!profile) {
      if (user && user.profiles && user.profiles.length > 0) {
        id = user.profiles[0].profileId;
      }
    }

    if (profile && profile.profileId) {
      id = profile && profile.profileId;
    }

    dashboardBuyer(id);
  };

  const dashboardBuyerAccess = () => {
    let id = "";

    if (!profile) {
      if (user && user.profiles && user.profiles.length > 0) {
        id = user.profiles[0].profileId;
      }
    }

    if (profile && profile.profileId) {
      id = profile && profile.profileId;
    }

    DashboardService.dashboardBuyerAccess(token, id)
      .then((response) => {
        if (response) {
          VerifyDashboardBuyer();
        }
      })
      .catch((error) => {
        setError(error);
      });
  };

  useEffect(() => {
    if (!menuStorage) dashboardBuyerAccess();
    if (!profile && user && user.profiles && user.profiles.length > 0) {
      localStorage.setItem("profile", JSON.stringify(user.profiles[0]));
    }
    // eslint-disable-next-line
  }, []);

  const handleSelect = (profile) => {
    localStorage.setItem("profile", JSON.stringify(profile));
    dashboardBuyer(profile.profileId);
    // dashboardBuyerAccess();
  };

  const handleLogout = () => {
    dispatch(actions.logout());
  };

  const handleSideMenu = (value) => {
    setClosedMenu(value);
  };

  let styles = closedMenu ? "" : "294px";

  return (
    <div id="private-app-wrapper" className="private-app">
      <Navbar
        user={user}
        profile={profile}
        handleLogout={handleLogout}
        handleSelect={handleSelect}
        error={error}
      />
      <Sidebar menuStorage={menuStorage} handleSideMenu={handleSideMenu} />

      <div
        id="private-app-content"
        style={{ marginLeft: styles, transition: "all 0.3s linear" }}
      >
        {props.children}
      </div>
    </div>
  );
}
