import React from "react";

// import "../private-app/dashboard/dashboard.scss";

export default function PlusTooltip({ label, click }) {
  return (
    <>
      <div className={'PlusTooltip'} onClick={click}>
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fillRule="evenodd" clipRule="evenodd" d="M3.75 18H14.25C16.35 18 18 16.35 18 14.25V3.75C18 1.725 16.35 0 14.25 0H3.75C1.725 0 0 1.65 0 3.75V14.25C0 16.275 1.65 18 3.75 18ZM11.25 11.25H14.25V6.75H11.25V3.75H6.75V6.75H3.75V11.25H6.75V14.25H11.25V11.25Z" fill="#558CE5"/>
        </svg>
        <div className="PlusTooltip-boxlabel">
          <span className="PlusTooltip-label">{label}</span>
        </div>
      </div>
    </>
  );
}
