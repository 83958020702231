import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import InputRadio from "../../../components/InputRadio/InputRadio";

import DocumentLicenceService from "../../../services/DocumentLicenceService";

export default function DocumentLicenceForm({ token, current, callback }) {
  const [license, setLicense] = useState(current);
  const [sending, setSending] = useState(false);

  const handleGeraBloqueio = (e) => {
    e.persist();

    setLicense((values) => ({
      ...values,
      geraBloqueioHomologacao: e.target.value == 1 ? true : false,
    }));
  }

  const handleChange = (e) => {
    e.persist();

    setLicense((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (license.id) {
      update(license);
    } else {
      create(license);
    }
  }

  const create = (payload) => {
    if (!payload.nome) {
      toast.error("Por favor, informe o nome do documento");
      return;
    }
    setSending(true);

    DocumentLicenceService.create(token, payload.nome, payload.geraBloqueioHomologacao)
      .then((response) => {
        if (response) {
          toast.success("Item cadastrado com sucesso!");
          setLicense({});
          setSending(false);
          callback();
        }
      })
      .catch((error) => {
        toast.error("Erro ao cadastrar item!");
        setSending(false);
      });
  }

  const update = (payload) => {
    if (!payload.id && !payload.nome) {
      toast.error("Por favor, informe o nome do documento");
      return;
    }
    setSending(true);

    DocumentLicenceService.update(token, payload.nome, payload.geraBloqueioHomologacao, payload.id)
      .then((response) => {
        if (response) {
          toast.success("Item editado com sucesso!");
          setLicense({});
          setSending(false);
          callback();
        }
      })
      .catch((error) => {
        toast.error("Erro ao editar item!");
        setSending(false);
      });
  }

  const isComprador = () => {
    const profile = JSON.parse(localStorage.getItem("profile"));
    return (profile.profileId === 2);
  };

  useEffect(() => {
    if (current) {
      setLicense(current)
    }
  }, [current]);

  return (
    <>
      <div className="card-container">
        <div className="card">
          <div className="card-header">
            <h1>CADASTRO DE NOVO DOCUMENTO E LICENÇA</h1>
          </div>
          <div className="card-body">
            <form
              className="form-container"
              onSubmit={handleSubmit}>
              <div>
                <div className="group-input created-form gt" style={{ display: "flex", flexDirection: "column" }}>
                  <div className="group-input created-form gt" >
                    <label><b>Nome do documento</b></label>
                    <input
                      className="global-input"
                      type="text"
                      id="nome"
                      style={{ marginRight: "120px" }}
                      value={license.nome || ''}
                      placeholder="Novo Documento"
                      name="nome"
                      onChange={handleChange}
                    />
                  </div>
                  {isComprador() && <div style={{ width: "100%", display: "flex", marginTop: '20px', alignItems: 'center' }}>
                    <div>
                      <label>Identidade de usuário</label>
                    </div>
                    <div style={{ display: 'inline-flex', width: 400 }}>
                      <InputRadio
                        key={1}
                        name={'escopo-1'}
                        label="Sim"
                        value="1"
                        checked={(license.geraBloqueioHomologacao === true) ? '1' : license.geraBloqueioHomologacao}
                        onChange={handleGeraBloqueio}
                      />
                      <InputRadio
                        key={2}
                        name={'escopo-2'}
                        label="Não"
                        value="0"
                        checked={!license.geraBloqueioHomologacao ? '0' : license.geraBloqueioHomologacao}
                        onChange={handleGeraBloqueio}
                      />
                    </div>
                  </div>
                  }
                  <div style={{ marginLeft: "auto" }}>
                    <button
                      className="global-btn"
                      disabled={sending && true}
                    >
                      {sending ? '' : license.id ? "SALVAR" : "CRIAR"}
                      {sending && <FontAwesomeIcon icon={faCircleNotch} pulse />}
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
