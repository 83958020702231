import React, { useRef } from "react";

import ThemeListItem from "./themeListItem";

export default function List({
  escopo, temas, perguntas, create, comprador,
  enableTheme, editTheme, removeTheme,
  enableQuestion, editQuestion, removeQuestion, token, getTheme, scrollToTop
}) {
  return (
    <>
      <div className="card-container">
        <div className="card">
          <div className="card-header">
            <h1>{escopo.nome}</h1>
          </div>
          <div className="card-body p-0">
            <div className="list-container">
              <div className="list listEscopoAvaliacaoTemas">
                <div className="list-header">
                  <label>Temas / Perguntas</label>
                  <label>Ativar / Desativar</label>
                  <label>Editar</label>
                </div>
                {
                  (temas || []).map((data, i) => (
                    <ThemeListItem
                      token={token}
                      data={data}
                      perguntas={perguntas.filter(elem => {
                        return elem.temaId == data.id
                      })}
                      create={create}
                      comprador={comprador}
                      enable={enableTheme}
                      edit={editTheme}
                      remove={removeTheme}

                      enableQuestion={enableQuestion}
                      editQuestion={editQuestion}
                      removeQuestion={removeQuestion}
                      getTheme={getTheme}
                      scrollToTop={scrollToTop}
                    />
                  ))
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}