import React from "react";


export default function ImgRobo() {

    return (
        <svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <g filter="url(#filter0_i_2746_511)">
                <ellipse cx="98.5" cy="182.5" rx="69.5" ry="15.5" fill="#AAAAAA" />
            </g>
            <ellipse cx="99" cy="182" rx="45" ry="10" fill="url(#paint0_radial_2746_511)" />
            <path d="M99.5004 87.5C80.0004 87.5 50.8204 131.18 53.5004 150.5C55.5004 164.96 75.5004 186.5 99.5004 186.5C125.5 186.5 144.95 165.22 146.5 150.5C148.5 131.5 121.5 87.5 99.5004 87.5Z" fill="url(#paint1_radial_2746_511)" />
            <path d="M95.33 86.21H105.66C105.967 86.2087 106.271 86.268 106.555 86.3845C106.839 86.501 107.097 86.6724 107.314 86.8889C107.531 87.1054 107.704 87.3627 107.822 87.646C107.939 87.9294 108 88.2332 108 88.54C108 90.2533 107.319 91.8964 106.108 93.1079C104.896 94.3194 103.253 95 101.54 95H99.46C97.7467 95 96.1036 94.3194 94.8921 93.1079C93.6806 91.8964 93 90.2533 93 88.54C93 87.922 93.2455 87.3294 93.6824 86.8924C94.1194 86.4555 94.712 86.21 95.33 86.21Z" fill="black" />
            <path d="M95.33 76H105.66C105.967 75.9987 106.271 76.058 106.555 76.1745C106.839 76.291 107.097 76.4624 107.314 76.6789C107.531 76.8954 107.704 77.1527 107.822 77.4361C107.939 77.7194 108 78.0232 108 78.33V87.54C108 89.2533 107.319 90.8964 106.108 92.1079C104.896 93.3194 103.253 94 101.54 94H99.46C97.7467 94 96.1036 93.3194 94.8921 92.1079C93.6806 90.8964 93 89.2533 93 87.54V78.33C93 77.7121 93.2455 77.1194 93.6824 76.6825C94.1194 76.2455 94.712 76 95.33 76Z" fill="url(#paint2_radial_2746_511)" />
            <path d="M106 111H96C95.2044 111 94.4413 110.684 93.8787 110.121C93.3161 109.559 93 108.796 93 108V101C93 100.204 93.3161 99.4413 93.8787 98.8787C94.4413 98.3161 95.2044 98 96 98H106C106.796 98 107.559 98.3161 108.121 98.8787C108.684 99.4413 109 100.204 109 101V108C109 108.796 108.684 109.559 108.121 110.121C107.559 110.684 106.796 111 106 111Z" fill="black" />
            <path d="M62.5 123.5C62.5 123.5 87.5 145.5 88.5 184.5" stroke="#282828" strokeLinecap="round" strokeLinejoin="round" />
            <path d="M137.5 123.5C137.5 123.5 112.5 144.5 111.5 183.5" stroke="#282828" strokeLinecap="round" strokeLinejoin="round" />
            <path opacity="0.1" d="M90.7608 91.9496C91.33 92.8414 92.0794 93.6044 92.9608 94.1896C93.1908 94.3396 93.2008 94.7596 92.9608 94.8096C88.5308 95.4996 80.6108 101.39 80.6108 101.39C80.5255 101.433 80.4277 101.445 80.3344 101.424C80.2411 101.402 80.1582 101.349 80.1001 101.273C80.0419 101.197 80.0122 101.103 80.0159 101.008C80.0197 100.912 80.0568 100.821 80.1208 100.75C82.3808 98.3296 86.6208 94.0096 90.1208 91.7496C90.2339 91.7021 90.3602 91.6961 90.4773 91.7327C90.5945 91.7693 90.6949 91.8461 90.7608 91.9496Z" fill="white" />
            <path opacity="0.1" d="M109.42 91.9496C108.867 92.8375 108.135 93.6004 107.27 94.1896C107.05 94.3396 107.04 94.7596 107.27 94.8096C111.61 95.4996 119.38 101.39 119.38 101.39C119.465 101.428 119.561 101.436 119.651 101.413C119.741 101.389 119.82 101.336 119.876 101.262C119.932 101.187 119.961 101.096 119.958 101.003C119.955 100.91 119.921 100.82 119.86 100.75C117.65 98.3296 113.49 94.0096 110.1 91.7496C109.983 91.6882 109.847 91.6748 109.72 91.7121C109.593 91.7494 109.486 91.8346 109.42 91.9496Z" fill="white" />
            <path opacity="0.2" d="M65 124C65 124 89 141 90 181C90 181 92 163 86 147C82.2837 136.878 74.743 128.619 65 124Z" fill="white" />
            <path opacity="0.2" d="M135.32 124C135.32 124 111.32 141 110.32 181C110.32 181 108.32 163 114.32 147C118.036 136.878 125.577 128.619 135.32 124Z" fill="white" />
            <path d="M148 53H144.53C144 53 143.491 52.7893 143.116 52.4142C142.741 52.0391 142.53 51.5304 142.53 51V38C142.53 37.4696 142.741 36.9609 143.116 36.5858C143.491 36.2107 144 36 144.53 36H148C149.061 36 150.078 36.4214 150.828 37.1716C151.579 37.9217 152 38.9391 152 40V49C152 50.0609 151.579 51.0783 150.828 51.8284C150.078 52.5786 149.061 53 148 53Z" fill="url(#paint3_radial_2746_511)" />
            <path d="M139.5 61H140.29C144.432 61 147.79 57.6421 147.79 53.5V35.5C147.79 31.3579 144.432 28 140.29 28H139.5C135.358 28 132 31.3579 132 35.5V53.5C132 57.6421 135.358 61 139.5 61Z" fill="url(#paint4_radial_2746_511)" />
            <path d="M53 53H56.47C57.0004 53 57.5091 52.7893 57.8842 52.4142C58.2593 52.0391 58.47 51.5304 58.47 51V38C58.47 37.4696 58.2593 36.9609 57.8842 36.5858C57.5091 36.2107 57.0004 36 56.47 36H53C51.9391 36 50.9217 36.4214 50.1716 37.1716C49.4214 37.9217 49 38.9391 49 40V49C49 50.0609 49.4214 51.0783 50.1716 51.8284C50.9217 52.5786 51.9391 53 53 53Z" fill="url(#paint5_radial_2746_511)" />
            <path d="M61.5 61H60.71C56.5678 61 53.21 57.6421 53.21 53.5V35.5C53.21 31.3579 56.5678 28 60.71 28H61.5C65.6421 28 69 31.3579 69 35.5V53.5C69 57.6421 65.6421 61 61.5 61Z" fill="url(#paint6_radial_2746_511)" />
            <ellipse cx="100.5" cy="45.5" rx="44.5" ry="39.5" fill="url(#paint7_radial_2746_511)" />
            <path d="M126.5 50.5C126.5 50.5 106.63 51.5 100 51.5C93.12 51.5 72.5 50.5 72.5 50.5C71.1739 50.5 69.9021 49.9732 68.9645 49.0355C68.0268 48.0979 67.5 46.8261 67.5 45.5V31.5C67.5 30.1739 68.0268 28.9021 68.9645 27.9645C69.9021 27.0268 71.1739 26.5 72.5 26.5C72.5 26.5 92.75 25.5 99.5 25.5C106.25 25.5 126.5 26.5 126.5 26.5C127.826 26.5 129.098 27.0268 130.036 27.9645C130.973 28.9021 131.5 30.1739 131.5 31.5V45.5C131.5 46.8261 130.973 48.0979 130.036 49.0355C129.098 49.9732 127.826 50.5 126.5 50.5Z" fill="black" />
            <path opacity="0.1" d="M114.42 16.5C114.42 16.5 132.31 31.26 124.84 57.5C121.71 68.5 118.59 73.5 118.59 73.5C120.67 72.5 142.93 62.62 138.4 36.5C136.32 24.5 123.81 15.5 123.81 15.5C120.659 15.5861 117.519 15.9205 114.42 16.5Z" fill="white" />
            <path opacity="0.1" d="M85.5011 16.5C85.5011 16.5 68.3411 31.26 75.5011 57.5C78.5011 68.5 81.5011 73.5 81.5011 73.5C79.5011 72.5 58.1511 62.62 62.5011 36.5C64.5011 24.5 76.5011 15.5 76.5011 15.5C79.5234 15.5866 82.5335 15.9211 85.5011 16.5Z" fill="white" />
            <path d="M126.5 50.5C126.5 50.5 106.63 51.5 100 51.5C93.12 51.5 72.5 50.5 72.5 50.5C71.1739 50.5 69.9021 49.9732 68.9645 49.0355C68.0268 48.0979 67.5 46.8261 67.5 45.5V31.5C67.5 30.1739 68.0268 28.9021 68.9645 27.9645C69.9021 27.0268 71.1739 26.5 72.5 26.5C72.5 26.5 92.75 25.5 99.5 25.5C106.25 25.5 126.5 26.5 126.5 26.5C127.826 26.5 129.098 27.0268 130.036 27.9645C130.973 28.9021 131.5 30.1739 131.5 31.5V45.5C131.5 46.8261 130.973 48.0979 130.036 49.0355C129.098 49.9732 127.826 50.5 126.5 50.5Z" fill="black" />
            <rect style={{mixBlendMode:"screen", x:"71", y:"23", width:"1", height:"1", fill:"url(#pattern0)"}}  />
            <g style={{mixBlendMode:"screen", opacity:"0.75"}}>
                <g style={{mixBlendMode:"screen", opacity:"0.75"}}>
                    <path d="M86.5 32.5H82.5C81.3954 32.5 80.5 33.3954 80.5 34.5V42.5C80.5 43.6046 81.3954 44.5 82.5 44.5H86.5C87.6046 44.5 88.5 43.6046 88.5 42.5V34.5C88.5 33.3954 87.6046 32.5 86.5 32.5Z" fill="#4BE0E0" />
                </g>
                <g style={{mixBlendMode:"screen", opacity:"0.75"}} >
                    <path d="M86.5 32.5H82.5C81.3954 32.5 80.5 33.3954 80.5 34.5V42.5C80.5 43.6046 81.3954 44.5 82.5 44.5H86.5C87.6046 44.5 88.5 43.6046 88.5 42.5V34.5C88.5 33.3954 87.6046 32.5 86.5 32.5Z" stroke="#4BE0E0" strokeMiterlimit="10" />
                </g>
            </g>
            <path d="M86.5 32.5H82.5C81.3954 32.5 80.5 33.3954 80.5 34.5V42.5C80.5 43.6046 81.3954 44.5 82.5 44.5H86.5C87.6046 44.5 88.5 43.6046 88.5 42.5V34.5C88.5 33.3954 87.6046 32.5 86.5 32.5Z" fill="#008EEE" />
            <path d="M86.5 32.5H82.5C81.3954 32.5 80.5 33.3954 80.5 34.5V42.5C80.5 43.6046 81.3954 44.5 82.5 44.5H86.5C87.6046 44.5 88.5 43.6046 88.5 42.5V34.5C88.5 33.3954 87.6046 32.5 86.5 32.5Z" stroke="#55A2FF" strokeMiterlimit="10" />
            <rect style={{mixBlendMode:"screen",  x:"101", y:"23", width:"1", height:"1", fill:"url(#pattern1)"}} />
            <path d="M116.5 32.5H112.5C111.395 32.5 110.5 33.3954 110.5 34.5V42.5C110.5 43.6046 111.395 44.5 112.5 44.5H116.5C117.605 44.5 118.5 43.6046 118.5 42.5V34.5C118.5 33.3954 117.605 32.5 116.5 32.5Z" fill="#008EEE" />
            <path d="M116.5 32.5H112.5C111.395 32.5 110.5 33.3954 110.5 34.5V42.5C110.5 43.6046 111.395 44.5 112.5 44.5H116.5C117.605 44.5 118.5 43.6046 118.5 42.5V34.5C118.5 33.3954 117.605 32.5 116.5 32.5Z" stroke="#55A2FF" strokeMiterlimit="10" />
            <path opacity="0.2" d="M131.5 37.5V45.5C131.5 46.8261 130.973 48.0979 130.036 49.0355C129.098 49.9732 127.826 50.5 126.5 50.5C126.5 50.5 106.63 51.5 100 51.5C93.12 51.5 72.5 50.5 72.5 50.5C71.1739 50.5 69.9021 49.9732 68.9645 49.0355C68.0268 48.0979 67.5 46.8261 67.5 45.5V37.5" fill="white" />
            <path d="M105.122 103.008C104.587 102.941 104.074 103.32 104.007 103.877C103.94 104.413 104.319 104.925 104.877 104.992C105.435 105.059 105.925 104.68 105.992 104.123C106.059 103.587 105.658 103.075 105.122 103.008Z" fill="white" />
            <path d="M103.288 105.073C103.103 105.033 102.917 105.053 102.753 
            105.114C102.609 105.114 102.403 105.093 102.28 104.952L101.951 104.566C101.889 104.425 
            101.868 104.242 101.93 104.1C101.951 104.039 101.951 103.979 101.951 103.938C101.951 
            103.431 101.539 103.026 101.046 103.026C100.943 103.026 100.86 103.046 100.758 103.
            066C100.593 103.107 100.408 103.066 100.264 102.965L99.9348 102.58C99.8936 102.478 99.
            873 102.377 99.8936 102.276C99.8319 102.478 99.6879 102.641 99.5234 102.742C99.7702 102.
            58 99.9348 102.316 99.9348 101.992C99.9348 101.87 99.9142 101.769 99.873 101.647L99.8113
             101.607C99.7702 101.485 99.7702 101.363 99.8319 101.242L100.284 100.796C100.367 100.735 
             100.47 100.715 100.572 100.735C100.819 100.877 101.128 100.877 101.395 100.735C101.498 
             100.694 101.621 100.735 101.704 100.796L102.136 101.282C102.197 101.343 102.218 101.445
              102.177 101.526C101.93 101.972 102.115 102.519 102.568 102.762C103.02 103.005 103.576 
              102.823 103.822 102.377C103.966 102.113 103.966 101.789 103.822 101.526C103.617 101.12 
              103.123 100.938 102.691 101.1C102.568 101.12 102.444 101.08 102.362 100.978C102.362 100.
              978 102.033 100.613 102.012 100.593L101.909 100.471C101.868 100.431 101.848 100.35 101.868
               100.269C101.909 100.167 101.93 100.046 101.93 99.9444C101.93 99.8835 101.93 99.8227 101.909 
               99.7619C101.827 99.2754 101.354 98.9308 100.86 99.0118C100.428 99.0929 100.099 99.4578 100.
               099 99.9038C100.099 99.9444 100.099 100.025 100.099 100.025C100.12 100.107 100.12 100.188 
               100.161 100.269C100.182 100.33 100.182 100.411 100.12 100.471L99.9965 100.553C99.9759 100.
               573 99.6468 100.897 99.6468 100.897C99.5234 101.019 99.3382 101.08 99.1737 101.059C99.112 
               101.039 99.0297 101.039 98.968 101.039C98.4537 101.039 98.0218 101.445 98.0012 101.951C97.
               9806 102.458 98.4126 102.884 98.9268 102.904C99.0503 102.904 99.1737 102.884 99.2765 102.
               843C99.4205 102.823 99.5645 102.843 99.6879 102.904L100.079 103.33C100.14 103.431 100.14 
               103.553 100.12 103.654C100.12 103.674 100.099 103.715 100.099 103.735C100.099 103.756 100.
               099 103.776 100.099 103.796C100.017 104.283 100.367 104.749 100.86 104.81C100.902 104.81 
               100.963 104.81 101.004 104.81C101.046 104.81 101.107 104.81 101.148 104.789C101.148 104.789 
               101.58 104.749 101.724 104.87L102.115 105.296C102.177 105.398 102.177 105.519 102.156 105.621C102.
               136 105.661 102.115 105.702 102.115 105.742C102.074 105.925 102.074 106.107 102.156 106.29L102.177
                106.33C102.218 106.452 102.218 106.573 102.156 106.695L101.704 107.141C101.621 107.202 101.519 
                107.222 101.416 107.202C101.169 107.06 100.86 107.06 100.593 107.182C100.49 107.222 100.367 107.182 
                100.284 107.121L99.8525 106.634C99.8113 106.594 99.7908 106.492 99.8113 106.431C100.058 105.985 
                99.873 105.438 99.4205 105.195C98.968 104.952 98.4126 105.134 98.1658 105.58C98.0218 105.844 
                98.0218 106.168 98.1658 106.452C98.3715 106.857 98.8651 107.04 99.2971 106.877C99.4205 106.857 
                99.5439 106.898 99.6262 106.999C99.6262 106.999 99.9553 107.364 99.9759 107.384L100.079 107.506C100.
                12 107.567 100.14 107.648 100.14 107.729C99.9348 108.195 100.161 108.742 100.634 108.925C101.107 
                109.128 101.663 108.905 101.848 108.438C101.889 108.337 101.93 108.215 101.93 108.094C101.93 108.
                053 101.93 107.972 101.93 107.972C101.909 107.891 101.889 107.81 101.868 107.729C101.848 107.668 
                101.848 107.587 101.909 107.526L102.012 107.425C102.033 107.405 102.362 107.08 102.362 107.08C102.
                485 106.959 102.671 106.898 102.835 106.918C103.349 107.04 103.843 106.736 103.966 106.229C104.11 
                105.702 103.781 105.195 103.288 105.073Z" fill="white" />
            <path d="M97.157 103.013C96.6187 102.923 96.1028 103.305 96.0131 103.843C95.9234 104.381 96.3047 104.897 96.843 104.987C97.3813 105.077 97.8972 104.695 97.9869 104.157C98.0766 103.619 97.6953 103.103 97.157 103.013Z" fill="white" />
            <defs>
                <filter id="filter0_i_2746_511" x="29" y="167" width="139" height="35" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                    <feBlend mode="normal" in2="shape" result="effect1_innerShadow_2746_511" />
                </filter>
                <pattern id="pattern0" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use xlinkHref="#image0_2746_511" transform="scale(0.037037 0.0322581)" />
                </pattern>
                <pattern id="pattern1" patternContentUnits="objectBoundingBox" width="1" height="1">
                    <use xlinkHref="#image0_2746_511" transform="scale(0.037037 0.0322581)" />
                </pattern>
                <radialGradient id="paint0_radial_2746_511" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(99 182) rotate(90) scale(10 45)">
                    <stop offset="0.638889" stopColor="#838383" />
                    <stop offset="1" stopColor="#838383" stopOpacity="0" />
                </radialGradient>
                <radialGradient id="paint1_radial_2746_511" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(99.9704 137) scale(48.09)">
                    <stop stopColor="white" />
                    <stop offset="0.17" stopColor="#F9F9F9" />
                    <stop offset="0.39" stopColor="#E8E8E8" />
                    <stop offset="0.64" stopColor="#CCCCCC" />
                    <stop offset="0.92" stopColor="#A5A5A5" />
                    <stop offset="1" stopColor="#999999" />
                </radialGradient>
                <radialGradient id="paint2_radial_2746_511" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(1358.29 1714) scale(112.05 162)">
                    <stop stopColor="white" />
                    <stop offset="0.17" stopColor="#F9F9F9" />
                    <stop offset="0.39" stopColor="#E8E8E8" />
                    <stop offset="0.6399" stopColor="#6A6A6A" />
                    <stop offset="0.64" stopColor="#CCCCCC" />
                    <stop offset="0.722222" stopColor="#A5A5A5" />
                    <stop offset="0.800347" stopColor="#999999" />
                </radialGradient>
                <radialGradient id="paint3_radial_2746_511" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(147.05 44.5) rotate(-90) scale(6.8 42.976)">
                    <stop stopColor="white" />
                    <stop offset="0.03" stopColor="#F5F5F5" />
                    <stop offset="0.19" stopColor="#CACACA" />
                    <stop offset="0.35" stopColor="#A6A6A6" />
                    <stop offset="0.51" stopColor="#8A8A8A" />
                    <stop offset="0.67" stopColor="#767676" />
                    <stop offset="0.83" stopColor="#6A6A6A" />
                    <stop offset="1" stopColor="#666666" />
                </radialGradient>
                <radialGradient id="paint4_radial_2746_511" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(139.95 44.5) rotate(-90) scale(12.82 107.944)">
                    <stop stopColor="#B3B3B3" />
                    <stop offset="0.03" stopColor="#AEAEAE" />
                    <stop offset="0.21" stopColor="#8B8B8B" />
                    <stop offset="0.4" stopColor="#707070" />
                    <stop offset="0.6" stopColor="#5C5C5C" />
                    <stop offset="0.79" stopColor="#515151" />
                    <stop offset="1" stopColor="#4D4D4D" />
                </radialGradient>
                <radialGradient id="paint5_radial_2746_511" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(53.95 44.5) rotate(-90) scale(6.8 42.976)">
                    <stop stopColor="white" />
                    <stop offset="0.03" stopColor="#F5F5F5" />
                    <stop offset="0.19" stopColor="#CACACA" />
                    <stop offset="0.35" stopColor="#A6A6A6" />
                    <stop offset="0.51" stopColor="#8A8A8A" />
                    <stop offset="0.67" stopColor="#767676" />
                    <stop offset="0.83" stopColor="#6A6A6A" />
                    <stop offset="1" stopColor="#666666" />
                </radialGradient>
                <radialGradient id="paint6_radial_2746_511" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(61.05 44.5) rotate(-90) scale(12.82 107.944)">
                    <stop stopColor="#B3B3B3" />
                    <stop offset="0.03" stopColor="#AEAEAE" />
                    <stop offset="0.21" stopColor="#8B8B8B" />
                    <stop offset="0.4" stopColor="#707070" />
                    <stop offset="0.6" stopColor="#5C5C5C" />
                    <stop offset="0.79" stopColor="#515151" />
                    <stop offset="1" stopColor="#4D4D4D" />
                </radialGradient>
                <radialGradient id="paint7_radial_2746_511" cx="0" cy="0" r="1" gradientUnits="userSpaceOnUse" gradientTransform="translate(100.5 45.5) rotate(90) scale(39.5 44.5)">
                    <stop stopColor="white" />
                    <stop offset="1" stopColor="#9F9F9F" />
                </radialGradient>
                <image id="image0_2746_511" width="27" height="31" xlinkHref="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABsAAAAfCAYAAAAWRbZDAAAACXBIWXMAAAsSAAALEgHS3X78AAAC50lEQVRIS8VWS2sTURROa2t91QpKfeADN4q2IJqV1kUwGpN5BEGyEaw7F0pxU1yIi9kopd250I0rVxrQvSBUcSUUESTS0Jl77ySlFPojxu+7mYmTdtqomHFxe3rO+R73NZPJBEGQSWt0BSSSMpm+bpi/MsOfvvZwnH7EX6OVt/u/obWFCcQciObm5wdyQTCQXVgYHKvVtkeDOevsOzHzPzIjITKpQDQn5Q57ZWVXrlbbM7G4OFzGYGSu6+hrXGS6iWGiUaVa3aZnDZHC6uruq543kl9e3o//Rw0pD11X6jAjc9bZJ4548shPMkxcEQkXm82dpaWlvXa9fqDcaBwpKHXS8LxTqJ21fX+MkTnr7BNHPHnkJ62w0wx7rs8HMySx4LqjhhAnTKXOmK6bLbnuhCVlrqTUFR2Rs84+ccSTRz51qJdoFq2Ke88t4Uy1UaMxDrFL2LaiodRNW6lbqN/WETnr7BNHPHnkU2f96jrMolXxDLg1ekUQKglhW74/aQoxhfgQtUc6tvJJ9rUh8OSR317dpma4xrxd+jLgLEzfzxrNZtGU8g6EprGKp4jPkD9nDPNp9jUOePLI17cUeslm2N9sEAzyOueFOFgU4nTR9y9j9hWIPoDgDIRfIr5BfBdG5jO6Dxzx5JFPHerFzy2+sn4+qOW1teFr3ELXHccW5fWshXgMwRcQf4vxAbVPOiJnnX3iNB488qlDPep2mPFdx8PETRrifiMetYQ4pw9fyruY9RNchleG779H/sVS6isjc9bZJ4548sgPdYb0JQnfpe3z4oPIZoUPqOsew/U+j5mbGPcgPguh14gfkX+D8A9G5mF9ljjiySO/EprFH/ANZjek3Gd73nFc7QuYuYWZ34fIHEYV4zPGd5jVdWzlrM8RRzx55FOnq1nvV5bmmaV6G//Hc5biGyTtd6OT1ls/OrdUfs/iq0vllzoyTOUbZP0Ke/51FTdM5btxg2nQ4y/iLUm9+tb/l+MnO+j3YM+2Hm4AAAAASUVORK5CYII=" />
            </defs>
        </svg>

    );
}