import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import { toast } from "react-toastify";

import BuyerService from "../../../services/BuyerService";

import UserCreate from "./UserCreate";

export default function AddUser(props) {
  const { token } = props.state;
  const [user, setUser] = useState({
    name: "",
    email: "",
    cnpj: "",
    companyName: "",
    isActive: true
  });

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const send = async (user) => {
    setLoading(true);

    const promise = await BuyerService.register(token, user)

    if (promise) {

      if (promise.isAxiosError) {
        toast.error(promise.response.data);
        setLoading(false);
        setError(true);
        return
      }
      
      toast.success("Dados salvos com sucesso!");
      setSuccess(true);
      setUser({
        name: "",
        email: "",
        cnpj: "",
        companyName: "",
        isActive: true
      })
      setLoading(false);

      props.updateList()
    } else {
      toast.error("Erro ao salvar dados!");
      setLoading(false);
      setError(true);
    }
  };

  return (
    <div className="dashboard-container">
      {loading && (
        <div className={"loading-mask"}>
          <FontAwesomeIcon icon={faCircleNotch} pulse />
        </div>
      )}

      <h1>Configuração de usuários</h1>
      <div className="module-container">
        <div className="module-item">
          <UserCreate
            initicialUser={user}
            error={error}
            send={send}
            loading={loading}
            success={success}
            back={() => props.handleNavegation('')}
          />
        </div>
      </div>
    </div>
  );
}
