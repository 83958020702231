import React, { useState } from "react";

import EscopoAvaliacaoList from "./List/escopoAvaliacaoList";
import EscopoAvaliacaoForm from "./Form/escopoAvaliacaoForm";
import QuestionTheme from "../questionTheme/";

import "../../dashboard/dashboard.scss";

export default function List({ current, token, embed, handleEditEscopo }) {
  const [currentEscopoAvaliacao, setCurrentEscopoAvaliacao] = useState(current);
  const [currentTheme, setCurrentTheme] = useState(null);

  const handleTheme = (data) => {
    setCurrentTheme(currentTheme === data ? null : data);
  }

  return (
    <>
      {(
          currentEscopoAvaliacao ? (
            <>
              <EscopoAvaliacaoForm 
                token={token} 
                handleEditEscopo={handleEditEscopo}
                item={(currentTheme)}
                handleItem={handleTheme} 
                currentEscopoAvaliacao={currentEscopoAvaliacao} 
                back={() => {
                  setCurrentEscopoAvaliacao(null)
                  setCurrentTheme(null)
                }}
                embed={embed} 
              /> 
              {currentTheme && (
                <QuestionTheme token={token} escopo={currentTheme} handleEscopo={handleTheme} comprador={true}/>
              )}
            </>
          ) : (
            <EscopoAvaliacaoList token={token} initialEscopoAvaliacao={[]} handleEscopos={setCurrentEscopoAvaliacao}/>
          )
      )}
    </>
  );
}
