import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  INDICADOR_DESEMPENHO: BASE_URL + "IndicadorDesempenho",
};

export default class IndicadorDesempenho {
  static register = async (token, indicadorDesempenho) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let body = {
      nome: indicadorDesempenho.nome,
      padraoDesempenho: indicadorDesempenho.padraoDesempenho,
      peso: indicadorDesempenho.peso
    }

    try {
      const { data } = await axios.post(URLS.INDICADOR_DESEMPENHO, body, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static get = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        },
      };
        
    let url = URLS.INDICADOR_DESEMPENHO

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static update = async (token, indicadorDesempenho) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
        
    let url = URLS.INDICADOR_DESEMPENHO
    try {
      const { data } = await axios.put(url, indicadorDesempenho, config);
      return data;
    } catch (err) {
      return err;
    }
  }

  static delete = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
      
    let url = URLS.INDICADOR_DESEMPENHO + "/" + id

    try {
      const { data } = await axios.delete(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };
}