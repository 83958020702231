import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  GET: BASE_URL + "Provedor",
};

export default class RelacaoClientesServices {
  static get = async (token, search) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.GET + "/relacao-clientes"
    if (search === undefined) url = `${url}?Nome=&Pagination=1`
    else  url = `${url}?Nome=${search}&Pagination=1`
    
    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  }
}