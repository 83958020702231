import React, { useEffect, useState } from "react";

import Modal from "../../../../components/ModalExit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { IF } from "../../../../components/Condition/Condition";
import InputRadio from "../../../../components/InputRadio/InputRadio";

import NotificacaoProvedoresAnaliseService from "../../../../services/NotificacaoProvedoresAnaliseService";

export default function AnaliseCriticaForm({ token, current, callback, show }) {
    const defaultCurrent = {
        respostaProvedor: 1,
        motivo: "",
        necessidadeAuditoriaInLoco: true,
        nome: "",
        cargo: "",
        data: ""
    }

    const [modal, setModal] = useState(false);
    const [notificacao, setNotificacao] = useState(defaultCurrent);
    const [sending, setSending] = useState(false);
    const [loading, setLoading] = useState(false);

    const formatData = (date, formatBR = false, time = false) => {
        if (!date) return '';
        let data = '';

        let dia = date.getDate();           // 1-31
        let mes = date.getMonth() + 1;      // 0-11 (zero=janeiro)
        let ano4 = date.getFullYear();       // 4 dígitos
        let hora = date.getHours();          // 0-23
        let min = date.getMinutes();        // 0-59
        let seg = date.getSeconds();        // 0-59

        if (!formatBR) {
            data = ("0" + ano4).slice(-4) + '-' + ("0" + mes).slice(-2) + '-' + ("0" + dia).slice(-2);
        } else {
            data = ("0" + dia).slice(-2) + '/' + ("0" + mes).slice(-2) + '/' + ("0" + ano4).slice(-4);

            if (time) {
                data += ' ' + ("0" + hora).slice(-2) + ':' + ("0" + min).slice(-2) + ':' + ("0" + seg).slice(-2);
            }
        }

        return data;
    }

    const handleChangeNum = (e) => {
        e.persist();

        if (e.target.name === 'respostaProvedor' && e.target.value === '1') {
            setNotificacao((values) => ({
                ...values,
                motivo: "",
                [e.target.name]: (e.target.type === 'radio') ?
                    (e.target.value === '1' ? 1 : 2) : e.target.value
            }));
        } else
            setNotificacao((values) => ({
                ...values,
                [e.target.name]: (e.target.type === 'radio') ?
                    (e.target.value === '1' ? 1 : 2) : e.target.value
            }));
    };

    const handleChange = (e) => {
        e.persist();

        setNotificacao((values) => ({
            ...values,
            [e.target.name]: (e.target.type === 'radio') ?
                (e.target.value === '1' ? true : false) : e.target.value
        }));
    };

    const handleSubmitDescricao = (e) => {
        e.preventDefault();

        if (notificacao.nome === ''
            || notificacao.cargo === ''
            || notificacao.data === ''
        ) {
            setModal(true)
            return
        }

        create(notificacao);
    }

    const create = (payload) => {
        setSending(true);
        
        const respostaProvedor = current.provedorNotificacaoRespostasProvedor[0]

        NotificacaoProvedoresAnaliseService.create(token, respostaProvedor.id, payload)
            .then((response) => {
                if (response) {
                    toast.success("Análise cadastrada com sucesso!");
                    setNotificacao(defaultCurrent);
                    setSending(false);
                    callback();
                }
            })
            .catch((error) => {
                toast.error("Erro ao cadastrar análise crítica!");
                setSending(false);
            });
    }

    const _init = async () => {
        setLoading(true);

        setLoading(false);
    }

    useEffect(() => {
        _init();
    }, []);

    return (
        <>
            {loading && (
                <div className={"loading-mask"}>
                    <FontAwesomeIcon icon={faCircleNotch} pulse />
                </div>
            )}

            {modal &&
                <Modal
                    title={"Todos os campos são obrigatórios"}
                    handleDelete={null}
                    handleModal={setModal}
                    alert
                />
            }

            <form
                className={`form-container ` + (show === 'analise' ? 'tab-active' : '')}
                onSubmit={handleSubmitDescricao}>
                <div className="row newRow oldBg">
                    <h1>CONCLUSÃO</h1>
                </div>
                <div className="row rowCardPadding p-0">
                    <div className="col-lg-12 flex-flow-wrap">

                        <div className="row w-100 d-flex bg-col-bottom m-0">
                            <div className="col-lg-2 br-none">
                                <label className="global-label">Resposta do Provedor</label>
                            </div>
                            <div className="col-lg-4 px-0">
                                <div className="InputRadioGroup">
                                    <InputRadio name="respostaProvedor" label="Aceito" value="1" checked={(notificacao.respostaProvedor === 1) ? '1' : notificacao.respostaProvedor} onChange={handleChangeNum} />
                                    <InputRadio name="respostaProvedor" label="Reprovado" value="2" checked={!notificacao.respostaProvedor ? '2' : notificacao.respostaProvedor} onChange={handleChangeNum} />
                                </div>
                            </div>
                            <IF condition={notificacao.respostaProvedor !== 1}>
                                <div className="col-lg-6">
                                    <input
                                        className="global-input"
                                        type="text"
                                        id="motivo"
                                        value={notificacao.motivo || ''}
                                        placeholder=""
                                        name="motivo"
                                        onChange={handleChange}
                                    />
                                </div>
                            </IF>
                        </div>

                        <div className="row w-100 d-flex bg-col-bottom m-0">
                            <div className="col-lg-2 br-none">
                                <label className="global-label">Necessidade de auditoria in loco?</label>
                            </div>
                            <div className="col-lg-4 px-0">
                                <div className="InputRadioGroup">
                                    <InputRadio name="necessidadeAuditoriaInLoco" label="Sim" value="1" checked={(notificacao.necessidadeAuditoriaInLoco === true) ? '1' : notificacao.necessidadeAuditoriaInLoco} onChange={handleChange} />
                                    <InputRadio name="necessidadeAuditoriaInLoco" label="Não" value="0" checked={!notificacao.necessidadeAuditoriaInLoco ? '0' : notificacao.necessidadeAuditoriaInLoco} onChange={handleChange} />
                                </div>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="row newRow oldBg">
                    <h1>RESPONSABILIDADE PELA AVALIAÇÃO</h1>
                </div>
                <div className="row rowCardPadding">
                    <div className="col-lg-2 br-none">
                        <label className="global-label">Nome</label>
                    </div>
                    <div className="col-lg-3">
                        <input
                            className="global-input"
                            type="text"
                            id="nome"
                            value={notificacao.nome || ''}
                            placeholder="Nome Completo do responsável"
                            name="nome"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-lg-1 br-none">
                        <label className="global-label">Cargo</label>
                    </div>
                    <div className="col-lg-3">
                        <input
                            className="global-input"
                            type="text"
                            id="cargo"
                            value={notificacao.cargo || ''}
                            placeholder="Cargo do responsável"
                            name="cargo"
                            onChange={handleChange}
                        />
                    </div>
                    <div className="col-lg-1 br-none">
                        <label className="global-label">Data</label>
                    </div>
                    <div className="col-lg-2">
                        <input
                            className="global-input"
                            type="date"
                            id="data"
                            value={formatData(new Date(notificacao.data || ''))}
                            placeholder="xx / xx / xxxx"
                            name="data"
                            onChange={handleChange}
                        />
                    </div>
                </div>
                <button
                    className="global-btn"
                    type="submit"
                    disabled={sending && true}
                >
                    {sending ? '' : "ENVIAR"}
                    {sending && <FontAwesomeIcon icon={faCircleNotch} pulse />}
                </button>
            </form>
        </>
    );
}
