import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons";

// import BuyerService from "../../../services/BuyerService";

export default function UserConfig({ loading, token, initicialUser, send, handleNavegation}) {
  //   const [isToggled, setIsToggled] = useState(false);

  const [user, setUser] = useState(initicialUser);

  // const [error, setError] = useState(false);

  const onToggle = () => {
    // setIsToggled(!isToggled)
    // event.persist();
    setUser((values) => ({
      ...values,
      isAuthorised: !user.isAuthorised,
    }));
  };

  // const handleChange = (event) => {
  //   event.persist();
  //   setUser((values) => ({
  //     ...values,
  //     [event.target.name]: event.target.value,
  //   }));
  // };

  // const handleChangeCompany = (event) => {
  //   event.persist();
  //   setUser((values) => ({
  //     ...values,
  //     company: {
  //       ...values.company,
  //       [event.target.name]: event.target.value,
  //     },
  //   }));
  // };

  // const handleChangeCompanyName = (event) => {
  //   event.persist();
  //   setUser((values) => ({
  //     ...values,
  //     company: {
  //       ...values.company,
  //       name: event.target.value,
  //     },
  //   }));
  // };

  const handleSubmit = (e) => {
    e.preventDefault();
    send(user);
  };

  useEffect(() => {
    setUser(initicialUser);
    // eslint-disable-next-line
  }, [initicialUser]);

  return (
    <>
      <div className="user-config-container">
        <div className="card-container">
          <div className="card">
            <div className="card-header">
              <h1>Dados do usuário</h1>
            </div>
            <div className="card-body">
              <form className="form-container" onSubmit={handleSubmit}>
                <div className="group-input">
                  <label>Nome</label>
                  <input
                    className="global-input"
                    // className={`global-input ${
                    // submitted && !values.name ? "error" : ""
                    // }`}
                    id="name"
                    name="name"
                    value={user.name}
                    // onChange={handleChange}
                    disabled
                  />
                  {/* {submitted && !values.name && (
                            <span className="error-msg">Esse campo é necessario</span>
                        )} */}
                </div>
                <div className="group-input">
                  <label>Email</label>
                  <input
                    className="global-input"
                    // className={`global-input ${
                    // submitted && !values.email ? "error" : ""
                    // }`}
                    id="email"
                    name="email"
                    value={user.email}
                    // onChange={handleChange}
                    disabled
                  />
                  {/* {submitted && !values.email && (
                            <span className="error-msg">Esse campo é necessario</span>
                        )} */}
                </div>
                <div className="group-input">
                  <label>Empresa</label>
                  <input
                    className="global-input"
                    // className={`global-input ${
                    // submitted && !values.company ? "error" : ""
                    // }`}
                    id="company"
                    name="company"
                    value={user.company.name}
                    // onChange={handleChangeCompanyName}
                    disabled
                  />
                  {/* {submitted && !values.company && (
                            <span className="error-msg">Esse campo é necessario</span>
                        )} */}
                </div>
                <div className="group-input">
                  <label>CNPJ</label>
                  <input
                    className="global-input"
                    // className={`global-input ${
                    // submitted && !values.cnpj ? "error" : ""
                    // }`}
                    id="cnpj"
                    name="cnpj"
                    value={user.company.cnpj}
                    // onChange={handleChangeCompany}
                    disabled
                  />
                  {/* {submitted && !values.cnpj && (
                            <span className="error-msg">Esse campo é necessario</span>
                        )} */}
                </div>
                <div className="group-input switch-container __user-status">
                  <label>Status</label>
                  <label className="toggle-switch">
                    <input
                      type="checkbox"
                      checked={user.isAuthorised}
                      onChange={onToggle}
                      value={user.isAuthorised}
                    />
                    <span className="switch">
                      {user.isAuthorised ? "Ativo" : "Inativo"} 
                    </span>
                  </label>
                </div>
                <button className="global-btn">
                  SALVAR{" "}
                  {loading && <FontAwesomeIcon icon={faCircleNotch} pulse />}
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="back-container">
          <button
            className="global-btn"
            onClick={() => handleNavegation('')}
          >
            <FontAwesomeIcon icon={faAngleDoubleLeft} /> VOLTAR{" "}
            {loading && <FontAwesomeIcon icon={faCircleNotch} pulse />}
          </button>
        </div>
      </div>
    </>
  );
}
