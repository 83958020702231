import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  URL: BASE_URL + "Provedor",
};

export default class IncluirProvedoresServices {
  static post = async (token, body) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data } = await axios.post(URLS.URL, body, config);
      return data;
    } catch (err) {
      return err;
    }
  }


  static get = async (token, busca, tipoChave) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = `${URLS.URL}/GetProvedorByCnpjMatricula?busca=${busca}&tipoChave=${tipoChave}`;

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  }
}