import React from "react";

import "./cardRegistroRecebimento.scss"
import { useHistory } from "react-router-dom";
import infoIcon from "../../../../../assets/icons/info.svg";
import truckIcon from "../../../../../assets/icons/truck.svg";
import addIcon from "../../../../../assets/icons/WhiteSubtract.svg";
import notificationIcon from "../../../../../assets/icons/icon-notification.svg";

export default function CardRegistroRecebimento(props) {

  const history = useHistory();

  const navigate = (url) => history.push(url)

  return (
    <>
      <div className="d-flex justify-content-end mr-4" >

        <div className="card-registro">
          <div className="row row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-2 h-100 justify-content-between">

            <div className="card-registro-body d-flex flex-column justify-content-between align-items-center">

              <div className="situacao-title d-flex text-left align-items-center pl-5">
                <img src={truckIcon} />
                <span>
                  Registro de recebimento
                </span>
              </div>

              <div className="novo-registro w-100">
                <button className="w-100" onClick={() => navigate('/parametrizacao/informacoes-entrada')}>
                  <img src={addIcon} />
                  <span>
                    Criar novo registro de recebimento
                  </span>
                </button>
              </div>

              <div className="xxxxx d-flex w-100">
                <div className="title d-flex align-items-center">
                  {/* TO DO dados abaixo */}
                  <img src={infoIcon} />
                  xxxxxxxxx
                </div>
                <div className="count">
                  <div className="number">
                    XX
                  </div>
                </div>
              </div>

            </div>

            <div className="card-registro-body d-flex flex-column justify-content-between align-items-center">

              <div className="situacao-title d-flex text-left align-items-center pl-5">
                <img src={notificationIcon} />
                <span>
                  Notificação aos Provedores
                </span>
              </div>

              <div className="novo-registro w-100">
                <button className="w-100" onClick={() => navigate('/provedores/notificacao-provedores')}>
                  <img src={addIcon} />
                  <span>
                    Criar uma notificação ao provedor
                  </span>
                </button>
              </div>

              <div className="xxxxx d-flex w-100">
                <div className="title d-flex align-items-center">
                  {/* TO DO count de notificações não respondidas */}
                  <img src={infoIcon} />
                  Nº de notificações não respondidas
                </div>
                <div className="count">
                  <div className="number">
                    8
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>

      </div>
    </>
  );
}
