import React, { Component } from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AppRoute from "./Route";
import App from "../App";
import { actions } from "../redux/actions";

import {
  Index,
  AddUser,
  Forget,
  ForgetReset,
  Dashboard,
  Buyers,
  ManageAccess,
  AuditCriteria,
  CriticalityTypeInformation,
  EditAuditCriteria,
  AddAuditCriteria,
  GestaoDeIdoneidade,
  AddGestaoDeIdoneidade,
  EditGestaoDeIdoneidade,
  InformationType,
  AddInformationType,
  EditInformationType,
  CriticidadeAvaliacao,
  CertifyingCompanies,
  MaterialService,
  DocumentLicence,
  ApprovalOpportunity,
} from "./Pages";

// Provedor
import SubmodulosProvedor from '../private-app/submodulesProvedor/SubmodulosProvedor';
import SubModulosProvedorCadastro from "../private-app/submodulosProvedorCadastro/submodulosProvedorCadastro";

// Comprador
import Submodulos from "../private-app/submodulos/Submodulos";
import SubModulosCompradorCadastro from "../private-app/submodulosCompradorCadastro/submodulosCompradorCadastro";
import SubmodulosCompradorProvedor from "../private-app/submodulosCompradorProvedor/submodulosCompradorProvedor";
import SubModulosAdminBaseDados from "../private-app/submodulosAdminBaseDados/SubModulosAdminBaseDados";


export class Routes extends Component {
  render() {
    const state = { ...this.props };

    return (
      <Router>
        <Switch>
          <App>
            {/* Public Routes */}
            <AppRoute public exact path="/" {...this.props} component={Index} />
            <AppRoute
              public
              exact
              path="/login"
              {...this.props}
              component={Index}
            />
            <AppRoute
              public
              exact
              path="/cadastro-comprador"
              {...this.props}
              component={AddUser}
            />
            <AppRoute
              public
              exact
              path="/recuperar-senha"
              {...this.props}
              component={Forget}
            />
            <AppRoute
              public
              exact
              path="/nova-senha"
              {...this.props}
              component={ForgetReset}
            />
            {/* Private Routes */}
            <AppRoute
              private
              exact
              state={state}
              path="/dashboard"
              {...this.props}
              component={Dashboard}
            />
            <AppRoute
              private
              exact
              state={state}
              path="/oportunidade-de-homologacao"
              {...this.props}
              component={ApprovalOpportunity}
            />
            <AppRoute
              private
              exact
              state={state}
              path="/compradores"
              {...this.props}
              component={Buyers}
            />
            <AppRoute
              private
              exact
              state={state}
              path="/acessos"
              {...this.props}
              component={ManageAccess}
            />
            <AppRoute
              private
              state={state}
              path="/gestao-de-idoneidade"
              {...this.props}
              component={GestaoDeIdoneidade}
            />
            <AppRoute
              private
              state={state}
              path="/edit-gestao-de-idoneidade/:id"
              {...this.props}
              component={EditGestaoDeIdoneidade}
            />
            <AppRoute
              private
              state={state}
              path="/criar-gestao-de-idoneidade"
              {...this.props}
              component={AddGestaoDeIdoneidade}
            />
            <AppRoute
              private
              state={state}
              path="/criticidade-de-avaliacao"
              {...this.props}
              component={CriticidadeAvaliacao}
            />
            <AppRoute
              private
              state={state}
              path="/criterios-auditoria/:id"
              {...this.props}
              component={EditAuditCriteria}
            />
            <AppRoute
              private
              exact
              state={state}
              path="/adicionar-criterios-auditoria/"
              {...this.props}
              component={AddAuditCriteria}
            />
            <AppRoute
              private
              exact
              state={state}
              path="/criterios-auditoria/"
              {...this.props}
              component={AuditCriteria}
            />
            <AppRoute
              private
              exact
              state={state}
              path="/criticidade"
              {...this.props}
              component={CriticalityTypeInformation}
            />
            <AppRoute
              private
              exact
              state={state}
              path="/tipo-informacao"
              {...this.props}
              component={InformationType}
            />
            <AppRoute
              private
              exact
              state={state}
              path="/criar-tipo-informacao"
              {...this.props}
              component={AddInformationType}
            />
            <AppRoute
              private
              state={state}
              path="/editar-tipo-informacao/:id"
              {...this.props}
              component={EditInformationType}
            />

            <AppRoute
              private
              exact
              state={state}
              path="/organismos-certificadores"
              {...this.props}
              component={CertifyingCompanies}
            />
            <AppRoute
              private
              exact
              state={state}
              path="/materiais-servicos"
              {...this.props}
              component={MaterialService}
            />
            <AppRoute
              private
              exact
              state={state}
              path="/documentos-licencas"
              {...this.props}
              component={DocumentLicence}
            />
            <AppRoute
              private
              state={state}
              path="/parametrizacao"
              {...this.props}
              component={Submodulos}
            />
            <AppRoute
              private
              state={state}
              path="/gerenciamento"
              {...this.props}
              component={SubmodulosProvedor}
            />
            <AppRoute
              private
              state={state}
              path="/cadastro"
              {...this.props}
              component={SubModulosCompradorCadastro}
            />
            <AppRoute
              private
              state={state}
              path="/cadastro-provedor"
              {...this.props}
              component={SubModulosProvedorCadastro}
            />
            <AppRoute
              private
              state={state}
              path="/provedores"
              {...this.props}
              component={SubmodulosCompradorProvedor}
            />
            <AppRoute
              private
              state={state}
              path="/base-dados"
              {...this.props}
              component={SubModulosAdminBaseDados}
            />
          </App>
        </Switch>
      </Router>
    );
  }
}

Routes.propTypes = {
  logout: PropTypes.func,
  isAuthenticated: PropTypes.bool,
  token: PropTypes.string,
};

const mapStateToProps = (state) => {
  const { isAuthenticated, token, active_access } = state;
  const user = state.user && state.user.user ? state.user.user : state.user;
  // console.log("mapStateToProps user", user);
  return {
    isAuthenticated,
    token,
    user,
    active_access,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    logout: () => dispatch(actions.logout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Routes);
