import React from "react";

import DashboardComprador from "./comprador"

export default function Index(props) {
  const { token } = props.state;

  const isComprador = () => {
    const profile = JSON.parse(localStorage.getItem("profile"));
    return (profile.profileId === 2);
  };

  return (
    isComprador() ? (
      <DashboardComprador {...{ state: { token: token } }} />
    ) : (
      // TO DO outros dashboards
      <div className="dashboard-container">
        <h1>Dashboard</h1>
      </div>
    )
  );
}
