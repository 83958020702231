import React from "react";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import "../dashboard/dashboard.scss";

export default function Index(props) {
  // const [loading, setLoading] = useState(false);

  return (
    <>
      <div className="dashboard-container">
        {/* {loading && (
          <div className={"loading-mask"}>
            <FontAwesomeIcon icon={faCircleNotch} pulse />
          </div>
        )} */}
        <h1>Cadastro de padrão de critérios de auditoria</h1>
      </div>
    </>
  );
}
