import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  ACESSARCLIENTES: BASE_URL + "AcessarClientes",
};

export default class AcessarClientesService {
    static get = async (token, search) => {
        let config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        }
        let url = URLS.ACESSARCLIENTES;
        if (search) 
            url += `?Nome=${search}&Pagination=20`;
        else
            url += `?Nome=&Pagination=20`;

        try {
            const { data } = await axios.get(url, config);
            return data;
        } catch (error) {
            return error;
        }
    }
}