import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  GET: BASE_URL + "Provedor",
};

export default class RelacaoProvedoresServices {
  static get = async (token, search) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = ''
    if (search === undefined) url = `${URLS.GET}?Nome=&Pagination=1`
    else  url = `${URLS.GET}?Nome=${search}&Pagination=1`
    
    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  }
}