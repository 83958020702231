import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  URL: BASE_URL + "Comprador",
};

export default class MeusDadosService {
  static get = async (token, ehComprador) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.URL
    if (!ehComprador) {
      url = BASE_URL + "Provedor/meus-dados"
    }

    try {
      return await axios.get(url, config);
    } catch (err) {
      return err;
    }
  };

  static update = async (token, payload, ehComprador) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.URL
    if (!ehComprador) {
      url = BASE_URL + "Provedor/meus-dados"
    }

    return await axios.post(url, payload, config);
  };

  static updateLogo = async (token, archive, ehComprador) => {
    if (archive != null) {
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      };

      let url = URLS.URL;
      if (!ehComprador) {
        url = `${BASE_URL}Provedor/UploadLogo`
      }
      else
        url = `${BASE_URL}Comprador/UploadLogo`

      return await axios.post(url, archive, config);
    }
    return true;
  };

  static download = async (token, ehComprador) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        responseType: 'blob',
      },
    };

    let url = `${URLS.URL}/DownloadLogo`
    if (!ehComprador) {
      url = `${BASE_URL}Provedor/DownloadLogo`
    }

    try {
      const blob = await fetch(url, config)
        .then(response => response.blob())
        .then(blob => blob)
      return blob;
    } catch (err) {
      return err;
    }
  };
}