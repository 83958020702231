import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons";

export default function UserCreate({ loading, initicialUser, send, back }) {
    const [isToggled, setIsToggled] = useState(true);

  const [user, setUser] = useState(initicialUser);

  const onToggle = () => {
    setIsToggled(!isToggled)
    setUser((values) => ({
      ...values,
      isActive: !user.isActive,
    }));
  };

  const handleChange = (event) => {
    event.persist();
    setUser((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    send(user);
  };

  useEffect(() => {
    setUser(initicialUser);
    // eslint-disable-next-line
  }, [initicialUser]);

  return (
    <>
      <div className="user-config-container" style={{ width: '50%' }}>
        <div className="card-container">
          <div className="card">
            <div className="card-header">
              <h1>Dados do usuário</h1>
            </div>
            <div className="card-body">
              <form className="form-container" onSubmit={handleSubmit}>
                <div className="group-input">
                  <label>Nome</label>
                  <input
                    className="global-input"
                    id="name"
                    name="name"
                    value={user.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="group-input">
                  <label>Email</label>
                  <input
                    className="global-input"
                    id="email"
                    name="email"
                    value={user.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="group-input">
                  <label>Empresa</label>
                  <input
                    className="global-input"
                    id="companyName"
                    name="companyName"
                    value={user.companyName}
                    onChange={handleChange}
                  />
                </div>
                <div className="group-input">
                  <label>CNPJ</label>
                  <input
                    className="global-input"
                    id="cnpj"
                    name="cnpj"
                    value={user.cnpj}
                    onChange={handleChange}
                  />
                </div>
                <div className="group-input switch-container __user-status">
                  <label>Status</label>
                  <label className="toggle-switch">
                    <input
                      type="checkbox"
                      checked={isToggled}
                      onChange={onToggle}
                      value={isToggled}
                    />
                    <span className="switch">
                      {isToggled ? "Ativo" : "Inativo"}
                    </span>
                  </label>
                </div>
                <button className="global-btn">
                  SALVAR{" "}
                  {loading && <FontAwesomeIcon icon={faCircleNotch} pulse />}
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="back-container">
          <button
            className="global-btn"
            onClick={back}
          >
            <FontAwesomeIcon icon={faAngleDoubleLeft} /> VOLTAR{" "}
            {loading && <FontAwesomeIcon icon={faCircleNotch} pulse />}
          </button>
        </div>
      </div>
    </>
  );
}
