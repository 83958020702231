import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import Form from "./Form/criticidadeAvaliacaoForm";
import List from "./List/criticidadeAvaliacaoList";
import CriticidadeAvaliacaoCompradorService from "../../services/CriticidadeAvaliacaoCompradorService";
import Modal from "../../components/ModalExit";

import "./style.scss";

export default function Index(props) {
  const { token } = props.state;
  const [loading, setLoading] = useState(false);
  const [deleteID, setDeleteID] = useState(0);

  const [current, setCurrent] = useState({});
  const [list, setList] = useState([]);

  const [handleFromList, setHandleFromList] = useState(false);

  const defaultCurrent = {
    nome: '',
    autoAvaliacao: false,
    auditoria2Parte: false,
    auditoria2ParteCustoProcesso: 0,
    certificacaoObrigatoria: false,
    escoposAvaliacoes: [],
    tiposCertificacoes: []
  };

  const get = () => {
    setCurrent(defaultCurrent);
    setLoading(true);

    CriticidadeAvaliacaoCompradorService.get(token)
      .then((response) => {
        if (response) {
          if (response.valores) {
            setList(response.valores);
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  const getById = (id) => {
    setCurrent(defaultCurrent);
    setLoading(true);

    CriticidadeAvaliacaoCompradorService.getById(token, id)
      .then((response) => {
        if (response) {
          if (response) {
            setCurrent(response);
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  const remove = (id) => {
    setDeleteID(0);

    CriticidadeAvaliacaoCompradorService.delete(token, id)
      .then((response) => {
        if (response) {
          toast.success("Item excluído com sucesso!");
          get();
        }
      })
      .catch((error) => {
        toast.error("Erro ao excluir item!");
        setLoading(false);
      });
  }

  useEffect(() => {
    setLoading(true);
    get();
  }, []);

  /* SE ESTIVER EM CADASTRO/MODULO "MATERIAL / SERVIÇO" */
  useEffect(() => {
    if (current.id) {
      setHandleFromList(!handleFromList)
      if (props.handleShowFrom)
        props.handleShowFrom()
    }
  }, [current]);

  return (

    <div className="dashboard-container criticidadeAvaliacao-container">
      {deleteID > 0 && (
        <Modal handleDelete={() => remove(deleteID)} handleModal={() => { setDeleteID(0) }} />
      )}
      {loading && (
        <div className={"loading-mask"}>
          <FontAwesomeIcon icon={faCircleNotch} pulse />
        </div>
      )}
      
      {props.inRegister &&
        /* SE ESTIVER EM CADASTRO/MODULO "MATERIAL / SERVIÇO" */
        <button
          className={`global-btn btn-new-criticidade handleFromList-${handleFromList}`}
          onClick={() => {
            setHandleFromList(true)
            if (!handleFromList && props.handleShowFrom)
              props.handleShowFrom()
          }}
        >
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.5799 10.5H10.4142V14.5H4.16567V10.5H0V4.5H4.16567V0.5H10.4142V4.5H14.5799V10.5Z" fill="#FFF" />
          </svg>
          Criar novo padrão de criticidade por tipo de avaliação
        </button>
      }

      {props.inRegister !== undefined
        ? handleFromList
          /* SE ESTIVER EM CADASTRO/MODULO "MATERIAL / SERVIÇO" */
          ? <Form
            token={token}
            current={current}
            callback={get}
            handleFromList={handleFromList}
            back={() => {
              setHandleFromList(!handleFromList)
              setCurrent(defaultCurrent);
              if (props.handleShowFrom)
                props.handleShowFrom()
            }}
          />
          : <div className="mt-2">
            <List
              token={token}
              loading={loading}
              list={list}
              remove={setDeleteID}
              edit={getById}
            />
          </div>
        : /* SE ESTIVER EM PARAMETRIZAÇÃO/MODULO "CRITICIDADE POR TIPO DE AVALIAÇÃO" */
        <>
          <Form
            token={token}
            current={current}
            callback={get}
          />
          <div className="mt-2">
            <List
              token={token}
              loading={loading}
              list={list}
              remove={setDeleteID}
              edit={getById}
            />
          </div>
        </>}

    </div>
  );
}