import React, { useState, useEffect } from "react";

import Form from "./Form/Form";
import List from "./List/List";

export default function PadraoDesempenho(props) {
  const { token } = props.state

  const [showForm, setShowForm] = useState(false)

  const [editCurrentValue, setCurrent] = useState(null)

  const [updated, setUpdated] = useState(false);

  const [current, setCurrentValue] = useState();

  const initComponent = () => {
    setShowForm(false)
    setCurrent(null)
  }

  useEffect(() => {
    initComponent()
    setCurrent(null)
  }, []);

  useEffect(() => {
    if (editCurrentValue) {
      setShowForm(true)
    }
  }, [editCurrentValue])

  const send = (value) => {
    if (value) {
      if (updated)
        setUpdated(false)

      setCurrentValue(null);
      return setUpdated(true)
    }

    return;
  }

  const currentDocuments = (value) => {
    setShowForm(true)
    setCurrentValue(value)
  }

  return (
    <div className="dashboard-container" id="material-service-container">
      {showForm
        ? <Form
          token={token}
          send={send}
          currentValue={current}
        />
        : <button
          className={`global-btn btn-new-material-service mr-2`}
          onClick={() => setShowForm(true)}
        >
          <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.5799 10.5H10.4142V14.5H4.16567V10.5H0V4.5H4.16567V0.5H10.4142V4.5H14.5799V10.5Z" fill="#FFF" />
          </svg>
          Criar novo Padrão de Desempenho
        </button>
      }

      {
        <>
          <div className="mt-2">
            <List
              token={token}
              updated={updated}
              currentDocuments={currentDocuments}
            />
          </div>
        </>
      }
    </div>
  );
}