import React, { useState } from "react";
import DocumentLicence from "./ListDocumentLicence";
import DocumentLicenceForm from "../Form/documentLicenceForm";
import "../../dashboard/dashboard.scss";

export default function List({ documentLicence, token }) {
  const [updated, setUpdated] = useState(false);
  const [currentDocument, setCurrentDocument] = useState(null)

  const send = (value) => {
    if (value) {
      if (updated) 
        setUpdated(false)
      
      setCurrentDocument(null);
      return setUpdated(true);
    }

    return;
  }

  const document = (value) => {
    setCurrentDocument(value);
  }

  return (
    <>
      <DocumentLicenceForm token={token} send={send} currentDocument={currentDocument} /> 
      {<DocumentLicence initialDocumentLicence={documentLicence} token={token} updated={updated} document={document} />}
    </>
  );
}
