import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import { toast } from "react-toastify";

import "../dashboard/dashboard.scss";

import InformationTypeList from "./List/InformationTypeList";
import Modal from "../../components/ModalExit";
import InformationTypeService from "../../services/InformationTypeService";

export default function Index(props) {
  const { token } = props.state;
  const [informationType, setInformationType] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [modal, setModal] = useState(false);
  const [currentId, setCurrentId] = useState("");

  const getInformationTypes = () => {
    InformationTypeService.getInformationTypes(token)
      .then((response) => {
        setInformationType(response);
      })
      .catch((error) => {
        setLoading(false);
        setError(true);
      });
  };

  useEffect(() => {
    getInformationTypes();
    // eslint-disable-next-line
  }, []);

  const handleModal = (value) => {
    setModal(value);
  };

  const handleId = (id) => {
    setCurrentId(id);
    handleModal(true);
  };

  const handleDelete = () => {
    setLoading(true);
    setError(false);
    setSuccess(false);
    InformationTypeService.deleteInformationType(token, currentId)
      .then((response) => {
        if (response) {
          toast.success("Tipo de informação excluído com sucesso!");
          setSuccess(true);
          setLoading(false);
          setError(false);
          getInformationTypes();
          handleModal(false);
        }
        setLoading(false);
        setError(false);
      })
      .catch((error) => {
        toast.error("Erro ao excluir tipo de informação !");
        setSuccess(false);
        setLoading(false);
        setError(true);
      });
  };

  return (
    <>
      <div className="dashboard-container">
        {modal && (
          <Modal handleDelete={handleDelete} handleModal={handleModal} />
        )}
        {loading && (
          <div className={"loading-mask"}>
            <FontAwesomeIcon icon={faCircleNotch} pulse />
          </div>
        )}
        <h1>Cadastro de tipos de informações</h1>
        <div className="gestao-list-container">

          <InformationTypeList
            loading={loading}
            error={error}
            informationType={informationType}
            handleId={handleId}
          />
        </div>
      </div>
    </>
  );
}
