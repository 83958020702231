import React, { useState } from "react";

import List from "./List/";

export default function Index(props) {
  const { token } = props.state;

  return (
    <div className="dashboard-container">
      {/* {loading && (
        <div className={"loading-mask"}>
          <FontAwesomeIcon icon={faCircleNotch} pulse />
        </div>
      )} */}
      <div className="gestaoAcessoContainer">
        <List token={token} users={[]} profiles={[]} />
      </div>
    </div>
  );
}
