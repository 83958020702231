import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
// import { faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons";
// import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";

export default function UserList({ loading, users, search, handleNavegation, handleGetId }) {
  const [searchInput, setSearchInput] = useState("");

  return (
    <>
      <div className="user-list-container">
        <div className="user-list-search">
          <div className="user-list-search-item">
            <a onClick={() => handleNavegation('create-user')} className="global-white-btn">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M14 10H10V14H4V10H0V4H4V0H10V4H14V10Z" fill="white" />
              </svg>
              Criar novo usuário
            </a>
          </div>
          <div className="user-list-search-item">
            <input
              type="text"
              placeholder="Pesquisar"
              className="search-input"
              name="search"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
            />
            <button className="search-btn" onClick={() => search(searchInput)}>
              {loading && <FontAwesomeIcon icon={faCircleNotch} pulse />}
              <FontAwesomeIcon icon={faSearch} />
            </button>
          </div>
        </div>
        <div className="list-container">
          <div className="list">
            <div className="list-header">
              <label>Nome</label>
              <label>Email</label>
              <label>Empresa</label>
              <label>CNPJ</label>
              <label>Status</label>
              <label>Editar</label>
            </div>

            {users &&
              users.length > 0 &&
              users.map((user, index) => {
                return <div className="list-body" key={user.id + index}>
                  <span className="list-item">{user.name}</span>
                  <span className="list-item">{user.email}</span>
                  <span className="list-item">{user.company && user.company.name}</span>
                  <span className="list-item">{user.company && user.company.cnpj}</span>
                  <span className={"list-item " + (user.isAuthorised ? "green" : "red")}>
                    {user.isAuthorised ? "Ativo" : "Inativo"}
                  </span>
                  <span className="list-item">
                    <a onClick={() => {
                      handleGetId(user.id)
                      handleNavegation('edit-user')
                    }}>
                      <svg
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M19.2006 5.55627L18.1167 4.63023C17.4974 4.09003 16.6458 4.1672 16.1038 4.7074L5.49738 15.0482C5.41996 15.1254 5.26513 15.2797 5.26513 15.4341L4.25867 19.2154C4.18126 19.5241 4.41351 19.7556 4.72319 19.6785L8.43932 18.6752C8.59416 18.5981 8.749 18.5209 8.82642 18.4437L19.3554 7.71704C19.8974 7.09968 19.8974 6.17363 19.2006 5.55627ZM5.18771 18.8296L5.88448 16.2058L7.74255 18.135L5.18771 18.8296ZM8.43932 17.7492L6.19416 15.4341L13.9361 7.94855L16.0264 10.0322L8.43932 17.7492ZM16.6458 9.41479L14.5554 7.33119L15.0974 6.791L17.1877 8.8746L16.6458 9.41479ZM18.7361 7.17685L17.8071 8.18006L15.7942 6.17363L16.6458 5.32476C16.878 5.09325 17.2651 5.09325 17.5748 5.32476L18.7361 6.32797C19.0458 6.48231 19.0458 6.94534 18.7361 7.17685ZM7.74255 15.5884C7.58771 15.7428 7.58771 16.0514 7.74255 16.283C7.89738 16.4373 8.20706 16.4373 8.43932 16.283L14.478 10.2637C14.6329 10.1093 14.6329 9.80064 14.478 9.56913C14.3232 9.41479 14.0135 9.41479 13.7813 9.56913L7.74255 15.5884ZM24.0006 17.1318C24.0006 20.9132 20.9813 24 17.1103 24H6.89093C3.09738 24 0.000610352 20.9904 0.000610352 17.1318V6.86817C0.000610352 3.08682 3.01996 0 6.89093 0H17.1877C20.9038 0 24.0006 3.00965 24.0006 6.86817V17.1318Z"
                          fill="#4B4B4B"
                        />
                      </svg>
                    </a>
                  </span>
                </div>
              })}
          </div>
        </div>
      </div>
    </>
  );
}
