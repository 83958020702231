import React, { useState } from "react";

import IdoneidadeIntegridadeList from "./idoneidadeIntegridadeList";
import IdoneidadeIntegridadeForm from "../Form/idoneidadeIntegridadeForm";
import "../../dashboard/dashboard.scss";

export default function List({ idoneidadeIntegridade, token }) {
  const [updated, setUpdated] = useState();
  const [currentIdoneidade, setCurrentIdoneidade] = useState(null)

  const send = (value) => {
    if (value) {
      if (updated) 
        setUpdated(false)
      
        setCurrentIdoneidade(null);
      return setUpdated(true)
    }

    return;
  }

  const idoneidade = (value) => {
    setCurrentIdoneidade(value)
  }

  return (
    <>
      <IdoneidadeIntegridadeForm token={token} send={send} currentIdoneidade={currentIdoneidade} /> 
      <IdoneidadeIntegridadeList initialIdoneidade={idoneidadeIntegridade} token={token} updated={updated} idoneidade={idoneidade} />
    </>
  );
}
