import React, { useEffect, useState } from "react";
import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";

import {
  OrganimosCertificadores,
  IdoneidadeIntegridade,
  ManageAccess,
  GroupMaterialService,
  RegistrationMaterialsServices,
  EscopoAvaliacao,
  DocumentLicence,
  Buyers,
  MeusDados,
  TiposInformacoesDocumentadas,
  PadraoDesempenho
} from "../../routes/Pages";

import "./submodulosCompradorCadastro.scss";

export default function SubModulosCompradorCadastro(props) {
  const [submodulos, setSubmodulos] = useState(false);
  const [submodulosActive, setSubmodulosActive] = useState('');
  const history = useHistory();
  const location = useLocation();
  const currentUrl = "/cadastro/";
  const state = { ...props };

  const changeSubmodule = (moduleUrl) => {
    history.push(currentUrl + moduleUrl);
  };

  useEffect(() => {
    const menuStorage = JSON.parse(localStorage.getItem("menuStorage"));
    
    const provedoresModulo = menuStorage.find(
      (a) => a.name == "Cadastro"
    );
    setSubmodulos(provedoresModulo.subModules);
  }, []);

  const activeSubmodulos = (url) => {
    return (location.pathname == currentUrl + url) ? 'active' : '';
  }

  return (
    <section>
      <nav className="submodules-container">
        {submodulos &&
          submodulos.length > 0 &&
          submodulos.map((s, i) => (
            <button key={i} data-url={currentUrl + s.url} onClick={() => changeSubmodule(s.url)} className={activeSubmodulos(s.url)}>
                <div>
                  <img
                    // src={require(`../../assets/icons/iconeGestaoAcesso.png`)}
                    src={require(`../../assets/icons/${s.icon}`)}
                  />
                  <a><b>{s.name}</b></a>
                </div>
            </button>
          ))}
      </nav>
      <div>
        {/* Pessoal que for fazer os componentes para o cadastro do comprador, basta trocar o componente ali no "render" */}
        <Switch>
          <Route
            path={currentUrl + "meus-dados"}
            render={() => <MeusDados {...props} />}
            state={state}
          />
          <Route
            path={currentUrl + "grupo-materiais-servicos"}
            render={() => <GroupMaterialService {...props} />}
            state={state}
          />
          <Route
            path={currentUrl + "materiais-servicos"}
            render={() => <RegistrationMaterialsServices {...props} />}
            state={state}
          />
          <Route
            path={currentUrl + "tipos-informacoes-documentadas"}
            render={() => <TiposInformacoesDocumentadas {...props} />}
            state={state}
          />
          <Route
            path={currentUrl + "padrao-desempenho"}
            render={() => <PadraoDesempenho {...props} />}
            state={state}
          />
        </Switch>
      </div>
    </section>
  );
}
