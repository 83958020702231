import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  URL: BASE_URL + "TipoInformacaoDocumentada",
};

export default class TipoInformacaoDocumentadaService {
  static register = async (token, params, archive) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    console.log('body',params )

    let body = {
      nome: params.nome,
      data: params.data,
      geraBloqueioHomologacao: params.geraBloqueioHomologacao
    }

    try {
      const { data } = await axios.post(URLS.URL, body, config);
      const id = data

      if (id) {
        let config = {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        };

        let url = `${URLS.URL}/Upload?id=${id}`

        const { data } = await axios.post(url, archive, config);
        return data
      }

    } catch (err) {
      return err;
    }
  };

  static getAll = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.URL

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static update = async (token, params) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    console.log('body',params )
    let url = URLS.URL
    try {
      const { data } = await axios.put(url, params, config);
      return data;
    } catch (err) {
      return err;
    }
  }

  static delete = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.URL + "/" + id

    try {
      const { data } = await axios.delete(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static download = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        responseType: 'blob',
      },
    };

    let url = `${URLS.URL}/Download?id=${id}`

    try {
      const blob = await fetch(url, config)
        .then(response => response.blob())
        .then(blob => blob)
      return blob
    } catch (err) {
      return err;
    }
  };

}