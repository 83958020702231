import React, { useState, useEffect } from "react";

import "./cardMateriaisServicos.scss"
import MateriaisChart from "./materiais-chart"
import { useHistory } from "react-router-dom";
import infoIcon from "../../../../../assets/icons/info.svg";
import lupaIcon from "../../../../../assets/icons/lupaVazia.svg";
import addIcon from "../../../../../assets/icons/WhiteSubtract.svg";
import materiaisIcon from "../../../../../assets/icons/materiais-icon.svg";
import materiaisServicosIcon from "../../../../../assets/icons/materiais-servicos-icon.svg";
import MaterialsServicesService from "../../../../../services/MaterialsServicesService";

export default function CardMateriaisServicos(props) {
  const { token } = props.state;

  const history = useHistory();

  const navigate = (url) => history.push(url)

  const [materialServicoCount, setMaterialServicoCount] = useState({
    materiais: 0,
    servicos: 0,
    total: 0,
  });

  useEffect(() => {
    _init();
  }, []);

  const _init = async () => {
    const materialServicoCount = await MaterialsServicesService.getCountMaterialServico(token);
    setMaterialServicoCount(materialServicoCount);
  }

  return (
    <>
      <div className="materiais-custom" >

        <div className="card-materiais-servicos">
          <div className="row row-cols-sm-1 row-cols-md-2 row-cols-lg-2 row-cols-xl-2 row-cols-xxl-2 h-100 justify-content-between">

            <div className="card-materiais-body d-flex flex-column justify-content-between align-items-center">

              <div className="situacao-title d-flex text-left align-items-center pl-5">
                <img src={materiaisIcon} />
                <span>
                  Materiais / serviços
                </span>
              </div>

              <div className="provedores-search w-100">
                <button className="w-100" onClick={() => navigate('/cadastro/materiais-servicos')}>
                  <img src={lupaIcon} />
                  <span>
                    Busque seus materiais / serviços
                  </span>
                </button>
              </div>

              <div className="novo-registro w-100">
                <button className="w-100" onClick={() => navigate('/cadastro/materiais-servicos')}>
                  {/* TO DO abrir o form quando navegar para a página */}
                  <img src={addIcon} />
                  <span>
                    Incluir um novo material / serviço
                  </span>
                </button>
              </div>

              <div className="novo-registro w-100">
                <button className="w-100" onClick={() => navigate('/cadastro/grupo-materiais-servicos')}>
                  <img src={addIcon} />
                  <span>
                    Criar grupo de material / serviço
                  </span>
                </button>
              </div>

              <div className="xxxxx d-flex w-100">
                <div className="title d-flex align-items-center">
                  {/* TO DO dados abaixo */}
                  <img src={infoIcon} />
                  xxxxxxxxx
                </div>
                <div className="count">
                  <div className="number">
                    XX
                  </div>
                </div>
              </div>

            </div>

            <div className="card-materiais-body d-flex flex-column justify-content-between align-items-center">

              <div className="d-flex w-100 justify-content-between align-items-center" style={{ marginBottom: '60px' }}>
                <img src={materiaisServicosIcon} />
                <span className="numero-provedor">
                  Número de materiais / serviços
                </span>
                <span className="blue">
                  {materialServicoCount.total}
                </span>
              </div>

              <div className="text-center">
                <div className="material-chart-label">
                  {materialServicoCount.total}

                </div>
                <div className="d-flex justify-content-center">
                  <MateriaisChart materialServicoCount={materialServicoCount} />
                </div>
              </div>

              <div className="provedores-data d-flex flex-row w-100 justify-content-between">
                {/* TO DO dados abaixo */}
                <div className="d-flex flex-column justify-content-between">
                  <span className="homologados-label">
                    xxxxxxxxxxxxxxxxxxxxxxxxxxx
                  </span>
                  <span className="homologados-label">
                    xxxxxxxxxxxxxxxxxxxxxxxxxxx
                  </span>
                </div>

                <div className="d-flex flex-column justify-content-between">
                  <span className="provedor-count warning">{materialServicoCount.materiais}</span>
                  <span className="provedor-count red">{materialServicoCount.servicos}</span>
                </div>

              </div>

            </div>

          </div>
        </div>

      </div>
    </>
  );
}
