import React, { useEffect, useState } from "react";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faUsers } from "@fortawesome/free-solid-svg-icons";

import userIcon from "../../../assets/icons/iconeUser.png";

import "./navbar.scss";

export default function Index({
  profile,
  user,
  handleLogout,
  handleSelect,
  error,
}) {
  const [menuActive, setMenuState] = useState(false);

  // Fechar dropdown clicando em qualquer lugar da página
  useEffect(() => {
    if (menuActive) {
      const rootElement = document.querySelector('div#root');
      const divElem = document.createElement('div')

      divElem.addEventListener('click', () => {
        setMenuState(!menuActive)
        divElem.remove()
      })

      rootElement.appendChild(divElem)
      divElem.setAttribute('id', 'navbar-overlay')
    } else {
      const navbarOverlay = document.querySelector('#navbar-overlay')
      if (navbarOverlay) {
        navbarOverlay.remove()
      }
    }
  }, [menuActive])

  return (
    <>
      <div className="navbar-container">
        <div className="navbar-item">
          <div className="dropdown-container">
            <div
              className="dropdown-selected"
              onClick={() => setMenuState(!menuActive)}
            >
              {/* <FontAwesomeIcon icon={faUsers} /> */}
              <p>{profile && profile.profile && profile.profile.name}</p>
              <img src={userIcon} alt="User" />
            </div>
            <ul className={`dropdown-options ${menuActive ? "" : "hide"}`}>
              {user &&
                user.profiles &&
                user.profiles.length > 0 &&
                user.profiles.map((p, index) => {
                  return (
                    <li key={index} onClick={() => handleSelect(p)}>
                      {p && p.profile && p.profile.name}
                    </li>
                  );
                })}
              <li onClick={() => handleLogout()}>
                Logout
              </li>
            </ul>
            {error && error}
          </div>
        </div>

        {/* <div className="navbar-item">
          <button className="global-btn" onClick={() => handleLogout()}>
            Logout
          </button>
        </div> */}
      </div>
      {/* <h5>Bem vindo, {user && user.name}</h5> */}
    </>
  );
}
