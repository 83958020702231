import React, { useState } from "react";

import EscopoAvaliacao from "./Admin/";
import EscopoAvaliacaoComprador from "./Comprador";
import QuestionTheme from "./questionTheme/";

import "./style.scss";

export default function Index({ state, currentEscopoAvaliacao, embed, handleEditEscopo }) {
  const { token } = state;
  const [item, setItem] = useState(null);

  const isComprador = () => {
    const profile = JSON.parse(localStorage.getItem("profile"));
    return (profile.profileId === 2);
  };

  const handleItem = (data) => {
    setItem(data);
  }

  return (
    <div className="dashboard-container">
      {/* {loading && (
        <div className={"loading-mask"}>
          <FontAwesomeIcon icon={faCircleNotch} pulse />
        </div>
      )} */}
      <div>
        {
          !isComprador() ? (
            item ? (
              <QuestionTheme token={token} escopo={item} handleEscopo={handleItem} />
            ) : (
              <EscopoAvaliacao token={token} initialEscopoAvaliacao={[]} handleItem={handleItem} />
            )
          ) : (
            <EscopoAvaliacaoComprador token={token} current={currentEscopoAvaliacao || null} embed={embed || false} handleEditEscopo={handleEditEscopo} />
          )
        }
      </div>
    </div>
  );
}