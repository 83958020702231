import React, { useEffect, useState } from "react";

export default function MinhasNotificacoesProvedor({
    token,
    notificacoes
}) {
    const [list, setList] = useState([{}]);

    return (
        <>
            <div className="user-list-container escopoAvaliacao-list-container module-EscopoAvaliacaoComprador">
                {/* {loading && (
                    <div className={"loading-mask"}>
                        <FontAwesomeIcon icon={faCircleNotch} pulse />
                    </div>
                )} */}

                <div className="list-container">
                    <div className="list">
                        <div className="list-header">
                            <label>Comprador</label>
                            <label>Data da Notificação</label>
                            <label>Status da Notificação</label>
                            <label>Ver Notificação</label>
                        </div>

                        {(list || []).map((data, index) => (
                            <div className="list-body" key={index}>
                                <span className="list-item">{"Comprador 1"}</span>
                                <span className="list-item">{"12/01/2022"}</span>
                                <span className="list-item"><text style={{ color: 'red' }}>Erro</text></span>

                                <span style={{marginRight:30}} className="list-item document-list">
                                    <a className="list-button">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19 0H5C2.3 0 0 2.2 0 5V19C0 21.7 2.2 24 5 24H19C21.8 24 24 21.8 24 19V5C24 2.3 21.8 0 19 0ZM21.3 12.3L21.2 12.4C20.1 14 18.6 15.3 16.9 16.3C13.9 18 10.1 18 7.1 16.3C5.4 15.3 3.9 14 2.8 12.4L2.7 12.3C2.6 12.1 2.6 11.8 2.7 11.6L2.8 11.5C3.9 10 5.4 8.7 7.1 7.7C10.1 6 13.9 6 16.9 7.7C18.6 8.7 20.1 10 21.2 11.6L21.3 11.7C21.5 11.8 21.5 12.2 21.3 12.3ZM12 13.2C11.4 13.2 10.9 12.7 10.9 12.1C10.9 11.5 11.4 11 12 11C12.6 11 13.1 11.5 13.1 12.1C13.1 12.7 12.6 13.2 12 13.2ZM12 8.7C10.2 8.7 8.7 10.2 8.7 12C8.7 13.8 10.2 15.3 12 15.3C13.9 15.4 15.4 13.9 15.3 12C15.3 10.2 13.8 8.7 12 8.7Z" fill="#4B4B4B" />
                                        </svg>
                                    </a>
                                </span>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </>
    );
}
