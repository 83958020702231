import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import { toast } from "react-toastify";

import NewCriteria from "../../components/NewCriteria";

import AuditService from "../../services/AuditService";

export default function EditAuditCriteria(props) {
  const { token } = props.state;
  const [audit, setAudit] = useState({
    approved: "",
    approvedRestriction: "",
    audit: "",
    auditAdmin: "",
    autoEvaluation: "",
    disapproved: "",
    groupId: "",
    id: "",
    name: "",
  });

  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [redirect, setRedirect] = useState(false);

  const [id] = useState(props.match.params.id || "");

  const getAuditByID = () => {
    AuditService.getAuditByID(token, id)
      .then((response) => {
        if (response) {
          let {
            approved,
            approvedRestriction,
            audit,
            auditAdmin,
            autoEvaluation,
            disapproved,
            groupId,
            id,
            name,
          } = response;

          if (approved) {
            // approved = approved.toString().replace(".", "");
            approved = approved.toString();
          }

          if (approvedRestriction) {
            // approvedRestriction = approvedRestriction
            //   .toString()
            //   .replace(".", "");
            approvedRestriction = approvedRestriction.toString();
          }

          // if (approvedRestriction) {
          //   approvedRestriction = toString(approvedRestriction);
          // }

          let newAudit = {
            approved,
            approvedRestriction,
            audit,
            auditAdmin,
            autoEvaluation,
            disapproved,
            groupId,
            id,
            name,
          };

          setAudit(newAudit);
        }
      })
      .catch((error) => {
        setError(true);
      });
  };

  useEffect(() => {
    getAuditByID();
    // eslint-disable-next-line
  }, []);

  const send = (audit) => {
    setLoading(true);
    setError(false);
    setSuccess(false);
    setRedirect(false);

    if (audit && audit.approved) {
      audit.approved = parseFloat(audit.approved);
    }

    if (audit && audit.approvedRestriction) {
      audit.approvedRestriction = parseFloat(audit.approvedRestriction);
    }

    AuditService.getAuditUpdate(token, audit)
      .then((response) => {
        if (response) {
          let status =
            response && response.response && response.response.status
              ? response.response.status
              : "";

          if (status !== "") {
            if (
              response &&
              response.response &&
              response.response.data &&
              response.response.data.message
            ) {
              let finalMessage = response.response.data.message;

              toast.error(finalMessage);
              setLoading(false);
              setError(false);
              setRedirect(false);
            }
            return false;
          }

          toast.success("Dados salvos com sucesso!");
          setSuccess(true);
          setRedirect(true);
        }

        setLoading(false);
        setError(false);
        setRedirect(false);
      })
      .catch((error) => {
        toast.error("Erro ao salvar dados!");
        setSuccess(false);
        setLoading(false);
        setError(true);
        setRedirect(false);
      });
  };

  if (redirect) {
    return <Redirect to={{ pathname: "/criterios-auditoria" }} />;
  }

  return (
    <div className="dashboard-container">
      {loading && (
        <div className={"loading-mask"}>
          <FontAwesomeIcon icon={faCircleNotch} pulse />
        </div>
      )}
      <h1>Configuração de Critérios de Auditoria</h1>
      <div className="module-container">
        <div className="module-item">
          <NewCriteria
            loading={loading}
            success={success}
            error={error}
            initialAudit={audit}
            send={send}
          />
        </div>
        <div className="module-item"></div>
      </div>
    </div>
  );
}
