import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  MATERIAL_SERVICE: BASE_URL + "MaterialService",
};

export default class MaterialsServicesService {
  static registerMaterialService = async (token, nome) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    let body = {
      name: nome
    }
    try {
      const { data } = await axios.post(URLS.MATERIAL_SERVICE, body, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getMaterialsServices = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        },
      };
        
    let url = URLS.MATERIAL_SERVICE

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getMaterialsServicesProvedor = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        },
      };
        
    let url = URLS.MATERIAL_SERVICE + "/provedor"

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getApprovalOpportunity = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        },
      };
      let url = URLS.MATERIAL_SERVICE + "/" + id + "/" + "oportunidade-homologacao"

      try {
        const { data } = await axios.get(url, config);
        return data;
      } catch (err) {
        return err;
      }
  }

  static updateStatusMaterialsService = async (token, name, isEnable, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
          

    let url = URLS.MATERIAL_SERVICE
    try {
      const { data } = await axios.put(url, { name, isEnable, id}, config);
      return data;
    } catch (err) {
      return err;
    }
  }

  static deleteMaterialService = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
      
    let url = URLS.MATERIAL_SERVICE + "/" + id

    try {
      const { data } = await axios.delete(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getCountMaterialServico = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data } = await axios.post(
        // TO DO remover mock na integracao
        'https://run.mocky.io/v3/2c1adb78-1e76-4e23-952f-b0c60bf0bce6',
        // URLS.PROVEDORES,
        {},
        config
      );
      return data;
    } catch (err) {
      return err;
    }
  };

  static registerMaterialServiceProvedor = async (token, payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    console.log(payload);
    throw Error('Falta implementar rota no backend para salvar!');
    //const { data } = await axios.post(URLS.MATERIAL_SERVICE, payload, config);
    //return data;
  };

  static getCategoriaServiceProvedor = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return [
      {
        id: 1,
        name: 'Categoria 1'
      },
      {
        id: 2,
        name: 'Categoria 2'
      },
      {
        id: 3,
        name: 'Categoria 3'
      }
    ]
    //const { data } = await axios.post(URLS.MATERIAL_SERVICE, payload, config);
    //return data;
  };

  static getCategoriaMaterialServiceProvedor = async (token, payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    return [
      {
        id: 1,
        name: 'Material 1'
      },
      {
        id: 2,
        name: 'Material 2'
      },
      {
        id: 3,
        name: 'Material 3'
      }
    ]
    //const { data } = await axios.post(URLS.MATERIAL_SERVICE, payload, config);
    //return data;
  };
}