import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  USERS: BASE_URL + "users",
};

export default class UserService {
  static getUsers = async (token, page, nome) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    
    let url = URLS.USERS + "?Pagination=" + page
    
    if (nome)
      url = url + "&Nome=" + nome
    
    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getTotalBuyers = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.USERS + "/total-compradores";

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getTotalUsers = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.USERS + "/total";

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static searchUsers = async (token, page, input) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.USERS + "/all/" + page + "/" + input;

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getUser = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.USERS + "/" + id;

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };
}
