import React, { memo, useState, useEffect } from 'react';

import './styles.scss'

function CardHeader({ comprador }) {
    const [dadosComprador, setDadosComprador] = useState(comprador);
    const MSG_FALTA_PREENCHIMENTO = "Falta preenchimento comprador";

    let endereco = () => {
        if (!dadosComprador.logradouro || !dadosComprador.numero) {
            return MSG_FALTA_PREENCHIMENTO;
        }
        return dadosComprador.logradouro + ", nº" + dadosComprador.numero;
    }
    useEffect(() => {
        setDadosComprador(comprador);
    }, [comprador])

    return (
        <div className="card-header-wrap">
            <div className="card-01">
                <div id="logo-wrap">
                    <img src={dadosComprador.logo || ""} rel="image-logo" />
                </div>
                <section>
                    <h1>{dadosComprador.nome || ""}</h1>
                    <p><span>Razão Social:  </span>{dadosComprador.razaoSocial || MSG_FALTA_PREENCHIMENTO}</p>
                    <p><span>Telefone:  </span>{dadosComprador.telefone || MSG_FALTA_PREENCHIMENTO}</p>
                    <p><span>Site:  </span>{dadosComprador.site || MSG_FALTA_PREENCHIMENTO}</p>
                </section>
            </div>
            <div className="card-02">
                <section>
                    <h1>DADOS CADASTRADOS</h1>
                    <p><span>Nome Fantasia:  </span>{dadosComprador.nomeFantasia || MSG_FALTA_PREENCHIMENTO}</p>
                    <div>
                        <p><span>CNPJ:  </span>{dadosComprador.cnpj || MSG_FALTA_PREENCHIMENTO}</p>
                        <p><span>Inscrição Estadual:  </span>{dadosComprador.inscricaoEstadual || MSG_FALTA_PREENCHIMENTO}</p>
                    </div>
                    <p><span>Endereço:  </span>{endereco()}</p>
                    <div>
                        <p><span>Nome:  </span>{dadosComprador.nome || MSG_FALTA_PREENCHIMENTO}</p>
                        <p><span>Cargo:  </span>{dadosComprador.cargo || MSG_FALTA_PREENCHIMENTO}</p>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default memo(CardHeader)