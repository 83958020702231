import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import Modal from "../../../components/ModalExit";
import Service from "../../../services/tiposInformacoesDocumentadasService";

import { toast } from "react-toastify";

export default function List({
  valueInitial,
  token,
  updated,
  currentDocuments,
  back
}) {
  const [data, setData] = useState(valueInitial);
  const [modal, setModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [currentId, setCurrentId] = useState("");

  const getDocuments = () => {
    setLoading(true);
    Service.getAll(token)
      .then((response) => {
        if (response) {
          setData(response);
          console.log('data response', response)
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  };

  const handleDelete = () => {
    setLoading(true);
    Service.delete(token, currentId)
      .then((response) => {
        if (response) {
          if (back) {
            back();
          }
          toast.success("Item excluído com sucesso!");
          setLoading(false);
          getDocuments();
          handleModal(false);
        }
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Erro ao excluir item!");
        setLoading(false);
      });
  };

  const handleModal = (value) => {
    setModal(value);
  };

  const handleDownload = (item) => {
    Service.download(token, item.id)
      .then(blob => {
        if (blob) {
          toast.success("Iniciando download!");
          setLoading(false);
          handleModal(false);

          var url = window.URL.createObjectURL(blob);
          var a = document.createElement('a');
          a.href = url;
          a.download = `${item.nome}-${item.data.slice('T', -9)}${item.extensao}`;
          document.body.appendChild(a);
          a.click();
          a.remove();

        }
        setLoading(false);
      })
      .catch((error) => {
        toast.error("Erro fazer download!");
        setLoading(false);
      });
  }

  const handleId = (id) => {
    setCurrentId(id);
    handleModal(true);
  };

  const isComprador = () => {
    const profile = JSON.parse(localStorage.getItem("profile"));
    return (profile.profileId === 2);
  };

  const handleEdit = (value) => {
    currentDocuments({ ...value, data: dateFormat(value.data) });
  };

  useEffect(() => {
    getDocuments();
  }, [updated]);

  function dateFormat(date) {
    const fullDate = new Date(`${date}`)

    function convert(n) { return n < 10 ? `0${n}` : n }

    const dd = convert(fullDate.getDate())
    const mm = convert(fullDate.getMonth() + 1)
    const yy = fullDate.getFullYear()

    return `${dd}/${mm}/${yy}`
  }

  return (
    <>
      {modal && <Modal handleDelete={handleDelete} handleModal={handleModal} />}
      <div className="user-list-container">
        {loading && (
          <div className={"loading-mask"}>
            <FontAwesomeIcon icon={faCircleNotch} pulse />
          </div>
        )}
        <div className="list-container">
          <div className="list">
            <div className="list-header">
              <label style={{ flex: "1 0 33%" }}>Título</label>
              <label style={{textAlign: "center"}} >Data</label>
              {isComprador() && <label style={{ flex: "1 0 30%", textAlign: "center" }}>Gera Bloqueio Homologação</label>}
              <label style={{ textAlign: "center" }}>Download</label>
              <label>Editar</label>
            </div>
            {data &&
              data.length > 0 &&
              data.map((item, index) => {
                return (
                  <div className="list-body" key={index}>
                    <span className="list-item" style={{ flex: "1 0 32%" }}>{item.nome}</span>
                    <span className="list-item" style={{textAlign: "center"}}>{dateFormat(item.data)}</span>
                    {isComprador() && <span className="list-item" style={{ flex: "1 0 30%" , textAlign: "center"}}>{item.geraBloqueioHomologacao ? "SIM" : "NÃO"}</span>}

                    <span className="list-item document-list" style={{ textAlign: "center" }}>
                      <a onClick={() => handleDownload(item)} download>
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M3 0.5C1.34315 0.5 0 1.84315 0 3.5V21.5C0 23.1569 1.34315 24.5 3 24.5H21C22.6569 24.5 24 23.1569 24 21.5V3.5C24 1.84315 22.6569 0.5 21 0.5H3ZM4.61111 14.7222C4.61111 14.1699 4.1634 13.7222 3.61111 13.7222C3.05883 13.7222 2.61111 14.1699 2.61111 14.7222V17.6852C2.61111 18.4164 2.96839 19.0651 3.51028 19.5074C4.04656 19.9452 4.73766 20.1667 5.42593 20.1667H18.1296C18.8179 20.1667 19.509 19.9452 20.0453 19.5074C20.5872 19.0651 20.9444 18.4164 20.9444 17.6852V14.7222C20.9444 14.1699 20.4967 13.7222 19.9444 13.7222C19.3922 13.7222 18.9444 14.1699 18.9444 14.7222V17.6852C18.9444 17.7398 18.9193 17.8448 18.7805 17.9581C18.6361 18.076 18.404 18.1667 18.1296 18.1667H5.42593C5.15155 18.1667 4.91945 18.076 4.77503 17.9581C4.63624 17.8448 4.61111 17.7398 4.61111 17.6852V14.7222ZM6.4659 10.385C6.81515 9.95718 7.4451 9.89348 7.87294 10.2427L10.7778 12.614V5.83333C10.7778 5.28105 11.2255 4.83333 11.7778 4.83333C12.3301 4.83333 12.7778 5.28105 12.7778 5.83333V12.6137L15.6823 10.2427C16.1101 9.89348 16.74 9.95718 17.0893 10.385C17.4385 10.8128 17.3748 11.4428 16.947 11.7921L12.4442 15.4678C12.2673 15.626 12.0338 15.7222 11.7778 15.7222C11.5237 15.7222 11.2918 15.6275 11.1155 15.4715L6.60818 11.7921C6.18035 11.4428 6.11665 10.8128 6.4659 10.385Z" fill="#4B4B4B" />
                        </svg>
                      </a>
                    </span>

                    <span className="list-item document-list">
                      <a onClick={() => handleId(item.id)}>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M17.2096 6.32797H13.5054V6.17363C13.5054 5.09325 12.6566 4.24437 11.5762 4.24437H11.4218C10.3414 4.24437 9.49257 5.09325 9.49257 6.17363V6.32797H5.71122C5.47971 6.32797 5.2482 6.55949 5.2482 6.791V8.72026C5.32537 8.95177 5.47971 9.10611 5.71122 9.10611H5.94273L7.25463 18.5209C7.25463 18.7524 7.48614 18.9068 7.71765 18.9068H15.2804C15.5119 18.9068 15.6662 18.7524 15.7434 18.5209L16.9781 9.10611H17.2096C17.4411 9.10611 17.6726 8.8746 17.6726 8.64309V6.791C17.6726 6.55949 17.4411 6.32797 17.2096 6.32797ZM10.2643 6.17363C10.2643 5.55627 10.7273 5.09325 11.3447 5.09325H11.499C12.1164 5.09325 12.5794 5.55627 12.5794 6.17363V6.32797H10.2643V6.17363ZM14.8173 18.0579H8.02633L6.86878 9.10611H16.0521L14.8173 18.0579ZM16.7466 8.25723C16.6694 8.25723 6.25141 8.25723 6.17424 8.25723V7.25402H16.7466V8.25723ZM9.56974 16.8232C9.80125 16.8232 9.95559 16.5916 9.95559 16.3601L9.4154 10.5723C9.4154 10.3408 9.18389 10.1865 8.95238 10.1865C8.72087 10.1865 8.56653 10.418 8.56653 10.6495L9.10672 16.4373C9.10672 16.6688 9.33823 16.8232 9.56974 16.8232ZM13.3511 16.8232C13.5826 16.8232 13.8141 16.6688 13.8141 16.4373L14.3543 10.6495C14.3543 10.418 14.2 10.1865 13.9685 10.1865C13.7369 10.1865 13.5054 10.3408 13.5054 10.5723L12.9652 16.3601C12.9652 16.5916 13.1196 16.8232 13.3511 16.8232ZM11.4218 16.8232C11.6533 16.8232 11.8849 16.5916 11.8849 16.3601V10.5723C11.8849 10.3408 11.6533 10.1093 11.4218 10.1093C11.1903 10.1093 10.9588 10.3408 10.9588 10.5723V16.3601C11.036 16.6688 11.1903 16.8232 11.4218 16.8232ZM24.0006 17.1318C24.0006 20.9132 20.991 24 17.1324 24H6.86878C3.08743 24 0.000610352 20.9904 0.000610352 17.1318V6.86817C0.000610352 3.08682 3.01026 0 6.86878 0H17.1324C20.9138 0 24.0006 3.00965 24.0006 6.86817V17.1318Z"
                            fill="#4B4B4B"
                          />
                        </svg>
                      </a>

                      <a onClick={() => handleEdit(item)}>
                        <svg
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M19.2006 5.55627L18.1167 4.63023C17.4974 4.09003 16.6458 4.1672 16.1038 4.7074L5.49738 15.0482C5.41996 15.1254 5.26513 15.2797 5.26513 15.4341L4.25867 19.2154C4.18126 19.5241 4.41351 19.7556 4.72319 19.6785L8.43932 18.6752C8.59416 18.5981 8.749 18.5209 8.82642 18.4437L19.3554 7.71704C19.8974 7.09968 19.8974 6.17363 19.2006 5.55627ZM5.18771 18.8296L5.88448 16.2058L7.74255 18.135L5.18771 18.8296ZM8.43932 17.7492L6.19416 15.4341L13.9361 7.94855L16.0264 10.0322L8.43932 17.7492ZM16.6458 9.41479L14.5554 7.33119L15.0974 6.791L17.1877 8.8746L16.6458 9.41479ZM18.7361 7.17685L17.8071 8.18006L15.7942 6.17363L16.6458 5.32476C16.878 5.09325 17.2651 5.09325 17.5748 5.32476L18.7361 6.32797C19.0458 6.48231 19.0458 6.94534 18.7361 7.17685ZM7.74255 15.5884C7.58771 15.7428 7.58771 16.0514 7.74255 16.283C7.89738 16.4373 8.20706 16.4373 8.43932 16.283L14.478 10.2637C14.6329 10.1093 14.6329 9.80064 14.478 9.56913C14.3232 9.41479 14.0135 9.41479 13.7813 9.56913L7.74255 15.5884ZM24.0006 17.1318C24.0006 20.9132 20.9813 24 17.1103 24H6.89093C3.09738 24 0.000610352 20.9904 0.000610352 17.1318V6.86817C0.000610352 3.08682 3.01996 0 6.89093 0H17.1877C20.9038 0 24.0006 3.00965 24.0006 6.86817V17.1318Z"
                            fill="#4B4B4B"
                          />
                        </svg>
                      </a>
                    </span>
                  </div>
                )
              })}
          </div>
        </div>
      </div>
    </>
  );
}
