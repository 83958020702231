import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  CERTIFICATION_TYPES: BASE_URL + "TipoCertificacao",
};

export default class CertificationTypesService {
  static create = async (token, payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    
    try {
      const { data } = await axios.post(URLS.CERTIFICATION_TYPES, payload, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static get = async (token, searchQuery = '') => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        },
      };
        
    let url = URLS.CERTIFICATION_TYPES;

    if(searchQuery != ''){
      url += "?Nome=" + searchQuery;
    }

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static update = async (token, payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.CERTIFICATION_TYPES
    try {
      const { data } = await axios.put(url, payload, config);
      return data;
    } catch (err) {
      return err;
    }
  }

  static delete = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
      
    let url = URLS.CERTIFICATION_TYPES + "/" + id

    try {
      const { data } = await axios.delete(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };
}