import React, { useEffect, useState } from "react";

import EscopoAvaliacaoPerguntas from "../../../../../services/EscopoAvaliacaoPerguntas";
import RespostaPergunta from "../RespostaPergunta";
import Loading from "../../../../../components/Loading";

import './styles.scss';

export default function ResultadosEscopoAvaliacao({ token, compradorId, idEscopoAvaliacao, handleViewResultadosEscopoAvaliacao, nomeEscopoAvaliacao }) {
    const [resultadosEscopo, setresultadosEscopo] = useState(false)
    const [loading, setLoading] = useState(false);
    const [showRespostaPergunta, setShowRespostaPergunta] = useState(false);
    const [pergunta, setPergunta] = useState(null);


    const _init = async () => {
        setLoading(true);
        setShowRespostaPergunta(false);
        setPergunta(null);
        const response = await EscopoAvaliacaoPerguntas.getByCompradorEscopo(token, idEscopoAvaliacao, compradorId);
        setresultadosEscopo(response);
        setLoading(false);
    }

    const handleViewRespostaPergunta = () => {
        setShowRespostaPergunta(!showRespostaPergunta);
        setPergunta(null);
    }

    useEffect(() => {
        if (compradorId)
            _init()
    }, [compradorId])



    return (
        <>
            <Loading loading={loading} />
            <h1>PERGUNTAS E RESPOSTAS POR ESCOPO DE AVALIAÇÃO</h1>
            {showRespostaPergunta && (
                <RespostaPergunta token={token} nomeEscopoAvaliacao={nomeEscopoAvaliacao} pergunta={pergunta} handleViewRespostaPergunta={handleViewRespostaPergunta} reload={_init} />
            )}
            {!showRespostaPergunta && (
                <>
                    <div className="resultados-container">
                        <div className="card-container">
                            <div className="card subcard">
                                <div className="card-header">
                                    <h1>{nomeEscopoAvaliacao}</h1>
                                </div>
                                <div className="card-body">
                                    <div className="list-container">
                                        <div className="list">
                                            <div className="list-header">
                                                <label style={{ maxWidth: "95%", width: "95%" }}>Temas / Perguntas</label>
                                                <label style={{ flexGrow: 0, maxWidth: "170px" }}>Status</label>
                                                <label style={{ flexGrow: 0 }}>Minhas Respostas</label>
                                            </div>
                                            {(resultadosEscopo || []).map((data, i) => (
                                                <>
                                                    <div className="list-body" key={i}>
                                                        <span style={{ maxWidth: "95%", width: "95%", fontWeight: 900, fontSize: "16px", fontFamily: "Poppins-Medium", color: "#353535" }}>
                                                            {data.tema.name}
                                                        </span>
                                                    </div>

                                                    {(data.perguntas || []).map((item, index) => (
                                                        <div className="list-body" key={index + 10}>
                                                            <span style={{ maxWidth: "95%", width: "95%", marginLeft: item.idPerguntaPai === 0 ? "30px" : "60px" }}><strong>{item.descricao}</strong></span>
                                                            <span className={"list-item " + (item.respondida ? 'respondido' : 'naoRespondido')} style={{ maxWidth: "150px", whiteSpace: "nowrap", textAlign: "center" }}><strong>{item.respondida ? "Respondido" : "Não Respondido"}</strong></span>
                                                            <span className="list-item" style={{ flexGrow: 0 }}>
                                                                <a className="list-button" onClick={() => {
                                                                    setShowRespostaPergunta(true);
                                                                    setPergunta(item);
                                                                }}>
                                                                    <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <path fillRule="evenodd" clipRule="evenodd" d="M5 0.5C2.23858 0.5 0 2.73858 0 5.5V19.5C0 22.2614 2.23858 24.5 5 24.5H19C21.7614 24.5 24 22.2614 24 19.5V5.5C24 2.73858 21.7614 0.5 19 0.5H5ZM5.59868 19.5H18.3553C18.6776 19.5 19 19.0922 19 18.6165C19 18.1408 18.7237 17.733 18.4013 17.733H5.59868C5.27632 17.733 5 18.1408 5 18.6165C5 19.0922 5.27632 19.5 5.59868 19.5ZM5.59868 15.4223H18.3553C18.6776 15.4223 19 15.0146 19 14.5388C19 14.0631 18.7237 13.6553 18.4013 13.6553H5.59868C5.27632 13.6553 5 14.0631 5 14.5388C5 15.0146 5.27632 15.4223 5.59868 15.4223ZM5.59868 11.3447H18.3553C18.6776 11.3447 19 10.9369 19 10.4612C19 9.98544 18.7237 9.57767 18.4013 9.57767H5.59868C5.27632 9.57767 5 9.98544 5 10.4612C5 10.9369 5.27632 11.3447 5.59868 11.3447ZM5.59868 7.26699H18.3553C18.6776 7.26699 19 6.85922 19 6.38349C19 5.90777 18.7237 5.5 18.4013 5.5H5.59868C5.27632 5.5 5 5.90777 5 6.38349C5 6.85922 5.27632 7.26699 5.59868 7.26699Z" fill="#4B4B4B" />
                                                                    </svg>
                                                                </a>
                                                            </span>
                                                        </div>
                                                    ))}
                                                </>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={`row mx-0`}>
                        <div className="col-12 px-0 justify-content-between">
                            <button
                                type="button"
                                className="global-btn w-auto"
                                onClick={() => handleViewResultadosEscopoAvaliacao(false)}
                            >
                                <svg className="mr-1 back-icon" width="10" height="10" viewBox="0 0 10 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1.47211 5.05021L5.10332 9.09061L4.31819 9.69667L0 5.05021L4.31819 0.302734L5.00518 1.00981L1.47211 5.05021ZM9.71593 1.00981L9.02894 0.302734L4.71075 5.05021L9.02894 9.69667L9.71593 8.9896L6.18286 5.05021L9.71593 1.00981Z" fill="white" />
                                </svg>
                                VOLTAR
                            </button>
                        </div>
                    </div>
                </>
            )}

        </>
    )
}