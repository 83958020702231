import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  URL: BASE_URL + "ProvedorNotificacao",
  ACOES_INTERNAS: BASE_URL + "ProvedorNotificacao/acoes-internas",
  CATEGORIAS: BASE_URL + "ProvedorNotificacao/categorias",
  IDENTIFICACOES: BASE_URL + "ProvedorNotificacao/identificacoes",
  IMPACTOS: BASE_URL + "ProvedorNotificacao/impactos",
  NIVEIS_CRITICIDADES: BASE_URL + "ProvedorNotificacao/niveis-criticidades",
  UPLOAD_DOCUMENTO: BASE_URL + "ProvedorNotificacao/UploadDocumento",
  MATERIAL_SERVICOS: BASE_URL + "MaterialService/Comprador",
};

export default class InformacaoEntradaService {
  static create = async (token, payload) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    try {
      const { data } = await axios.post(URLS.URL, payload, config);
      return data
    } catch (err) {
      return err;
    }
  };

  static upload = async (token, archive, id) => {

    try {
      if (id) {
        let configFormData = {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: `Bearer ${token}`,
          },
        };

        let url = `${URLS.UPLOAD_DOCUMENTO}/${id}`

        const { data } = await axios.post(url, archive, configFormData);
        return data
      }
    } catch (err) {
      return err;
    }
  };

  static get = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.URL

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static delete = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.INFORMACAO_ENTRADA + "/" + id

    // items = items.filter((elem) => {
    //   return elem.id !== id
    // });

    // console.log('delete', items);
    // return true;

    try {
      const { data } = await axios.delete(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getAcoesInternas = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.ACOES_INTERNAS

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getCategorias = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.CATEGORIAS

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getIdentificacoes = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.IDENTIFICACOES

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getImpactos = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.IMPACTOS

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getNiveisCriticidades = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.NIVEIS_CRITICIDADES

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getMaterialServicos = async (token) => {

    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.MATERIAL_SERVICOS + '?Nome=&Pagination=1'

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getMaterialServicosById = async (token, idProvedor) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = `${URLS.URL}/${idProvedor}`

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

}