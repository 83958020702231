import React, { useEffect, useState } from "react";

import { toast } from "react-toastify";
import Modal from "../../../components/ModalExit";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import Service from "../../../services/padroesDesempenhoService";

export default function Form({
  token,
  send,
  currentValue
}) {
  const [modal, setModal] = useState(false);

  const [loading, setLoading] = useState(false);

  const [data, setData] = useState({
    nome: "",
    meta: 1.0
  });

  const handleChangeNome = (event) => {
    event.persist();
    setData(values => ({
      ...values,
      nome: event.target.value,
    }));

  };
  const handleChangeMeta = (event) => {
    event.persist();
    let metaDigitada = event.target.value.replace("%", "").replace(",", ".");
    metaDigitada = metaDigitada.replace(/[^\d\.]+/g, '');

    if (metaDigitada > 100)
        metaDigitada = 100;

    if (metaDigitada < 1.0 || metaDigitada == "")
        metaDigitada = 1.0;

    setData(values => ({
      ...values,
      meta: metaDigitada,
    }));

  };

  const handleSubmit = () => {
    if (data.nome === "") return
    if (data.meta === "" || data.meta === "%") return

    if (currentValue && currentValue.id > 0) {
      return handleUpdate();
    }
    handleRegister();
  };

  const handleRegister = () => {
    setLoading(true);

    Service.register(token, { ...data })
      .then((response) => {

        if (response) {
          setLoading(false);
        }
        setLoading(false);
        setData({
          nome: "",
          meta: 1.0
        });

        toast.success("Item cadastrado com sucesso!");

        send(response);
      }).catch((erro) => {
        toast.error("Erro ao cadastrar item!");
        setLoading(false);
      });
  };

  const handleUpdate = () => {
    setLoading(true);

    Service.update(token, { ...data })
      .then((response) => {
        setLoading(false);

        setData({
          nome: "",
          meta: 1.0
        });

        toast.success("Item editado com sucesso!");

        send(response);
      }).catch((error) => {
        toast.error("Erro ao atualizar item!");
        setLoading(false);
      });
  };

  useEffect(() => {
    if (currentValue)
      setData(currentValue)
    // eslint-disable-next-line
  }, [currentValue]);

  const handleModal = (value) => {
    setModal(value);
  };

  return (
    <>
      {modal &&
        <Modal
          title={'Tem certeza que deseja excluir este item? Se o padrão de desempenho estiver atrelado a algum indicador não poderá ser excluido.'}
          handleDelete={null}
          handleModal={handleModal}
          alert
        />
      }
      <div >
        <div className="card-container">
          <div className="card">
            <div className="card-header">
              <h1>CADASTRO DE NOVO PADRÃO DE DESEMPENHO</h1>
            </div>
            <div className="card-body">
              <form className="form-container">
                <div className="group-input escopo-avaliacao created-form">
                  <div className="content-input">
                    <label style={{width: 430}} htmlFor="nome">Nome do Padrão de Desempenho</label>
                    <input
                      className="global-input"
                      type="text"
                      id="nome"
                      placeholder=""
                      name="nome"
                      value={data.nome}
                      onChange={handleChangeNome}
                      style={{
                        width: '200%',
                        marginLeft: 0
                      }}
                    />
                  </div>
                  <div className="content-input">
                    <label style={{width: 60, marginRight: 0, minWidth: '40px'}} htmlFor="meta">Meta</label>
                    <input
                      className="global-input"
                      type="text"
                      id="meta"
                      placeholder="100%"
                      name="meta"
                      value={data.meta + "%"}
                      onChange={handleChangeMeta}
                      style={{
                        width: '30%',
                        marginLeft: 0,
                        marginRight : "50%"
                      }}
                     />
                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div>
        <button className="global-btn" onClick={handleSubmit} style={{
          width: 85,
          marginTop: 14
        }}>
          {currentValue && currentValue.id > 0
            ? !loading && "SALVAR"
            : !loading && "CRIAR"}

          {loading && <FontAwesomeIcon icon={faCircleNotch} pulse />}
        </button>
      </div>
    </>
  );
}
