import React, { useEffect, useState, memo } from "react";
import InputRadio from "../../../../components/InputRadio/InputRadio";

function IdoneidadeIntegridadeComponent({ current, dados, change }) {
    const [options, setIdoneidadeIntegridade] = useState(dados);
    
    const handleChange = (e) => {
        e.persist();

        let newArray = [...options];
        let indexKey = parseInt(e.target.name.toString().replace('idoneidade-integridade-', ''));

        newArray[indexKey] = {
            ...newArray[indexKey], ...{
                enableGroupMaterial: (e.target.value == '1' ? true : false)
            }
        }

        setIdoneidadeIntegridade(newArray);
        change(newArray);
    };

    const checkEnable = (id) => {
        return current.idoneidadeIntegridades.find(elem => (elem.id === id)) || false;
    }

    useEffect(() => {
        if (dados != undefined && dados != null) {
            setIdoneidadeIntegridade(dados.map((dado) => (
                { ...dado, ...{ enableGroupMaterial: (checkEnable(dado.id)) ? true : false } }
            )));
        }
    }, [dados]);

    const randomKey = () => {
        const key = (Math.random() * 0xFFFFFF << 0).toString(16)
        return `${key}`
    }

    return (
        <>
            <div className="card-container">
                <div className="card">
                    <div className="card-header">
                        <h1>Idoneidade Integridade</h1>
                    </div>
                    <div className="card-body">
                        <section className="form-container">
                            <div className="group-input m-0">
                                <div className="d-flex flex-wrap">
                                    <div className="row equal">
                                        {(options || []).map((data, i) => (
                                            <div className="col-md-12 col-Itens" key={randomKey()}>
                                                <label className="itemName">{data.nome}</label>
                                                <div className="InputRadioGroup">
                                                    <InputRadio
                                                        key={randomKey()}
                                                        name={`idoneidade-integridade-${i}`}
                                                        label="Sim"
                                                        value="1"
                                                        checked={(data.enableGroupMaterial === true) ? '1' : data.enableGroupMaterial}
                                                        onChange={handleChange}
                                                    />
                                                    <InputRadio
                                                        key={randomKey()}
                                                        name={`idoneidade-integridade-${i}`}
                                                        label="Não"
                                                        value="0"
                                                        checked={!data.enableGroupMaterial ? '0' : data.enableGroupMaterial}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
        </>
    );
}

export default memo(IdoneidadeIntegridadeComponent)