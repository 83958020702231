import React, { memo, useState, useEffect } from 'react';

import './styles.scss'

function CardHeader({provedor}) {
const [dadosProvedor, setDadosProvedor] = useState(provedor);
const MSG_FALTA_PREENCHIMENTO = "Falta preenchimento provedor";

let endereco = () =>{
    if (!dadosProvedor.logradouro || !dadosProvedor.numero) {
        return MSG_FALTA_PREENCHIMENTO;
    }
    return dadosProvedor.logradouro + ", nº" + dadosProvedor.numero; 
} 
useEffect(() => {
    setDadosProvedor(provedor);
}, [provedor])

    return (
        <div className="card-header-wrap">
            <div className="card-01">
                <div id="logo-wrap">
                    <img src={dadosProvedor.logo || ""} rel="image-logo" />
                </div>
                <section>
                    <h1>{dadosProvedor.nome || ""}</h1>
                    <p><span>Razão Social:  </span>{dadosProvedor.razaoSocial || MSG_FALTA_PREENCHIMENTO}</p>
                    <p><span>Telefone:  </span>{dadosProvedor.telefone || MSG_FALTA_PREENCHIMENTO}</p>
                    <p><span>Site:  </span>{dadosProvedor.webSite || MSG_FALTA_PREENCHIMENTO}</p>
                </section>
            </div>
            <div className="card-02">
                <section>
                    <h1>DADOS CADASTRADOS</h1>
                    <p><span>Nome Fantasia:  </span>{dadosProvedor.nomeSocial || MSG_FALTA_PREENCHIMENTO}</p>
                    <div>
                        <p><span>CNPJ:  </span>{dadosProvedor.cnpj || MSG_FALTA_PREENCHIMENTO}</p>
                        <p><span>Inscrição Estadual:  </span>{dadosProvedor.IncricaoEstadual || MSG_FALTA_PREENCHIMENTO}</p>
                    </div>
                    <p><span>Endereço:  </span>{endereco()}</p>
                    <div>
                        <p><span>Nome:  </span>{dadosProvedor.nomeEmpresa || MSG_FALTA_PREENCHIMENTO}</p>
                        <p><span>Cargo:  </span>{dadosProvedor.cargo || MSG_FALTA_PREENCHIMENTO}</p>
                    </div>
                </section>
            </div>
        </div>
    )
}

export default memo(CardHeader)