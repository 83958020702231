import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import MaterialService from "./ListMaterialService";

import PlusBranco from "../../../assets/icons/plusBranco.svg";

import "../../dashboard/dashboard.scss";
import MaterialServiceForm from "../Form/materialServicesForm";

export default function List({ loading, materialsService, token }) {
  const [updated, setUpdated] = useState();
  const send = (value) => {
    if (value) {
      if (updated)
        setUpdated(false)
      
      return setUpdated(true)
    }

    return;
  }

  return (
    <>
      <MaterialServiceForm token={token} send={send}/>
      {<MaterialService initialMaterialsService={materialsService} token={token} updated={updated} />}
    </>
  );
}
