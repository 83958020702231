import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import DropList from "../../../components/DropList/DropList"
import CriteriosAvaliacaoList from "../CriteriosAvaliacaoList";

import InformacaoEntradaService from "../../../services/InformacaoEntradaService";

export default function InformacaoEntradaForm({ token, current, callback, provedores }) {
  const [informacao, setInformacao] = useState(current);
  const [materialServices, setMaterialServices] = useState([]);
  const [listaCriteriosavaliacao, setlistaCriteriosavaliacao] = useState([]);
  const [sending, setSending] = useState(false);
  const [dropListSelect, setDropListSelect] = useState('');

  const [optionSelected, setOptionSelected] = useState({
    provedor: { id: 0, name: '' },
    material: { id: 0, name: '' }
  });

  const sum = (arr) => {
    var total = 0
    for (var i = 0; i < arr.length; i++) {
      total += arr[i]
    }
    return total;
  }

  const handleCriterios = (e) => {
    e.preventDefault();
    const nome = e.target.name;

    let listaTemporaria = listaCriteriosavaliacao;
    listaTemporaria = listaTemporaria.map((criterio, i) => {
      if (criterio.descricao === nome) {
        criterio.estaConformeIndicador = !criterio.estaConformeIndicador;
      }
      return criterio;
    })
    handleIndice(listaTemporaria);
  }

  const handleIndice = (listaTemporaria) => {
    let listaCriteriosSelecionados = listaTemporaria.filter(item => item.estaConformeIndicador === true);
    let valoresIndice = [];
    if (listaCriteriosSelecionados.length > 0) {
      valoresIndice = listaCriteriosSelecionados.map((criterio, i) => {
        return criterio.peso;
      })
    }
    const newIndice = sum(valoresIndice);
    setInformacao({
      ...informacao,
      indice: newIndice,
      criteriosAvaliacao: listaCriteriosavaliacao
    })
    setlistaCriteriosavaliacao(listaTemporaria);
  }

  const handleChange = (e) => {
    e.persist();

    const porcMask = () => {
      if (e.target.name != 'indice') return

      const currentValue = e.target.value.replace('%', '').replace(',', '.')
      if (isNaN(Number(currentValue))) return

      return e.nativeEvent.inputType === 'deleteContentBackward'
        ? currentValue
        : currentValue + '%'
    }

    setInformacao((values) => {
      let newValue = {
        ...values,
        [e.target.name]: (e.target.type == 'radio') ?
          (e.target.value == '1' ? true : false) :
          (e.target.name === 'indice' ? porcMask() :
            (e.target.name === 'materialServicesId' ? parseInt(e.target.value) :
              (e.target.name === 'provedorId' ? parseInt(e.target.value) : e.target.value)))
      }
      return newValue;
    });
  };

  const formatDate = (date) => {
    if (!date) return '';

    let dia = date.split("-")[2] || '00';
    dia = dia.split("T")[0] || '00';
    let mes = date.split("-")[1] || '00';
    let ano = date.split("-")[0] || '00';

    return ("0" + ano).slice(-4) + '-' + ("0" + mes).slice(-2) + '-' + ("0" + dia).slice(-2);
  }

  const handleSubmit = (e) => {
    e.preventDefault();

    if ("compradorId" in informacao) delete informacao["compradorId"];
    if ("materialServices" in informacao) delete informacao["materialServices"];
    if ("provedor" in informacao) delete informacao["provedor"];

    if (informacao.indice == "") { // significa que nao teve nenhum criterio de avaliacao
      informacao.indice = "0";
    }

    if (
      informacao &&
      informacao.data &&
      informacao.provedorId &&
      informacao.materialServicesId &&
      informacao.notaFiscal
    )
      if (informacao.id) {
        update(informacao);
      } else {
        create(informacao);
      }
    else {
      toast.warning(
        "Data, Provedor, Material / Serviço e Nº da Nota Fiscal são necessários"
      );
    }
  };

  const create = async (payload) => {
    setSending(true);
    const promise = await InformacaoEntradaService.create(token, payload)

    if (promise) {
      if (promise.isAxiosError) {
        toast.error(promise.response.data || 'Erro inesperado ao cadastrar item!')
        setSending(false)
        return
      }

      toast.success("Item cadastrado com sucesso!");
      setInformacao({})
     
      setOptionSelected({
        provedor: { id: 0, name: '' },
        material: { id: 0, name: '' }
      })

      setMaterialServices([])
      callback()
      setSending(false)
    } else {
      toast.error("Erro ao cadastrar item!");
      setSending(false)
    }
  }

  const update = (payload) => {
    if (!payload.id) return;
    setSending(true);
    const promise = InformacaoEntradaService.update(token, payload)

    if (promise) {
      if (promise.isAxiosError) {
        toast.error(promise.response.data || 'Erro inesperado ao editar item!')
        setSending(false)
        return
      }

      toast.success("Item editado com sucesso!")
      setInformacao({})
      setSending(false)

      setOptionSelected({
        provedor: { id: 0, name: '' },
        material: { id: 0, name: '' }
      })

      setMaterialServices([])
      callback()
    } else {
      toast.error("Erro ao cadastrar item!")
      setSending(false)
    }
  }

  const [isEdit, setIsEdit] = useState(false)
  useEffect(() => {
    if (current.id) {
      setInformacao(current)
      const dataProvedor = provedores.filter(item => item.id === current.provedorId)[0]

      if (!dataProvedor) {
        setMaterialServices([])

        setOptionSelected({
          provedor: { id: 0, name: '' },
          material: { id: 0, name: '' }
        })
        toast.error('Provedor não encontrado na base de Provedores!')
        return
      }

      setlistaCriteriosavaliacao(current.criteriosAvaliacao)
      setMaterialServices(dataProvedor.materialServices)

      const newData = {
        id: dataProvedor.id,
        name: dataProvedor.nome
      }

      setOptionSelected({
        provedor: newData,
        material: { id: 0, name: '' }
      })
      setIsEdit(true)
    }
  }, [current]);

  useEffect(() => {
    if (isEdit === true) {
      const dataMaterial = materialServices.filter(item => item.id === current.materialServicesId)[0]

      const newData = {
        id: dataMaterial.id,
        name: dataMaterial.name
      }

      setOptionSelected((values) => ({
        ...values,
        material: newData
      }))
      setIsEdit(false)
    }
    setlistaCriteriosavaliacao(listaCriteriosavaliacao)
  }, [isEdit]);


  const handleSelectedOptionProvedor = (id) => {
    const data = provedores.filter(item => item.id === id)[0]

    if (!data) {
      setMaterialServices([])

      setOptionSelected({
        provedor: { id: 0, name: '' },
        material: { id: 0, name: '' }
      })

      setInformacao({
        ...informacao,
        provedorId: 0,
        materialServicesId: 0
      })
      return
    }

    const newData = {
      id: data.id,
      name: data.nome
    }
    setOptionSelected({
      provedor: newData,
      material: { id: 0, name: '' }
    })

    setMaterialServices(data.materialServices)

    setInformacao({
      ...informacao,
      provedorId: data.id,
      materialServicesId: 0
    })
  }

  const handleSelectedOptionMaterial = (id) => {
    const data = materialServices.filter(item => item.id === id)[0]

    if (!data) {
      setOptionSelected((values) => ({
        ...values,
        material: { id: 0, name: '' }
      }))

      setInformacao({ ...informacao, materialServicesId: 0 })
      return
    }

    const newData = {
      id: data.id,
      name: data.name
    }
    setOptionSelected((values) => ({
      ...values,
      material: newData
    }))

    setInformacao({ ...informacao, materialServicesId: data.id })

    getCriteriosAvaliacao(data.id);
  }

  const getCriteriosAvaliacao = async (materialId) => {

    if (!materialId) return;
    const response = await InformacaoEntradaService.getCriteriosAvaliacao(token, materialId)

    if (response) {
      if (response.isAxiosError) {
        toast.error(response.response.data || 'Erro inesperado ao buscar critérios de avaliação!')
        return
      }
      let listaRetornada = response;
      setlistaCriteriosavaliacao(listaRetornada)
    }
  }

  const handleDropList = (key) => {
    setDropListSelect((dropListSelect !== key) ? key : '');
  }

  return (
    <>
      <div className="card-container">
        <div className="card">
          <div className="card-header">
            <h1>CADASTRO DE NOVO REGISTRO DE RECEBIMENTO</h1>
          </div>
          <div className="card-body newPaddingCard">
            <form
              className="form-container"
              onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-xs-6 col-md-4 col-lg-3">
                  <label className="global-label">Data</label>
                  <input
                    className="global-input"
                    type="date"
                    id="data"
                    value={formatDate(informacao.data) || ''}
                    placeholder="XX/XX/XXXX"
                    name="data"
                    onChange={handleChange}
                  />
                </div>
                <div className="col-xs-6 col-md-4 col-lg-3">
                  <label className="global-label">Provedor</label>
                  <div className="input-group-span">
                    <div className="input-list-wrap">
                      <input
                        disabled={true}
                        type="text"
                        id="provedorId"
                        name="provedorId"
                        className="global-input"
                        value={''}
                      />
                      <div className={"list-item d-inline-flex"}>
                        <span>{optionSelected.provedor.name
                          ? optionSelected.provedor.name
                          : 'Lista de provedores'}
                        </span>
                        <DropList
                          uniqid={'provedor'}
                          label="Lista de provedores"
                          selectable
                          defaultValue={optionSelected.provedor.id}
                          options={provedores}
                          selectedOption={handleSelectedOptionProvedor}
                          active={(dropListSelect === 'provedor')}
                          onClick={handleDropList}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-6 col-md-4 col-lg-3">
                  <label className="global-label">Material / Serviço</label>
                  <div className="input-group-span">
                    <div className="input-list-wrap">
                      <input
                        disabled={true}
                        type="text"
                        id="materialServicesId"
                        name="materialServicesId"
                        className="global-input"
                        value={''}
                      />
                      <div className={"list-item d-inline-flex"}>
                        <span style={{ width: '77%' }}>{optionSelected.material.name
                          ? optionSelected.material.name
                          : 'Lista de materiais / serviços'}
                        </span>
                        <DropList
                          uniqid={'material-servico'}
                          label="Lista de provedores"
                          selectable
                          defaultValue={optionSelected.material.id}
                          options={materialServices}
                          selectedOption={handleSelectedOptionMaterial}
                          active={(() => {
                            if (dropListSelect === 'material-servico' && optionSelected.provedor.id !== 0) {
                              return true
                            }
                          })()}
                          onClick={(e) => {
                            if (optionSelected.provedor.id === 0 || materialServices.length === 0) {
                              toast.warn("Selecione primeiro um Provedor!")
                            }
                            handleDropList(e)
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xs-6 col-md-4 col-lg-3">
                  <label className="global-label">Nº Nota Fiscal</label>
                  <input
                    className="global-input"
                    type="text"
                    id="notaFiscal"
                    value={informacao.notaFiscal || ''}
                    placeholder="número"
                    name="notaFiscal"
                    onChange={handleChange}
                  />
                </div>
              </div>

              <CriteriosAvaliacaoList informacao={informacao} handleChange={handleCriterios} optionSelected={optionSelected} listaCriteriosavaliacao={listaCriteriosavaliacao} />
              <button
                className="global-btn"
                disabled={sending && true}
              >
                {sending ? '' : informacao.id ? "SALVAR" : "CRIAR"}
                {sending && <FontAwesomeIcon icon={faCircleNotch} pulse />}
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
