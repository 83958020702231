import React, { memo } from "react";

import "./InputRadio.scss";

function InputRadio({ label, name, value, checked, onChange, required }) {
  let btn = null;
  const clickInputBar = (e) => {
    btn.click();
  }

  return (
    <>
      <div className={'InputRadio '+(checked == value ? 'active' : '')} onClick={clickInputBar}>
        <input
          className="checkIcon"
          type="radio"
          id={'radio-'+name+value}
          name={name}
          value={value}
          onChange={onChange}
          checked={checked == value && true}
          required={required || false}
          ref={input => btn = input}
        />
        <label style={{marginLeft: '23px'}} className="checkLabel" htmlFor={'radio-'+name+value}>{label}</label>
      </div>
    </>
  );
}

export default memo(InputRadio)
