import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  MODULES: BASE_URL + "modules",
};

export default class BuyerService {
  static getModules = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.MODULES;

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static handleStatusModule = async (token, idModule, status, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.MODULES;

    if (status) {
      url = url + "/remove-profile/" + idModule + "/" + id;
    } else {
      url = url + "/add-profile/" + idModule + "/" + id;
    }

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static handleStatusModuleUser = async (token, idModule, status, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.MODULES;

    if (status) {
      url = url + "/remove-user/" + idModule + "/" + id;
    } else {
      url = url + "/add-user/" + idModule + "/" + id;
    }

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };
}
