import React, { useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import Form from "./Form/informacaoEntradaForm";
import List from "./List/informacaoEntradaList";
import InformacaoEntradaService from "../../services/InformacaoEntradaService";
import MaterialsServicesService from "../../services/registrationMaterialsService";
import ProvedoresService from "../../services/ProvedoresService";
import Modal from "../../components/ModalExit";

import "./style.scss";

export default function Index(props) {
  const { token } = props.state;
  const [loading, setLoading] = useState(false);
  const [deleteID, setDeleteID] = useState(0);

  const defaultCurrent = {
    data: "",
    provedorId: 0,
    materialServicesId: 0,
    notaFiscal: "",
    atendimento: false,
    prazo: false,
    condicoesRecebidas: false,
    especificacoes: false,
    indice: ""
  }
  const [current, setCurrent] = useState(defaultCurrent);
  const [list, setList] = useState([]);

  const [materialServices, setMaterialServices] = useState([]);
  const [provedores, setProvedores] = useState([]);

  const _init = async () => {
    await MaterialsServicesService.getAll(token)
      .then((response) => {
        setMaterialServices(response.valores || []);
      });

    await InformacaoEntradaService.get(token)
      .then((response) => {
        setList(response || []);
        setLoading(false);
      });

    await ProvedoresService.get(token)
      .then((response) => {
        setProvedores(response.valores || []);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      });
  }

  const get = async (nome = '') => {
    setCurrent(defaultCurrent);
    setLoading(true);
    await _init();
  }

  const remove = (id) => {
    setDeleteID(0);

    InformacaoEntradaService.delete(token, id)
      .then((response) => {
        if (response) {
          toast.success("Item excluído com sucesso!");
          get();
        }
      })
      .catch((error) => {
        toast.error("Erro ao excluir item!");
        setLoading(false);
      });
  }

  useEffect(() => {
    setLoading(true);
    get();
  }, []);

  const formRef = useRef(null);

  const scrollToTop = () => formRef.current.scrollIntoView({ behavior: "smooth" })

  return (
    <div className="dashboard-container informacaoEntrada-container" ref={formRef}>
      {deleteID > 0 && (
        <Modal handleDelete={() => remove(deleteID)} handleModal={() => { setDeleteID(0) }} />
      )}
      {loading && (
        <div className={"loading-mask"}>
          <FontAwesomeIcon icon={faCircleNotch} pulse />
        </div>
      )}
      <Form
        token={token}
        current={current}
        callback={get}
        materialServices={materialServices}
        provedores={provedores}
      />
      <div className="row mt-2">
        <div className="col-md-12">
          <List
            list={list}
            remove={setDeleteID}
            edit={setCurrent}
            scrollToTop={scrollToTop}
          />
        </div>
      </div>
    </div>
  );
}