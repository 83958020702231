import React, { useState } from "react";

import OrganismosCertificadoresList from "./organismosCertificadoresList";
import OrganismosCertificadoresForm from "../Form/organismosCertificadoresForm";
import "../../dashboard/dashboard.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

export default function List({ loading, organismosCertificadores, token }) {
  const [updated, setUpdated] = useState();
  const [currentOrganismoCertificador, setCurrentOrganimoCertificador] = useState(null)
  const [searchInput, setSearchInput] = useState("");
  const [filter, setFilter] = useState("");
  const search = (value) => {
    setFilter(value)
  }

  const send = (value) => {
    if (value) {
      if (updated) 
        setUpdated(false)
      
        setCurrentOrganimoCertificador(null);
      return setUpdated(true)
    }

    return;
  }

  const organismoCertificador = (value) => {
    setCurrentOrganimoCertificador(value)
  }

  return (
    <>
      <OrganismosCertificadoresForm token={token} send={send} updated={updated} currentOrganismoCertificador={currentOrganismoCertificador} />

      <div className="user-list-search">
        <div className="user-list-search-item start">
          <input
            type="text"
              placeholder="Busque digitando aqui o nome do organismo certificador cadastrado"
              className="search-input start"
              name="search"
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
          />
          <button className="search-btn start"  onClick={() => search(searchInput)}>
            {loading && <FontAwesomeIcon icon={faCircleNotch} pulse />}
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </div>
      </div>

      <OrganismosCertificadoresList organismosCertificadores={organismosCertificadores} filter={filter} token={token} updated={updated} filter={filter} organismoCertificador={organismoCertificador} />
    </>
  );
}
