import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons";

export default function InformationTypeForm({
  props,
  loading,
  initialInformationType,
  send,
}) {
  const [informationType, setInformationType] = useState(initialInformationType);

  const handleChange = (event) => {
    event.persist();
    setInformationType((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  useEffect(() => {
    setInformationType(initialInformationType);
    // eslint-disable-next-line
  }, [initialInformationType]);

  const handleSubmit = (e) => {
    e.preventDefault();
    send(informationType);
  };

  const history = useHistory();

  return (
    <>
      <div className="user-config-container">
        <div className="card-container">
          <div className="card">
            <div className="card-header">
              <h1>Novo Tipo de informação</h1>
            </div>
            <div className="card-body">
              <form className="form-container" onSubmit={handleSubmit}>
                <div className="group-input">
                  <label>O Que</label>
                  <input
                    className="global-input"
                    type="text"
                    id="name"
                    name="name"
                    value={informationType.name || ""}
                    onChange={handleChange}
                  />
                </div>
                <button className="global-btn">
                  SALVAR{" "}
                  {loading && <FontAwesomeIcon icon={faCircleNotch} pulse />}
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="back-container">
          <button
            className="global-btn"
            onClick={() => {
              history.goBack();
            }}
          >
            <FontAwesomeIcon icon={faAngleDoubleLeft} /> VOLTAR{" "}
            {loading && <FontAwesomeIcon icon={faCircleNotch} pulse />}
          </button>
        </div>
      </div>
    </>
  );
}
