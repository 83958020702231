import React, { useState } from "react";

import EscopoAvaliacaoList from "./List/escopoAvaliacaoList";
import EscopoAvaliacaoForm from "./Form/escopoAvaliacaoForm";

import "../../dashboard/dashboard.scss";

export default function List({ initialEscopoAvaliacao, token, handleItem }) {
  const [updated, setUpdated] = useState();
  const [currentEscopoAvaliacao, setCurrentEscopoAvaliacao] = useState(null)

  const send = (value) => {
    if (value) {
      if (updated) 
        setUpdated(false)
      
        setCurrentEscopoAvaliacao(null);
      return setUpdated(true)
    }

    return;
  }

  const escopoAvaliacao = (value) => {
    setCurrentEscopoAvaliacao(value)
  }

  return (
    <>
      <EscopoAvaliacaoForm token={token} send={send} currentEscopoAvaliacao={currentEscopoAvaliacao} /> 
      <EscopoAvaliacaoList initialEscopoAvaliacao={initialEscopoAvaliacao} token={token} updated={updated} escopoAvaliacao={escopoAvaliacao} handleItem={handleItem}/>
    </>
  );
}
