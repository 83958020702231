import React, { useEffect, useState } from "react";
import { Switch } from "react-router-dom";
import { Route } from "react-router-dom";
import { useHistory, useLocation } from "react-router-dom";

import { AcessarClientes } from "../../routes/Pages";

import "./submodulosAdminBaseDados.scss";

export default function SubModulosAdminBaseDados(props) {
  const [submodulos, setSubmodulos] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const currentUrl = "/base-dados/";
  const state = { ...props };

  const changeSubmodule = (moduleUrl) => {
    history.push(currentUrl + moduleUrl);
  };

  useEffect(() => {
    const menuStorage = JSON.parse(localStorage.getItem("menuStorage"));
    const modulosAdmin = menuStorage.find(
      (a) => a.name == "Base de Dados"
    );
    setSubmodulos(modulosAdmin.subModules);
  }, []);

  const activeSubmodulos = (url) => {
    return (location.pathname == currentUrl + url) ? 'active' : '';
  }

  return (
    <section>
      <nav className="submodules-container">
        {submodulos &&
          submodulos.length > 0 &&
          submodulos.map((s, i) => (
            <button key={i} data-url={currentUrl + s.url} onClick={() => changeSubmodule(s.url)} className={activeSubmodulos(s.url)}>
                <div>
                  <img
                    src={require(`../../assets/icons/${s.icon}`)}
                  />
                  <a><b>{s.name}</b></a>
                </div>
            </button>
          ))}
      </nav>
      <div>
        <Switch>
          <Route
            path={currentUrl + "acessar-clientes"}
            render={() => <AcessarClientes {...props} />}
            state={state}
          />
        </Switch>
      </div>
    </section>
  );
}
