export const eTipoChaveBuscaProvedor = [{ id: 1, name: "Cnpj" }, { id: 2, name: "Matricula" }];
     
export const statusError = [400, 401, 404];

export const statusSuccess = [200, 201, 204];

export const MSG_SUCESSO_EXCLUIR = "Item excluído com sucesso!"
export const MSG_SUCESSO_CADASTRO = "Item cadastrado com sucesso!"
export const MSG_SUCESSO_EDICAO = "Item atualizado com sucesso!"
export const MSG_ERRO_VISUALIZAR_ARQUIVO = "Erro ao visualizar arquivo!"
export const MSG_ERRO_EXCLUIR = "Erro ao excluir item!"
export const MSG_ERRO_ATUALIZAR = "Erro ao atualizar item!"
export const MSG_ERRO_CADASTRO = "Erro ao cadastrar item!"
export const MSG_NAO_CADASTRADO = "Não cadastrado"