import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import CertificationTypesService from "../../../services/CertificationTypesService";

export default function CertificationTypesForm({ token, current, callback }) {
  const [certification, setCertification] = useState(current);
  const [sending, setSending] = useState(false);

  const handleChange = (e) => {
    e.persist();

    setCertification((values) => ({
      ...values,
      [e.target.name]: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (certification.id) {
      update(certification);
    } else {
      create(certification);
    }
  }

  const create = (payload) => {
    if (!payload.tema) {
      toast.error("Por favor, informe o tema");
      return;
    }
    if (!payload.nome) {
      toast.error("Por favor, informe o nome do tipo de certificação");
      return;
    }
    setSending(true);

    CertificationTypesService.create(token, payload)
      .then((response) => {
        if (response) {
          toast.success("Item cadastrado com sucesso!");
          setCertification({});
          setSending(false);
          callback();
        }
      })
      .catch((error) => {
        toast.error("Erro ao cadastrar item!");
        setSending(false);
      });
  }

  const update = (payload) => {
    if (!payload.id) return;
    if (!payload.tema) {
      toast.error("Por favor, informe o tema");
      return;
    }
    if (!payload.nome) {
      toast.error("Por favor, informe o nome do tipo de certificação");
      return;
    }
    setSending(true);

    CertificationTypesService.update(token, payload)
      .then((response) => {
        if (response) {
          toast.success("Item editado com sucesso!");
          setCertification({});
          setSending(false);
          callback();
        }
      })
      .catch((error) => {
        toast.error("Erro ao editar item!");
        setSending(false);
      });
  }

  useEffect(() => {
    if (current) {
      setCertification(current)
    }
  }, [current]);

  return (
    <>
      <div className="card-container">
        <div className="card">
          <div className="card-header">
            <h1>CADASTRO DE NOVO TIPO DE CERTIFICAÇÃO</h1>
          </div>
          <div className="card-body newPaddingCard">
            <form
              className="form-container"
              onSubmit={handleSubmit}>
              <div className="group-input created-form">
                <label className="newLabel"><b>Tema</b></label>
                <input
                  className="global-input"
                  type="text"
                  id="tema"
                  value={certification.tema || ''}
                  placeholder="Tema"
                  name="tema"
                  onChange={handleChange}
                />
                <label className="newLabel2"><b>O que</b></label>
                <input
                  className="global-input"
                  type="text"
                  id="nome"
                  value={certification.nome || ''}
                  placeholder="Nome do tipo de certificação"
                  name="nome"
                  onChange={handleChange}
                />
                <button
                  className="global-btn"
                  disabled={sending && true}
                >
                  {sending ? '' : certification.id ? "SALVAR" : "CRIAR"}
                  {sending && <FontAwesomeIcon icon={faCircleNotch} pulse />}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
