export default function FormataTelefone (telefone) {
    let telefoneFormatado = telefone.replace(/^(\d{2})(\d{5})(\d{4}).*/,"($1) $2-$3");
    return telefoneFormatado;
}

export  function dataIsMaiorQueHoje (data) {  
    var strData = data;
        var partesData = strData.split("/");
        var dataFormatada = new Date(partesData[2], partesData[1] - 1, partesData[0]);
        if (dataFormatada > new Date())
            return true;

        return false;
}

export function ValidateEmail(input) {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

    if (input.match(validRegex)) return true;
    else return false;
}

export function removerMascaraCnpj(cnpj) {
    return cnpj.replace(".", "").replace(".", "").replace("/", "").replace("-", "");
}

export function formataCnpj(cnpj){
    if(cnpj.length > 18)
        return cnpj.substring(0, 18);
    cnpj=cnpj.replace(/\D/g,"")
    cnpj=cnpj.replace(/^(\d{2})(\d)/,"$1.$2")
    cnpj=cnpj.replace(/^(\d{2})\.(\d{3})(\d)/,"$1.$2.$3")
    cnpj=cnpj.replace(/\.(\d{3})(\d)/,".$1/$2")
    cnpj=cnpj.replace(/(\d{4})(\d)/,"$1-$2")
    return cnpj
}