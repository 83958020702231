import React from "react";

import { removerMascaraCnpj } from "../../../../../components/Common/FormataCamposComMascara";
import { TOKENBUBBLE } from "../../../../../services/settings";

import './styles.scss'

export default function ConsultarCnpj({ cnpj }) {


    return (
        <div className="consultar-cnpj">
            <div className="card-container">
                <div className="card subcard">
                    <div className="card-header">
                        <h1>Consultar CNPJ</h1>
                    </div>

                    <div className="card-body">

                        <div className="list-container">
                            <div className="list">
                                <div className="list-body" >
                                    <iframe
                                        src={`https://the.ghostdevelopers.app.br/version-test/supply_consultas?cnpj=${removerMascaraCnpj(cnpj)}&token=${TOKENBUBBLE}`}
                                        width={"100%"}
                                        height={"900px"}
                                    >
                                    </iframe>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}