import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

export default function CertificationTypesDescription({ token }) {
  const [description, setDescription] = useState('');
  const [sending, setSending] = useState(false);

  const handleChange = (e) => {
    e.persist();

    setDescription(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
  }

  useEffect(() => {
    setDescription('Estou ciente e autorizo a verificação de autenticidade deste certificado, que visa evitar que certificados falsos ou adulterados sejam utilizados. Estou ciente de que esta prática é ilegal e configura crime de falsidade ideológia (Art. 299 do Código Penal - Decreto Lei 2848/40');
  }, []);

  return (
    <>
      <div className="card-container">
        <div className="card subcardDescription">
          <div className="card-header">
            <h1>Descrição do campo informação</h1>
          </div>
          <div className="card-body newPaddingCardDescription">
            <form
              className="form-container"
              onSubmit={handleSubmit}
            >
              <div className="group-input created-form justify-content-end">
                <textarea 
                  className="global-input"
                  onChange={handleChange}
                  value={description}
                ></textarea>
              </div>
              <div className="group-input created-form justify-content-end">
                <button
                  className="global-btn"
                  disabled={sending && true}
                >
                  {sending ? '' : description ? "SALVAR" : "CRIAR"}
                  {sending && <FontAwesomeIcon icon={faCircleNotch} pulse />}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
