import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons";

// import "../private-app/dashboard/dashboard.scss";

export default function GestaoDeIdoneidadeForm({
  props,
  loading,
  initialDoc,
  send,
}) {
  const [doc, setDoc] = useState(initialDoc);

  const handleChange = (event) => {
    event.persist();
    setDoc((values) => ({
      ...values,
      [event.target.name]: event.target.value,
    }));
  };

  useEffect(() => {
    setDoc(initialDoc);
    // eslint-disable-next-line
  }, [initialDoc]);

  const handleSubmit = (e) => {
    e.preventDefault();
    send(doc);
  };

  const history = useHistory();

  return (
    <>
      <div className="user-config-container">
        <div className="card-container">
          <div className="card">
            <div className="card-header">
              <h1>Novo Documento</h1>
            </div>
            <div className="card-body">
              <form className="form-container" onSubmit={handleSubmit}>
                <div className="group-input">
                  <label>Nome</label>
                  <input
                    className="global-input"
                    type="text"
                    id="name"
                    name="name"
                    value={doc.name || ""}
                    onChange={handleChange}
                  />
                </div>
                <div className="group-input radio">
                  <label>Upload obrigatório</label>
                  <div className={"inputs " +( doc.uploadRequired === "yes" ? "white" : "dark")}>
                    <input
                      type="radio"
                      name="uploadRequired"
                      value="yes"
                      onChange={handleChange}
                      checked={doc.uploadRequired === "yes" ? true : ""}
                    />
                    <span>Sim</span>
                  </div>
                  <div className={"inputs " +( doc.uploadRequired === "no" ? "white" : "dark")}>
                    <input
                      type="radio"
                      name="uploadRequired"
                      value="no"
                      onChange={handleChange}
                      checked={doc.uploadRequired === "no" ? true : ""}
                    />
                    <span>Nao</span>
                  </div>
                </div>
                <div className="group-input radio">
                  <label>Reposta por texto obrigatório</label>
                  <div className={"inputs " +( doc.answerRequired === "yes" ? "white" : "dark")}>
                    <input
                      type="radio"
                      name="answerRequired"
                      value="yes"
                      onChange={handleChange}
                      checked={doc.answerRequired === "yes" ? true : ""}
                    />
                    <span>Sim</span>
                  </div>
                  <div className={"inputs " +( doc.answerRequired === "no" ? "white" : "dark")}>
                    <input
                      type="radio"
                      name="answerRequired"
                      value="no"
                      onChange={handleChange}
                      checked={doc.answerRequired === "no" ? true : ""}
                    />
                    <span>Nao</span>
                  </div>
                </div>
                <button className="global-btn">
                  SALVAR{" "}
                  {loading && <FontAwesomeIcon icon={faCircleNotch} pulse />}
                </button>
              </form>
            </div>
          </div>
        </div>
        <div className="back-container">
          <button
            className="global-btn"
            onClick={() => {
              history.goBack();
            }}
          >
            <FontAwesomeIcon icon={faAngleDoubleLeft} /> VOLTAR{" "}
            {loading && <FontAwesomeIcon icon={faCircleNotch} pulse />}
          </button>
        </div>
      </div>
    </>
  );
}
