import React from "react";
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faUserPlus } from "@fortawesome/free-solid-svg-icons";

import Edit from "../assets/icons/editar.svg";

import "../private-app/dashboard/dashboard.scss";

export default function CreateCriteria({ loading, audit }) {
  return (
    <>
      <div className="btn-container">
        <Link to="/adicionar-criterios-auditoria/" className="global-white-btn">           
          <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14 10H10V14H4V10H0V4H4V0H10V4H14V10Z" fill="white"/>
          </svg>
          Criar novo Critério
        </Link>
      </div>
      <div className="user-list-container">
        <div className="list-container">
          <div className="list">
            <div className="list-header">
              <label>Nome</label>
              <label>Aprovado (%)</label>
              <label>Aprovado com Restrição (%)</label>
              <label>Reprovado (%)</label>
              <label></label>
            </div>

            {audit &&
              audit.length > 0 &&
              audit.map((a, index) => (
            <div className="list-body" key={index}>
              <span className="list-item">{a.name}</span>
              <span className="list-item">{a.approved}</span>
              <span className="list-item">{a.approvedRestriction}</span>
              <span className="list-item">{a.disapproved}</span>
              <span className="list-item">
                <a href={`criterios-auditoria/${a.id}`}>
                <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M24.8 7.2L23.4 6C22.6 5.3 21.5 5.4 20.8 6.1L7.1 19.5C7 19.6 6.8 19.8 6.8 20L5.5 24.9C5.4 25.3 5.7 25.6 6.1 25.5L10.9 24.2C11.1 24.1 11.3 24 11.4 23.9L25 10C25.7 9.2 25.7 8 24.8 7.2ZM6.7 24.4L7.6 21L10 23.5L6.7 24.4ZM10.9 23L8 20L18 10.3L20.7 13L10.9 23ZM21.5 12.2L18.8 9.5L19.5 8.8L22.2 11.5L21.5 12.2ZM24.2 9.3L23 10.6L20.4 8L21.5 6.9C21.8 6.6 22.3 6.6 22.7 6.9L24.2 8.2C24.6 8.4 24.6 9 24.2 9.3ZM10 20.2C9.8 20.4 9.8 20.8 10 21.1C10.2 21.3 10.6 21.3 10.9 21.1L18.7 13.3C18.9 13.1 18.9 12.7 18.7 12.4C18.5 12.2 18.1 12.2 17.8 12.4L10 20.2ZM31 22.2C31 27.1 27.1 31.1 22.1 31.1H8.9C4 31.1 0 27.2 0 22.2V8.9C0 4 3.9 0 8.9 0H22.2C27 0 31 3.9 31 8.9V22.2Z" fill="#4B4B4B"/>
                </svg>
                </a>
              </span>
            </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
}
