import React, { useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch, faPlus } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

import InputRadio from "../../../../components/InputRadio/InputRadio";
import EscopoAvaliacaoTema from "../../../../services/EscopoAvaliacaoTema";

export default function ThemeForm({ token, defaultCurrent, current, callback }) {
  const [tema, setTema] = useState(current);
  const [sending, setSending] = useState(false);

  const create = (payload) => {
    setSending(true);

    EscopoAvaliacaoTema.create(token, payload)
      .then((response) => {
        if (response) {
          toast.success("Item cadatrado com sucesso!");
          setTema(defaultCurrent);
          setSending(false);
          callback();
        }
      })
      .catch((error) => {
        toast.error("Erro ao cadatrar item!");
        setSending(false);
      });
  }

  const update = (payload) => {
    if (!payload.id) return;
    setSending(true);

    EscopoAvaliacaoTema.update(token, payload)
      .then((response) => {
        if (response) {
          toast.success("Item editado com sucesso!");
          setTema(defaultCurrent);
          setSending(false);
          callback();
        }
      })
      .catch((error) => {
        toast.error("Erro ao editar item!");
        setSending(false);
      });
  }

  const handleChange = (e) => {
    e.persist();

    setTema((values) => ({
      ...values,
      [e.target.name]: (e.target.type == 'radio') ? (e.target.value == '1' ? true : false) : e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (tema.id) {
      update(tema);
    } else {
      create(tema);
    }
  }

  useEffect(() => {
    if (current) {
      setTema(current)
    }
  }, [current]);

  return (
    <>
      <div className="card-container formTheme mb-2">
        <div className="card">
          <div className="card-body p-0">
            <form className="form-container" onSubmit={handleSubmit}>
              <div className="group-input created-form m-0">
                <input
                  className="global-input"
                  type="text"
                  id="name"
                  value={tema.name || ''}
                  placeholder={!tema.name ? "Insira aqui um novo tema" : "Edite aqui um nome do tema"}
                  name="name"
                  onChange={handleChange}
                />
                <button 
                  className="global-btn"
                  disabled={sending && true}
                >
                  {sending ? '' : tema.id ? "SALVAR" : "CRIAR"}
                  {sending && <FontAwesomeIcon icon={faCircleNotch} pulse />}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
} 