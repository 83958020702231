export default class DragAndDrop {

    constructor(elementDragged, elementType, elementId, eleDragged, listItens) {
        this.elementDragged = elementDragged;
        this.elementType = elementType;
        this.elementId = elementId;
        this.eleDragged = eleDragged;
        this.listItens = listItens;
    }

    static dragStart(e, data) {

        this.elementDragged = e.currentTarget
        this.elementType = this.elementDragged.attributes.datatype.value
        this.elementId = Number(this.elementDragged.attributes.id.value.split('-')[2])
        this.eleDragged = data

        this.elementDragged.classList.add('drag');

        this.listItens = document.querySelectorAll(`[datatype="${this.elementType}"]`);

        const idEle = this.elementId;

        [].forEach.call(this.listItens, function (item) {
            const id = Number(item.id.split('-')[2]);
            if (id !== idEle)
                item.classList.add('dragged');
        })
    };

    static dragEnter(e) {
        const id = Number(e.currentTarget.attributes.id.value.split('-')[2]);

        if (id === this.elementId) return;

        if (e.currentTarget.attributes.datatype.value === this.elementType) {
            e.currentTarget.classList.add('draggin-over');
        }
    };

    static dragLeave(e) {
        e.currentTarget.classList.remove('draggin-over');
    };

    static dragDrop(e, data) {
        
        if (e.currentTarget.attributes.datatype.value !== this.elementType) return

        const id = Number(e.currentTarget.attributes.id.value.split('-')[2]);
        const idElementDropped = this.elementId;

        if (isNaN(id) || id === idElementDropped) return;

        const ordem = this.eleDragged.ordem

        this.eleDragged.ordem = data.ordem
        data.ordem = ordem

        return [this.eleDragged, data]
    };

    static dragEnd(e) {
        this.elementDragged.classList.remove('drag');

        [].forEach.call(this.listItens, function (item) {
            item.classList.remove('dragged');
            item.classList.remove('draggin-over');
        });
    };
}