import axios from "axios";
import { BASE_URL } from "./settings";

const URLS = {
  URL: BASE_URL + "PadroesDesempenhos",
};

export default class TipoInformacaoDocumentadaService {
  static register = async (token, params) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let body = {
      nome: params.nome,
      meta: params.meta
    }

    try {
      const { data } = await axios.post(URLS.URL, body, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static create = async (token, groupMeterial) => {
    if(!groupMeterial.nome) return;
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.GROUP_MATERIAL_SERVICES;

    try {
      const { data } = await axios.post(url, groupMeterial, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static getAll = async (token) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.URL

    try {
      const { data } = await axios.get(url, config);
      return data;
    } catch (err) {
      return err;
    }
  };

  static update = async (token, params) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.URL
    try {
      const { data } = await axios.put(url, params, config);
      return data;
    } catch (err) {
      return err;
    }
  }

  static delete = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    let url = URLS.URL + "/" + id

    try {
      const response = await axios.delete(url, config);
      return response;
    } catch (err) {
      throw err;
    }
  };

  static download = async (token, id) => {
    let config = {
      headers: {
        Authorization: `Bearer ${token}`,
        responseType: 'blob',
      },
    };

    let url = `${URLS.URL}/Download?id=${id}`

    try {
      const blob = await fetch(url, config)
        .then(response => response.blob())
        .then(blob => blob)
      return blob
    } catch (err) {
      return err;
    }
  };

}